<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" title="Delega" scrollable @hidden="closeForm()" @close="closeForm()">
      <b-row>
        <b-col lg="2">
          <b-form-group label="Data">
            <b-form-input type="date" v-model="data.delegation.date"/>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group label="Delegato">
            <b-form-select v-model="data.delegation.employees_id" :options="data.employees" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group label="Delegante">
            <b-form-select v-model="data.delegation.employees_id_from" :options="data.employees" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Delega">
            <b-form-textarea v-model="data.delegation.delegation" rows="10" max-rows="20"/>
          </b-form-group>
        </b-col>
      </b-row>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.delegation.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "job",
  data() {
    return {
      show: false,
      data: null
    }
  },
  methods: {
    loadData( id ){
      this.show = false;
      this.openHttp("delegations_delegation", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("delegations_delegation_save", this.data.delegation, "Delega salvata!" ).then((result) => {
        if ( result.result == true ) this.closeForm(true);
      })
    },
    deleteData(){
      this.deleteHttp("delegations_delegation_delete", { id: this.data.delegation.id }, "Delega eliminata!" ).then((result) => {
        if ( result.result == true ) this.closeForm(true);
      })
    },
    closeForm(reload = false){
      this.show = false;
      this.data = null;
      if (reload === true) this.$emit('reload');
    }
  }
}
</script>