<template>
  <div>
    <doctoken ref="doctoken"/>
    <target ref="target" @reload="loadData"/>
    <b-modal v-model="showFilter" title="Filtra" @ok="filterSet">
      <b-form-group label="Obiettivo">
        <b-form-input type="text" v-model="tempFilter.target"/>
      </b-form-group>
      <b-form-group label="Anno">
        <b-form-select v-model="tempFilter.year" :options="comboSeeAll(filtersMenu.years)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Stato">
        <b-form-select v-model="tempFilter.archive" :options="comboSeeAll(archiveCombo)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Tipologia">
        <b-form-select v-model="tempFilter.type" :options="comboSeeAll(typeCombo)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Norma">
        <b-form-select v-model="tempFilter.standards_id" :options="comboSeeAll(filtersMenu.standards)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Mansione coinvolta">
        <b-form-select v-model="tempFilter.jobdescriptions_id" :options="comboSeeAll(filtersMenu.jobdescriptions)" value-field="id" text-field="val"/>
      </b-form-group>
    </b-modal>
    <b-btn class="mr-2" variant="primary" @click="loadData">Aggiorna</b-btn>
    <b-btn class="mr-2" variant="warning" @click="filterReset">Reset filtri</b-btn>
    <b-btn class="mr-2" variant="secondary" @click="filterOpen">Filtra</b-btn>
    <b-btn class="mr-2" variant="success" @click="$refs.target.loadData(0)">Nuovo</b-btn>
    <b-btn variant="info" v-show="items.record_id > 0" @click="$refs.doctoken.downloadRecord(items.record_id)">Scarica</b-btn>
    <b-table no-border-collapse :sticky-header="(winHeight - 205) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="items.data" :fields="fields" :busy="isBusy" :no-local-sorting="true" :sort-by.sync="search.sort.by" :sort-desc.sync="search.sort.desc" @sort-changed="sortingChange" @row-clicked="$refs.target.loadData($event.id)">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>Non ci sono risultati</template>
      <template v-slot:cell(monitoring)="row">
        <b-table-simple hover small responsive v-if="row.item.monitoringstargets !== null">
          <b-thead head-variant="dark">
            <b-tr>
              <b-th>Da</b-th>
              <b-th>A</b-th>
              <b-th>Percentuale</b-th>
              <b-th>Commento</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(item, index) of row.item.monitoringstargets" :key="index">
              <b-td>{{ item.from }}</b-td>
              <b-td>{{ item.to }}</b-td>
              <b-td>{{ item.val }}%</b-td>
              <b-td>{{ item.note }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <b-table-simple small responsive v-if="row.item.monitoringskpis !== null">
          <b-thead head-variant="dark">
            <b-tr>
              <b-th>Da</b-th>
              <b-th>A</b-th>
              <b-th>Obiettivo</b-th>
              <b-th v-for="(item, index) of row.item.monitoringskpis[0].kpis" :key="index">{{ index + 1}}° {{ row.item.monitoringskpis[0].key  }}</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(item, index) of row.item.monitoringskpis" :key="index">
              <b-td>{{ item.from }}</b-td>
              <b-td>{{ item.to }}</b-td>
              <b-td>{{ item.mustbe + item.valuetarget }}</b-td>
              <b-td v-for="(item2, index2) of item.kpis" :key="index2">{{ item2.kpi }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </template>
    </b-table>
    <tablebottom :perpage="search.page.perpage" @updatePerPage="search.page.perpage = $event" :currentpage="search.page.currentpage" @updateCurrentPage="search.page.currentpage = $event" :total="items.total" @loadData="loadData"/>
  </div>
</template>

<script>
import target from './target.vue'

export default {
  name: "targets",
  components: { target },
  data() {
    return {
      isBusy: true,
      showFilter: false,
      search: {
        page: {
          currentpage: 1,
          perpage: 50,
        },
        filters: {
          target: null,
          year: null,
          type: null,
          archive: false,
          standards_id: null,
          jobdescriptions_id: null,
        },
        sort: {
          by: 'kpiname',
          desc: false,
        },
      },
      tempFilter: {
        target: null,
        year: null,
        type: null,
        archive: false,
        standards_id: null,
        jobdescriptions_id: null,
      },
      filtersMenu: {
        years: [],
        standards: [],
        jobdescriptions: [],
      },
      typeCombo: [{
        id: false,
        val: "Obiettivo"
      },{
        id: true,
        val: "Indicatore"
      }],
      archiveCombo:[{
        id: false,
        val: "In corso"
      },{
        id: true,
        val: "Archiviato"
      }],
      fields: [{
        key: 'target',
        label: "Obiettivo",
        sortable: true
      },{
        key: 'standards',
        label: 'Norma',
      },{
        key: 'targettype',
        label: "Indicatore",
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          return value === false ? "Si" : "No";
        },
      },{
        key: 'jobdescriptions',
        label: 'Responsabilità',
      },{
        key: "monitoring",
        label: "Monitoraggio",
      }],
      items: {
        total: 0,
        data: [],
      }
    }  
  },
  computed: {
    token (){
      return this.$store.getters.getToken;
    },
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.isBusy = true;
      this.openHttp("targets_targets", this.search).then((result) => {
        this.items = result;
        this.isBusy = false;
      })
    },
    filterOpen(){
      this.openHttp("targets_targets_filters", {}).then((result) => {
        this.filtersMenu = result;
        this.showFilter = true;
      })
    },
    filterSet(){
      this.search.page.currentpage = 1;
      this.search.filters = this.tempFilter;
      this.showFilter = false;
      this.loadData();
    },
    filterReset(){
      this.search.page.currentpage = 1;
      this.tempFilter = {
        target: null,
        year: null,
        type: null,
        archive: false,
        standards_id: null,
        jobdescriptions_id: null,
      };
      this.filterSet();
    },
    sortingChange(ctx) {
      this.search.sort.by = ctx.sortBy;
      this.search.sort.desc = ctx.sortDesc;
      this.loadData();
    }
  }
}
</script>