<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" title="Template" size="xl" @hidden="closeForm()" @close="closeForm()">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Istruzione" active>
            <b-row>
              <b-col lg="8">
                <b-form-group label="Template">
                  <b-form-select v-model="data.instruction.templates_id" :options="data.templates" value-field="id" text-field="val" @change="changeParams($event)"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Nome">
                  <b-form-input v-model="data.instruction.val"/>
                </b-form-group> 
              </b-col>
            </b-row>
            <b-form-group>
              <b-table no-border-collapse sticky-header="500px" class="mt-3" :items="data.instruction.sqlparams" :fields="fields">
                <template v-slot:cell(val)="row">
                  <b-form-input size="sm" v-model="row.item.val"/>
                </template>
              </b-table>
            </b-form-group>
          </b-tab>
          <b-tab title="Norme e ateco" :disabled="data.instruction.id === 0">
            <b-table no-border-collapse :sticky-header="(winHeight - 315) + 'px'" :items="data.standardsnaces" :fields="fields2">
              <template v-slot:cell(naces)="row">
                <b-btn v-for="(item, index) of row.item.naces" :key="index" :variant="item.e === true ? 'success' : ''" size="sm" class="mr-1 mb-1" @click="savePointData( !item.e, row.item.id, [ item.id ] )">{{ item.val }}</b-btn>
              </template>
              <template v-slot:cell(all)="row">
                <nobr>
                  <b-btn variant="success" size="sm" class="mr-1" @click="savePointData( true, row.item.id, row.item.naces.map(x => x.id) )">Tutti</b-btn>
                  <b-btn variant="danger" size="sm" @click="savePointData( false, row.item.id, row.item.naces.map(x => x.id) )">Tutti</b-btn>
                </nobr>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="warning" class="mr-2" v-show="data.instruction.id > 0" @click="duplicateData">Duplicate</b-btn>
          <b-btn variant="danger" v-show="data.instruction.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "templateinstruction",
  data() {
    return {
      show: false,
      data: null,
      fields: [{
        key: "field",
        label: "Campo"
      },{
        key: "val",
        label: "Valore"
      }],
      fields2: [{
        key: "val",
        label: "Standard"
      },{
        key: "naces",
        label: "Ateco"
      },{
        key: "all",
        label: ""
      }]
    }  
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    loadData(id) {
      this.openHttp("templates_instruction", { id: id }).then((result) => {
        if ( this.data !== null ){
          this.data.standardsnaces = result.standardsnaces;
        } else {
          this.data = result;
        }
        this.show = true;
      })
    },
    changeParams(templatesId){
      this.data.instruction.sqlparams = [];
      this.data.templates.find(x => x.id === templatesId).params.map(x => x.col).forEach((item) => {
        if ( this.data.instruction.sqlparams.findIndex(x => x.field === item ) === -1 && item !== "companies_id" ){        
          this.data.instruction.sqlparams.push({ field: item, val: "?" });
        }
      });
    },
    savePointData( status, standardsId, naces ){
      this.saveHttp("templates_instructionpoint_save", { instruction_id: this.data.instruction.id, status: status, standards_id: standardsId, naces: naces }, "Punti salvati!" ).then((result) => {
        if ( result.result == true ) this.loadData( this.data.instruction.id );
      })
    },
    saveData(){
      this.saveHttp("templates_instruction_save", this.data.instruction, "Istruzione salvata!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    duplicateData(){
      this.data.instruction.id = 0;
    },
    deleteData(){
      this.deleteHttp("templates_instruction_delete", { id: this.data.instruction.id }, "Istruzione eliminata!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('reload');
    }
  }
}
</script>