<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" title="Legge" @hidden="closeForm" @close="closeForm">
      <b-row>
        <b-col lg="12">
          <b-form-group label="Area">
            <b-form-input v-model="data.law.area"/>
          </b-form-group>
        </b-col>
        <b-col lg="12">
          <b-form-group label="Legge">
            <b-form-input v-model="data.law.title"/>
          </b-form-group>
        </b-col>
        <b-col lg="12">
          <b-form-group label="Dettaglio">
            <b-form-textarea v-model="data.law.explanation" rows="6"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6">
          <b-form-group label="Valida da">
            <b-form-input type="date" v-model="data.law.datevalid"/>
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group label="Abrogazione / Cessazione">
            <b-form-input type="date" v-model="data.law.dateunvalid"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <b-form-group label="Link">
            <b-form-input v-model="data.law.link"/>
          </b-form-group>
        </b-col>
      </b-row>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.law.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "law",
  data() {
    return {
      show: false,
      data: null,
    }  
  },
  methods: {
    loadData(id){
      this.openHttp("laws_law", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("laws_law_save", this.data.law, "Legge salvata!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("laws_law_delete", { id: this.data.law.id }, "Legge eliminata!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('reload');
    }
  }
}
</script>