<template>
  <div>
    <calibrationsheet ref="calibrationsheet" @reload="loadData"/>
    <toolstype ref="toolstype" @reload="loadData"/>
    <doctoken ref="doctoken"/>
    <calibration ref="calibration" @reload="loadDataTools"/>
    <tool ref="tool" @reload="loadDataTools"/>
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Scadenze" active>
          <toolsdeadlines/>
        </b-tab>
        <b-tab title="Elenco strumenti">
          <b-modal v-model="tools.showFilter" title="Filtra" @ok="filterSetTools">
            <b-form-group label="Matricola / Identificativo">
              <b-form-input type="text" v-model="tools.tempFilter.tool"/>
            </b-form-group>
            <b-form-group label="Stato">
              <b-form-select v-model="tools.tempFilter.unactive" :options="comboSeeAll(tools.activeCombo)" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Tipologia di strumento">
              <b-form-select v-model="tools.tempFilter.toolstypes_id" :options="comboSeeAll(tools.filtersMenu.toolstypes)" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Tipologia di taratura">
              <b-form-select v-model="tools.tempFilter.calibrationsheets_id" :options="comboSeeAll(tools.filtersMenu.calibrationsheets)" value-field="id" text-field="val"/>
            </b-form-group>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadDataTools">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="warning" @click="filterResetTools">Reset filtri</b-btn>
          <b-btn class="mr-2" variant="secondary" @click="filterOpenTools">Filtra</b-btn>
          <b-btn class="mr-2" variant="success" @click="$refs.tool.loadData(0)">Nuovo</b-btn>
          <b-btn class="mr-2" variant="info" @click="excelOpen(1)">Excel Strumenti</b-btn>
          <b-btn class="mr-2" variant="info" @click="excelOpen(2)">Excel Tarature</b-btn>
          <b-btn variant="info" v-show="tools.items.record_id > 0" @click="$refs.doctoken.downloadRecord(tools.items.record_id)">Scarica</b-btn>
          <form ref="excelForm1" method="POST" target="_blank" action="excel.php?tools1">
            <input type="hidden" name="token" :value="token">
            <input type="hidden" name="filters" :value="filterComputed">
          </form>
          <form ref="excelForm2" method="POST" target="_blank" action="excel.php?tools2">
            <input type="hidden" name="token" :value="token">
          </form>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :tbody-tr-class="rowClassToolsDeadlines" :items="tools.items.data" :fields="tools.fields" :busy="tools.isBusy" :no-local-sorting="true" :sort-by.sync="tools.search.sort.by" :sort-desc.sync="tools.search.sort.desc" @sort-changed="sortingChangeTools" @row-clicked="$refs.tool.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
            <template v-slot:cell(nextdate)="row">
              <b-button variant="secondary" size="sm" @click="row.toggleDetails" class="mr-2">
                {{ row.detailsShowing ? 'Nascondi' : (checkCalibrations(row.item.calibrations) ? 'Scadute' : 'Regolari')}}
              </b-button>
            </template>
            <template v-slot:row-details="row">
              <b-table hover :items="row.item.calibrations" :fields="tools.fieldsDetails" @row-clicked="loadCalibration(0, $event.id, $event.calibrationsheets_id, $event.nextdate)" :tbody-tr-class="rowDetailClass"/>
            </template>
          </b-table>
          <tablebottom :perpage="tools.search.page.perpage" @updatePerPage="tools.search.page.perpage = $event" :currentpage="tools.search.page.currentpage" @updateCurrentPage="tools.search.page.currentpage = $event" :total="tools.items.total" @loadData="loadDataTools"/>
        </b-tab>
        <b-tab title="Elenco schede di calibrazione">
          <b-modal v-model="sheets.showFilter" title="Filtra" @ok="filterSetSheets">
            <b-form-group label="Tipologia calibratura">
              <b-form-input v-model="sheets.tempFilter.mainteinancesheet"/>
            </b-form-group>
            <b-form-group label="Strumento">
              <b-form-select v-model="sheets.tempFilter.tools_id" :options="comboSeeAll(sheets.filtersMenu.tools)" value-field="id" text-field="val"/>
            </b-form-group>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadDataSheets">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="warning" @click="filterResetSheets">Reset filtri</b-btn>
          <b-btn class="mr-2" variant="secondary" @click="filterOpenSheets">Filtra</b-btn>
          <b-btn class="mr-2" variant="success" @click="$refs.calibrationsheet.loadData(0)">Nuovo</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="sheets.items.data" :fields="sheets.fields" :busy="sheets.isBusy" :no-local-sorting="true" :sort-by.sync="sheets.search.sort.by" :sort-desc.sync="sheets.search.sort.desc" @sort-changed="sortingChangeSheets" @row-clicked="$refs.calibrationsheet.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
            <template v-slot:cell(count)="row">
              {{ row.item.tools.length }}
            </template>
            <template v-slot:cell(tools)="row">
              <b-button variant="primary" size="sm" @click="row.toggleDetails">
                {{ row.detailsShowing ? 'Nascondi' : 'Dettagli'}}
              </b-button>
            </template>
            <template v-slot:row-details="row">
              <b-table :items="row.item.tools" :fields="sheets.fieldsDetails"/>
            </template>
          </b-table>
          <tablebottom :perpage="sheets.search.page.perpage" @updatePerPage="sheets.search.page.perpage = $event" :currentpage="sheets.search.page.currentpage" @updateCurrentPage="sheets.search.page.currentpage = $event" :total="sheets.items.total" @loadData="loadDataSheets"/>
        </b-tab>
        <b-tab title="Elenco tipologie">
          <b-btn class="mr-2" variant="primary" @click="loadDataToolstypes">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="success" @click="$refs.toolstype.loadData(0)">Nuovo</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="toolstypes.items.data" :fields="toolstypes.fields" :busy="toolstypes.isBusy" :no-local-sorting="true" :sort-by.sync="toolstypes.search.sort.by" :sort-desc.sync="toolstypes.search.sort.desc" @sort-changed="sortingChangeToolstypes" @row-clicked="$refs.toolstype.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
          <tablebottom :perpage="toolstypes.search.page.perpage" @updatePerPage="toolstypes.search.page.perpage = $event" :currentpage="toolstypes.search.page.currentpage" @updateCurrentPage="toolstypes.search.page.currentpage = $event" :total="toolstypes.items.total" @loadData="loadDataToolstypes"/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import toolsdeadlines from './toolsdeadlines.vue'

import tool from './tool.vue';
import calibration from './calibration.vue';
import doctoken from "../doctoken";

import calibrationsheet from './calibrationsheet.vue'
import toolstype from './toolstype.vue'

export default {
  name: "tools",
  components: { toolsdeadlines, tool, calibration, doctoken, calibrationsheet, toolstype },
  data() {
    return {

      tools: {
        isBusy: true,
        showFilter: false,
        search: {
          page: {
            currentpage: 1,
            perpage: 50,
          },
          filters: {
            tool: null,
            unactive: false,
          },
          sort: {
            by: 'nextdate',
            desc: false,
          },
        },
        activeCombo: [{
          id: false,
          val: 'Attivo',
        },{
          id: true,
          val: 'Dismesso',
        },{
          id: null,
          val: 'Tutti',
        }],
        tempFilter: {
          tool: null,
          unactive: false,
          toolstypes_id: null,
          calibrationsheets_id: null,
        },
        filtersMenu: {
          toolstypes: [],
          calibrationsheets: [],
        },
        fields: [{
          key: 'machineriestype',
          label: 'Tipologia',
          sortable: true,
        },{
          key: 'serialnumber',
          label: "Identificativo",
          sortable: true,
        },{
          key: 'registrationnumber',
          label: "Matricola",
          sortable: true,
        },{
          key: 'unactive',
          label: 'Stato',
          sortable: true,
          formatter: value => {
            return value ? 'Non attivo' : 'Attivo';
          }
        },{
          key: 'location',
          label: 'Ubicazione',
          sortable: true,
        },{
          key: 'leader',
          label: 'Tipologia',
          sortable: true,
          formatter: value => {
            return value ? 'Primario' : 'Secondario';
          }
        },{
          key: 'nextdate',
          label: 'Tarature',
          sortable: true
        }],
        fieldsDetails: [{
          key: 'calibrationsheet',
          label: 'Taratura',
        },{
          key: 'nextdate',
          label: "Prossima",
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'lastdate',
          label: "Ultima",
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        }],
        items: {
          total: 0,
          data: [],
        },
      },
      sheets: {
        isBusy: true,
        showFilter: false,
        search: {
          page: {
            currentpage: 1,
            perpage: 50,
          },
          filters: {},
          sort: {
            by: 'mainteinancesheet',
            desc: false,
          },
        },
        tempFilter: {
          mainteinancesheet: null,
          tools_id: null,
        },
        filtersMenu: {
          tools: [],
        },
        fields: [{
          key: 'mainteinancesheet',
          label: 'Taratura',
          sortable: true,
        },{
          key: 'count',
          label: "Totali",
        },{
          key: 'external',
          label: "Tipologia",
          sortable: true,
          formatter: value => {
            return value ? 'Esterna' : 'Interna';
          }
        },{
          key: 'periodicity',
          label: "Periodicità",
          formatter: value => {
            return value.replace("year", "anni").replace("month", "mesi").replace("week", "settimane");
          }
        },{
          key: 'tools',
          label: "Strumenti",
        }],
        fieldsDetails: [{
          key: "tool",
          label: "Strumento",
        }],
        items: {
          total: 0,
          data: [],
        }, 
      },
      toolstypes: {
        isBusy: true,
        search: {
          page: {
            currentpage: 1,
            perpage: 50,
          },
          sort: {
            by: 'machineriestype',
            desc: false,
          },
        },
        fields: [{
          key: 'machineriestype',
          label: 'Tipologia',
          sortable: true,
        },{
          key: 'tools',
          label: "Strumenti",
        }],
        items: {
          total: 0,
          data: [],
        },
      }
    }  
  },
  created(){
    this.loadData();
  },
  computed: {
    token (){
      return this.$store.getters.getToken;
    },
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
    filterComputed(){
      return JSON.stringify(this.tools.search.filters);
    }
  },
  methods: {
    loadData(){
      this.loadDataTools();
      this.loadDataSheets();
      this.loadDataToolstypes();
    },
    checkCalibrations(calibrations){
      var today = new Date();
      let expired = false;
      for (let item of calibrations){
        let d = new Date(item.nextdate);
        if (d.getTime() <= today.getTime()){
          expired = true;
        }
      }
      return expired;
    },
    rowClassToolsDeadlines(row) {
      if (row === null) return "";
      let today = new Date();
      let nextdate = new Date(row.nextdate);
      if (nextdate.getTime() <= today.getTime() && row.nextdate !== null && row.calibrations.length > 0) return "table-danger";
    },
    rowDetailClass(item) {
      let today = new Date();
      let d = new Date(item.nextdate);
      return 'table-' + (d.getTime() <= today.getTime() ? 'danger' : 'success');
    },
    loadDataTools(){
      this.tools.isBusy = true;
      this.openHttp("tools_tools2", { search: this.tools.search }).then((result) => {
        this.tools.items = result;
        this.tools.isBusy = false;
      })
    },
    filterOpenTools(){
      this.openHttp("tools_tools_filters", { }).then((result) => {
        this.tools.filtersMenu = result;
        this.tools.showFilter = true;
      })
    },
    filterSetTools(){
      this.tools.search.page.currentpage = 1;
      this.tools.showFilter = false;
      this.tools.search.filters = this.tools.tempFilter;
      this.loadDataTools();
    },
    filterResetTools(){
      this.tools.tempFilter = {
        tool: null,
        unactive: false,
        toolstypes_id: null,
        calibrationsheets_id: null,
      };
      this.filterSetTools();
    },
    sortingChangeTools(ctx) {
      this.tools.search.sort.by = ctx.sortBy;
      this.tools.search.sort.desc = ctx.sortDesc;
      this.loadDataTools();
    },
    loadCalibration(id, toolsId, calibrationsheetsId, date){
      this.$refs.calibration.loadData(id, toolsId, calibrationsheetsId, date);
    },
    excelOpen(num){
      this.$refs["excelForm" + num].submit();
    },
    loadDataSheets(){
      this.sheets.isBusy = true;
      this.openHttp("tools_calibrationssheets2", { search: this.sheets.search }).then((result) => {
        this.sheets.items = result;
        this.sheets.isBusy = false;
      })
    },
    filterOpenSheets(){
      this.openHttp("tools_calibrationssheets_filters", { }).then((result) => {
        this.sheets.filtersMenu = result;
        this.sheets.showFilter = true;
      })
    },
    filterSetSheets(){
      this.sheets.showFilter = false;
      this.tools.search.page.currentpage = 1;
      this.sheets.search.filters = this.sheets.tempFilter;
      this.loadDataSheets();
    },
    filterResetSheets(){
      this.sheets.tempFilter = {
        mainteinancesheet: null,
        tools_id: null,
      }
      this.filterSetSheets();
    },
    sortingChangeSheets(ctx) {
      this.sheets.search.sort.by = ctx.sortBy;
      this.sheets.search.sort.desc = ctx.sortDesc;
      this.loadDataSheets();
    },
    loadDataToolstypes(){
      this.toolstypes.isBusy = true;
      this.openHttp("tools_toolstypes2", { search: this.toolstypes.search }).then((result) => {
        this.toolstypes.items = result;
        this.toolstypes.isBusy = false;
      })
    },
    sortingChangeToolstypes(ctx) {
      this.toolstypes.search.sort.by = ctx.sortBy;
      this.toolstypes.search.sort.desc = ctx.sortDesc;
      this.loadDataToolstypes();
    }
  }
}
</script>