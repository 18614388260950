<template>
  <div>
    <knowledge ref="knowledge" @reload="loadData()"/>
    <doctoken ref="doctoken"/>
    <b-modal v-model="showFilter" title="Filtra" @ok="filterSet">
      <b-form-group label="Conoscenza">
        <b-form-input type="text" v-model="tempFilter.knowledge" />
      </b-form-group>
      <b-form-group label="Tipologia">
        <b-form-select v-model="tempFilter.external" :options="filtersMenu.external" value-field="id" text-field="val"/>
      </b-form-group>
    </b-modal>
    <b-btn class="mr-2" variant="primary" @click="loadData">Aggiorna</b-btn>
    <b-btn class="mr-2" variant="warning" @click="filterReset">Reset filtri</b-btn>
    <b-btn class="mr-2" variant="secondary" @click="showFilter = true">Filtra</b-btn>
    <b-btn class="mr-2" variant="success" @click="loadItem(0)">Nuovo</b-btn>
    <b-btn variant="info" v-show="items.record_id > 0" @click="$refs.doctoken.downloadRecord(items.record_id)">Scarica</b-btn>
    <b-table no-border-collapse :sticky-header="(winHeight - 215) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="items.data" :fields="fields" :busy="isBusy" :no-local-sorting="true" :sort-by.sync="search.sort.by" :sort-desc.sync="search.sort.desc" @sort-changed="sortingChange" @row-clicked="loadItem($event.id)">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>Non ci sono risultati</template>
    </b-table>
    <tablebottom :perpage="search.page.perpage" @updatePerPage="search.page.perpage = $event" :currentpage="search.page.currentpage" @updateCurrentPage="search.page.currentpage = $event" :total="items.total" @loadData="loadData"/>
  </div>
</template>

<script>
import knowledge from './knowledge.vue'

export default {
  name: "knowledges",
  components: { knowledge },
  data() {
    return {
      isBusy: true,
      showFilter: false,
      search: {
        page: {
          currentpage: 1,
          perpage: 50,
        },
        filters: {},
        sort: { by: 'knowledge', desc: false, },
      },
      tempFilter: {
        process: null,
        external: null,
      },
      filtersMenu: {
        external: [{
          id: null,
          val: "Tutti"
        },{
          id: false,
          val: "Interni"
        },{
          id: true,
          val: "Esterni"
        }],
      },
      fields: [{
        key: 'knowledge',
        label: 'Conoscenza',
        sortable: true,
      },{
        key: 'external',
        label: 'Tipologia',
        sortable: true,
        formatter: value => {
          return value ? 'Esterna' : 'Interna';
        }
      },{
        key: 'dateknowledge',
        label: "Data",
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'owners',
        label: "Possessori",
        sortable: false
      }],
      items: {
        total: 0,
        data: [],
      }
    }  
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.isBusy = true;
      this.openHttp("knowledges_knowledges", { search: this.search }).then((result) => {
        this.items = result;
        this.isBusy = false;
      })
    },
    filterSet(){
      this.showFilter = false;
      this.search.filters = this.tempFilter;
      this.loadData();
    },
    filterReset(){
      this.tempFilter = {
        knowledge: null,
        external: null,
      };
      this.filterSet();
    },
    sortingChange(ctx) {
      this.search.sort.by = ctx.sortBy;
      this.search.sort.desc = ctx.sortDesc;
      this.loadData();
    },
    loadItem(id){
      this.$refs.knowledge.loadData(id);
    }
  }
}
</script>