<template>
  <div>
    <b-modal v-model="show" v-if="data !== null" size="lg" title="Visita medica" scrollable @hidden="closeForm" @close="closeForm">
      <b-modal v-model="showUploadModal" title="Carica idoneità alla mansione" :hide-footer="true">
        <b-form-file placeholder="Scegli un file o trascinalo qui..." drop-placeholder="Trascinalo qui..." @change="saveAttachment($event)"/>
      </b-modal>
      <b-form-group label="Visita medica">
        <b-form-select v-model="data.medical.trainingskinds_id" :options="data.trainingskinds" value-field="id" text-field="val"/>
      </b-form-group>
      <b-row>
        <b-col lg="8">
          <b-form-group label="Medico / Ente">
            <b-form-input v-model="data.medical.teacher"/>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group label="Data">
            <b-form-input type="date" v-model="data.medical.verifydate"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-show="data.medical.id > 0">
        <b-col lg="12">
          <b-table hover :items="data.medical.certificates" :fields="fieldsCertificates">
            <template v-slot:cell(file)="row">
              <b-btn size="sm" variant="primary" class="mr-2" v-if="row.item.file === false" @click="employeesUpload(row.item.id)">Carica</b-btn>
              <b-btn size="sm" variant="info" class="mr-2" v-if="row.item.file == true" @click="employeesAttachmentDownload(row.item.id)">Scarica</b-btn>
              <b-btn size="sm" variant="danger" v-if="row.item.file == true" @click="employeesAttachmentDelete(row.item.id)">Elimina</b-btn>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <b-form-group label="Personale">
            <multiselect v-model="data.medical.employeesSupport" :options="data.employees" placeholder="" selectLabel="" deselectLabel="" :multiple="true" track-by="id" label="val" @input="data.medical.employees = updateCombo(data.medical.employeesSupport)"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Note">
        <b-form-textarea v-model="data.medical.note"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="info" class="mr-2" v-show="data.medical.id > 0" @click="downloadData">Scarica</b-btn>
          <b-btn variant="danger" v-show="data.medical.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
      <form ref="downloadForm" method="POST" target="_blank" action="/api/file.php">
        <input type="hidden" name="form" value="medicals">
        <input type="hidden" name="document" value="medical">
        <input type="hidden" name="id" :value="data.medical.id">
        <input type="hidden" name="token" :value="token">
      </form>
      <form ref="downloadAttachmentForm" method="POST" target="_blank" action="/api/file.php">
        <input type="hidden" name="form" value="medicals">
        <input type="hidden" name="document" value="attachmentmedical">
        <input type="hidden" name="id" ref="downloadAttachmentFormId">
        <input type="hidden" name="token" :value="token">
      </form>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "medical",
  data() {
    return {
      show: false,
      data: null,
      uploadEmployeesId: -1,
      showUploadModal: false,
      fieldsCertificates: [{
        key: 'fullname',
        label: "Idoneità alla mansione",
        sortable: true,
      },{
        key: 'file',
        label: "",
      },],
    }
  },
  computed: {
    token (){
      return this.$store.getters.getToken;
    }
  },
  methods: {
    loadData(id, trainingsKindsId, employees, date){
      this.openHttp("medicals_medical", { id: id, trainingskinds_id: trainingsKindsId, employees: employees, date: date }).then((result) => {
        result.medical.employeesSupport = this.convertCombo(result.employees, result.medical.employees);
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("medicals_medical_save", this.data.medical, "Visita medica salvata!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("medicals_medical_delete", { id: this.data.medical.id }, "Visita medica eliminata!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    downloadData(){
      this.$refs.downloadForm.submit();
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('reload');
    },
    employeesUpload(id){
      this.showUploadModal = true;
      this.uploadEmployeesId = id;
    },
    employeesAttachmentDownload(id){
      this.$refs.downloadAttachmentFormId.value = id;
      this.$refs.downloadAttachmentForm.submit();
    },
    saveAttachment(event){
      this.showUploadModal = false;
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = ()=> {
        this.saveHttp("medicals_medical_attachment_save", { id: this.uploadEmployeesId, filename: event.target.files[0].name, base64: reader.result }, "Certificato salvato!" ).then((result) => {
          if ( result.result == true ) this.loadData(this.data.medical.id, null, null, null);
        })
      };
    },
    employeesAttachmentDelete(id){
      this.deleteHttp("medicals_medical_attachment_delete", { id: id }, "Certificato eliminato!" ).then((result) => {
          if ( result.result == true ) this.loadData(this.data.medical.id, null, null, null);
      })
    },
  }
}
</script>