<template>
  <div>
    <tool ref="tool" @reload="loadData"/>
    <calibration ref="calibration" @reload="loadData"/>
    <b-btn variant="primary" @click="loadData">Aggiorna</b-btn>
    <b-table bordered hover responsive show-empty :sticky-header="(winHeight - 265) + 'px'" class="mt-3" :busy="isBusy" :items="items" :fields="fields">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>Non ci sono risultati</template>
      <template v-slot:cell(val)="row">
        <nobr @click="loadTool(row.item.id)">{{ row.value }}</nobr>
      </template>
      <template v-slot:cell(expired)="row">
        <b-button v-for="(item, index) of row.value" :key="index" variant="danger" size="sm" class="mr-2 mb-2" @click="loadCalibration(0, row.item.id, item.id, item.date)">{{ item.val + ' ' + dateFormatter(item.date) }}</b-button>
      </template>
      <template v-slot:cell(month0)="row">
        <b-button v-for="(item, index) of row.value" :key="index" variant="warning" size="sm" class="mr-2 mb-2" @click="loadCalibration(0, row.item.id, item.id, item.date)">{{ item.val + ' ' + dateFormatterShort(item.date) }}</b-button>
      </template>
      <template v-slot:cell(month1)="row">
        <b-button v-for="(item, index) of row.value" :key="index" variant="success" size="sm" class="mr-2 mb-2" @click="loadCalibration(0, row.item.id, item.id, item.date)">{{ item.val + ' ' + dateFormatterShort(item.date) }}</b-button>
      </template>
      <template v-slot:cell(month2)="row">
        <b-button v-for="(item, index) of row.value" :key="index" variant="success" size="sm" class="mr-2 mb-2" @click="loadCalibration(0, row.item.id, item.id, item.date)">{{ item.val }}</b-button>
      </template>
      <template v-slot:cell(month3)="row">
        <b-button v-for="(item, index) of row.value" :key="index" variant="success" size="sm" class="mr-2 mb-2" @click="loadCalibration(0, row.item.id, item.id, item.date)">{{ item.val }}</b-button>
      </template>
      <template v-slot:cell(month4)="row">
        <b-button v-for="(item, index) of row.value" :key="index" variant="success" size="sm" class="mr-2 mb-2" @click="loadCalibration(0, row.item.id, item.id, item.date)">{{ item.val }}</b-button>
      </template>
      <template v-slot:cell(month5)="row">
        <b-button v-for="(item, index) of row.value" :key="index" variant="success" size="sm" class="mr-2 mb-2" @click="loadCalibration(0, row.item.id, item.id, item.date)">{{ item.val }}</b-button>
      </template>
      <template v-slot:cell(month6)="row">
        <b-button v-for="(item, index) of row.value" :key="index" variant="success" size="sm" class="mr-2 mb-2" @click="loadCalibration(0, row.item.id, item.id, item.date)">{{ item.val }}</b-button>
      </template>
      <template v-slot:cell(month7)="row">
        <b-button v-for="(item, index) of row.value" :key="index" variant="success" size="sm" class="mr-2 mb-2" @click="loadCalibration(0, row.item.id, item.id, item.date)">{{ item.val }}</b-button>
      </template>
      <template v-slot:cell(month8)="row">
        <b-button v-for="(item, index) of row.value" :key="index" variant="success" size="sm" class="mr-2 mb-2" @click="loadCalibration(0, row.item.id, item.id, item.date)">{{ item.val }}</b-button>
      </template>
      <template v-slot:cell(month9)="row">
        <b-button v-for="(item, index) of row.value" :key="index" variant="success" size="sm" class="mr-2 mb-2" @click="loadCalibration(0, row.item.id, item.id, item.date)">{{ item.val }}</b-button>
      </template>
      <template v-slot:cell(month10)="row">
        <b-button v-for="(item, index) of row.value" :key="index" variant="success" size="sm" class="mr-2 mb-2" @click="loadCalibration(0, row.item.id, item.id, item.date)">{{ item.val }}</b-button>
      </template>
      <template v-slot:cell(month11)="row">
        <b-button v-for="(item, index) of row.value" :key="index" variant="success" size="sm" class="mr-2 mb-2" @click="loadCalibration(0, row.item.id, item.id, item.date)">{{ item.val }}</b-button>
      </template>
      <template v-slot:cell(month12)="row">
        <b-button v-for="(item, index) of row.value" :key="index" variant="success" size="sm" class="mr-2 mb-2" @click="loadCalibration(0, row.item.id, item.id, item.date)">{{ item.val }}</b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
import tool from './tool.vue'
import calibration from './calibration.vue'

export default {
  name: "toolsdeadlines",
  components: { tool, calibration },
  data() {
    return {
      isBusy: true,
      fields: [{
        key: "val",
        label: "Strumento",
        sortable: true,
      },{
        key: 'mindate',
        label: 'Prossima',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'expired',
        label: "Scadute",
      },{
        key: "month0",
        label: "",
      },{
        key: 'month1',
        label: '',
      },{
        key: 'month2',
        label: '',
      },{
        key: 'month3',
        label: '',
      },{
        key: 'month4',
        label: '',
      },{
        key: 'month5',
        label: '',
      },{
        key: 'month6',
        label: '',
      },{
        key: 'month7',
        label: '',
      },{
        key: 'month8',
        label: '',
      },{
        key: 'month9',
        label: '',
      },{
        key: 'month10',
        label: '',
      },{
        key: 'month11',
        label: '',
      },{
        key: 'month12',
        label: '',
      }],
      items: [],
    }  
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    dateFormatter(date){
      let tempDate = new Date(date);
      return [tempDate.getDate(), tempDate.getMonth() + 1, tempDate.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
    },
    dateFormatterShort(date){
      let tempDate = new Date(date);
      return [tempDate.getDate(), tempDate.getMonth() + 1].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
    },
    loadData(){
      let i;
      let today = new Date();
      for (i = 0; i <= 12; i++) {
        this.fields[i + 3].label = [today.getMonth() + 1, today.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        today.setMonth( today.getMonth() + 1);
      }
      this.isBusy = true;
      this.openHttp("tools_deadlines", { }).then((result) => {
        this.isBusy = false;
        this.items = result;
      })
    },
    loadTool(id){
      this.$refs.tool.loadData(id);
    },
    loadCalibration(id, toolsId, calibrationsheetsId, date){
      this.$refs.calibration.loadData(id, toolsId, calibrationsheetsId, date);
    },
  }
}
</script>