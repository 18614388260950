<template>
  <div>
    <doctoken ref="doctoken"/>
    <customer ref="customer" @reload="loadData"/>
    <customerevaluation ref="customerevaluation" @reload="loadData"/>
    <customerproduct ref="customerproduct" @reload="loadData"/>
    <customerquestion ref="question" @reload="loadData"/>
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Elenco clienti" active>
          <b-modal v-model="customers.showFilter" title="Filtra" @ok="filterSetCustomers()">
            <b-form-group label="Cliente">
              <b-form-input v-model="customers.tempFilter.customer"/>
            </b-form-group>
            <b-form-group label="Attivo">
              <b-form-select v-model="customers.tempFilter.unactive" :options="customers.activeCombo" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Prodotto">
              <b-form-select v-model="customers.tempFilter.products_id" :options="customers.filtersMenu.products" value-field="id" text-field="val"/>
            </b-form-group>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadDataCustomers">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="warning" @click="filterResetCustomers">Reset filtri</b-btn>
          <b-btn class="mr-2" variant="secondary" @click="filterOpenCustomers">Filtra</b-btn>
          <b-btn class="mr-2" variant="success" @click="$refs.customer.loadData(0)">Nuovo</b-btn>
          <b-btn class="mr-2" variant="info" v-if="document != null && document.records_id > 0" @click="$refs.doctoken.downloadRecord(document.records_id)">Scarica Documento {{ document.date }}</b-btn>
          <b-btn class="mr-2" variant="success" @click="generateDocument()">Genera Documento</b-btn>
          <b-btn class="mr-2" variant="primary">ISC: {{ customers.items.avg }}</b-btn>
          <b-btn variant="info" v-show="customers.items.record_id > 0" @click="$refs.doctoken.downloadRecord(customers.items.record_id)">Scarica</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="customers.items.data" :fields="customers.fields" :busy="customers.isBusy" :no-local-sorting="true" :sort-by.sync="customers.search.sort.by" :sort-desc.sync="customers.search.sort.desc" @sort-changed="sortingChangeCustomers" @row-clicked="$refs.customer.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
            <template v-slot:cell(index)="row">
              <b-btn type="button" size="sm" v-if="row.item.evaluationyesno == true" variant="info" @click="evaluateCustomer(row.item.id)">Valuta</b-btn>
            </template>
          </b-table>
          <tablebottom :perpage="customers.search.page.perpage" @updatePerPage="customers.search.page.perpage = $event" :currentpage="customers.search.page.currentpage" @updateCurrentPage="customers.search.page.currentpage = $event" :total="customers.items.total" @loadData="loadDataCustomers"/>
        </b-tab>
        <b-tab title="Prodotti venduti">
          <b-btn class="mr-2" variant="primary" @click="loadDataProducts">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="success" @click="$refs.customerproduct.loadData(0)">Nuovo</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="products.items.data" :fields="products.fields" :busy="products.isBusy" :no-local-sorting="true" :sort-by.sync="products.search.sort.by" :sort-desc.sync="products.search.sort.desc" @sort-changed="sortingChangeProducts" @row-clicked="$refs.customerproduct.loadData($event.id)">
          <template v-slot:table-busy>
            <div class="text-center text-primary">
              <b-spinner class="align-middle"/>
            </div>
          </template>
          <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
          <tablebottom :perpage="products.search.page.perpage" @updatePerPage="products.search.page.perpage = $event" :currentpage="products.search.page.currentpage" @updateCurrentPage="products.search.page.currentpage = $event" :total="products.items.total" @loadData="loadDataProducts"/>
        </b-tab>
        <b-tab title="Criteri di valutazione">
          <b-btn class="mr-2" variant="primary" @click="loadDataQuestions">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="success" @click="loadQuestion(0)">Nuovo</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="questions.items.data" :fields="questions.fields" :busy="questions.isBusy" :no-local-sorting="true" :sort-by.sync="questions.search.sort.by" :sort-desc.sync="questions.search.sort.desc" @sort-changed="sortingChangeQuestions" @row-clicked="loadQuestion($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
          <tablebottom :perpage="questions.search.page.perpage" @updatePerPage="questions.search.page.perpage = $event" :currentpage="questions.search.page.currentpage" @updateCurrentPage="questions.search.page.currentpage = $event" :total="questions.items.total" @loadData="loadDataQuestions"/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import customer from './customer.vue'
import customerevaluation from './customerevaluation.vue'
import customerproduct from './customerproduct.vue'
import customerquestion from './customerquestion.vue'

export default {
  name: "customers",
  components: { customer, customerevaluation, customerproduct, customerquestion },
  data() {
    return {
      customers: {
        isBusy: true,
        showFilter: false,
        search: {
          page: {
            currentpage: 1,
            perpage: 50,
          },
          filters: {
            customer: null,
            unactive: false,
            products_id: null,
          },
          sort: {
            by: 'customer',
            desc: false,
          },
        },
        tempFilter: {
          customer: null,
          unactive: false,
          products_id: null,
        },
        activeCombo: [{
          id: null,
          val: "Tutto",
        },{
          id: false,
          val: "Attivo",
        },{
          id: true,
          val: "Non Attivo",
        },],  
        filtersMenu: {
          products: [],
        },
        fields: [{
          key: "customer",
          label: "Cliente",
          sortable: true,
        },{
          key: "unactive",
          label: "Attivo",
          sortable: true,
          formatter: value => {
            return value ? 'Non attivo' : 'Attivo';
          }
        },{
          key: 'products',
          label: "Prodotti",
        },{
          key: 'importance',
          label: 'Importanza',
          sortable: true,
          formatter: value => {
            return value == 0 ? 'Bassa' : ( value == 1 ? 'Media' : ( value == 2 ? 'Alta' : 'Non valutato' ) );
          }
        },{
          key: 'dateeval',
          label: 'Data',
          sortable: true,
          formatter: (value, key, item) => {
            if (item.evaluationyesno === true ){
              if (value == null) return "Da valutare";
              var d = new Date(value);
              return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
            } else {
              return "Non valutare";
            }
          }
        },{
          key: 'evaluation',
          label: 'Valutazione',
          sortable: true,
          formatter: (value, key, item) => {
            if (item.evaluationyesno === true ){
              if (value == null) return "Da valutare";
              return value;
            } else {
              return "Non valutare";
            }
          }
        },{
          key: 'index',
          label: "",
        }],
        items: {
          total: 0,
          data: [],
        },
      },
      products: {
        isBusy: true,
        search: {
          page: {
            currentpage: 1,
            perpage: 50,
          },
          filters: {},
          sort: {
            by: 'product',
            desc: false,
          },
        },
        fields: [{
          key: 'product',
          label: 'Prodotto',
          sortable: true,
        },{
          key: 'customers',
          label: 'Clienti',
        }],
        items: {
          total: 0,
          data: [],
        },
      },
      questions: {
        isBusy: true,
        search: {
          page: {
            currentpage: 1,
            perpage: 50,
          },
          filters: {},
          sort: {
            by: 'criteria',
            desc: false,
          },
        },
        fields: [{
          key: 'criteria',
          label: 'Criterio',
          sortable: true,
        },{
          key: 'indirect',
          label: 'Tipologia',
          sortable: true,
          formatter: value => {
            return value ? 'Indiretto' : 'Diretto';
          }
        },{
          key: 'criteriaweight',
          label: "Peso",
          sortable: true
        }],
        items: {
          total: 0,
          data: [],
        },
      },
      document: null
    }  
  },
  computed: {
    token (){
      return this.$store.getters.getToken;
    },
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.loadDataCustomers();
      this.loadDataProducts();
      this.loadDataQuestions();
    },
    loadDataCustomers(){
      this.customers.isBusy = true;
      this.openHttp("customers_customers", this.customers.search).then((result) => {
        this.customers.items = result;
        this.customers.isBusy = false;
        this.loadDocument();
      })
    },
    loadDocument(){
      this.openHttp("customers_document", null).then((result) => {
        this.document = result;
      })
    },
    generateDocument(){
      this.generateDocumentHttp("customers");
    },
    filterOpenCustomers(){
      this.openHttp("customers_customers_filters", {}).then((result) => {
        this.customers.filtersMenu = result;
        this.customers.showFilter = true;
      })
    },
    filterSetCustomers(){
      this.customers.search.filters = this.customers.tempFilter;
      this.customers.showFilter = false;
      this.loadDataCustomers();
    },
    filterResetCustomers(){
      this.customers.tempFilter = {
        customer: null,
        unactive: false,
        products_id: null,
      };
      this.filterSetCustomers();
    },
    sortingChangeCustomers(ctx) {
      this.customers.search.sort.by = ctx.sortBy;
      this.customers.search.sort.desc = ctx.sortDesc;
      this.loadDataCustomers();
    },
    evaluateCustomer(id){
      let d = new Date();
      this.$refs.customerevaluation.loadData(id, [d.getFullYear(), d.getMonth() + 1, d.getDate()].map(n => n < 10 ? `0${n}` : `${n}`).join('-'));
    },
    excelOpenCustomers(){
      this.$refs.excelForm.submit();
    },
    downloadOpenCustomers(){
      this.$refs.downloadForm.submit();
    },
    loadDataProducts(){
      this.products.isBusy = true;
      this.openHttp("customers_products", this.products.search).then((result) => {
        this.products.items = result;
        this.products.isBusy = false;
      })
    },
    sortingChangeProducts(ctx) {
      this.products.search.sort.by = ctx.sortBy;
      this.products.search.sort.desc = ctx.sortDesc;
      this.loadDataProducts();
    },
    loadDataQuestions(){
      this.questions.isBusy = true;
      this.openHttp("customers_questions", this.questions.search).then((result) => {
        this.questions.items = result;
        this.questions.isBusy = false;
      })
    },
    sortingChangeQuestions(ctx) {
      this.questions.search.sort.by = ctx.sortBy;
      this.questions.search.sort.desc = ctx.sortDesc;
      this.loadDataQuestions();
    },
    loadQuestion(id){
      this.$refs.question.loadData(id);
    },
  }
}
</script>