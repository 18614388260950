<template>
  <div>
    <document ref="document" @reload="loadData()"/>
    <b-modal v-model="showFilter" title="Filtra" @ok="filterSet">
      <b-form-group label="Documento">
        <b-form-input type="text" v-model="tempFilter.document"/>
      </b-form-group>
      <b-form-group label="Origine">
        <b-form-select v-model="tempFilter.internal" :options="comboSeeAll(intenalCombo)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Tipologia">
        <b-form-select v-model="tempFilter.documentskinds_id" :options="comboSeeAll(filtersMenu.documentskinds)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Archiviazione">
        <b-form-select v-model="tempFilter.archived" :options="comboSeeAll(archiveCombo)" value-field="id" text-field="val"/>
      </b-form-group>
    </b-modal>
    <b-btn variant="primary" class="mr-2" @click="loadData">Aggiorna</b-btn>
    <b-btn variant="warning" class="mr-2" @click="filterReset">Reset filtri</b-btn>
    <b-btn variant="secondary" class="mr-2" @click="filterOpen">Filtra</b-btn>
    <b-btn variant="success" class="mr-2" @click="loadItem(0)">Nuovo</b-btn>
    <b-btn variant="success" class="mr-2" v-show="items.canapprove === true" @click="approveAll">Approva Tutto</b-btn>
    <b-btn class="mr-2" variant="info" v-if="document != null && document.records_id > 0" @click="$refs.doctoken.downloadRecord(document.records_id)">Scarica Documento {{ document.date }}</b-btn>
    <b-btn class="mr-2" variant="success" @click="generateDocument()">Genera Documento</b-btn>
    <b-btn variant="info"  @click="$refs.doctoken.downloadAll()">Scarica Tutto</b-btn>
    <doctoken ref="doctoken"/>
    <b-table no-border-collapse :sticky-header="(winHeight - 215) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :tbody-tr-class="rowClass" :items="items.data" :fields="fields" :busy="isBusy" :no-local-sorting="true" :sort-by.sync="search.sort.by" :sort-desc.sync="search.sort.desc" @sort-changed="sortingChange" @row-clicked="loadItem($event.id)">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>Non ci sono risultati</template>
      <template v-slot:cell(download)="row">
        <b-btn variant="info" size="sm" v-if="row.item.revisions_id > 0 && row.item.records_id == null" @click="$refs.doctoken.downloadRevision(row.item.revisions_id)">Scarica</b-btn>
        <b-btn variant="info" size="sm" v-if="row.item.records_id > 0" @click="$refs.doctoken.downloadRecord(row.item.records_id)">Scarica</b-btn>
      </template>
    </b-table>
    <tablebottom :perpage="search.page.perpage" @updatePerPage="search.page.perpage = $event" :currentpage="search.page.currentpage" @updateCurrentPage="search.page.currentpage = $event" :total="items.total" @loadData="loadData"/>
  </div>
</template>

<script>
import document from './document.vue'

export default {
  name: "documents",
  components: { document },
  data() {
    return {
      showFilter: false,
      intenalCombo: [{
        id: true,
        val: "Interno"
      },{
        id: false,
        val: "Esterno"
      }],
      archiveCombo: [{
        id: false,
        val: "Non archiviato"
      },{
        id: true,
        val: "Archiviato"
      }],
      fields: [{
        key: "documentskind",
        label: "Tipologia",
        sortable: true,
      },{
        key: "code",
        label: "Codice",
        sortable: true,
      },{
        key: 'document',
        label: 'Nome',
        sortable: true,
      },{
        key: "internal",
        label: "Origine",
        sortable: true,
        formatter: value => {
          return value === true ? "Interna" : "Esterna";
        }
      },{
        key: 'createdate',
        label: 'Revisione',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'approveddate',
        label: 'Approvazione',
        sortable: true,
        formatter: (value, key, item) => {
          if (item.needapproval == false ){
            return 'Non necessaria';
          } else {
            if (value == null) return "Da approvare";
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        }
      },{
        key: 'expirydate',
        label: 'Scadenza',
        sortable: true,
        formatter: value => {
          if (value == null) return 'Non scade';
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'recorddate',
        label: 'Registrazione',
        sortable: true,
        formatter: value => {
          if (value == null) return "";
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: "download",
        label: "",
      }],
      filtersMenu: {
        documentskinds: [],
        internal: [],
        archived: [],
      },
      isBusy: true,
      items: {
        total: 0,
        canapprove: false,
        data: [],
      },    
      search:{
        page: {
          currentpage: 1,
          perpage: 50
        },
        filters: {
          document: null,
          documentskinds_id: null,
          internal: null,
          archived: false,
        },
        sort: {
          by: 'code',
          desc: false,
        },
      },
      tempFilter: {
        document: null,
        documentskinds_id: null,
        internal: null,
        archived: false,
      },
      document: null 
    }  
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    approveAll(){
      this.saveHttp("documents_document_approve_all", {}, "Documenti approvati!" ).then((result) => {
        if ( result.result == true ) this.loadData();
      })
    },
    rowClass(item) {
      if ( item !== null && item.needapproval == true && item.approveddate === null) return 'table-danger';
      if ( item !== null && item != null && item.expirydate != null){
        let today = new Date();
        let dateeval = new Date(item.expirydate);
        if (today.getTime() >= dateeval.getTime()) return 'table-danger';
      }
    },
    loadData(){
      this.isBusy = true;
      this.openHttp("documents_documents", { search: this.search }).then((result) => {
        this.isBusy = false;
        this.items = result;
        this.loadDocument();
      })
    },
    loadDocument(){
      this.openHttp("documents_documentdocument", null).then((result) => {
        this.document = result;
      })
    },
    generateDocument(){
      this.generateDocumentHttp("documents");
    },
    filterOpen(){
      this.openHttp("documents_documents_filters", { }).then((result) => {
        this.showFilter = true;
        this.filtersMenu = result;
      })
    },
    filterSet(){
      this.showFilter = false;
      this.search.filters = this.tempFilter;
      this.loadData();
    },
    filterReset(){
      this.search.page.currentpage = 1;
      this.tempFilter = {
        document: null,
        documentskinds_id: null,
        internal: null,
        archived: false,
      };
      this.filterSet();
    },
    sortingChange(ctx) {
      this.search.sort.by = ctx.sortBy;
      this.search.sort.desc = ctx.sortDesc;
      this.loadData();
    },
    loadItem(id){
      this.$refs.document.loadData(id);
    },
  }
}
</script>