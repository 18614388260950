<template>
  <div>
    <doctoken ref="doctoken"/>
    <organizationchartdetail ref="organizationchart" @reload="loadData"/>
    <b-btn class="mr-2" variant="primary" @click="loadData">Aggiorna</b-btn>
    <b-btn class="mr-2" variant="success" @click="loadItem(0)">Nuovo</b-btn>
    <b-table no-border-collapse :sticky-header="(winHeight - 205) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="items.data" :fields="fields" :busy="isBusy" :no-local-sorting="true" :sort-by.sync="search.sort.by" :sort-desc.sync="search.sort.desc" @sort-changed="sortingChange" @row-clicked="loadItem($event.id)">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>Non ci sono risultati</template>
      <template v-slot:cell(revisionfile)="row">
        <b-btn variant="info" size="sm" v-show="row.value > 0" @click="$refs.doctoken.downloadRevision(row.value)">Scarica</b-btn>
      </template>
    </b-table>
    <tablebottom :perpage="search.page.perpage" @updatePerPage="search.page.perpage = $event" :currentpage="search.page.currentpage" @updateCurrentPage="search.page.currentpage = $event" :total="items.total" @loadData="loadData"/>
  </div>
</template>

<script>
import organizationchartdetail from './organizationchartdetail.vue'

export default {
  name: "organizationchart",
  components: { 
    organizationchartdetail
  },
  data() {
    return {
      isBusy: true,
      search:{
        page: {
          currentpage: 1,
          perpage: 50
        },
        filters: { },
        sort: {
          by: 'code',
          desc: false
        },
      },
      fields: [{
        key: 'code',
        label: 'Codice',
      },{
        key: 'document',
        label: 'Documento',
      },{
        key: 'createdate',
        label: 'Creazione',
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'approveddate',
        label: 'Approvazione',
        formatter: (value, key, item) => {
          if (item.needapproval == false ){
            return 'Non necessaria';
          } else {
            if (value == null) return "Da approvare";
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        }
      },{
        key: 'revisionfile',
        label: "",
      }],
      items: {
        total: 0,
        data: [],
      },
    }  
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.isBusy = true;
      this.openHttp("organizationchart_organizationchart", { search: this.search }).then((result) => {
        this.items = result;
        this.isBusy = false;
      })
    },
    sortingChange(ctx) {
      this.search.sort.by = ctx.sortBy;
      this.search.sort.desc = ctx.sortDesc;
      this.loadData();
    },
    loadItem(id){
      this.$refs.organizationchart.loadData(id);
    }
  }
}
</script>