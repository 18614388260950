<template>
  <div>
    <b-modal v-if="data != null" v-model="show" size="lg" title="Non conformità" scrollable @hidden="closeForm" @close="closeForm">
      <b-card no-body>
        <b-tabs v-model="tabsId" card>
          <b-tab title="Apertura" active>
            <b-row>
              <b-col lg="2">
                <b-form-group label="Progressivo">
                  <b-form-input v-model="data.nonconformity.progressive" :disabled="data.nonconformity.id == 0"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Data apertura">
                  <b-form-input v-model="data.nonconformity.dateopening" type="date"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Responsabile apertura">
                  <b-form-select v-model="data.nonconformity.employeesopen_id" :options="comboNull(data.employees)" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Norme">
                  <multiselect v-model="data.nonconformity.standardsSupport" :options="data.standards" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.nonconformity.standards = updateCombo(data.nonconformity.standardsSupport)"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Descrizione">
              <b-form-textarea v-model="data.nonconformity.description"/>
            </b-form-group>
            <b-row>
              <b-col lg="8">
                <b-form-group label="Analisi delle cause">
                  <b-form-textarea v-model="data.nonconformity.causestext"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Note apertura">
                  <b-form-textarea v-model="data.nonconformity.noteopen"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-alert variant="warning" show v-if="data.nonconformity.id === 0 && data.companies_id === 39">      
              <b-row>
                <b-col lg="2">
                  <b-form-group label="Inizio compito">
                    <b-form-input type="date" v-model="data.nonconformity.fromdate"/>
                  </b-form-group>
                </b-col>
                <b-col lg="2">
                  <b-form-group label="Scadenza compito">
                    <b-form-input type="date" v-model="data.nonconformity.todate"/>
                  </b-form-group>
                </b-col>
                <b-col lg="2">
                  <b-form-group label="Alert con email?">
                    <b-form-select v-model="data.nonconformity.alert" :options="alertTaskCombo" value-field="id" text-field="val"/>
                  </b-form-group>
                </b-col>
                <b-col lg="6">
                  <b-form-group label="Personale coinvolto">
                    <multiselect v-model="data.nonconformity.employeesSupport" :options="data.employees" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.nonconformity.employees = updateCombo(data.nonconformity.employeesSupport)"/>
                  </b-form-group>
                </b-col>
                <b-col lg="12">
                  <b-form-group :label="'Compito da fare' + ((data.nonconformity.tododescription === null || data.nonconformity.tododescription === '') ? ' Obbligatorio!!!' : '')">
                    <b-form-textarea v-model="data.nonconformity.tododescription"/>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-alert>    
            <b-row>
              <b-col lg="4">
                <b-form-group label="Processi">
                  <multiselect v-model="data.nonconformity.processesSupport" :options="data.processes" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.nonconformity.processes = updateCombo(data.nonconformity.processesSupport)"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Clienti">
                  <multiselect v-model="data.nonconformity.customersSupport" :options="data.customers" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.nonconformity.customers = updateCombo(data.nonconformity.customersSupport)"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Fornitori">
                  <multiselect v-model="data.nonconformity.suppliersSupport" :options="data.suppliers" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.nonconformity.suppliers = updateCombo(data.nonconformity.suppliersSupport)"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4">
                <b-form-group label="Prodotti">
                  <multiselect v-model="data.nonconformity.productsSupport" :options="data.products" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.nonconformity.products = updateCombo(data.nonconformity.productsSupport)"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Difetti">
                  <multiselect v-model="data.nonconformity.defectsSupport" :options="data.defects" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.nonconformity.defects = updateCombo(data.nonconformity.defectsSupport)"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Cause">
                  <multiselect v-model="data.nonconformity.causesSupport" :options="data.causes" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.nonconformity.causes = updateCombo(data.nonconformity.causesSupport)"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Reclamo / Allegati">
              <b-btn v-show="data.nonconformity.id == 0" variant="info">Prima di caricare dei files è necessario salvare</b-btn>
              <div v-if="data.nonconformity.id > 0">
                <b-btn variant="info" size="sm" class="mr-2 mb-2" v-b-modal.modalFiltersComplaints>Carica reclamo</b-btn>
                <b-modal v-model="showModalComplaint" id="modalFiltersComplaints" title="Carica reclamo" :hide-footer="true">
                  <b-form-file placeholder="Scegli un file o trascinalo qui..." drop-placeholder="Trascinalo qui..." @change="saveComplaint($event)"/>
                </b-modal>
                <b-dropdown v-for="(item, index) of data.files" :key="index" dropright id="dropdown-1" variant="secondary" size="sm" class="mr-2 mb-2" :text="item.file">
                  <b-dropdown-item @click="downloadDataComplaint(item.file)">Scarica</b-dropdown-item>
                  <b-dropdown-item @click="deleteComplaint(item.file)">Elimina</b-dropdown-item>
                </b-dropdown>
              </div>
            </b-form-group>
          </b-tab>
          <b-tab title="Avanzamento" v-if="data.nonconformity.progress !== null">
            <b-form-group label="Avanzamento">
              <b-form-textarea v-model="data.nonconformity.progress" rows="3"/>
            </b-form-group>
          </b-tab>
          <b-tab title="Trattamento">
            <b-row>
              <b-col lg="2">
                <b-form-group label="Data apertura">
                  <b-form-input v-model="data.nonconformity.datetreatment" type="date"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Responsabile Trattamento">
                  <b-form-select v-model="data.nonconformity.employeestreatment_id" :options="comboNull(data.employees)" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Trattamento">
              <b-form-textarea v-model="data.nonconformity.treatment" rows="3"/>
            </b-form-group>
          </b-tab>
          <b-tab title="Compiti / Avanzamento" :disabled="data.nonconformity.id === 0">
            <nonconformitytask ref="task" @reload="loadData(data.nonconformity.id, data.nonconformity.auditsanswers_id)"/>
            <b-btn variant="primary" size="sm" @click="loadTask(0)">Nuovo</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty class="mt-3" :items="data.tasks" :fields="fieldsTasks" @row-clicked="loadTask($event.id)">
              <template v-slot:empty>Non ci sono Compiti</template>
            </b-table>
          </b-tab>
          <b-tab title="Chiusura" :disabled="data.nonconformity.id === 0">
            <b-row>
              <b-col lg="2">
                <b-form-group label="Data chiusura">
                  <b-form-input v-model="data.nonconformity.dateclosure" type="date"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Responsabile chiusura">
                  <b-form-select v-model="data.nonconformity.employeesclose_id" :options="comboNull(data.employees)" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Costo (€)">
                  <b-form-input v-model="data.nonconformity.cost" step="0.01" min="0" type="number"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Note chiusura">
              <b-form-textarea v-model="data.nonconformity.noteclose" rows="2"/>
            </b-form-group>
            <b-row v-if="data.nonconformity.correctiveaction == null">
              <b-col lg="12">
                <b-btn variant="info" @click="openCorrectiveAction">Apri azione correttiva</b-btn>
              </b-col>
            </b-row>
            <div v-if="data.nonconformity.correctiveaction != null">
              <hr>
              <b-row>
                <b-col lg="2">
                  <b-form-group label="Data apertura">
                    <b-form-input type="date" v-model="data.nonconformity.correctiveaction.dateopen" required/>
                  </b-form-group>
                </b-col>
                <b-col lg="2">
                  <b-form-group label="Data obiettivo">
                    <b-form-input type="date" v-model="data.nonconformity.correctiveaction.datetarget" required/>
                  </b-form-group>
                </b-col>
                <b-col lg="4">
                  <b-form-group label="Responsabile apertura">
                    <b-form-select v-model="data.nonconformity.correctiveaction.employeesopen_id" :options="comboNull(data.employees)" value-field="id" text-field="val"/>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group label="Azione correttiva">
                <b-form-textarea v-model="data.nonconformity.correctiveaction.descriptionopen"/>
              </b-form-group>
              <b-row>
                <b-col lg="2">
                  <b-form-group label="Data verifica">
                    <b-form-input type="date" v-model="data.nonconformity.correctiveaction.dateverify"/>
                  </b-form-group>
                </b-col>
                <b-col lg="2">
                  <b-form-group label="Data chiusura">
                    <b-form-input type="date" v-model="data.nonconformity.correctiveaction.dateclose"/>
                  </b-form-group>
                </b-col>
                <b-col lg="4">
                  <b-form-group label="Responsabile chiusura">
                    <b-form-select v-model="data.nonconformity.correctiveaction.employeesclose_id" :options="comboNull(data.employees)" value-field="id" text-field="val"/>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group label="Evidenza del risultato">
                <b-form-textarea v-model="data.nonconformity.correctiveaction.descriptionclose"/>
              </b-form-group>
              <b-row>
                <b-col lg="2">
                  <b-form-group label="Esito">
                    <b-form-select v-model="data.nonconformity.correctiveaction.success" :options="successCombo" value-field="id" text-field="val"/>
                  </b-form-group>
                </b-col>
                <b-col lg="2">
                  <b-form-group label="Costo (€)">
                    <b-form-input type="number" v-model="data.nonconformity.correctiveaction.cost"/>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="12">
                  <b-btn variant="warning" @click="deleteCorrectiveAction">Cancellare azione correttiva</b-btn>
                </b-col>
              </b-row>
            </div>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData" :disabled="data.companies_id === 39 && data.nonconformity.id === 0 && (data.nonconformity.tododescription === null || data.nonconformity.tododescription === '')">Salva</b-btn>
          <b-btn variant="primary" class="mr-2" v-show="data.nonconformity.id > 0" @click="downloadData">Scarica</b-btn>
          <b-btn variant="danger" v-show="data.nonconformity.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
      <form ref="downloadForm" method="POST" target="_blank" action="/api/file.php">
        <input type="hidden" name="form" value="nonconformities">
        <input type="hidden" name="document" value="nonconformity">
        <input type="hidden" name="id" :value="data.nonconformity.id">
        <input type="hidden" name="token" :value="token">
      </form>
      <form ref="downloadComplaintForm" method="POST" target="_blank" action="/api/file.php">
        <input type="hidden" name="form" value="nonconformities">
        <input type="hidden" name="document" value="complaint">
        <input type="hidden" name="id" :value="data.nonconformity.id">
        <input type="hidden" name="filename" ref="fileComplaint" value="">
        <input type="hidden" name="token" :value="token">
      </form>
    </b-modal>
  </div>
</template>

<script>
import nonconformitytask from './nonconformitytask.vue'

export default {
  name: "nonconformity",
  components: { nonconformitytask },
  data() {
    return {
      show: false,
      data: null,
      tabsId: 0,
      showModalComplaint: false,
      successCombo: [{
        id: null,
        val: "In corso"
      },{
        id: true,
        val: "Conclusa con esito positivo"
      },{
        id: false,
        val: "Conclusa con esito negativo"
      }],
      fieldsTasks: [{
        key: 'fromdate',
        label: 'Inizio',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'todate',
        label: 'Scadenza',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'tododescription',
        label: 'Da fare',
      },{
        key: 'donedescription',
        label: 'Fatto',
      },{
        key: "done",
        label: "Concluso?",
        formatter: value => {
          return this.successCombo.find(x => x.id === value).val;
        }
      },{
        key: 'alert',
        label: 'Alert?',
        formatter: value => {
          return value === true ? "Si" : "No";
        }
      },{
        key: 'employees',
        label: 'Personale',
      }],
      alertTaskCombo: [{
        id: false,
        val: "Nessun alert Mail"
      },{
        id: true,
        val: "Alert per Mail"
      }],
      comboDoneTaskCombo: [{
        id: false,
        val: "In corso"
      },{
        id: true,
        val: "Concluso"
      }],
      taskItem: null,
      taskShow: false,
    }
  },
  computed: {
    token (){
      return this.$store.getters.getToken;
    },
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    loadTask(id){
      this.$refs.task.loadData(id, this.data.nonconformity.id);
    },
    openCorrectiveAction(){
      var today = new Date();
      this.data.nonconformity.correctiveaction = {
        id: 0,
        employeesopen_id: this.data.nonconformity.employeesopen_id,
        employeesclose_id: this.data.nonconformity.employeesclose_id,
        dateopen: [today.getFullYear(), today.getMonth() + 1, today.getDate()].map(n => n < 10 ? `0${n}` : `${n}`).join('-'),
        dateclose: null,
        datetarget: [today.getFullYear(), today.getMonth() + 1, today.getDate()].map(n => n < 10 ? `0${n}` : `${n}`).join('-'),
        dateverify: [today.getFullYear(), today.getMonth() + 1, today.getDate()].map(n => n < 10 ? `0${n}` : `${n}`).join('-'),
        descriptionopen: null,
        descriptionclose: null,
        success: null,
        cost: 0,
      };
    },
    deleteCorrectiveAction(){
      this.tabsId = 0;
      this.data.nonconformity.correctiveaction = null;
    },
    loadData(id, auditsanswers_id, ){
      this.openHttp("nonconformities_nonconformity", { id: id, auditsanswers_id: auditsanswers_id }).then((result) => {
        if ( this.data === null ){
          result.nonconformity.standardsSupport = this.convertCombo(result.standards, result.nonconformity.standards);
          result.nonconformity.processesSupport = this.convertCombo(result.processes, result.nonconformity.processes);
          result.nonconformity.customersSupport = this.convertCombo(result.customers, result.nonconformity.customers);
          result.nonconformity.suppliersSupport = this.convertCombo(result.suppliers, result.nonconformity.suppliers);
          result.nonconformity.productsSupport = this.convertCombo(result.products, result.nonconformity.products);
          result.nonconformity.defectsSupport = this.convertCombo(result.defects, result.nonconformity.defects);
          result.nonconformity.causesSupport = this.convertCombo(result.causes, result.nonconformity.causes);
          if (result.nonconformity.id  === 0) result.nonconformity.employeesSupport = this.convertCombo(result.employees, result.nonconformity.employees);
          this.data = result;
          this.show = true;
        } else {
          this.data.nonconformity.id = result.nonconformity.id;
          this.data.tasks = result.tasks;
          this.data.files = result.files;
        }
      })
    },
    saveComplaint(event){
      this.showModalComplaint = false;      
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = ()=> {
        this.saveHttp("nonconformities_nonconformity_save_complaint", { id: this.data.nonconformity.id, filename: event.target.files[0].name, base64: reader.result }, "Reclamo salvato!" ).then((result) => {
          if ( result.result == true ) this.loadData( this.data.nonconformity.id, this.data.nonconformity.auditsanwers_id );
        })
      };
    },
    deleteComplaint(filename){
      this.deleteHttp("nonconformities_nonconformity_delete_complaint", { id: this.data.nonconformity.id, filename: filename }, "Reclamo eliminato!" ).then((result) => {
        if ( result.result == true ) this.loadData( this.data.nonconformity.id, this.data.nonconformity.auditsanwers_id );
      })
    },
    downloadDataComplaint(fileName){
      this.$refs.fileComplaint.value = fileName;
      this.$refs.downloadComplaintForm.submit();
    },
    saveData(){
      this.saveHttp("nonconformities_nonconformity_save", this.data.nonconformity, "Non conformità salvata!" ).then((result) => {
        if ( result.result == true && this.data.nonconformity.auditsanswers_id > 0 ){
          this.loadData(result.id, this.data.nonconformity.auditsanswers_id);
        } else {
          this.closeForm();
        }
      })
    },
    deleteData(){
      this.deleteHttp("nonconformities_nonconformity_delete", { id: this.data.nonconformity.id }, "Non conformità eliminata!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    downloadData(){
      this.$refs.downloadForm.submit();
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('reload');
    }
  }
}
</script>