<template>
  <div>
    <law ref="law" @reload="loadData"/>
    <b-modal v-model="showFilter" title="Filtra" @ok="filterSet">
      <b-form-group label="Area">
        <b-form-input v-model="tempFilter.area"/>
      </b-form-group>     
      <b-form-group label="Legge">
        <b-form-input v-model="tempFilter.law"/>
      </b-form-group>     
    </b-modal>
    <b-btn class="mr-2" variant="primary" @click="loadData">Aggiorna</b-btn>
    <b-btn class="mr-2" variant="warning" @click="filterReset">Reset filtri</b-btn>
    <b-btn class="mr-2" variant="secondary" @click="filterOpen">Filtra</b-btn>
    <b-btn class="mr-2" variant="success" @click="$refs.law.loadData(0)">Nuovo</b-btn>
    <b-btn class="mr-2" variant="info" v-if="document != null && document.records_id > 0" @click="$refs.doctoken.downloadRecord(document.records_id)">Scarica Documento {{ document.date }}</b-btn>
    <b-btn variant="success" @click="generateDocument()">Genera Documento</b-btn>
    <doctoken ref="doctoken"/>
    <b-table no-border-collapse :sticky-header="(winHeight - 205) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="items.data" :fields="fields" :busy="isBusy" :no-local-sorting="true" :sort-by.sync="search.sort.by" :sort-desc.sync="search.sort.desc" @sort-changed="sortingChange" @row-clicked="$refs.law.loadData($event.id)">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>Non ci sono risultati</template>
      <template v-slot:cell(link)="row">
        <a v-if="row.value !== ''" :href="row.value" target="blank">Link</a>
      </template>
    </b-table>
    <tablebottom :perpage="search.page.perpage" @updatePerPage="search.page.perpage = $event" :currentpage="search.page.currentpage" @updateCurrentPage="search.page.currentpage = $event" :total="items.total" @loadData="loadData"/>
  </div>
</template>

<script>
import law from './law.vue'

export default {
  name: "laws",
  components: { law },
  data() {
    return {
      isBusy: true,
      showFilter: false,
      search:{
        page: {
          currentpage: 1,
          perpage: 50,
        },
        filters: {
          area: null,
          law: null
        },
        sort: {
          by: 'area',
          desc: false,
        },
      },
      tempFilter: {
        area: null,
        law: null,
      },
      fields: [{
        key: 'area',
        label: "Area",
        sortable: true
      },{
        key: 'title',
        label: 'Legge',
        sortable: true,
      },{
        key: 'explanation',
        label: 'Spiegazione',
      },{
        key: 'datevalid',
        label: 'Emissione',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth()+1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'dateunvalid',
        label: 'Scadenza',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth()+1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'link',
        label: 'Link',
      }],
      items: {
        total: 0,
        data: [],
      },
      document: null
    }  
  },
  computed: {
    token (){
      return this.$store.getters.getToken;
    },
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.isBusy = true;
      this.openHttp("laws_laws", this.search).then((result) => {
        this.items = result;
        this.isBusy = false;
        this.loadDocument();
      })
    },
    loadDocument(){
      this.openHttp("laws_document", null).then((result) => {
        this.document = result;
      })
    },
    generateDocument(){
      this.generateDocumentHttp("laws");
    },
    filterOpen(){
      this.showFilter = true;
    },
    filterSet(){
      this.search.filters = this.tempFilter;
      this.loadData();
    },
    filterReset(){
      this.tempFilter = {
        area: null,
        law: null
      };
      this.filterSet();
    },
    sortingChange(ctx) {
      this.search.sort.by = ctx.sortBy;
      this.search.sort.desc = ctx.sortDesc;
      this.loadData();
    },
    downloadOpen(){
      this.$refs.downloadForm.submit();
    },
  }
}
</script>