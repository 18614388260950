<template>
  <div>
    <b-modal v-if="data != null" v-model="show" size="xl" id="modalForm" title="Strumento di misura" scrollable @hidden="closeForm()" @close="closeForm()">
      <calibration ref="calibration" @reload="loadData(data.tool.id)"/>
      <form ref="downloadForm" method="POST" target="_blank" action="/api/file.php">
        <input type="hidden" name="form" value="tools">
        <input type="hidden" name="document" value="tool">
        <input type="hidden" name="id" :value="data.tool.id">
        <input type="hidden" name="token" :value="token">
      </form>
      <form ref="downloadFormOrdinary" method="POST" target="_blank" action="/api/file.php">
        <input type="hidden" name="form" value="tools">
        <input type="hidden" name="document" value="tool_ordinary">
        <input type="hidden" name="id" :value="data.tool.id">
        <input type="hidden" name="token" :value="token">
      </form>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Scheda" active>
            <b-row>
              <b-col lg="4">
                <b-form-group label="Tipologia">
                  <b-form-select v-model="data.tool.machineriestypes_id" :options="data.toolstypes" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Identificativo">
                  <b-form-input v-model="data.tool.serialnumber" required/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Matricola">
                  <b-form-input v-model="data.tool.registrationnumber" required/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="2">
                <b-form-group label="Stato">
                  <b-form-select v-model="data.tool.unactive" :options="unactiveCombo" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Primario">
                  <b-form-select v-model="data.tool.leader" :options="leaderCombo" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Responsabile">
                  <b-form-select v-model="data.tool.employees_id" :options="data.employees" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Ubicazione">
                  <b-form-input v-model="data.tool.location"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Tarature associate">
              <multiselect v-model="data.tool.calibrationsheetsSupport" :options="data.calibrationsheets" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.tool.calibrationsheets = updateCombo(data.tool.calibrationsheetsSupport)"/>
            </b-form-group>
            <b-form-group label="Note">
              <b-form-textarea v-model="data.tool.note"/>
            </b-form-group>
            <b-form-group label="Scadenze tarature ordinarie:" v-show="data.tool.id > 0">
              <b-table hover :items="data.deadlines" :fields="deadlinesFields" class="mt-3" @row-clicked="$refs.calibration.loadData(0, data.tool.id, $event.id, $event.nextdate)" :tbody-tr-class="rowDetailClass"/>
            </b-form-group>
          </b-tab>
          <b-tab title="Storico tarature" v-if="data.tool.id > 0">
            <b-row>
              <b-col lg="9">
                <b-btn variant="success" @click="$refs.calibration.loadData(0, data.tool.id, null, null)">Nuova</b-btn>
              </b-col>
              <b-col lg="3">
                <b-form-input v-model="criteriaCalibrations" style="float: right" placeholder="Filtra..."/>
              </b-col>
            </b-row>
            <b-table hover :items="data.ordinarycalibrations" :fields="ordinaryFields" class="mt-3" @row-clicked="$refs.calibration.loadData($event.id, data.tool.id, null, null)" :filter="criteriaCalibrations"/>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" v-if="data.tool.id > 0" @click="loadData(data.tool.id)">Aggiorna</b-btn>
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="info" class="mr-2" v-show="data.tool.id > 0" @click="$refs.downloadForm.submit()">Scarica</b-btn>
          <b-btn variant="danger" v-show="data.tool.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import calibration from './calibration.vue'

export default {
  name: "tool",
  components: { calibration },
  data() {
    return {
      show: false,
      data: null,
      unactiveCombo:[{
        id: false,
        val: 'Attivo'
      },{
        id: true,
        val: 'Non attivo'
      }],
      leaderCombo:[{
        id: false,
        val: 'Non primario'
      },{
        id: true,
        val: 'Primario'
      }],
      deadlinesFields: [{
        key: 'calibrationsheet',
        label: 'Taratura',
        sortable: true,
      },{
        key: 'nextdate',
        label: 'Prossima',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'lastdate',
        label: 'Ultima',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'periodicity',
        label: 'Periodicità',
        formatter: value => {
          return value.replace("year", "anni").replace("month", "mesi").replace("week", "settimane");
        }
      }],
      ordinaryFields: [{
        key: 'date',
        label: 'Data',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'mainteinancesheet',
        label: 'Strumento',
        sortable: true,
      },{
        key: 'employee',
        label: 'Interno',
        sortable: true,
      },{
        key: 'supplier',
        label: 'Fornitore',
        sortable: true,
      },{
        key: 'cost',
        label: 'Costo',
        sortable: true,
        formatter: value => {
          return value.toFixed(2) + " €";
        }
      }],
      criteriaCalibrations: null,
    }
  },
  computed: {
    token (){
      return this.$store.getters.getToken;
    }
  },
  methods: {
    rowDetailClass(item) {
      let today = new Date();
      let d = new Date(item.nextdate);
      return 'table-' + ( d.getTime() <= today.getTime() ? 'danger' : 'success' );
    },
    loadData(id){
      this.openHttp("tools_tool", { id: id }).then((result) => {
        if (this.data !== null){
          this.data.deadlines = result.deadlines;
          this.data.ordinarycalibrations = result.ordinarycalibrations;
        } else {
          result.tool.calibrationsheetsSupport = this.convertCombo(result.calibrationsheets, result.tool.calibrationsheets);
          this.data = result;
        }
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("tools_tool_save", this.data.tool, "Strumento salvato!").then((result) => {
        if ( result.result == true ) this.closeForm(true);
      })
    },
    deleteData(){
      this.deleteHttp("tools_tool_delete", { id: this.data.tool.id }, "Strumento eliminato!" ).then((result) => {
        if ( result.result == true ) this.closeForm(true);
      })
    },
    closeForm(reload = false){
      this.show = false;
      this.data = null;
      if (reload === true ) this.$emit('reload');
    }
  }
}
</script>