<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" title="Sito" size="lg" scrollable @hidden="closeForm()" @close="closeForm()">
      <b-form-group label="Indirizzo">
        <b-form-input v-model="data.site.site"/>
      </b-form-group>
      <b-row>
        <b-col lg="4">
          <b-form-group label="Tipo">
            <b-form-input v-model="data.site.type"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Turni">
            <b-form-input v-model="data.site.shifts" type="number"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Stato">
            <b-form-select v-model="data.site.active" :options="activeCombo" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Aree">
        <b-btn variant="primary" size="sm" @click="departmentAdd()">Nuovo</b-btn>
        <b-table :items="data.site.departments" :fields="fields" class="mt-3">
          <template v-slot:cell(department)="row">
            <b-form-input v-model="row.item.department"/>
          </template>
          <template v-slot:cell(note)="row">
            <b-form-textarea v-model="row.item.note" rows="3"/>
          </template>
          <template v-slot:cell(id)="row">
            <b-btn variant="danger" size="sm" @click="data.site.departments.splice(row.index, 1)">Elimina</b-btn>
          </template>
        </b-table>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.site.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "site",
  data() {
    return {
      show: false,
      data: null,
      activeCombo: [{
        id: true,
        val: "Attivo"
      },{
        id: false,
        val: "Non attivo"
      }],
      fields: [{
        key: "department",
        label: "Area",
      },{
        key: "note",
        label: "Note",
      },{
        key: "id",
        label: "",
      }]
    }
  },
  methods: {
    loadData( id ){
      this.openHttp("company_site", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    departmentAdd(){
      this.data.site.departments.push({
        department: null,
        note: null,
        id: 0
      })
    },
    saveData(){
      this.saveHttp("company_site_save", this.data.site, "Sito salvato!" ).then((result) => {
        if ( result.result == true ) this.closeForm(true);
      })
    },
    deleteData(){
      this.deleteHttp("company_site_delete", { id: this.data.site.id }, "Sito eliminato!" ).then((result) => {
        if ( result.result == true ) this.closeForm(true);
      })
    },
    closeForm(reload = false){
      this.show = false;
      this.data = null;
      if ( reload === true ) this.$emit('reload');
    }
  }
}
</script>