<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" title="Criterio di valutazione cliente" @hidden="closeForm()" @close="closeForm()">
      <b-form-group label="Criterio">
        <b-form-input v-model="data.question.criteria"/>
      </b-form-group>
      <b-form-group label="Importanza (1 - 10)">
        <b-form-input type="number" v-model="data.question.criteriaweight" min="1" max="10"/>
      </b-form-group>
      <b-form-group label="Tipologia">
        <b-form-select v-model="data.question.indirect" :options="directCombo" value-field="id" text-field="val"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.question.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "customerquestion",
  data() {
    return {
      show: false,
      data: null,
      directCombo: [{
        id: false,
        val: "Diretto"
      },{
        id: true,
        val: "Indiretto"
      }]
    }
  },
  methods: {
    loadData( id ){
      this.show = false;
      this.openHttp("customers_question", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("customers_question_save", this.data.question, "Criterio di valutazione cliente salvato!" ).then((result) => {
        if ( result.result == true ) this.closeForm(true);
      })
    },
    deleteData(){
      this.deleteHttp("customers_question_delete", { id: this.data.question.id }, "Criterio di valutazione cliente eliminato!" ).then((result) => {
        if ( result.result == true ) this.closeForm(true);
      })
    },
    closeForm(reload = false){
      this.show = false;
      this.data = null;
      if ( reload === true ) this.$emit('reload');
    }
  }
}
</script>