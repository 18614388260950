<template>
  <div>
    <doctoken ref="doctoken"/>
    <employee ref="employee" @reload="loadData"/>
    <b-modal v-model="showFilter" title="Filtra" @ok="filterSet">
      <b-form-group label="Nome">
        <b-form-input type="text" v-model="tempFilter.employee"/>
      </b-form-group>
      <b-form-group label="Stato">
        <b-form-select v-model="tempFilter.active" :options="activeCombo" value-field="id" text-field="val"/>
      </b-form-group>
    </b-modal>
    <b-btn class="mr-2" variant="primary" @click="loadData">Aggiorna</b-btn>
    <b-btn class="mr-2" variant="warning" @click="filterReset">Reset filtri</b-btn>
    <b-btn class="mr-2" variant="secondary" @click="filterOpen">Filtra</b-btn>
    <b-btn class="mr-2" variant="success" @click="$refs.employee.loadData(0)">Nuovo</b-btn>
    <b-btn class="mr-2" variant="info" v-if="document != null && document.records_id > 0" @click="$refs.doctoken.downloadRecord(document.records_id)">Scarica Documento {{ document.date }}</b-btn>
    <b-btn variant="success" @click="generateDocument()">Genera Documento</b-btn>
    <b-table no-border-collapse :sticky-header="(winHeight - 205) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :tbody-tr-class="rowClass" :items="items.data" :fields="fields" :busy="isBusy" :no-local-sorting="true" :sort-by.sync="search.sort.by" :sort-desc.sync="search.sort.desc" @sort-changed="sortingChange" @row-clicked="$refs.employee.loadData($event.id)">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>Non ci sono risultati</template>
    </b-table>
    <tablebottom :perpage="search.page.perpage" @updatePerPage="search.page.perpage = $event" :currentpage="search.page.currentpage" @updateCurrentPage="search.page.currentpage = $event" :total="items.total" @loadData="loadData"/>
  </div>
</template>

<script>
import employee from './employee.vue'

export default {
  name: "employees",
  components: { employee },
  data() {
    return {
      fields: [{
        key: "employee",
        label: "Nome",
        sortable: true
      },{
        key: 'active',
        label: 'Stato',
        sortable: true,
        formatter: value => {
          return value ? "Assunto" : "Licenziato / Dimesso"
        }
      },{
        key: 'employeeskind',
        label: 'Contratto',
        sortable: true
      },{
        key: 'jobdescriptions',
        label: 'Mansioni',
      },{
        key: 'startdate',
        label: "Inizio",
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        },
      },{
        key: 'dismissaldate',
        label: "Fine",
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        },
      },{
        key: 'mindatedoc',
        label: "Documento",
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        },
      },{
        key: 'note',
        label: "Note",
      }],
      isBusy: true,
      showFilter: false,
      search: {
        page: {
          currentpage: 1,
          perpage: 50,
        },
        filters: { 
          employee: null,
          active: true
        },
        sort: {
          by: "employee",
          desc: false
        },
      },
      activeCombo: [{
        id: true,
        val: 'Assunto',
      },{
        id: false,
        val: 'Dimesso / Licenziato',
      },{
        id: null,
        val: 'Tutti',
      }],
      tempFilter: {
        employee: null,
        active: true,
      },
      items: {
        total: 0,
        data: [],
      },
      document: null
    }  
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    rowClass(row) {
      let today = new Date();
      if (row !== null){
        let dismissaldate = new Date(row.dismissaldate);
        let documentdate = new Date(row.mindatedoc);
        if (row.active === true && documentdate.getTime() <= today.getTime() && row.mindatedoc != null) return 'table-danger';
        if (row.active === true && dismissaldate.getTime() <= today.getTime() && row.dismissaldate != null) return 'table-warning';
      }
      return "";
    },
    loadData(){
      this.isBusy = true;
      this.openHttp("employees_employees", { search: this.search }).then((result) => {
        this.isBusy = false;
        this.items = result;
        this.loadDocument();
      })
    },
    loadDocument(){
      this.openHttp("employees_document", null).then((result) => {
        this.document = result;
      })
    },
    generateDocument(){
      this.generateDocumentHttp("employees");
    },
    filterOpen(){
      this.showFilter = true;
    },
    filterSet(){
      this.showFilter = false;
      this.search.page.currentpage = 1;
      this.search.filters = this.tempFilter;
      this.loadData();
    },
    filterReset(){
      this.tempFilter = {
        employee: null,
        active: true
      };
      this.filterSet();
    },
    sortingChange(ctx) {
      this.search.sort.by = ctx.sortBy;
      this.search.sort.desc = ctx.sortDesc;
      this.loadData();
    }
  }
}
</script>