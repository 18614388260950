<template>
    <Bar v-if="show" id="my-chart-id" :options="chartOptions" :data="chartData"/>
  </template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
    name: 'BarChart',
    components: { Bar },
    data() {
        return {
            show: false,
            chartData: {
                labels: [],
                datasets: [ { data: [] } ]
            },
            chartOptions: {
                responsive: true,
                scales: {
                    yAxes: [{
                        ticks: {
                            suggestedMin: 0
                        }
                    }]
                }
            }
        }
    },
    methods: {
        load(chartdata){
            this.show = false;
            this.chartData = chartdata;
            this.$nextTick(() => {
                this.show = true;
            });
        }
    }

}
</script>