<template>
  <div>
    <expiration ref="expiration" @reload="loadData"/>
    <expirationtypesite ref="typesite" @reload="loadData"/>
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Scadenze" active>
          <b-table-simple responsive>
            <b-thead>
              <b-tr>
                <b-th v-for="(item, index) of deadlines.data" :key="index">{{ item.month === "Expired" ? "Scadute" : item.month }}</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-td v-for="(item, index) of deadlines.data" :key="index">
                  <b-btn v-for="(item2, index2) of item.report" :variant="item.month === 'Expired' ? 'danger' : 'success'" :key="index2"><b>{{ item2.expirationtypesite }}</b> - {{ item2.expired }}</b-btn>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-tab>
        <b-tab title="Storico">
          <b-modal v-model="expirations.showFilter" title="Filtra" @ok="filterSetExpirations">
            <b-form-group label="Tipologia">
              <b-form-select v-model="expirations.tempFilter.expirationtypes_id" :options="comboSeeAll(expirations.filtersMenu.types)" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Sito">
              <b-form-select v-model="expirations.tempFilter.sites_id" :options="comboSeeAll(expirations.filtersMenu.sites)" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Stato">
              <b-form-select v-model="expirations.tempFilter.expired" :options="comboSeeAll(expirations.expiredCombo)" value-field="id" text-field="val"/>
            </b-form-group>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadDataExpirations">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="warning" @click="filterResetExpirations">Reset filtri</b-btn>
          <b-btn class="mr-2" variant="secondary" @click="filterOpenExpirations">Filtra</b-btn>
          <b-btn class="mr-2" variant="success" @click="$refs.expiration.loadData(0)">Nuovo</b-btn>
          <!--
          <b-btn variant="info" v-show="consumptions.items.record_id > 0" @click="$refs.doctoken.downloadRecord(consumptions.items.record_id)">Scarica</b-btn>
          <doctoken ref="doctoken"/>
          -->
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :tbody-tr-class="rowClassExpired" :items="expirations.items.data" :fields="expirations.fields" :busy="expirations.isBusy" :no-local-sorting="true" :sort-by.sync="expirations.search.sort.by" :sort-desc.sync="expirations.search.sort.desc" @sort-changed="sortingChangeExpirations" @row-clicked="$refs.expiration.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
          <tablebottom :perpage="expirations.search.page.perpage" @updatePerPage="expirations.search.page.perpage = $event" :currentpage="expirations.search.page.currentpage" @updateCurrentPage="expirations.search.page.currentpage = $event" :total="expirations.items.total" @loadData="loadDataExpirations"/>





        </b-tab>
        <b-tab title="Tipologie per sito">
          <b-btn class="mr-2" variant="primary" @click="loadDataTypessites">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="success" @click="$refs.typesite.loadData(0)">Nuovo</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="typessites.items.data" :fields="typessites.fields" :busy="typessites.isBusy" :no-local-sorting="true" :sort-by.sync="typessites.search.sort.by" :sort-desc.sync="typessites.search.sort.desc" @sort-changed="sortingChangeTypessites" @row-clicked="$refs.typesite.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
          <tablebottom :perpage="typessites.search.page.perpage" @updatePerPage="typessites.search.page.perpage = $event" :currentpage="typessites.search.page.currentpage" @updateCurrentPage="typessites.search.page.currentpage = $event" :total="typessites.items.total" @loadData="loadDataTypessites"/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import expiration from './expiration.vue';
import expirationtypesite from './expirationtypesite.vue';

export default {
  name: "expirations",
  components: { expiration, expirationtypesite },
  data() {
    return {
      deadlines: {
        isBusy: true,
        data: []
      },
      expirations: {
        isBusy: true,
        showFilter: false,
        search: {
          page: {
            currentpage: 1,
            perpage: 50,
          },
          filters: {
            expirationtypes_id: null,
            sites_id: null,
            expired: null
          },
          sort: {
            by: 'expirationtype',
            desc: false,
          },
        },
        tempFilter: {
          expirationtypes_id: null,
          sites_id: null,
          expired: null
        },
        filtersMenu: {
          types: [],
          sites: [],
        },
        expiredCombo: [{
          id: true,
          val: "Scaduto"
        },{
          id: false,
          val: "In corso di validità"
        }],
        fields: [{
          key: 'expirationtype',
          label: 'Tipologia',
          sortable: true,
        },{
          key: 'site',
          label: "Sito",
          sortable: true,
        },{
          key: 'issuer',
          label: "Emittente",
        },{
          key: 'date',
          label: "Emissione",
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
          sortable: true,
        },{
          key: 'expireddate',
          label: "Scadenza",
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
          sortable: true
        }],
        items: {
          total: 0,
          data: [],
        },
      },


      typessites: {
        isBusy: true,
        showFilter: false,
        search: {
          page: {
            currentpage: 1,
            perpage: 50,
          },
          filters: {},
          sort: {
            by: 'expirationtype',
            desc: false,
          },
        },
        tempFilter: {},
        filtersMenu: {},
        fields: [{
          key: 'expirationtype',
          label: "Tipologia",
          sortable: true,
        },{
          key: 'site',
          label: "Sito",
          sortable: true,
        },{
          key: 'active',
          label: "Stato sito",
          formatter: value => {
            return value ? 'Attivo' : 'Non attivo';
          }
        },{
          key: 'applicable',
          label: "Applicabile",
          formatter: value => {
            return value ? 'Si' : 'No';
          }
        }],
        items: {
          total: 0,
          data: [],
        }, 
      }
    }  
  },
  created(){
    this.loadData();
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    }
  },
  methods: {


    rowClassExpired(row) {
      if (row === null) return "";
      if (row.expired === true) {
        return 'table-danger';
      } else if (row.expired === false) {
        return 'table-success';
      }
      return ""
    },


    loadData(){
      this.loadDataDeadlines();
      this.loadDataExpirations();
      this.loadDataTypessites();
    },

    loadDataDeadlines(){
      this.deadlines.isBusy = true;
      this.openHttp("expirations_deadlines", null).then((result) => {
        this.deadlines.data = result;
        this.deadlines.isBusy = false;
      })
    },


    loadDataExpirations(){
      this.expirations.isBusy = true;
      this.openHttp("expirations_expirations", this.expirations.search).then((result) => {
        this.expirations.items = result;
        this.expirations.isBusy = false;
      })
    },
    filterOpenExpirations(){
      this.openHttp("expirations_expirations_filters", null).then((result) => {
        this.expirations.filtersMenu = result;
        this.expirations.showFilter = true;
      })
    },
    filterSetExpirations(){
      this.expirations.search.page.currentpage = 1;
      this.expirations.search.filters = this.expirations.tempFilter;
      this.expirations.showFilter = false;
      this.loadDataExpirations();
    },
    filterResetExpirations(){
      this.expirations.tempFilter = {
        expirationtypes_id: null,
        sites_id: null,
        expired: null
      };
      this.filterSetExpirations();
    },
    sortingChangeExpirations(ctx) {
      this.expirations.search.sort.by = ctx.sortBy;
      this.expirations.search.sort.desc = ctx.sortDesc;
      this.loadDataExpirations();
    },



    loadDataTypessites(){
      this.typessites.isBusy = true;
      this.openHttp("expirations_typessites", { search: this.typessites.search }).then((result) => {
        this.typessites.items = result;
        this.typessites.isBusy = false;
      })
    },

    sortingChangeTypessites(ctx) {
      this.typessites.search.sort.by = ctx.sortBy;
      this.typessites.search.sort.desc = ctx.sortDesc;
      this.loadDataExpirations();
    }

    /*
    checkCalibrations(calibrations){
      var today = new Date();
      let expired = false;
      for (let item of calibrations){
        let d = new Date(item.nextdate);
        if (d.getTime() <= today.getTime()){
          expired = true;
        }
      }
      return expired;
    },
    rowClassToolsDeadlines(row) {
      if (row === null) return "";
      let today = new Date();
      let nextdate = new Date(row.nextdate);
      if (nextdate.getTime() <= today.getTime() && row.nextdate !== null && row.calibrations.length > 0) return "table-danger";
    },
    rowDetailClass(item) {
      let today = new Date();
      let d = new Date(item.nextdate);
      return 'table-' + (d.getTime() <= today.getTime() ? 'danger' : 'success');
    },
    loadDataTools(){
      this.tools.isBusy = true;
      this.openHttp("tools_tools2", { search: this.tools.search }).then((result) => {
        this.tools.items = result;
        this.tools.isBusy = false;
      })
    },
    filterOpenTools(){
      this.openHttp("tools_tools_filters", { }).then((result) => {
        this.tools.filtersMenu = result;
        this.tools.showFilter = true;
      })
    },
    filterSetTools(){
      this.tools.search.page.currentpage = 1;
      this.tools.showFilter = false;
      this.tools.search.filters = this.tools.tempFilter;
      this.loadDataTools();
    },
    filterResetTools(){
      this.tools.tempFilter = {
        tool: null,
        unactive: false,
        toolstypes_id: null,
        calibrationsheets_id: null,
      };
      this.filterSetTools();
    },
    sortingChangeTools(ctx) {
      this.tools.search.sort.by = ctx.sortBy;
      this.tools.search.sort.desc = ctx.sortDesc;
      this.loadDataTools();
    },
    loadCalibration(id, toolsId, calibrationsheetsId, date){
      this.$refs.calibration.loadData(id, toolsId, calibrationsheetsId, date);
    },
    excelOpen(num){
      this.$refs["excelForm" + num].submit();
    },
    loadDataSheets(){
      this.sheets.isBusy = true;
      this.openHttp("tools_calibrationssheets2", { search: this.sheets.search }).then((result) => {
        this.sheets.items = result;
        this.sheets.isBusy = false;
      })
    },
    filterOpenSheets(){
      this.openHttp("tools_calibrationssheets_filters", { }).then((result) => {
        this.sheets.filtersMenu = result;
        this.sheets.showFilter = true;
      })
    },
    filterSetSheets(){
      this.sheets.showFilter = false;
      this.tools.search.page.currentpage = 1;
      this.sheets.search.filters = this.sheets.tempFilter;
      this.loadDataSheets();
    },
    filterResetSheets(){
      this.sheets.tempFilter = {
        mainteinancesheet: null,
        tools_id: null,
      }
      this.filterSetSheets();
    },
    sortingChangeSheets(ctx) {
      this.sheets.search.sort.by = ctx.sortBy;
      this.sheets.search.sort.desc = ctx.sortDesc;
      this.loadDataSheets();
    },
    */
  }
}
</script>