<template>
  <div>
    <form ref="downloadRecord" method="POST" target="_blank" action="/api/download_record.php">
      <input type="hidden" name="id" ref="document_record">
      <input type="hidden" name="token" :value="token">
    </form>
    <form ref="downloadRevision" method="POST" target="_blank" action="/api/download_revision.php">
      <input type="hidden" name="id" ref="document_revision">
      <input type="hidden" name="token" :value="token">
    </form>
    <form ref="downloadAll" method="POST" target="_blank" action="/api/download_all.php">
      <input type="hidden" name="token" :value="token">
    </form>
  </div>
</template>

<script>
export default {
  name: "doctoken",
  computed: {
    token (){
      return this.$store.getters.getToken;
    }
  },
  methods: {
    downloadRecord( id ){
      this.$refs.document_record.value = id;
      this.$refs.downloadRecord.submit();
    },
    downloadRevision( id ){
      this.$refs.document_revision.value = id;
      this.$refs.downloadRevision.submit();
    },
    downloadAll( ){
      this.$refs.downloadAll.submit();
    }
  }
}
</script>