<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" title="Rischio chimico" @hidden="closeForm()" @close="closeForm()">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Scheda prodotto" active>
            <b-row>
              <b-col lg="8">
                <b-form-group label="Prodotto">
                  <b-form-input v-model="data.chemical.productname"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Produttore">
                  <b-form-input v-model="data.chemical.producer"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="12">
                <b-form-group label="Scheda tecnica in PDF">
                  <b-form-file placeholder="Scegli un file o trascinalo qui..." accept=".pdf" drop-placeholder="Trascinalo qui..." @change="handlerUpdate($event)"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="12">
                <b-form-group label="Note">
                  <b-form-textarea v-model="data.chemical.typenote"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="12">
                <b-form-group label="Codici H">
                  <multiselect v-model="data.chemical.codesSupport" :options="data.chemicalcodes" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.chemical.codes = updateCombo(data.chemical.codesSupport);"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Stoccaggio">
            <b-btn size="sm" variant="primary" @click="addSite">Nuovo</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 375) + 'px'"  class="mt-2" responsive show-empty :items="data.chemical.sitesdepartments" :fields="fieldsSitesdepartments">
              <template v-slot:cell(sitesdepartments_id)="row">
                <b-form-select v-model="row.item.sitesdepartments_id" :options="data.sitesdepartments" value-field="id" text-field="val"/>
              </template>
              <template v-slot:cell(quantity)="row">
                <b-form-input v-model="row.item.quantity"/>
              </template>
              <template v-slot:cell(storagemethod)="row">
                <b-form-textarea v-model="row.item.storagemethod" rows="2" max-rows="6"/>
              </template>
              <template v-slot:cell(applicationmethod)="row">
                <b-form-textarea v-model="row.item.applicationmethod" rows="2" max-rows="6"/>
              </template>
              <template v-slot:cell(index)="row">
                <b-btn variant="danger" @click="data.chemical.sitesdepartments.splice(row.index, 1)">Elimina</b-btn>
              </template>
              <template v-slot:empty>Non è stato individuato nessuno stoccaggio</template>
            </b-table>
          </b-tab>
          <b-tab title="Smaltimento">
          </b-tab>
          <b-tab title="Esposizione">
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.chemical.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "chemical",
  data() {
    return {
      show: false,
      data: null,
      fieldsSitesdepartments:[{
        key: "sitesdepartments_id",
        label: "Sito - Area",
        thStyle: { width: "40%"},
      },{
        key: "quantity",
        label: "Quantità (Kg)",
        thStyle: { width: "10%"},
      },{
        key: "storagemethod",
        label: "Metodi di stoccaggio",
        thStyle: { width: "20%"},
      },{
        key: "applicationmethod",
        label: "Modalità di applicazione",
        thStyle: { width: "20%"},
      },{
        key: "index",
        label: ""
      }]
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    loadData( id ){
      this.show = false;
      this.openHttp("chemicals_chemical", { id: id }).then((result) => {
        result.chemical.codesSupport = this.convertCombo(result.chemicalcodes, result.chemical.codes);
        this.data = result;
        this.show = true;
      })
    },
    handlerUpdate(event){
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = ()=> {
        this.data.chemical.docname = event.target.files[0].name;
        this.data.chemical.base64 = reader.result;
      };
    },
    addSite(){
      this.data.chemical.sitesdepartments.push({
        id: 0,
        sitesdepartments_id: this.data.sitesdepartments[0].id,
        quantity: 0,
        storagemethod: null,
        applicationmethod: null
      })
    },
    saveData(){
      this.saveHttp("chemicals_chemical_save", this.data.chemical, "Rischio chimico salvato!" ).then((result) => {
        if ( result.result == true ) this.closeForm(true);
      })
    },
    deleteData(){
      this.deleteHttp("chemicals_chemical_delete", { id: this.data.chemical.id }, "Rischio chimico eliminato!" ).then((result) => {
        if ( result.result == true ) this.closeForm(true);
      })
    },
    closeForm( reload = false ){
      this.show = false;
      this.data = null;
      if ( reload === true ) this.$emit('reload');
    }
  }
}
</script>