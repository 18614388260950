<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" title="Tipologia infrastruttura" size="lg" @hidden="closeForm()" @close="closeForm()">
      <b-form-group label="Tipologia">
        <b-form-input v-model="data.machineriestype.machineriestype"/>
      </b-form-group>
      <b-form-group label="Manutenzioni associate">
        <multiselect v-model="data.machineriestype.mainteinancesheetsSupport" :options="data.mainteinancesheets" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.machineriestype.mainteinancesheets = updateCombo(data.machineriestype.mainteinancesheetsSupport)"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.machineriestype.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "machineriestype",
  data() {
    return {
      show: false,
      data: null,
    }
  },
  methods: {
    loadData( id ){
      this.show = false;
      this.openHttp("machineries_machineriestype", { id: id }).then((result) => {
        result.machineriestype.mainteinancesheetsSupport = this.convertCombo(result.mainteinancesheets, result.machineriestype.mainteinancesheets);
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("machineries_machineriestype_save", this.data.machineriestype, "Tipologia salvata!" ).then((result) => {
        if ( result.result == true ) this.closeForm(true);
      })
    },
    deleteData(){
      this.deleteHttp("machineries_machineriestype_delete", { id: this.data.machineriestype.id }, "Tipologia eliminata!" ).then((result) => {
        if ( result.result == true ) this.closeForm(true);
      })
    },
    closeForm(reload = false){
      this.show = false;
      this.data = null;
      if ( reload === true ) this.$emit("reload");
    }
  }
}
</script>