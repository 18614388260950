<template>
  <div>
    <templatetemplate ref="template" @reload="loadTemplates"/>
    <templateinstruction ref="instruction" @reload="loadInstructions"/>
    <templatehtml ref="html" @reload="loadTemplatesHtml"/>
    <templateaudit ref="audit" @reload="loadInternalaudits"/>
    <templatemanagementreview ref="managementreview" @reload="loadManagementreviews"/>
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Templates" active>
          <b-row>
            <b-col lg="9">
              <b-btn variant="primary" class="mr-2" @click="loadTemplates">Aggiorna</b-btn>
              <b-btn variant="success" @click="loadTemplate(0)">Nuovo</b-btn>
            </b-col>
            <b-col lg="3">
              <b-form-input v-model="templates.criteria" style="float: right" placeholder="Filtra..."/>
            </b-col>
          </b-row>
          <b-table no-border-collapse :sticky-header="(winHeight - 270) + 'px'" hover class="mt-3" :items="templates.items" :fields="templates.fields" :busy="templates.isBusy" :filter="templates.criteria" @row-clicked="loadTemplate($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
        </b-tab>
        <b-tab title="Templates istruzioni">
          <b-row>
            <b-col lg="9">
              <b-btn variant="primary" class="mr-2" @click="loadInstructions">Aggiorna</b-btn>
              <b-btn variant="success" @click="loadInstruction(0)">Nuovo</b-btn>
            </b-col>
            <b-col lg="3">
              <b-form-input v-model="instructions.criteria" style="float: right" placeholder="Filtra..."/>
            </b-col>
          </b-row>
          <b-table no-border-collapse :sticky-header="(winHeight - 270) + 'px'" hover class="mt-3" :items="instructions.items" :fields="instructions.fields" :busy="instructions.isBusy" :filter="instructions.criteria" @row-clicked="loadInstruction($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
        </b-tab>
        <b-tab title="Templates HTML">
          <b-row>
            <b-col lg="9">
              <b-btn variant="primary" class="mr-2" @click="loadTemplatesHtml">Aggiorna</b-btn>
              <b-btn variant="success" @click="loadTemplateHtml('_')">Nuovo</b-btn>
            </b-col>
            <b-col lg="3">
              <b-form-input v-model="templatesHtml.criteria" style="float: right" placeholder="Filtra..."/>
            </b-col>
          </b-row>
          <b-table no-border-collapse :sticky-header="(winHeight - 270) + 'px'" hover class="mt-3" :items="templatesHtml.items" :fields="templatesHtml.fields" :busy="templatesHtml.isBusy" :filter="templatesHtml.criteria" @row-clicked="loadTemplateHtml($event.file)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
        </b-tab>
        <b-tab title="Audit interno">
          <b-row>
            <b-col lg="9">
              <b-btn variant="primary" class="mr-2" @click="loadInternalaudits">Aggiorna</b-btn>
              <b-btn variant="success" @click="loadAudit(0)">Nuovo</b-btn>
            </b-col>
            <b-col lg="3">
              <b-form-input v-model="internalaudits.criteria" style="float: right" placeholder="Filtra..."/>
            </b-col>
          </b-row>
          <b-table no-border-collapse :sticky-header="(winHeight - 270) + 'px'" hover class="mt-3" :items="internalaudits.items" :fields="internalaudits.fields" :busy="internalaudits.isBusy" :filter="internalaudits.criteria" @row-clicked="loadAudit($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
        </b-tab>
        <b-tab title="Riesame della direzione">
          <b-row>
            <b-col lg="9">
              <b-btn variant="primary" class="mr-2" @click="loadManagementreviews">Aggiorna</b-btn>
              <b-btn variant="success" @click="loadManagementreview(0)">Nuovo</b-btn>
            </b-col>
            <b-col lg="3">
              <b-form-input v-model="managementreviews.criteria" style="float: right" placeholder="Filtra..."/>
            </b-col>
          </b-row>
          <b-table no-border-collapse :sticky-header="(winHeight - 270) + 'px'" hover class="mt-3" :items="managementreviews.items" :fields="managementreviews.fields" :busy="managementreviews.isBusy" :filter="managementreviews.criteria" @row-clicked="loadManagementreview($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import templatetemplate from './templatetemplate.vue';
import templateinstruction from './templateinstruction.vue';
import templatehtml from './templatehtml.vue';
import templateaudit from './templateaudit.vue';
import templatemanagementreview from './templatemanagementreview.vue';

export default {
  components: { templatetemplate, templateinstruction, templatehtml, templateaudit, templatemanagementreview },
  name: "templates",
  data() {
    return {
      templates: {
        isBusy: true,
        fields: [{
          key: 'name',
          label: 'Nome',
          sortable: true,
        },{
          key: "parameters",
          label: "Parametri",
          formatter: value => {
            if(value !== null) return value.map(x => x.col).join(", ");
          }
        }],
        items: [],
        criteria: null,
      },
      instructions: {
        isBusy: true,
        fields: [{
          key: "templatename",
          label: "Template",
          sortable: true,
        },{
          key: "templateinstruction",
          label: "Istruzioni",
        },{
          key: "standards",
          label: "Standards",
        }],
        items: [],
        criteria: null,
      },
      templatesHtml: {
        isBusy: true,
        fields: [{
          key: "file",
          label: "Nome",
          sortable: true,
        }],
        items: [],
        criteria: null,
      },
      internalaudits: {
        isBusy: true,
        fields: [{
          key: "orderquest",
          label: "Ordine",
          sortable: true
        },{
          key: "question",
          label: "Domanda",
          sortable: true
        },{
          key: 'standardspoints',
          label: 'Punti',
        },{
          key: 'hide',
          label: 'Stato',
          sortable: true,
          formatter: value => {
            return value === true ? "Nascosta" : "Ok";
          }
        }],
        items: [],
        criteria: null,
      },
      managementreviews: {
        isBusy: true,
        fields: [{
          key: 'orderquest',
          label: 'Ordine',
          sortable: true,
        },{
          key: 'question',
          label: 'Domanda',
          sortable: true,
        },{
          key: 'isoutput',
          label: 'Tipologia',
          sortable: true,
          formatter: value => {
            return value === true ? "Uscita" : "Ingresso";
          }
        },{
          key: 'standards',
          label: 'Norme',
        }],
        items: [],
        criteria: null,
      }
    }  
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadTemplates();
    this.loadInstructions();
    this.loadTemplatesHtml();
    this.loadInternalaudits();
    this.loadManagementreviews();
  },
  methods: {
    loadTemplates(){
      this.templates.isBusy = true;
      this.openHttp("templates_templates", { }).then((result) => {
        this.templates.items = result;
        this.templates.isBusy = false;
      })
    },
    loadTemplate(id) {
      this.$refs.template.loadData(id);
    },
    loadInstructions(){
      this.instructions.isBusy = true;
      this.openHttp("templates_instructions", { }).then((result) => {
        this.instructions.items = result;
        this.instructions.isBusy = false;
      })
    },
    loadInstruction(id) {
      this.$refs.instruction.loadData(id);
    },
    loadTemplatesHtml(){
      this.templatesHtml.isBusy = true
      this.openHttp("templates_templateshtml", { }).then((result) => {
        this.templatesHtml.items = result;
        this.templatesHtml.isBusy = false;
      })
    },
    loadTemplateHtml(id) {
      this.$refs.html.loadData(id);
    },
    loadInternalaudits(){
      this.internalaudits.isBusy = true;
      this.openHttp("templates_auditsquestions", { }).then((result) => {
        this.internalaudits.items = result;
        this.internalaudits.isBusy = false;
      })
    },
    loadAudit(id) {
      this.$refs.audit.loadData(id);
    },
    loadManagementreviews(){
      this.managementreviews.isBusy = true;
      this.openHttp("templates_managementreviews", { }).then((result) => {
        this.managementreviews.items = result;
        this.managementreviews.isBusy = false;
      })
    },
    loadManagementreview(id) {
      this.$refs.managementreview.loadData(id);
    },
  }
}
</script>