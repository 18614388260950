<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" title="Domanda" size="lg" scrollable @hidden="closeForm" @close="closeForm">
      <b-form-group label="Domande">
        <b-form-textarea v-model="data.question.question"/>
      </b-form-group>
      <b-row>
        <b-col>
          <b-form-group label="Ordine">
            <b-form-input type="number" v-model="data.question.orderquest"/>
          </b-form-group>          
        </b-col>
        <b-col>
          <b-form-group label="Tipologia">
            <b-form-select v-model="data.question.isoutput" :options="typeOutput" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Punti norma">
        <multiselect v-model="data.question.standardsSupport" :options="data.standards" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.question.standards = updateCombo(data.question.standardsSupport)"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="warning" class="mr-2" v-show="data.question.id > 0" @click="data.question.id = 0; data.question.orderquest += 1">Duplica</b-btn>
          <b-btn variant="danger" v-show="data.question.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "templatemanagementreview",
  data() {
    return {
      show: false,
      data: null,
      typeOutput: [{
        id: false,
        val: "Elemento in ingresso"
      },{
        id: true,
        val: "Elemento in uscita"
      }]
    }  
  },
  methods: {
    loadData( id ) {
      this.openHttp("templates_managementreview", { id: id }).then((result) => {
        result.question.standardsSupport = this.convertCombo(result.standards, result.question.standards);
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("templates_managementreview_save", this.data.question, "Domanda salvata!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("templates_managementreview_delete", { id: this.data.question.id }, "Domanda eliminata!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('reload');
    }
  }
}
</script>