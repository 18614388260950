<template>
  <div>
    <doctoken ref="doctoken"/>
    <job ref="job" @reload="loadData"/>
    <competence ref="competence" @reload="loadData"/>
    <certificate ref="certificate" @reload="loadData"/>
    <responsibility ref="responsibility" @reload="loadData"/>
    <appointment ref="appointment" @reload="loadDataAppointments"/>
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Mansioni" active>          
          <b-modal v-model="jobs.showfilter" title="Filtra" @ok="filterSetJobs">
            <b-form-group label="Mansione">
              <b-form-input type="text" v-model="jobs.tempFilter.jobdescription"/>
            </b-form-group>
            <b-form-group label="Personale">
              <b-form-select v-model="jobs.tempFilter.employees_id" :options="comboSeeAll(jobs.filtersMenu.employees)" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Competenze">
              <b-form-select v-model="jobs.tempFilter.competences_id" :options="comboSeeAll(jobs.filtersMenu.competences)" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Responsabilità">
              <b-form-select v-model="jobs.tempFilter.responsibilities_id" :options="comboSeeAll(jobs.filtersMenu.responsibilities)" value-field="id" text-field="val"/>
            </b-form-group>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadDataJobs">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="warning" @click="filterResetJobs">Reset filtri</b-btn>
          <b-btn class="mr-2" variant="secondary" @click="filterOpenJobs">Filtra</b-btn>
          <b-btn class="mr-2" variant="success" @click="$refs.job.loadData(0)">Nuovo</b-btn>
          <b-btn class="mr-2" variant="info" v-if="document != null && document.records_id > 0" @click="$refs.doctoken.downloadRecord(document.records_id)">Scarica Documento {{ document.date }}</b-btn>
          <b-btn variant="success" @click="generateDocument()">Genera Documento</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="jobs.items.data" :fields="jobs.fields" :busy="jobs.isBusy" :no-local-sorting="true" :sort-by.sync="jobs.search.sort.by" :sort-desc.sync="jobs.search.sort.desc" @sort-changed="sortingChangeJobs" @row-clicked="$refs.job.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
          <tablebottom :perpage="jobs.search.page.perpage" @updatePerPage="jobs.search.page.perpage = $event" :currentpage="jobs.search.page.currentpage" @updateCurrentPage="jobs.search.page.currentpage = $event" :total="jobs.items.total" @loadData="loadDataJobs"/>
        </b-tab>
        <b-tab title="Attestati">
          <b-modal v-model="certificates.showfilter" title="Filtra" @ok="filterSetCertificates">
            <b-form-group label="Attestato">
              <b-form-input v-model="certificates.tempFilter.competence"/>
            </b-form-group>
            <b-form-group label="Mansione">
              <b-form-input v-model="certificates.tempFilter.jobdescription"/>
            </b-form-group>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadDataCertificates">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="warning" @click="filterResetCertificates">Reset filtri</b-btn>
          <b-btn class="mr-2" variant="secondary" @click="certificates.showfilter = true">Filtra</b-btn>
          <b-btn variant="success" @click="$refs.certificate.loadData(0)">Nuovo</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight-305) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="certificates.items.data" :fields="certificates.fields" :busy="certificates.isBusy" :no-local-sorting="true" :sort-by.sync="certificates.search.sort.by" :sort-desc.sync="certificates.search.sort.desc" @sort-changed="sortingChangeCertificates" @row-clicked="$refs.certificate.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
          <tablebottom :perpage="certificates.search.page.perpage" @updatePerPage="certificates.search.page.perpage = $event" :currentpage="certificates.search.page.currentpage" @updateCurrentPage="certificates.search.page.currentpage = $event" :total="certificates.items.total" @loadData="loadDataCertificates"/>
        </b-tab>
        <b-tab title="Competenze">
          <b-modal v-model="competences.showfilter" title="Filtra" @ok="filterSetCompetences">
            <b-form-group label="Competenza">
              <b-form-input v-model="competences.tempFilter.competence"/>
            </b-form-group>
            <b-form-group label="Mansione">
              <b-form-input v-model="competences.tempFilter.jobsdescription"/>
            </b-form-group>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadDataCompetences">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="warning" @click="filterResetCompetences">Reset filtri</b-btn>
          <b-btn class="mr-2" variant="secondary" @click="competences.showfilter = true">Filtra</b-btn>
          <b-btn variant="success" @click="$refs.competence.loadData(0)">Nuovo</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight-305) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="competences.items.data" :fields="competences.fields" :busy="competences.isBusy" :no-local-sorting="true" :sort-by.sync="competences.search.sort.by" :sort-desc.sync="competences.search.sort.desc" @sort-changed="sortingChangeCompetences" @row-clicked="$refs.competence.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
          <tablebottom :perpage="competences.search.page.perpage" @updatePerPage="competences.search.page.perpage = $event" :currentpage="competences.search.page.currentpage" @updateCurrentPage="competences.search.page.currentpage = $event" :total="competences.items.total" @loadData="loadDataCompetences"/>
        </b-tab>
        <b-tab title="Responsabilità">
          <b-modal v-model="responsibilities.showfilter" title="Filtra" @ok="filterSetResponsibilities">
            <b-form-group label="Responsabilità">
              <b-form-input v-model="responsibilities.tempFilter.responsibility"/>
            </b-form-group>
            <b-form-group label="Mansione">
              <b-form-input v-model="responsibilities.tempFilter.jobsdescription"/>
            </b-form-group>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadDataResponsibilities">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="warning" @click="filterResetResponsibilities">Reset filtri</b-btn>
          <b-btn class="mr-2" variant="secondary" @click="responsibilities.showfilter = true">Filtra</b-btn>
          <b-btn variant="success" @click="$refs.responsibility.loadData(0)">Nuovo</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight-305) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="responsibilities.items.data" :fields="responsibilities.fields" :busy="responsibilities.isBusy" :no-local-sorting="true" :sort-by.sync="responsibilities.search.sort.by" :sort-desc.sync="responsibilities.search.sort.desc" @sort-changed="sortingChangeResponsibilities" @row-clicked="$refs.responsibility.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
          <tablebottom :perpage="responsibilities.search.page.perpage" @updatePerPage="responsibilities.search.page.perpage = $event" :currentpage="responsibilities.search.page.currentpage" @updateCurrentPage="responsibilities.search.page.currentpage = $event" :total="responsibilities.items.total" @loadData="loadDataResponsibilities"/>
        </b-tab>
        <b-tab title="Nomine">
          <b-btn variant="primary" @click="loadDataAppointments">Aggiorna</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight-305) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :tbody-tr-class="rowClassAppointments" :items="appointments.items.data" :fields="appointments.fields" :busy="appointments.isBusy" :no-local-sorting="true" :sort-by.sync="appointments.search.sort.by" :sort-desc.sync="appointments.search.sort.desc" @sort-changed="sortingChangeAppointments" @row-clicked="$refs.appointment.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
            <template v-slot:cell(err)="row">
              <strong>{{ (row.item.employeesko === null && row.item.employeesok !== null) ? 'Nominato correttamente' : (row.item.employeesko !== null ? 'Mancano le nomine' : 'Mancano assegnazioni') }}</strong>
            </template>
          </b-table>
          <tablebottom :perpage="appointments.search.page.perpage" @updatePerPage="appointments.search.page.perpage = $event" :currentpage="appointments.search.page.currentpage" @updateCurrentPage="appointments.search.page.currentpage = $event" :total="appointments.items.total" @loadData="loadDataAppointments"/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import job from './job.vue'
import competence from './competence.vue';
import certificate from './certificate.vue';
import responsibility from './responsibility.vue';
import appointment from './appointment.vue';
import doctoken from "../doctoken";

export default {
  name: "jobs",
  components: { job, competence, certificate, responsibility, appointment, doctoken },
  data() {
    return {
      // 1st tab
      jobs: {
        showfilter: false,
        fields: [{
          key: 'jobdescription',
          label: "Mansione",
          sortable: true,
          thStyle: { width: "10%"},
        },{
          key: "employees",
          label: "Personale",
        },{
          key: "certificates",
          label: "Attestati",
          thStyle: { width: "15%"},
        },{
          key: "competences",
          label: "Competenze",
          thStyle: { width: "20%"},
        },{
          key: 'responsibilities',
          label: 'Responsabilità',
          thStyle: { width: "20%"},
        }],
        filtersMenu: {
          employees: [],
          competences: [],
          responsibilities: [],
        },
        items: {
          total: 0,
          data: [],
        },
        isBusy: true,
        search:{
          page: {
            currentpage: 1,
            perpage: 50,
          },
          filters: {},
          sort: {
            by: 'jobdescription',
            desc: false,
          },
        },
        tempFilter: {
          jobdescription: null,
          employees_id: null,
          competences_id: null,
          responsibilities_id: null,
        },
      },
      // 2nd tab
      certificates: {
        showfilter: false,
        fields: [{
          key: 'competence',
          label: 'Attestato',
          sortable: true,
        },{
          key: 'jobdescriptions',
          label: 'Mansioni',
        }],
        isBusy: true,
        items: {
          total: 0,
          data: [],
        },
        search:{
          page: {
            currentpage: 1,
            perpage: 50,
          },
          filters: {
            competence: null,
            jobsdescription: null,
          },
          sort: {
            by: 'competence',
            desc: false,
          },
        },
        tempFilter: {
          competence: null,
          jobdescription: null,
        },
      },
      // 3rd tab
      competences: {
        showfilter: false,
        fields: [{
          key: 'competence',
          label: 'Competenza',
          sortable: true,
        },{
          key: 'jobdescriptions',
          label: 'Mansioni',
        }],
        isBusy: true,
        items: {
          total: 0,
          data: [],
        },
        search:{
          page: {
            currentpage: 1,
            perpage: 50,
          },
          filters: {
            competence: null,
            jobsdescription: null,
          },
          sort: {
            by: 'competence',
            desc: false,
          },
        },
        tempFilter: {
          competence: null,
          jobdescription: null,
        },
      },
      // 4th tab
      responsibilities: {
        showfilter: false,
        fields: [{
          key: 'responsibility',
          label: 'Responsabilità',
          sortable: true,
        },{
          key: 'jobdescriptions',
          label: 'Mansioni',
        }],
        isBusy: true,
        items: {
          total: 0,
          data: [],
        },
        search:{
          page: {
            currentpage: 1,
            perpage: 50,
          },
          filters: {
            responsibility: null,
            jobsdescription: null,
          },
          sort: {
            by: 'responsibility',
            desc: false,
          },
        },
        tempFilter: {
          responsibility: null,
          jobsdescription: null,
        },
      },
      // 5th tab
      appointments: {
        showfilter: false,
        fields: [{
          key: 'jobdescription',
          label: 'Mansione',
          sortable: true,
        },{
          key: 'err',
          label: 'Stato',
        },{
          key: 'employeesok',
          label: 'Nominati',
        },{
          key: 'employeesko',
          label: 'Da nominare',
        }],
        isBusy: true,
        items: {
          total: 0,
          data: [],
        },
        search:{
          page: {
            currentpage: 1,
            perpage: 50,
          },
          sort: {
            by: 'jobdescription',
            desc: false,
          },
        },
      },
      document: null
    }
  },
  computed: {
    token (){
      return this.$store.getters.getToken;
    },
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.loadDataJobs();
      this.loadDataCompetences();
      this.loadDataCertificates();
      this.loadDataResponsibilities();
      this.loadDataAppointments();
    },
    // 1st tab
    loadDataJobs(){
      this.jobs.isBusy = true;
      this.openHttp("jobs_jobs", { search: this.jobs.search }).then((result) => {
        this.jobs.items = result;
        this.jobs.isBusy = false;
        this.loadDocument();
      })
    },
    loadDocument(){
      this.openHttp("jobs_document", null).then((result) => {
        this.document = result;
      })
    },
    generateDocument(){
      this.generateDocumentHttp("jobs");
    },
    filterOpenJobs(){
      this.openHttp("jobs_filters", this.search).then((result) => {
        this.jobs.showfilter = true;
        this.jobs.filtersMenu = result;
      })
    },
    filterSetJobs(){
      this.jobs.showfilter = false;
      this.jobs.search.filters = this.jobs.tempFilter;
      this.loadDataJobs();
    },
    filterResetJobs(){
      this.jobs.tempFilter = {
        jobdescription: null,
        employees_id: null,
        competences_id: null,
        responsibilities_id: null,
      };
      this.filterSetJobs();
    },
    sortingChangeJobs(ctx) {
      this.jobs.search.sort.by = ctx.sortBy;
      this.jobs.search.sort.desc = ctx.sortDesc;
      this.loadDataJobs();
    },
    //2nd tab
    loadDataCertificates(){
      this.certificates.isBusy = true;
      this.openHttp("jobs_certificates", { search: this.certificates.search }).then((result) => {
        this.certificates.items = result;
        this.certificates.isBusy = false;
        this.loadDocument();
      })
    },
    filterOpenCertificates(){
      this.certificates.showfilter = true;
    },
    filterSetCertificates(){
      this.certificates.showfilter = false;
      this.certificates.search.filters = this.certificates.tempFilter;
      this.loadDataCertificates();
    },
    filterResetCertificates(){
      this.certificates.tempFilter = {
        competence: null,
        jobdescription: null,
      };
      this.filterSetCertificates();
    },
    sortingChangeCertificates(ctx) {
      this.certificates.search.sort.by = ctx.sortBy;
      this.certificates.search.sort.desc = ctx.sortDesc;
      this.loadDataCertificates();
    },
    //3rd tab
    loadDataCompetences(){
      this.competences.isBusy = true;
      this.openHttp("jobs_competences", { search: this.certificates.search }).then((result) => {
        this.competences.items = result;
        this.competences.isBusy = false;
        this.loadDocument();
      })
    },
    filterSetCompetences(){
      this.competences.showfilter = false;
      this.competences.search.filters = this.competences.tempFilter;
      this.loadDataCompetences();
    },
    filterResetCompetences(){
      this.competences.tempFilter = {
        competence: null,
        jobdescription: null,
      };
      this.filterSetCompetences();
    },
    sortingChangeCompetences(ctx) {
      this.competences.search.sort.by = ctx.sortBy;
      this.competences.search.sort.desc = ctx.sortDesc;
      this.loadDataCompetences();
    },
    // 4th tab
    loadDataResponsibilities(){
      this.responsibilities.isBusy = true;
      this.openHttp("jobs_responsibilities", { search: this.responsibilities.search }).then((result) => {
        this.responsibilities.items = result;
        this.responsibilities.isBusy = false;
        this.loadDocument();
      })
    },
    filterSetResponsibilities(){
      this.responsibilities.showfilter = false;
      this.responsibilities.search.filters = this.responsibilities.tempFilter;
      this.loadDataResponsibilities();
    },
    filterResetResponsibilities(){
      this.responsibilities.tempFilter = {
        responsibility: null,
        jobsdescription: null,
      };
      this.filterSetResponsibilities();
    },
    sortingChangeResponsibilities(ctx) {
      this.responsibilities.search.sort.by = ctx.sortBy;
      this.responsibilities.search.sort.desc = ctx.sortDesc;
      this.loadDataResponsibilities();
    },
    // 5th tab
    rowClassAppointments(item) {
      if (item !== null){
        if (item.employeesko === null && item.employeesok !== null){
          return 'table-success';
        } else if (item.employeesko !== null) {
          return 'table-warning';
        } else {
          return 'table-danger';
        }
      }
    },
    loadDataAppointments(){
      this.appointments.isBusy = true
      this.openHttp("jobs_appointments", { search: this.appointments.search }).then((result) => {
        this.appointments.items = result;
        this.appointments.isBusy = false;
        this.loadDocument();
      })
    },
    sortingChangeAppointments(ctx) {
      this.appointments.search.sort.by = ctx.sortBy;
      this.appointments.search.sort.desc = ctx.sortDesc;
      this.loadDataAppointments();
    }
  }
}
</script>