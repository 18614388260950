<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" title="Template" size="lg" @hidden="closeForm" @close="closeForm">
      <b-form-group label="Nome">
        <b-form-input v-model="data.template.templatename"/>
      </b-form-group>
      <b-form-group label="Controllo">
        <b-form-input v-model="data.template.sqlcheck"/>
      </b-form-group>
      <b-row>
        <b-col lg="8">
          <b-form-group label="SQL">
            <b-form-textarea v-model="data.template.sqlaction" rows="5" max-rows="10" @change="checkParams($event)"/>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group>
            <b-table no-border-collapse sticky-header="400px" class="mt-3" :items="data.template.sqlparameters" :fields="fields">
              <template v-slot:cell(col)="row">
                <b-form-input size="sm" v-model="row.item.col"/>
              </template>
            </b-table>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Istruzioni">
        {{ data.template.templatesinstructions }}
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.template.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "templatetemplate",
  data() {
    return {
      show: false,
      data: null,
      fields: [{
        key: "prog",
        label: "Progressivo"
      },{
        key: "col",
        label: "Colname"
      }]
    }  
  },
  methods: {
    loadData(id) {
      this.openHttp("templates_template", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    checkParams( textCheck ){
      let i = 1;
      while ( textCheck.indexOf("$" + i) > 0 && i < 100 ) {
        if ( this.data.template.sqlparameters.findIndex(x => x.prog == i) === -1 ){
          this.data.template.sqlparameters.push({
            prog: i,
            col: "???",
          })
        }
        i++;
      }
      while ( i < 100 ) {
        if ( textCheck.indexOf("$" + i) === -1 && this.data.template.sqlparameters.findIndex(x => x.prog == i) > 0 ){
          this.data.template.sqlparameters.splice( this.data.template.sqlparameters.findIndex(x => x.prog == i), 1);
        }
        i++;
      }
    },
    saveData(){
      this.saveHttp("templates_template_save", this.data.template, "Template salvato!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("templates_template_delete", { id: this.data.template.id }, "Template eliminato!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('reload');
    }
  }
}
</script>