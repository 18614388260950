<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" title="Opportunità" scrollable @hidden="closeForm" @close="closeForm">
      <b-row>
        <b-col lg="2">
          <b-form-group label="Data apertura">
            <b-form-input type="date" v-model="data.opportunity.dateopening" required/>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group label="Responsabile apertura">
            <b-form-select v-model="data.opportunity.employeesopen_id" :options="data.employees" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6">
          <b-form-group label="Descrizione della situazione pre-opportunità">
            <b-form-textarea v-model="data.opportunity.description" rows="6"/>
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group label="Azione/opportunità di miglioramento">
            <b-form-textarea v-model="data.opportunity.treatment" rows="6"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6">
          <b-form-group label="Norme">
            <multiselect v-model="data.opportunity.standardsSupport" :options="data.standards" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.opportunity.standards = updateCombo(data.opportunity.standardsSupport)"/>
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group label="Processi">
            <multiselect v-model="data.opportunity.processesSupport" :options="data.processes" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.opportunity.processes = updateCombo(data.opportunity.processesSupport)"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="2">
          <b-form-group label="Data chiusura">
            <b-form-input type="date" v-model="data.opportunity.dateclosure"/>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group label="Responsabile chiusura">
            <b-form-select v-model="data.opportunity.employeesclose_id" :options="data.employees" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Costo">
            <b-form-input type="number" v-model="data.opportunity.cost"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Esito">
            <b-form-select v-model="data.opportunity.success" :options="successCombo" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
      </b-row>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.opportunity.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "opportunity",
  data() {
    return {
      show: false,
      data: null,
      successCombo: [{
        id: null,
        val: "In corso"
      },{
        id: true,
        val: "Conclusa con esito positivo"
      },{
        id: false,
        val: "Conclusa con esito negativo"
      }]
    }  
  },
  methods: {
    loadData( id, auditsanswers_id ){
      this.openHttp("opportunities_opportunity", { id: id, auditsanswers_id: auditsanswers_id }).then((result) => {
        result.opportunity.standardsSupport = this.convertCombo(result.standards, result.opportunity.standards);
        result.opportunity.processesSupport = this.convertCombo(result.processes, result.opportunity.processes);
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("opportunities_opportunity_save", this.data.opportunity, "Opportunità salvata!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("opportunities_opportunity_delete", { id: this.data.opportunity.id }, "Opportunità eliminata!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit("reload");
    }
  }
}
</script>