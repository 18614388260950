<template>
  <div>
    <audit ref="audit" @reload="loadData"/>
    <doctoken ref="doctoken"/>
    <b-btn class="mr-2" variant="primary" @click="loadData">Aggiorna</b-btn>
    <b-btn class="mr-2" variant="success" @click="loadItem(0)">Nuovo</b-btn>
    <b-btn variant="info" v-show="items.record_id > 0" @click="$refs.doctoken.downloadRecord(items.record_id)">Scarica</b-btn>
    <b-table no-border-collapse hover responsive :sticky-header="(winHeight - 205) + 'px'" show-empty mode="remote" class="mt-3" :items="items.data" :fields="fields" :busy="isBusy" :no-local-sorting="true" :sort-by.sync="search.sort.by" :sort-desc.sync="search.sort.desc" @sort-changed="sortingChange" @row-clicked="loadItem($event.id)">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>Non ci sono risultati</template>
      <template v-slot:cell(documentsrecords_id)="row">
        <b-btn variant="info" size="sm" v-show="row.item.documentsrecords_id > 0" @click="$refs.doctoken.downloadRecord(row.item.documentsrecords_id)">Scarica</b-btn>
      </template>
    </b-table>
    <tablebottom :perpage="search.page.perpage" @updatePerPage="search.page.perpage = $event" :currentpage="search.page.currentpage" @updateCurrentPage="search.page.currentpage = $event" :total="items.total" @loadData="loadData"/>
  </div>
</template>

<script>
import audit from './audit.vue'

export default {
  name: "audits",
  components: { audit },
  data() {
    return {
      isBusy: true,
      search: {
        page: {
          currentpage: 1,
          perpage: 50,
        },
        filters: {},
        sort: {
          by: 'employee',
          desc: false,
        },
      },
      fields: [{
        key: 'opening',
        label: "Apertura",
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'closure',
        label: "Chiusura",
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'auditor',
        label: "Auditor",
      },{
        key: 'standards',
        label: 'Norme',
      },{
        key: 'employees',
        label: 'Partecipanti',
      },{
        key: "documentsrecords_id",
        label: "Report"
      }],
      items: {
        total: 0,
        data: [],
      }
    }  
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.isBusy = true;
      this.openHttp("audits_audits", { search: this.search }).then((result) => {
        this.items = result;
        this.isBusy = false;
      })
    },
    sortingChange(ctx) {
      this.search.sort.by = ctx.sortBy;
      this.search.sort.desc = ctx.sortDesc;
      this.loadData();
    },
    loadItem(id){
      this.$refs.audit.loadData(id);
    }
  }
}
</script>