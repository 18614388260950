<template>
  <div>
    <b-modal v-model="show" v-if="data !== null" size="xl" title="Risorse umane" scrollable @hidden="closeForm" @close="closeForm">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Persona" active>
            <b-row>
              <b-col lg="2">
                <b-form-group label="Titolo">
                  <b-form-input v-model="data.employee.title"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Nome">
                  <b-form-input v-model="data.employee.firstname"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Cognome">
                  <b-form-input v-model="data.employee.lastname"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Stato">
                  <b-form-select v-model="data.employee.active" :options="activeCombo" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3">
                <b-form-group label="Tipologia">
                  <b-form-select v-model="data.employee.employeeskinds_id" :options="data.employeeskinds" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Data inizio contratto">
                  <b-form-input v-model="data.employee.startdate" type="date"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Data fine contratto">
                  <b-form-input v-model="data.employee.dismissaldate" type="date"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="10">
                <b-form-group label="Mansioni">
                  <multiselect v-model="data.employee.jobdescriptionsSupport" :options="data.jobdescriptions" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.employee.jobdescriptions = updateCombo(data.employee.jobdescriptionsSupport)"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Autorità sistema">
                  <b-form-select v-model="data.employee.canapprovedoc" :options="canapprovedocCombo" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Note">
              <b-form-textarea v-model="data.employee.note"/>
            </b-form-group>
          </b-tab>
          <b-tab title="Competenze" v-if="data.employee.id > 0">
            <b-table hover class="mt-3" :items="data.competences" :fields="fieldsCompetences"/>
          </b-tab>
          <b-tab title="Formazione" v-if="data.employee.id > 0">
            <b-table hover class="mt-3" :items="data.trainings" :fields="fieldsTrainings">
              <template v-slot:cell(certificate)="row">
                <b-btn v-if="row.value === true" size="sm" variant="info" @click="downloadCertificateTraining(row.item.id)">Scarica</b-btn>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Corsi sicurezza" v-if="data.employee.id > 0">
            <b-table hover class="mt-3" :items="data.safety" :fields="fieldsSafety">
              <template v-slot:cell(certificate)="row">
                <b-btn v-if="row.value === true" size="sm" variant="info" @click="downloadCertificateSafety(row.item.id)">Scarica</b-btn>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Visite mediche" v-if="data.employee.id > 0">
            <b-table hover class="mt-3" :items="data.medical" :fields="fieldsMedical">
              <template v-slot:cell(certificate)="row">
                <b-btn v-if="row.value === true" size="sm" variant="info" @click="downloadCertificateMedical(row.item.id)">Scarica</b-btn>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Documenti" v-if="data.employee.id > 0">
            <employeedocument ref="document" @reload="loadData(data.employee.id)"/>
            <b-btn variant="primary" class="mr-2" @click="loadDocument(0)">Nuovo</b-btn>
            <b-table hover class="mt-3" :items="data.documents" :fields="fieldsDocuments" @row-clicked="loadDocument($event.id)">
              <template v-slot:cell(id)="row">
                <b-btn size="sm" variant="info" @click="downloadDocument(row.item.id)">Scarica</b-btn>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.employee.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
    <form ref="downloadAttachmentTrainingForm" method="POST" target="_blank" action="/api/file.php">
      <input type="hidden" name="form" value="trainings">
      <input type="hidden" name="document" value="attachmenttraining">
      <input type="hidden" name="id" ref="downloadAttachmentTrainingFormId">
      <input type="hidden" name="token" :value="token">
    </form>
    <form ref="downloadAttachmentSaferyTrainingForm" method="POST" target="_blank" action="/api/file.php">
      <input type="hidden" name="form" value="safetytrainings">
      <input type="hidden" name="document" value="attachmentsafeytraining">
      <input type="hidden" name="id" ref="downloadAttachmentSaferyTrainingFormId">
      <input type="hidden" name="token" :value="token">
    </form>
    <form ref="downloadAttachmentMedicalForm" method="POST" target="_blank" action="/api/file.php">
      <input type="hidden" name="form" value="medicals">
      <input type="hidden" name="document" value="attachmentmedical">
      <input type="hidden" name="id" ref="downloadAttachmentMedicalFormId">
      <input type="hidden" name="token" :value="token">
    </form>
    <form ref="downloadDocumentEmployeeForm" method="POST" target="_blank" action="/api/file.php">
      <input type="hidden" name="form" value="employees">
      <input type="hidden" name="document" value="documentemployee">
      <input type="hidden" name="docname" ref="downloadDocumentEmployeeFormDocname">
      <input type="hidden" name="id" ref="downloadDocumentEmployeeFormId">
      <input type="hidden" name="token" :value="token">
    </form>
  </div>
</template>

<script>
import employeedocument from './employeedocument.vue';

export default {
  name: "employee",
  components: { employeedocument },
  data() {
    return {
      show: false,
      data: null,
      activeCombo: [{
        id: true,
        val: "Attivo"
      },{
        id: false,
        val: "Dimesso / Licenziato"
      }],
      comboLevel: [{
        id: 0,
        val: "Nessuna"
      },{
        id: 1,
        val: "Conoscenza di base"
      },{
        id: 2,
        val: "Autonomia parziale"
      },{
        id: 3,
        val: "Autonomia completa"
      },{
        id: 4,
        val: "Autonomia completa e responsabilità"
      }],
      canapprovedocCombo: [{
        id: true,
        val: "Può approvare documenti"
      },{
        id: false,
        val: "Non può approvare documenti"
      }],
      fieldsCompetences: [{
        key: 'competence',
        label: "Competenza",
        sortable: true,
      },{
        key: 'target',
        label: "Obiettivo",
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          return this.comboLevel.find(x => x.id === value).val;
        }
      },{
        key: 'actual',
        label: "Attuale",
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          return this.comboLevel.find(x => x.id === value).val;
        }
      },{
        key: 'date',
        label: "Aggiornato",
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      }],
      fieldsTrainings: [{
        key: 'trainingskind',
        label: "Ambito",
        sortable: true
      },{
        key: 'training',
        label: "Formazione",
        sortable: true
      },{
        key: 'verifydate',
        label: "Data",
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'verified',
        label: "Stato",
        sortable: true
      },{
        key: 'comment',
        label: "Commento",
      },{
        key: 'certificate',
        label: "Attestato",
      }],
      fieldsSafety: [{
        key: 'trainingskind',
        label: "Formazione",
        sortable: true
      },{
        key: 'verifydate',
        label: "Data",
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'teacher',
        label: "Formatore",
        sortable: true
      },{
        key: 'certificate',
        label: "Attestato",
      }],
      fieldsMedical: [{
        key: 'trainingskind',
        label: "Visita",
        sortable: true
      },{
        key: 'verifydate',
        label: "Data",
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'teacher',
        label: "Medico",
        sortable: true
      }],
      fieldsDocuments: [{
        key: 'docname',
        label: "Documento",
        sortable: true
      },{
        key: 'archived',
        label: "Stato",
        formatter: value => {
          return value === true ? "Archiviato" : "In corso di validità";
        }
      },{
        key: 'deadlinedate',
        label: "Scadenza",
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'note',
        label: "Note",
      },{
        key: "id",
        label: "",
      }],
    }  
  },
  computed: {
    token (){
      return this.$store.getters.getToken;
    }
  },
  methods: {
    loadData( id ){
      this.openHttp("employees_employee", { id: id }).then((result) => {
        result.employee.jobdescriptionsSupport = this.convertCombo(result.jobdescriptions, result.employee.jobdescriptions);
        this.data = result;
        this.show = true;
      })
    },
    loadDocument( id ){
      this.$refs.document.loadData( id, this.data.employee.id );
    },
    saveData(){
      this.saveHttp("employees_employee_save", this.data.employee, "Risorse umane salvata!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    deleteData(){
      alert("Attenzione: se la persona ha collegato un accesso, non sarà possibile eliminarla");
      this.deleteHttp("employees_employee_delete", { id: this.data.employee.id }, "Risorse umane eliminata!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('reload');
    },
    downloadCertificateTraining(id){
      this.$refs.downloadAttachmentTrainingFormId.value = id;
      this.$refs.downloadAttachmentTrainingForm.submit();
    },
    downloadCertificateSafety(id){
      this.$refs.downloadAttachmentSaferyTrainingFormId.value = id;
      this.$refs.downloadAttachmentSaferyTrainingForm.submit();
    },
    downloadCertificateMedical(id){
      this.$refs.downloadAttachmentMedicalFormId.value = id;
      this.$refs.downloadAttachmentMedicalForm.submit();
    },
    downloadDocument(id){
      this.$refs.downloadDocumentEmployeeFormId.value = id;
      this.$refs.downloadDocumentEmployeeFormDocname.value = this.data.documents.find(x => x.id === id).docname;
      this.$refs.downloadDocumentEmployeeForm.submit();
    },
  }
}
</script>