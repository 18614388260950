<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" title="Competenza" scrollable @hidden="closeForm()" @close="closeForm()">
      <b-form-group label="Competenza">
        <b-form-input v-model="data.competence.competence"/>
      </b-form-group>
      <b-form-group label="Mansioni associate">
        <b-table :sticky-header="(winHeight-355) + 'px'" responsive show-empty :items="data.competence.jobdescriptions" :fields="fieldsJobdescriptions">
          <template v-slot:empty>Non ci sono competenze necessarie</template>
          <template v-slot:cell(minlevel)="row">
            <b-form-select size="sm" v-model="row.item.minlevel" :options="levelCompetenceCombo" value-field="id" text-field="val"/>
          </template>
        </b-table>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.competence.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "competence",
  data() {
    return {
      show: false,
      data: null,
      fieldsJobdescriptions: [{
        key: 'jobdescriptions_id',
        label: 'Mansione',
        sortable: true,
        formatter: value => {
          return this.data !== null ? this.data.jobdescriptions.find(x => x.id === value).val : null;
        }
      },{
        key: 'minlevel',
        label: 'Livello necessario',
        sortable: true,
      }],
      levelCompetenceCombo: [{
        id: null,
        val: "Non necessaria",
      },{
        id: 1,
        val: "Conoscenza di base",
      },{
        id: 2,
        val: "Autonomia parziale",
      },{
        id: 3,
        val: "Autonomia completa",
      },{
        id: 4,
        val: "Autonomia completa e responsabilità",
      }]
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    loadData( id ){
      this.openHttp("jobs_competence", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("jobs_competence_save", this.data.competence, "Competenza salvata!" ).then((result) => {
        if ( result.result == true ) this.closeForm(true);
      })
    },
    deleteData(){
      this.deleteHttp("jobs_competence_delete", { id: this.data.competence.id }, "Competenza eliminata!" ).then((result) => {
        if ( result.result == true ) this.closeForm(true);
      })
    },
    closeForm(reload = false){
      this.show = false;
      this.data = null;
      if (reload === true) this.$emit('reload');
    }
  }
}
</script>