<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" title="Azione correttiva" scrollable @hidden="closeForm()" @close="closeForm()">
      <nonconformity ref="nonconformity"/>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Apertura" active>
            <b-alert show v-if="data.risksanalysis !== null">
              <b>Rischio: </b>{{ data.risksanalysis.risk }}<br>
              <b>Cause: </b>{{ data.risksanalysis.causes }}<br>
              <b>Mitigazione: </b>{{ data.risksanalysis.mitigation }}<br>
              <b>Prevenzione: </b>{{ data.risksanalysis.prevenction }}
            </b-alert>
            <b-alert show variant="danger" v-if="data.nonconformity !== null">
              <h4 class="alert-heading">Non conofrmità {{ data.nonconformity.progressive }} - {{ data.nonconformity.yearnc }}</h4>
              <p>{{ data.nonconformity.description }}</p>
              <hr>
              <p class="mb-0">
                <b-btn variant="danger"  @click="$refs.nonconformity.loadData(data.nonconformity.id)">Vedi la Non conformità</b-btn>
              </p>
            </b-alert>
            <b-row>
              <b-col lg="3">
                <b-form-group label="Data apertura">
                  <b-form-input type="date" v-model="data.correctiveaction.dateopen"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Data obiettivo">
                  <b-form-input type="date" v-model="data.correctiveaction.datetarget"/>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group label="Responsabile apertura">
                  <b-form-select v-model="data.correctiveaction.employeesopen_id" :options="data.employees" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Azione correttiva">
              <b-form-textarea v-model="data.correctiveaction.descriptionopen" rows="3"/>
            </b-form-group>
            <b-form-group label="Note apertura">
              <b-form-textarea v-model="data.correctiveaction.noteopen"/>
            </b-form-group>
            <b-row v-if="data.correctiveaction.nonconformities_id == null && data.correctiveaction.risksanalysis_id == null">
              <b-col lg="6">
                <b-form-group label="Norme">
                  <multiselect v-model="standardsSupport" :options="data.standards" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.correctiveaction.standards = updateCombo(standardsSupport)"/>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group label="Processi">
                  <multiselect v-model="processesSupport" :options="data.processes" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.correctiveaction.processes = updateCombo(processesSupport)"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Chiusura">            
            <b-row>
              <b-col lg="3">
                <b-form-group label="Data verifica">
                  <b-form-input type="date" v-model="data.correctiveaction.dateverify"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Data chiusura">
                  <b-form-input type="date" v-model="data.correctiveaction.dateclose"/>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group label="Responsabile chiusura">
                  <b-form-select v-model="data.correctiveaction.employeesclose_id" :options="data.employees" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Evidenza del risultato">
              <b-form-textarea v-model="data.correctiveaction.descriptionclose" rows="3"/>
            </b-form-group>
            <b-form-group label="Note chiusura">
              <b-form-textarea v-model="data.correctiveaction.noteclose"/>
            </b-form-group>
            <b-row>
              <b-col lg="4">
                <b-form-group label="Esito">
                  <b-form-select v-model="data.correctiveaction.success" :options="successCombo" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Costo (€)">
                  <b-form-input type="number" v-model="data.correctiveaction.cost"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="primary" class="mr-2" v-show="data.correctiveaction.id > 0" @click="downloadData">Scarica</b-btn>
          <b-btn variant="danger" v-show="data.correctiveaction.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
      <form ref="downloadForm" method="POST" target="_blank" action="/api/file.php">
        <input type="hidden" name="form" value="correctiveaction">
        <input type="hidden" name="document" value="correctiveaction">
        <input type="hidden" name="id" :value="data.correctiveaction.id">
        <input type="hidden" name="token" :value="token">
      </form>
    </b-modal>
  </div>
</template>

<script>
import nonconformity from './nonconformity.vue'

export default {
  name: "correctiveaction",
  components: { nonconformity },
  data() {
    return {
      show: false,
      data: null,
      successCombo: [{
        id: null,
        val: "In corso"
      },{
        id: true,
        val: "Conclusa con esito positivo"
      },{
        id: false,
        val: "Conclusa con esito negativo"
      }],
      standardsSupport: [],
      processesSupport: []
    }  
  },
  computed: {
    token (){
      return this.$store.getters.getToken;
    },
  },
  methods: {
    loadData( id, auditsanwers_id ){
      this.openHttp("correctiveactions_correctiveaction", { id: id, auditsanwers_id: auditsanwers_id }).then((result) => {
        this.standardsSupport = this.convertCombo(result.standards, result.correctiveaction.standards);
        this.processesSupport = this.convertCombo(result.processes, result.correctiveaction.processes);
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("correctiveactions_correctiveaction_save", this.data.correctiveaction, "Azione correttiva salvata!" ).then((result) => {
        if ( result.result == true ) this.closeForm( true );
      })
    },
    deleteData(){
      this.deleteHttp("correctiveactions_correctiveaction_delete", { id: this.data.correctiveaction.id }, "Azione correttiva eliminata!" ).then((result) => {
        if ( result.result == true ) this.closeForm( true );
      })
    },
    downloadData(){
      this.$refs.downloadForm.submit();
    },
    closeForm( reload = false ){
      this.show = false;
      this.data = null;
      if ( reload === true ) this.$emit('reload');
    }
  }
}
</script>