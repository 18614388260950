<template>
  <div>
    <training ref="training" @reload="loadData()"/>
    <doctoken ref="doctoken"/>
    <b-modal v-model="showFilter" title="Filtra" @ok="filterSet">
      <b-form-group label="Corso">
        <b-form-input type="text" v-model="tempFilter.training" />
      </b-form-group>
      <b-form-group label="Personale">
        <b-form-select v-model="tempFilter.employees_id" :options="comboSeeAll(filtersMenu.employees)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Norma">
        <b-form-select v-model="tempFilter.standards_id" :options="comboSeeAll(filtersMenu.standards)" value-field="id" text-field="val"/>
      </b-form-group>
    </b-modal>
    <b-btn class="mr-2" variant="primary" @click="loadData">Aggiorna</b-btn>
    <b-btn class="mr-2" variant="warning" @click="filterReset">Reset filtri</b-btn>
    <b-btn class="mr-2" variant="secondary" @click="filterOpen">Filtra</b-btn>
    <b-btn class="mr-2" variant="success" @click="loadItem(0)">Nuovo</b-btn>
    <b-btn class="mr-2" variant="info" v-if="document != null && document.records_id > 0" @click="$refs.doctoken.downloadRecord(document.records_id)">Scarica Documento {{ document.date }}</b-btn>
    <b-btn variant="success" @click="generateDocument()">Genera Documento</b-btn>
    <b-table no-border-collapse :sticky-header="(winHeight - 205) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="items.data" :fields="fields" :busy="isBusy" :no-local-sorting="true" :sort-by.sync="search.sort.by" :sort-desc.sync="search.sort.desc" @sort-changed="sortingChange" @row-clicked="loadItem($event.id)">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
      <template v-slot:empty>Non ci sono risultati</template>
      <template v-slot:cell(employees)="row">
        <b-button variant="primary" size="sm" @click="row.toggleDetails" class="mr-2">
          {{ row.detailsShowing ? 'Nascondi' : 'Dettagli'}}
        </b-button>
      </template>
      <template v-slot:cell(participantsnumber)="row">
        {{ row.item.employeesok.length + row.item.employeesko.length + row.item.employeeswait.length }}
      </template>
      <template v-slot:row-details="row">
        <b-row>
          <b-col>
            <b-card title="Superato">
              <b-card-text>
                <ul v-show="row.item.employeesok.length > 0">
                  <li v-for="(item, index) in row.item.employeesok" :key="index">{{ item }}</li>
                </ul>
                <ul v-show="row.item.employeesok.length == 0">
                  <li>Nessun partecipante ha superato il corso</li>
                </ul>
              </b-card-text>
            </b-card>
          </b-col>
          <b-col>
            <b-card title="Non superato">
              <b-card-text>
                <ul v-show="row.item.employeesko.length > 0">
                  <li v-for="(item, index) in row.item.employeesko" :key="index">{{ item }}</li>
                </ul>
                <ul v-show="row.item.employeesko.length == 0">
                  <li>Nessun partecipante ha fallito il corso</li>
                </ul>
              </b-card-text>
            </b-card>
          </b-col>
          <b-col>
            <b-card title="In corso">
              <b-card-text>
                <ul v-show="row.item.employeeswait.length > 0">
                  <li v-for="(item, index) in row.item.employeeswait" :key="index">{{ item }}</li>
                </ul>
                <ul v-show="row.item.employeeswait.length == 0">
                  <li>Nessun partecipante sta facendo il corso</li>
                </ul>
              </b-card-text>
            </b-card>
          </b-col>
          <b-col>
            <b-card title="Date">
              <b-card-text>
                <ul v-show="row.item.dates.length > 0">
                  <li v-for="(item, index) in row.item.dates" :key="index">{{ dateFormatter(item.from, item.to, item.hours) }}</li>
                </ul>
                <ul v-show="row.item.dates.length == 0">
                  <li>Nessuna data registrata</li>
                </ul>
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
      </template>
    </b-table>
    <tablebottom :perpage="search.page.perpage" @updatePerPage="search.page.perpage = $event" :currentpage="search.page.currentpage" @updateCurrentPage="search.page.currentpage = $event" :total="items.total" @loadData="loadData"/>
  </div>
</template>

<script>
import training from './training.vue'

export default {
  name: "trainings",
  components: { training },
  data() {
    return {
      isBusy: true,
      showFilter: false,
      search: {
        page: {
          currentpage: 1,
          perpage: 50,
        },
        filters: {},
        sort: {
          by: "training",
          desc: false, 
        },
      },
      tempFilter: {
        training: null,
        standards_id: null,
        employees_id: null,
      },
      filtersMenu: {
        standards: [],
        employees: [],
      },
      fields: [{
        key: 'training',
        label: 'Formazione',
        sortable: true,
      },{
        key: 'standards',
        label: 'Norme',
      },{
        key: 'participantsnumber',
        label: "Partecipanti",
      },{
        key: 'fromdate',
        label: 'Inizio',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'todate',
        label: 'Fine',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'verifydate',
        label: 'Verifica',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'repeatdate',
        label: 'Ripetere',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: "employees",
        label: "",
      }],
      items: {
        total: 0,
        data: [],
      },
      document: null
    }  
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    dateFormatter(fromDate, toDate, hours){
      let dateFromDate = fromDate !== null ? new Date(fromDate) : null;
      let dateToDate = toDate !== null ? new Date(toDate) : null;
      let stringReturn = '??' ;
      if (dateFromDate !== null){
        stringReturn = [dateFromDate.getDate(), dateFromDate.getMonth() + 1, dateFromDate.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
      }
      if (fromDate !== toDate){
        if (toDate !== null){
          stringReturn += ' - ' + [dateToDate.getDate(), dateToDate.getMonth() + 1, dateToDate.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        } else {
          stringReturn += ' - ??';
        }
      }
      stringReturn += " tot h: " + hours;
      return stringReturn;
    },
    loadData(){
      this.isBusy = true;
      this.openHttp("trainings_trainings", { search: this.search }).then((result) => {
        this.items = result;
        this.isBusy = false;
        this.loadDocument();
      })
    },
    loadDocument(){
      this.openHttp("trainings_document", null).then((result) => {
        this.document = result;
      })
    },
    generateDocument(){
      this.generateDocumentHttp("trainings");
    },
    filterOpen(){
      this.openHttp("trainings_trainings_filters", { }).then((result) => {
        this.filtersMenu = result;
        this.showFilter = true;
      })
    },
    filterSet(){
      this.showFilter = false;
      this.search.filters = this.tempFilter;
      this.loadData();
    },
    filterReset(){
      this.tempFilter = {
        training: null,
        standards_id: null,
        employees_id: null,
      };
      this.filterSet();
    },
    sortingChange(ctx) {
      this.search.sort.by = ctx.sortBy;
      this.search.sort.desc = ctx.sortDesc;
      this.loadData();
    },
    loadItem(id){
      this.$refs.training.loadData(id);
    }
  }
}
</script>