<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" title="Processo" scrollable @hidden="closeForm" @close="closeForm">
      <b-row>
        <b-col lg="5">
          <b-form-group label="Processo">
            <b-form-input v-model="data.process.process"/>
          </b-form-group>
        </b-col>
        <b-col lg="5">
          <b-form-group label="Processo principale">
            <b-form-select v-model="data.process.processes_id" :options="data.processes" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Tipologia">
            <b-form-select v-model="data.process.external" :options="internalCombo" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Mansioni">
        <multiselect v-model="jobdescriptionsSupport" :options="data.jobdescriptions" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.process.jobdescriptions = updateCombo(jobdescriptionsSupport)"/>
      </b-form-group>
      <b-form-group label="Note">
        <b-form-textarea v-model="data.process.note" rows="6"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.process.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "process",
  data() {
    return {
      show: false,
      data: null,
      internalCombo: [{
        id: false,
        val: "Interno",
      },{
        id: true,
        val: "Esterno",
      }],
      jobdescriptionsSupport: []
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
    winWidth (){
      return this.$store.getters.getWinWidth;
    },
  },
  methods: {
    loadData( id ){
      this.show = false;
      this.openHttp("processes_process", { id: id }).then((result) => {
        this.jobdescriptionsSupport = this.convertCombo(result.jobdescriptions, result.process.jobdescriptions);
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("processes_process_save", this.data.process, "Processo salvato!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("processes_process_delete", { id: this.data.process.id }, "Processo eliminato!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('reload');
    }
  }
}
</script>
