<template>
  <div>
    <contextdescription ref="contextdescription" @reload="loadData"/>
    <strengths ref="strengths" @reload="loadData"/>
    <weaknesses ref="weaknesses" @reload="loadData"/>
    <opportunities ref="opportunities" @reload="loadData"/>
    <b-row>
      <b-col lg="12">
        <b-btn class="mr-2" variant="primary" @click="loadData">Aggiorna</b-btn>
        <b-btn class="mr-2" variant="info" v-if="document != null && document.records_id > 0" @click="$refs.doctoken.downloadRecord(document.records_id)">Scarica Documento {{ document.date }}</b-btn>
        <b-btn variant="success" @click="generateDocument()">Genera Documento</b-btn>
        <doctoken ref="doctoken"/>
      </b-col>
    </b-row>
    <b-card no-body v-if="data != null" class="mt-2">
      <b-tabs card v-model="tabIndex">
        <b-tab v-for="(item, index) in data.analysis" :key="index" :title="item.standard">
          <b-table-simple bordered responsive>
            <b-thead head-variant="light">
              <b-tr>
                <b-th colspan="2">Analisi del Contesto</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-td colspan="2">
                  <div v-for="(item_context, index_context) in item.contexts" :key="index_context" @click="$refs.contextdescription.loadData(item_context.id)">
                    <div v-html="item_context.context"></div>
                    <b-btn variant="primary" class="mt-2" size="sm" @click="$refs.contextdescription.loadData(item_context.id)">Modifica</b-btn>
                  </div>
                  <b-btn v-if="item.contexts.length === 0" variant="primary" class="mt-2" size="sm" @click="$refs.contextdescription.loadData(0)">Modifica</b-btn>
                </b-td>
              </b-tr>
            </b-tbody>
            <b-thead head-variant="light">
              <b-tr>
                <b-th>Punti di forza</b-th>
                <b-th>Punti di debolezza</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-td style="width: 50%">
                  <div v-for="(item_strength, index_strength) in item.strengths" :key="index_strength">
                    {{ item_strength }}
                  </div>
                  <div v-if="item.strengths.length == 0">Nessun punto di forza</div>
                  <b-btn variant="primary" class="mt-2" size="sm" @click="$refs.strengths.loadData()">Modifica</b-btn>
                </b-td>
                <b-td style="width: 50%">
                  <div v-for="(item_weakness, index_weakness) in item.weaknesses" :key="index_weakness">
                    {{ item_weakness }}
                  </div>
                  <div v-if="item.weaknesses.length == 0">Nessun punto di debolezza</div>
                  <b-btn variant="primary" class="mt-2" size="sm" @click="$refs.weaknesses.loadData()">Modifica</b-btn>
                </b-td>
              </b-tr>
            </b-tbody>
            <b-thead head-variant="light">
              <b-tr>
                <b-th>Opportunità</b-th>
                <b-th>Minacce</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-td style="width: 50%">
                  <div v-for="(item_opportunity, index_opportunity) in item.opportunities2" :key="index_opportunity">
                    {{ item_opportunity }}
                  </div>
                  <div v-if="item.opportunities2.length == 0">Nessuna opportunità individuata</div>
                  <b-btn variant="primary" class="mt-2 mr-2" size="sm" @click="$refs.opportunities.loadData()">Modifica</b-btn>
                </b-td>
                <b-td style="width: 50%">
                  <div v-for="(item_threat, index_threat) in item.threats" :key="index_threat">
                    {{ item_threat }}
                  </div>
                  <div v-if="item.threats.length == 0">Nessuna minaccia individuata</div>
                  <b-btn variant="primary" class="mt-2" size="sm" to="risks">Modifica</b-btn>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-tab>
      </b-tabs>
    </b-card>
    <b-row v-if="data !== null && data.porters !== null">
      <b-col lg="12">
        <hr>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Modello Porter - non aggiornabile</h5> <b-button variant="danger" size="sm" @click="deleteOld()">Elimina il vecchio</b-button>
            <table style="width:100%">
              <tr>
                <td width="32%"></td>
                <td width="2%"></td>
                <td width="32%" style="border: 1px solid #cecece; padding:5px;"><b>Concorrenti potenziali</b></td>
                <td width="2%"></td>
                <td width="32%"></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td style="border: 1px solid #cecece; padding:5px;">
                  <ul>
                    <li v-for="(item,index) in data.porters.competitors" :key="index">{{ item }}</li>
                  </ul>
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td style="border: 1px solid #cecece; padding:5px;"><b>Fornitori</b></td>
                <td></td>
                <td style="border: 1px solid #cecece; padding:5px;"><b>Struttura e contesto competitivo</b></td>
                <td></td>
                <td style="border: 1px solid #cecece; padding:5px;"><b>Clienti</b></td>
              </tr>
              <tr>
                <td style="border: 1px solid #cecece; padding:5px;">
                  <ul>
                    <li v-for="(item,index) in data.porters.suppliers" :key="index">{{ item }}</li>
                  </ul>
                </td>
                <td></td>
                <td style="border: 1px solid #cecece; padding:5px;">
                  <ul>
                    <li v-for="(item,index) in data.porters.context" :key="index">{{ item }}</li>
                  </ul>
                </td>
                <td></td>
                <td style="border: 1px solid #cecece; padding:5px;">
                  <ul>
                    <li v-for="(item,index) in data.porters.customers" :key="index">{{ item }}</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td style="border: 1px solid #cecece; padding:5px;"><b>Produttori di beni sostitutivi</b></td>
                <td colspan="2"></td>
              </tr>
              <tr>
                  <td colspan="2"></td>
                  <td style="border: 1px solid #cecece; padding:5px;">
                    <ul>
                      <li v-for="(item,index) in data.porters.alternatives" :key="index">{{ item }}</li>
                    </ul>
                  </td>
                  <td colspan="2"></td>
              </tr>
            </table>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import doctoken from "../doctoken";
import contextdescription from './contextdescription.vue'
import strengths from './strengths.vue'
import weaknesses from './weaknesses.vue'
import opportunities from './opportunities.vue'

export default {
  name: "context",
  components: { doctoken, contextdescription, strengths, weaknesses, opportunities },
  data() {
    return {
      data: null,
      document: null,
      tabIndex: 0,
    }
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.openHttp("context_contexts", null).then((result) => {
        this.data = result;
        this.loadDocument();
      })
    },
    loadDocument(){
      this.openHttp("context_document", null).then((result) => {
        this.document = result;
      })
    },
    generateDocument(){
      this.generateDocumentHttp("context");
    },
    deleteOld(){
      this.deleteHttp("context_old_clean", { }, "Contesto modello porter eliminato!" ).then((result) => {
        if ( result.result == true ) this.loadData();
      })
    }
  }
}
</script>