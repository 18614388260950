<template>
  <div>
    <div class="bottom-table" v-show="total > 0">
      <div class="slot1">
        <b-form-select :value="perpage" :options="[10, 50, 100, 200]" @change="updatePerPage"/>
      </div>
      <div class="slot2">
        Visualizzati da {{ (currentpage - 1) * perpage + 1 }} a {{ currentpage * perpage > total ? total : currentpage * perpage }} su {{ total }}
      </div>
      <div class="slot3">
        <b-pagination :value="currentpage" :per-page="perpage" :total-rows="total" align="right" class="my-0" @input="updateCurrentPage"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "tablebottom",
  props: [ "perpage", "currentpage", "total", ],
  methods: {
    updatePerPage( event ){
      this.$emit( "updatePerPage", event );
      this.loadData();
    },
    updateCurrentPage( event ){
      this.$emit( "updateCurrentPage", event );
      this.loadData();
    },
    loadData(){
      this.$emit( "loadData" );
    }
  }
}
</script>

<style scoped>
:scope .bottom-table {
  display: flex;
  margin:0;
  padding:0;
}

:scope .bottom-table > .slot1 {
  min-width: 100px;
  max-width: 100px;
}

:scope .bottom-table > .slot2 {
  width: 100%;
  height: 38px;
  padding-top: 7px;
  padding-left: 10px;
  overflow-x: hidden;
  overflow-y: hidden;
}

:scope .bottom-table > .slot3 {
  min-width: 100px;
}
</style>