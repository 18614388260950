<template>
  <div>
    <b-modal v-model="show" v-if="data !== null" size="lg" title="Scheda manutenzione" scrollable @hidden="closeForm" @close="closeForm">
      <maintenance ref="maintenance" @reload="loadData(data.mainteinancesheet.id)"/>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Scheda" active>
            <b-row>
              <b-col lg="10">
                <b-form-group label="Scheda">
                  <b-form-input v-model="data.mainteinancesheet.mainteinancesheet"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Stato">
                  <b-form-select v-model="data.mainteinancesheet.active" :options="activeCombo" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4">
                <b-form-group label="Tipologia">
                  <b-form-select v-model="data.mainteinancesheet.external" :options="externalCombo" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Periodicità (unità)">
                  <b-form-select v-model="data.mainteinancesheet.periodunit" :options="periodicityCombo" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Periodicità" v-show="data.mainteinancesheet.periodunit !== null">
                  <b-form-input type="number" v-model="data.mainteinancesheet.periodnumber" required min="1" max="1000"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Tipologie di infrastrutture soggette">
              <multiselect v-model="data.mainteinancesheet.machineriestypesSupport" :options="data.machineriestypes" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.mainteinancesheet.machineriestypes = updateCombo(data.mainteinancesheet.machineriestypesSupport)"/>
            </b-form-group>
            <b-form-group label="Infrastrutture singole soggette">
              <multiselect v-model="data.mainteinancesheet.machineriesSupport" :options="data.machineries" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.mainteinancesheet.machineries = updateCombo(data.mainteinancesheet.machineriesSupport)"/>
            </b-form-group>
            <b-form-group label="Note">
              <b-form-textarea v-model="data.mainteinancesheet.note" rows="8"/>
            </b-form-group>
          </b-tab>
          <b-tab title="Scadenze" v-show="data.mainteinancesheet.id > 0">
            <b-btn variant="primary" @click="loadData(data.mainteinancesheet.id)">Aggiorna</b-btn>
            <b-table hover :items="data.deadlines" :fields="deadlinesFields" class="mt-3" @row-clicked="loadItem($event.id, $event.nextdate)">
              <template v-slot:cell(nextdate)="row">
                <b-badge v-show="expired(row.item.nextdate)" variant="danger">Scaduta</b-badge> {{ row.value }}
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.mainteinancesheet.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import maintenance from './maintenance.vue'

export default {
  name: "mainteinancesheet",
  components: {
    maintenance
  },
  data() {
    return {
      show: false,
      data: null,
      activeCombo: [{
        id: true,
        val: "Attiva"
      },{
        id: false,
        val: "Non attiva - Archiviata"
      }],
      externalCombo: [{
        id: false,
        val: "Interna"
      },{
        id: true,
        val: "Esterna"
      }],
      periodicityCombo: [{
        id: "day",
        val: "Giorno/i"
      },{
        id: "week",
        val: "Settimana/e"
      },{
        id: "month",
        val: "Mese/i"
      },{
        id: "year",
        val: "Anno/i"
      },{
        id: null,
        val: "Nessuna"
      },],
      deadlinesFields: [{
        key: 'machinery',
        label: 'Macchinario',
        sortable: true
      },{
        key: 'location',
        label: 'Luogo',
        sortable: false
      },{
        key: 'nextdate',
        label: 'Prossima',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'lastdate',
        label: 'Ultima',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      }],
    }
  },
  methods: {
    expired (checkDate){
      var today = new Date();
      var d = new Date(checkDate);
      return d.getTime() <= today.getTime() ? true : false;
    },
    loadData(id){
      this.openHttp("machineries_maintenancesheet", { id: id }).then((result) => {
        if (this.data != null){
          this.data.deadlines = result.deadlines;
        } else {
          result.mainteinancesheet.machineriestypesSupport = this.convertCombo(result.machineriestypes, result.mainteinancesheet.machineriestypes);
          result.mainteinancesheet.machineriesSupport = this.convertCombo(result.machineries, result.mainteinancesheet.machineries);
          this.data = result;
        }
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("machineries_maintenancesheet_save", this.data.mainteinancesheet, "Scheda manutenzione salvata!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("machineries_maintenancesheet_delete", { id: this.data.mainteinancesheet.id }, "Scheda manutenzione eliminata!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('reload');
    },
    loadItem(machineriesId, date){
      this.$refs.maintenance.loadData( 0, machineriesId, this.data.mainteinancesheet.id, date );
    }
  }
}
</script>