<template>
  <div>
    <impact ref="impact" @reload="loadData"/>
    <doctoken ref="doctoken"/>
    <b-modal v-model="showFilter" title="Filtra" @ok="filterSet">
      <b-form-group label="Fase">
        <b-form-input v-model="tempFilter.phase"/>
      </b-form-group>
      <b-form-group label="Processi">
        <b-form-select v-model="tempFilter.processes_id" :options="comboSeeAll(filtersMenu.processes)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Norme">
        <b-form-select v-model="tempFilter.standards_id" :options="comboSeeAll(filtersMenu.standards)" value-field="id" text-field="val"/>
      </b-form-group>
    </b-modal>
    <b-btn class="mr-2" variant="primary" @click="loadData">Aggiorna</b-btn>
    <b-btn class="mr-2" variant="warning" @click="filterReset">Reset filtri</b-btn>
    <b-btn class="mr-2" variant="secondary" v-b-modal.modalFiltersImpacts @click="filterOpen">Filtra</b-btn>
    <b-btn class="mr-2" variant="success" @click="$refs.impact.loadData(0)">Nuovo</b-btn>
    <b-btn class="mr-2" variant="info" v-if="document != null && document.records_id > 0" @click="$refs.doctoken.downloadRecord(document.records_id)">Scarica Documento {{ document.date }}</b-btn>
    <b-btn variant="success" @click="generateDocument()">Genera Documento</b-btn>
    <b-table no-border-collapse :sticky-header="(winHeight - 205) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="items.data" :fields="fields" :busy="isBusy" :no-local-sorting="true" :sort-by.sync="search.sort.by" :sort-desc.sync="search.sort.desc" @sort-changed="sortingChange" @row-clicked="$refs.impact.loadData($event.id)">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>Non ci sono risultati</template>
      <template v-slot:cell(impactsanalysistypes_low)="row">
        <div v-for="(item, index) of row.value" :key="index">{{ item }}</div>
      </template>
      <template v-slot:cell(impactsanalysistypes_medium)="row">
        <div v-for="(item, index) of row.value" :key="index">{{ item }}</div>
      </template>
      <template v-slot:cell(impactsanalysistypes_high)="row">
        <div v-for="(item, index) of row.value" :key="index">{{ item }}</div>
      </template>
    </b-table>
    <tablebottom :perpage="search.page.perpage" @updatePerPage="search.page.perpage = $event" :currentpage="search.page.currentpage" @updateCurrentPage="search.page.currentpage = $event" :total="items.total" @loadData="loadData"/>
  </div>
</template>

<script>
import impact from './impact.vue';

export default {
  name: "impacts",
  components: { impact },
  data() {
    return {
      isBusy: true,
      showFilter: false,
      search:{
        page: {
          currentpage: 1,
          perpage: 50,
        },
        filters: {},
        sort: {
          by: 'standard',
          desc: false,
        },
      },
      filtersMenu: {
        processes: [],
        standards: [],
      },
      tempFilter: {
        phase: null,
        processes_id: null,
        standards_id: null,
      },
      fields: [{
        key: "standard",
        label: "Norma",
        sortable: true
      },{
        key: "phase",
        label: "Fase",
        sortable: true,
      },{
        key: "processes",
        label: "Processi",
        sortable: true
      },{
        key: 'interestedparties',
        label: 'Parti interessate',
      },{
        key: 'machineriestypes',
        label: 'Attrezzature',
      },{
        key: 'impactsanalysistypes_low',
        label: 'Impatti bassi',
      },{
        key: 'impactsanalysistypes_medium',
        label: 'Impatti medi',
      },{
        key: "impactsanalysistypes_high",
        label: 'Impatti alti',
      }],
      items: {
        total: 0,
        data: [],
      },
      document: null
    }  
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.isBusy = true;
      this.openHttp("impacts_impacts", { search: this.search }).then((result) => {
        this.isBusy = false;
        this.items = result;
        this.loadDocument();
      })
    },
    loadDocument(){
      this.openHttp("impacts_document", null).then((result) => {
        this.document = result;
      })
    },
    generateDocument(){
      this.generateDocumentHttp("impacts");
    },
    filterOpen(){
      this.openHttp("impacts_filters", { }).then((result) => {
        this.showFilter = true;
        this.filtersMenu = result;
      })
    },
    filterSet(){
      this.showFilter = false;
      this.search.filters = this.tempFilter;
      this.loadData();
    },
    filterReset(){
      this.tempFilter = {
        phase: null,
        processes_id: null,
        standards_id: null,
      };
      this.filterSet();
    },
    sortingChange(ctx) {
      this.search.sort.by = ctx.sortBy;
      this.search.sort.desc = ctx.sortDesc;
      this.loadData();
    }
  }
}
</script>