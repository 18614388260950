<template>
  <div id="app">
    <header v-if="ready">
      <navbar/>
    </header>
    <main>
      <b-container fluid>
        <router-view/>
      </b-container>
    </main>
  </div>
</template>

<script>
import navbar from '@/components/navbar.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  components: { navbar },
  data(){
    return {
      ready: false,
    }
  },
  created() {
    this.ready = this.isLogged;
  },
  computed: {
    ...mapGetters(['isLogged', 'getWinHeight', 'getWinWidth']),
  },
  watch: {
    isLogged: function(val){
      this.ready = val;
    },
  },
  mounted() {
    this.$nextTick(function() {
      window.addEventListener('resize', this.getWindowWidth);
      window.addEventListener('resize', this.getWindowHeight);
      this.getWindowWidth();
      this.getWindowHeight();
    })
  },
  methods: {
    getWindowWidth() {
      this.$store.commit('setWinHeight', document.documentElement.clientHeight);
    },
    getWindowHeight() {
      this.$store.commit('setWinWidth', document.documentElement.clientWidth);
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getWindowWidth);
    window.removeEventListener('resize', this.getWindowHeight);
  }
}
</script>

<style lang="scss">
html, body {
  height:100%;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  max-height: 100%;
  background-color: rgba(0, 0, 0, 0.03);
}

header {
  position: relative; 
  top: 0px;
  height: 56px;
  z-index: 999;
}

main {
  flex-grow: 1;
  overflow-x: auto;
  overflow-y: visible;
  margin: 10px;
  padding: 10px 0;
  background-color: #ffffff;
  border-radius: 5px;
  z-index: 800;
}

.modal-xl {
  height: calc(100% - 10px);
  min-width: calc(100% - 20px);
}

.modal-lg {
  height: calc(100% - 10px);
  min-width: calc(70% - 20px);
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>