<template>
  <div>
    <doctoken ref="doctoken"/>
    <delegation ref="delegation" @reload="loadData"/>
    <b-modal v-model="showFilter" title="Filtra" @ok="filterSet">
      <b-form-group label="Delega">
        <b-form-input type="text" v-model="tempFilter.delegation"/>
      </b-form-group>
      <b-form-group label="Delegato">
        <b-form-select v-model="tempFilter.employees_id" :options="comboSeeAll(filtersMenu.employees)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Delegante">
        <b-form-select v-model="tempFilter.employees_id_from" :options="comboSeeAll(filtersMenu.employees_from)" value-field="id" text-field="val"/>
      </b-form-group>
    </b-modal>
    <b-btn class="mr-2" variant="primary" @click="loadData">Aggiorna</b-btn>
    <b-btn class="mr-2" variant="warning" @click="filterReset">Reset filtri</b-btn>
    <b-btn class="mr-2" variant="secondary" @click="filterOpen">Filtra</b-btn>
    <b-btn class="mr-2" variant="success" @click="loadItem(0)">Nuovo</b-btn>
    <b-btn class="mr-2" variant="info" v-if="document != null && document.records_id > 0" @click="$refs.doctoken.downloadRecord(document.records_id)">Scarica Documento {{ document.date }}</b-btn>
    <b-btn variant="success" @click="generateDocument()">Genera Documento</b-btn>
    <b-table no-border-collapse :sticky-header="(winHeight - 205) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="items.data" :fields="fields" :busy="isBusy" :no-local-sorting="true" :sort-by.sync="search.sort.by" :sort-desc.sync="search.sort.desc" @sort-changed="sortingChange" @row-clicked="loadItem($event.id)">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>Non ci sono risultati</template>
    </b-table>
    <tablebottom :perpage="search.page.perpage" @updatePerPage="search.page.perpage = $event" :currentpage="search.page.currentpage" @updateCurrentPage="search.page.currentpage = $event" :total="items.total" @loadData="loadData"/>
  </div>
</template>

<script>
import delegation from './delegation.vue';

export default {
  name: "delegations",
  components: { delegation },
  data() {
    return {
      isBusy: true,
      showFilter: false,
      search: {
        page: {
          currentpage: 1,
          perpage: 50,
        },
        filters: {
          archive: false,
        },
        sort: {
          by: 'date',
          desc: true,
        },
      },
      tempFilter: {
        delegation: null,
        employees_id: null,
        employees_id_from: null,
      },
      filtersMenu: {
        employees: [],
        employees_from: [],
      },
      fields: [{
        key: "employee1",
        label: "Delegato",
      },{
        key: "employee2",
        label: "Delegante",
      },{
        key: "delegation",
        label: "Delega",
      },{
        key: "date",
        label: "Data",
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      }],
      items: {
        total: 0,
        data: [],
      },
      document: null
    }  
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.isBusy = true
      this.openHttp("delegations_delegations", { search: this.search }).then((result) => {
        this.isBusy = false;
        this.items = result;
        this.loadDocument();
      })
    },
    loadDocument(){
      this.openHttp("delegations_document", null).then((result) => {
        this.document = result;
      })
    },
    generateDocument(){
      this.generateDocumentHttp("delegations");
    },
    filterOpen(){
      this.openHttp("delegations_delegations_filters", {}).then((result) => {
        this.showFilter = true;
        this.filtersMenu = result;
      })
    },
    filterSet(){
      this.search.filters = this.tempFilter;
      this.showFilter = false;
      this.loadData();
    },
    filterReset(){
      this.tempFilter = {
        delegation: null,
        employees_id: null,
        employees_id_from: null,
      };
      this.filterSet();
    },
    sortingChange(ctx) {
      this.search.sort.by = ctx.sortBy;
      this.search.sort.desc = ctx.sortDesc;
      this.loadData();
    },
    loadItem(id){
      this.$refs.delegation.loadData(id);
    },
  }
}
</script>