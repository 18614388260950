<template>
  <div>
    <doctoken ref="doctoken"/>
    <b-modal v-if="data != null" v-model="show" size="lg" title="Documento" @hidden="closeForm" @close="closeForm" no-close-on-esc no-close-on-backdrop>
      <documentrevision ref="revision" @reload="loadData(data.document.id)"/>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Scheda" active>
            <b-row>
              <b-col lg="2">
                <b-form-group label="Identificativo">
                  <b-form-input v-model="data.document.code"/>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group label="Descrizione">
                  <b-form-input v-model="data.document.document"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Tipologia">
                  <b-form-select v-model="data.document.documentskinds_id" :options="data.documentskinds" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Origine">
                  <b-form-select v-model="data.document.internal" :options="internalCombo" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4">
                <b-form-group label="Luogo di archiviazione">
                  <b-form-input v-model="data.document.storageplace"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Approvazione">
                  <b-form-select v-model="data.document.needapproval" :options="approvalCombo" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Archiviazione">
                  <b-form-select v-model="data.document.archived" :options="archivedCombo" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Punti norma">
              <multiselect v-model="standardspointsSupport" :options="data.standardspoints" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.document.standardspoints = updateCombo(standardspointsSupport)"/>
            </b-form-group>
            <b-form-group label="Note">
              <b-form-textarea v-model="data.document.note"/>
            </b-form-group>
          </b-tab>
          <b-tab title="Contenuto modificabile" v-if="data.document.documentstypes_id === null && data.document.internal === true ? true : false">
            <customeditor v-model="data.document.documenttext" :height="winHeight - 375" :showToolbar="true"/>
          </b-tab>
          <b-tab title="Registrazioni" v-if="data.records.length > 0">
            <b-table :items="data.records" :fields="fields.records">
              <template v-slot:cell(id)="row">
                <b-btn size="sm" variant="info" v-if="row.item.fileexist"  @click="downloadRecord(row.item.id)">Scarica</b-btn>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Revisioni" v-if="data.document.id > 0">
            <b-btn variant="primary" v-show="data.document.documentstypes_id == null" @click="loadRevision(0)">Nuova</b-btn>
            <b-table :items="data.revisions" :fields="fields.revisions" class="mt-3" @row-clicked="loadRevision($event.id)">
              <template v-slot:cell(index)="row">
                <b-btn size="sm" variant="info" v-if="row.item.fileexist"  @click="downloadRevision(row.item.id)">Scarica</b-btn>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.document.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import documentrevision from './documentrevision.vue';
import customeditor from '../customeditor.vue';

export default {
  name: "document",
  components: { documentrevision, customeditor },
  data() {
    return {
      show: false,
      data: null,
      standardspointsSupport: [],
      internalCombo: [{
        id: true,
        val: "Interna"
      },{
        id: false,
        val: "Esterna"
      },],
      approvalCombo: [{
        id: false,
        val: "Non necessaria"
      },{
        id: true,
        val: "Necessaria"
      },],
      archivedCombo: [{
        id: false,
        val: "Non archiviato"
      },{
        id: true,
        val: "Archiviato"
      },],
      fields: {
        revisions: [{
          key: 'createdate',
          label: 'Data',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
          sortable: true
        },{
          key: 'revision',
          label: 'Revisione',
        },{
          key: 'created_employee',
          label: 'Creato da',
        },{
          key: 'approveddate',
          label: 'Approvazione',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'approved_employee',
          label: 'Approvato da',
        },{
          key: 'expirydate',
          label: 'Scadenza',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "index",
          label: ""
        }],
        records: [{
          key: 'date',
          label: 'Data',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
          sortable: true
        },{
          key: "id",
          label: "",
        }],
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    loadData( id ){
      this.openHttp("documents_document", { id: id }).then((result) => {
        this.standardspointsSupport = this.convertCombo(result.standardspoints, result.document.standardspoints);
        if ( this.data !== null ){
          this.data.revisions = result.revisions;
        } else {
          this.data = result;
          this.show = true;
        }
      })
    },
    loadRevision(id){
      this.$refs.revision.loadData(this.data.document.id, id);
    },
    downloadRevision(id){
      this.$refs.doctoken.downloadRevision(id);
    },
    downloadRecord(id){
      this.$refs.doctoken.downloadRecord(id);
    },
    saveData(){
      this.saveHttp("documents_document_save", this.data.document, "Documento salvato!").then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("documents_document_delete", { id: this.data.document.id }, "Documento eliminato!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('reload');
    }
  }
}
</script>