<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" title="Obiettivo" scrollable @hidden="closeForm()" @close="closeForm()">
      <b-row>
        <b-col :lg="data.target.kpinames_id !== null ? 8: 2">
          <b-form-group label="Indicatore">
            <b-form-select v-model="data.target.kpinames_id" :options="data.kpinames" value-field="id" text-field="val" :disabled="data.target.id > 0"/>
          </b-form-group>
        </b-col>
        <b-col lg="6" v-show="data.target.kpinames_id === null">
          <b-form-group label="Obiettivo">
            <b-form-input v-model="data.target.target"/>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group label="Norme">
            <multiselect v-model="standardsSupport" :options="data.standards" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.target.standards = updateCombo(standardsSupport)"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Responsabilità">
        <multiselect v-model="jobdescriptionsSupport" :options="data.jobdescriptions" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.target.jobdescriptions = updateCombo(jobdescriptionsSupport)"/>
      </b-form-group>
      <b-row>
        <b-col lg="5">
          <b-form-group label="Modalità di raggiungimento">
            <b-form-textarea v-model="data.target.achievementmodes" rows="4"/>
          </b-form-group>
        </b-col>
        <b-col lg="5">
          <b-form-group label="Risorse">
            <b-form-textarea v-model="data.target.resources" rows="4"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Stato">
            <b-form-select v-model="data.target.archive" :options="archiveCombo" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Monitoraggi" v-if="data.target.kpinames_id === null">
        <b-btn variant="primary" size="sm" class="mb-2" @click="newTargetValue">Nuova Registrazione</b-btn>
        <b-table :items="data.target.valuestargets" :fields="fields.targets">
          <template v-slot:empty>Non ci sono registrazioni</template>
          <template v-slot:cell(datefrom)="row">
            <b-form-input type="date" v-model="row.item.datefrom"/>
          </template>
          <template v-slot:cell(dateto)="row">
            <b-form-input type="date" v-model="row.item.dateto"/>
          </template>
          <template v-slot:cell(percentage)="row">
            <b-form-input type="range" min="0" max="100" step="1" v-model="row.item.percentage"/>
            Valore {{ row.item.percentage }}%
          </template>
          <template v-slot:cell(comment)="row">
            <b-form-textarea v-model="row.item.comment" rows="2"/>
          </template>
          <template v-slot:cell(id)="row">
            <b-btn variant="danger" size="sm" @click="data.target.valuestargets.splice(row.index)">Elimina</b-btn>
          </template>
        </b-table>
      </b-form-group>
      <b-form-group label="Monitoraggi" v-if="data.target.kpinames_id !== null">
        <kpivalue ref="kpivalue" @reload="loadData(data.target.id)"/>
        <b-btn variant="primary" size="sm" class="mb-2 mr-2" @click="newMonitoring">Nuovo monitoraggio</b-btn>
        <b-btn variant="info" size="sm" class="mb-2" @click="showArchive = !showArchive">{{ !showArchive ? "Mostra" : "Nascondi" }} archiviati</b-btn>
        <b-table :items="data.target.monitorings.filter(x => !showArchive ? x.archive === false : true )" :fields="fields.kpis">
          <template v-slot:empty>Non ci sono registrazioni</template>
          <template v-slot:cell(targetsintervalstypes_id)="row">
            <b-form-select v-model="row.item.targetsintervalstypes_id" :options="data.intervalstypes" value-field="id" text-field="val"/>
          </template>
          <template v-slot:cell(datefrom)="row">
            <b-form-input type="date" v-model="row.item.datefrom"/>
          </template>
          <template v-slot:cell(dateto)="row">
            <b-form-input type="date" v-model="row.item.dateto"/>
          </template>
          <template v-slot:cell(target)="row">
            <b-row>
              <b-col>
                <b-form-select v-model="row.item.mustbe" :options="operatorCombo" value-field="id" text-field="val"/>
              </b-col>
              <b-col>
                <b-form-input type="number" v-model="row.item.valuetarget"/>
              </b-col>
            </b-row>
          </template>
          <template v-slot:cell(archive)="row">
            <b-form-select v-model="row.item.archive" :options="archiveCombo" value-field="id" text-field="val"/>
          </template>
          <template v-slot:cell(note)="row">
            <b-form-textarea v-model="row.item.note" rows="2"/>
          </template>
          <template v-slot:cell(id)="row">
            <b-btn variant="danger" size="sm" @click="data.target.monitorings.splice(row.index)">Elimina</b-btn>
          </template>
          <template v-slot:cell(kpis)="row">
            <b-table-simple small responsive>
              <b-thead>
                <b-th v-for="(item, index) of row.item.kpis" :key="index">{{ index + 1}}° {{ data.intervalstypes.find(x => x.id === row.item.targetsintervalstypes_id).val2 }}</b-th>
              </b-thead>
              <b-tbody>
                <b-td  v-for="(item, index) of row.item.kpis" :key="index">
                  <b-tooltip v-if="item.comment !== null" :target="'btnKpiValue-' + item.id" placement="top">{{ item.comment }}</b-tooltip>
                  <b-btn variant="warning" size="sm" v-show="item.kpi === null" @click="$refs.kpivalue.loadData(0, data.target.kpinames_id, item.max)">Registrare</b-btn>
                  <b-btn v-if="item.kpi !== null" :id="'btnKpiValue-' + item.id" :variant="(row.item.mustbe === '>=' && item.kpi >= row.item.valuetarget) || (row.item.mustbe === '>' && item.kpi > row.item.valuetarget) || (row.item.mustbe === '=' && item.kpi == row.item.valuetarget) || (row.item.mustbe === '<=' && item.kpi <= row.item.valuetarget) || (row.item.mustbe === '<' && item.kpi < row.item.valuetarget) ? 'success' : 'danger'" size="sm">{{ item.kpi }}</b-btn>
                </b-td>
              </b-tbody>
            </b-table-simple>
          </template>
        </b-table>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.target.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import kpivalue from './kpivalue.vue'

export default {
  name: "target",
  components: { kpivalue },
  data() {
    return {
      show: false,
      data: null,
      standardsSupport: [],
      jobdescriptionsSupport: [],
      showArchive: false,
      archiveCombo: [{
        id: false,
        val: "In corso"
      },{
        id: true,
        val: "Archiviato"
      }],
      operatorCombo: [{
        id: ">=",
        val: ">="
      },{
        id: ">",
        val: ">"
      },{
        id: "=",
        val: "="
      },{
        id: "<=",
        val: "<="
      },{
        id: "<",
        val: "<"
      }],
      fields: {
        targets: [{
          key: "datefrom",
          label: "Da",
        },{
          key: "dateto",
          label: "A",
        },{
          key: "percentage",
          label: "Percentage",
        },{
          key: "comment",
          label: "Commento",
        },{
          key: "id",
          label: "",
        }],
        kpis: [{
          key: "targetsintervalstypes_id",
          label: "Intervallo",
        },{
          key: "datefrom",
          label: "Da",
        },{
          key: "dateto",
          label: "A",
        },{
          key: "target",
          label: "Obiettivo",
        },{
          key: "archive",
          label: "Stato",
        },{
          key: "note",
          label: "Note",
        },{
          key: "id",
          label: "",
        },{
          key: "kpis",
          label: "Registrazioni",
        }],
      }
    }  
  },
  methods: {
    loadData( id ){
      if ( this.data === null ) this.show = false;
      this.openHttp("targets_target", { id: id }).then((result) => {
        this.standardsSupport = this.convertCombo(result.standards, result.target.standards);
        this.jobdescriptionsSupport = this.convertCombo(result.jobdescriptions, result.target.jobdescriptions);
        this.data = result;
        this.show = true;
      })
    },
    newTargetValue(){
      this.data.target.valuestargets.push({
        id: 0,
        datefrom: null,
        dateto: null,
        percentage: 0,
        comment: null,
      })
    },
    newMonitoring(){
      var date = new Date();
      this.data.target.monitorings.push({
        id: 0,
        datefrom: date,
        dateto: date,
        targetsintervalstypes_id: 1,
        mustbe: ">=",
        valuetarget: 0,
        note: null,
        archive: false,
        kpis: []
      })
    },
    saveData(){
      this.saveHttp("targets_target_save", this.data.target, "Obiettivo salvato!" ).then((result) => {
        if (result.result == true ) this.closeForm( true );
      })
    },
    deleteData(){
      this.deleteHttp("targets_target_delete", { id: this.data.target.id }, "Obiettivo eliminato!" ).then((result) => {
        if (result.result == true ) this.closeForm( true );
      })
    },
    closeForm(reload = false){
      this.show = false;
      this.data = null;
      if (reload === true) this.$emit('reload');
    }
  }
}
</script>