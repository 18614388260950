<template>
  <div>
    <b-card no-body v-if="data !== null">
      <b-tabs card>
        <b-tab title="Azienda" active>
          <companycompany ref="company" @reload="loadData()"/>
          <b-row>
            <b-col lg="6">
              <b-table-simple responsive>
                <b-thead>
                  <b-tr>
                    <b-th>Parametro</b-th>
                    <b-th>Valore</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-td>Azienda</b-td>
                    <b-td>{{ data.company.company }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Partita IVA</b-td>
                    <b-td>{{ data.company.vat }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Anno Fondazione</b-td>
                    <b-td>{{ data.company.foundation }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Sito Internet</b-td>
                    <b-td>{{ data.company.web }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Codici Ateco</b-td>
                    <b-td>{{ data.company.naces }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Lingua</b-td>
                    <b-td>{{ data.company.language }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Spazio utilizzato (MB)</b-td>
                    <b-td>{{ data.company.usedspace }}</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
            <b-col lg="6">
              <b-form-group label="Logo">
                <div style="max-width: 20%; height: auto; border-color:Gainsboro; border-style: solid;">
                  <b-img :src="'data:image/jpeg;base64,' + data.logo" fluid alt="Inserire logo in PNG..."/>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <companycompany ref="company" @reload="loadData()"/>
          <b-btn variant="primary" @click="$refs.company.loadData()">Modifica</b-btn>
        </b-tab>
        <b-tab title="Siti ed aree">
          <site ref="site" @reload="loadData()"/>
          <b-btn variant="primary" size="sm" @click="$refs.site.loadData(0)">Nuovo</b-btn>
          <b-table class="mt-3" :items="data.sites" :fields="fieldSites" show-empty @row-clicked="$refs.site.loadData($event.id)">
            <template v-slot:empty>Mancano i siti</template>
          </b-table>            
        </b-tab>
        <b-tab title="Scopi">
          <scope ref="scope" @reload="loadData()"/>
          <b-btn variant="primary" size="sm" @click="$refs.scope.loadData(0)">Nuovo</b-btn>
          <b-table class="mt-3" :items="data.scopes" :fields="fieldScopes" show-empty @row-clicked="$refs.scope.loadData($event.id)">
            <template v-slot:empty>Mancano gli scopi</template>
          </b-table>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import companycompany from "./companycompany.vue";
import site from "./site.vue";
import scope from "./scope.vue";

export default {
  name: "company",
  components: { companycompany, site, scope },
  data() {
    return {
      data: null,
      showModalLogo: false,
      fieldScopes: [{
        key: "standards",
        label: "Norme",
      },{
        key: "scope",
        label: "Scopo",
      },{
        key: "exclusion",
        label: "Esclusioni",
      }],
      fieldSites: [{
        key: "type",
        label: "Tipo",
      },{
        key: "site",
        label: "Sito",
      },{
        key: "shifts",
        label: "Turni",
      },{
        key: "active",
        label: "Stato",
        formatter: value => {
          return value === true ? "Attivo" : "Non attivo";
        }
      },{
        key: "departments",
        label: "Aree",
      }],
    }
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.openHttp("company_company", {}).then((result) => {
        this.data = result;
      })
    }
  }
}
</script>