<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" title="Criterio Due Diligence" @hidden="closeForm()" @close="closeForm()">
      <b-form-group label="Criterio">
        <b-form-input v-model="data.criteria.criteria"/>
      </b-form-group>
      <b-form-group label="Obbligatorio?">
        <b-form-select v-model="data.criteria.mandatory" :options="mandatoryCombo" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Tipologia">
        <b-form-select v-model="data.criteria.duediligencestypes_id" :options="data.duediligencestypes" value-field="id" text-field="val" :disabled="data.criteria.id > 0"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.criteria.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "duediligencecriteria",
  data() {
    return {
      show: false,
      data: null,
      mandatoryCombo: [{
        id: false,
        val: 'Non obbligatorio',
      },{
        id: true,
        val: "Obbligatorio",
      },],
    }
  },
  methods: {
    loadData( id ){
      this.show = false;
      this.openHttp("duediligences_criteria", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("duediligences_criteria_save", this.data.criteria, "Criterio Due Diligence salvato!" ).then((result) => {
        if ( result.result == true ) this.closeForm(true);
      })
    },
    deleteData(){
      this.deleteHttp("duediligences_criteria_delete", { id: this.data.criteria.id }, "Criterio Due Diligence eliminato!" ).then((result) => {
        if ( result.result == true ) this.closeForm(true);
      })
    },
    closeForm( reload = false ){
      this.show = false;
      this.data = null;
      if (reload === true) this.$emit('reload');
    }
  }
}
</script>