<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" title="Cliente" scrollable @hidden="closeForm()" @close="closeForm()">
      <customerevaluation ref="evalutation" @reload="loadData(data.customer.id)"/>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Anagrafica" active>
            <b-row>
              <b-col lg="8">
                <b-form-group label="Ragione sociale">
                  <b-form-input v-model="data.customer.customer"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Attivo">
                  <b-form-select v-model="data.customer.unactive" :options="activeCombo" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4">
                <b-form-group label="Importanza">
                  <b-form-select v-model="data.customer.importance" :options="importanceCombo" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Soddisfazione cliente?">
                  <b-form-select v-model="data.customer.evaluationyesno" :options="evaluationyesnoCombo" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Contatti">
              <b-form-textarea v-model="data.customer.contacts"/>
            </b-form-group>
            <b-form-group label="Prodotti venduti">
              <multiselect v-model="productsSupport" :options="data.products" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.customer.products = updateCombo(productsSupport)"/>
            </b-form-group>
          </b-tab>
          <b-tab title="Valutazioni" :disabled="data.customer.id == 0">
            <b-btn variant="info" @click="evaluate()">Valuta</b-btn>
            <b-table hover class="mt-3" :items="data.evaluations" :fields="fieldsEvaluations">
              <template v-slot:cell(details)="row">
                <div v-for="(item, index) in row.value" :key="index">{{ item.question }}: {{ item.evaluation }}/10</div>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.customer.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import customerevaluation from './customerevaluation.vue'

export default {
  name: "customer",
  components: { customerevaluation },
  data() {
    return {
      show: false,
      data: null,
      activeCombo: [{
        id: false,
        val: "Attivo"
      },{
        id: true,
        val: "Non attivo"
      },],
      importanceCombo: [{
        id: 2,
        val: "Alta"
      },{
        id: 1,
        val: "Media"
      },{
        id: 0,
        val: "Bassa"
      },{
        id: null,
        val: "Non valutato"
      }],
      evaluationyesnoCombo: [{
        id: false,
        val: "Non valutare"
      },{
        id: true,
        val: "Valutare"
      }],
      fieldsEvaluations: [{
        key: 'date',
        label: "Date",
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'evaluation',
        label: "Complessivo",
        sortable: true,
        formatter: value => {
          return value + "/100";
        }
      },{
        key: 'details',
        label: "Dettaglio",
      }],
      productsSupport: []
    }  
  },
  methods: {
    loadData( id ){
      this.openHttp("customers_customer", { id: id }).then((result) => {
        this.productsSupport = this.convertCombo(result.products, result.customer.products);
        this.data = result;
        this.show = true;
      })
    },
    evaluate(date){
      let d = ((date == null) ? new Date() : new Date(date));
      this.$refs.customerevaluation.loadData(this.data.customer.id, [d.getFullYear(), d.getMonth() + 1, d.getDate()].map(n => n < 10 ? `0${n}` : `${n}`).join('-'));
    },
    saveData(){
      this.saveHttp("customers_customer_save", this.data.customer, "Cliente salvato!" ).then((result) => {
        if ( result.result == true ) this.closeForm(true);
      })
    },
    deleteData(){
      this.deleteHttp("customers_customer_delete", { id: this.data.customer.id }, "Cliente eliminato!" ).then((result) => {
        if ( result.result == true ) this.closeForm(true);
      })
    },
    closeForm(reload = false){
      this.show = false;
      this.data = null;
      if ( reload === true ) this.$emit('reload');
    }
  }
}
</script>