<template>
  <div>
    <b-modal v-if="data != null" v-model="show" size="lg" :title="'Manutenzione - ' + ( data.maintenance.mainteinancesheets_id === null ? 'Straordinaria' : 'Ordinaria')" scrollable @hidden="closeForm" @close="closeForm">
      <form ref="downloadForm" method="POST" target="_blank" action="/api/file.php">
        <input type="hidden" name="form" value="machineries">
        <input type="hidden" name="document" value="maintenance">
        <input type="hidden" name="id" :value="data.maintenance.id">
        <input type="hidden" name="token" :value="token">
      </form>
      <form ref="downloadAttachmentForm" method="POST" target="_blank" action="/api/file.php">
        <input type="hidden" name="form" value="machineries">
        <input type="hidden" name="document" value="attachmentmachinery">
        <input type="hidden" name="id" :value="data.maintenance.id">
        <input type="hidden" name="filename" :value="fileAttachment">
        <input type="hidden" name="token" :value="token">
      </form>
      <b-form>
        <b-form-group label="Macchinario">
          <b-form-input :value="data.machinery.machineriestype + ' ' + data.machinery.serialnumber"/>
        </b-form-group>
        <b-card v-if="data.maintenancesheet != null" :title="'Manutenzione: ' + data.maintenancesheet.maintenancesheet" :sub-title="( data.maintenancesheet.external ? 'Esterna' : 'Interna' ) + ' - ' + data.maintenancesheet.periodnumber + ' ' + data.maintenancesheet.periodunit">
          <b-card-text>{{ data.maintenancesheet.details }}</b-card-text>
        </b-card>
        <b-row>
          <b-col lg="4">
            <b-form-group label="Data">
              <b-form-input type="date" v-model="data.maintenance.date"/>
            </b-form-group>
          </b-col>
          <b-col lg="4">
            <b-form-group label="Responsabile interno">
              <b-form-select v-model="data.maintenance.employees_id" :options="data.employees" value-field="id" text-field="val"/>
            </b-form-group>
          </b-col>
          <b-col lg="4">
            <b-form-group label="Responsabile esterno / fornitore">
              <b-form-input v-model="data.maintenance.supplier"/>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group label="Note">
          <b-form-textarea v-model="data.maintenance.note" rows="4"/>
        </b-form-group>
        <b-row>
          <b-col lg="4">
            <b-form-group label="Costo">
              <b-form-input type="number" v-model="data.maintenance.cost"/>
            </b-form-group>
          </b-col>
          <b-col lg="8">
            <b-form-group label="Allegati">
              <b-btn v-show="data.maintenance.id == 0" variant="info">Prima di caricare dei files è necessario salvare</b-btn>
              <div v-if="data.maintenance.id > 0">
                <b-btn variant="info" size="sm" class="mr-2 mb-2" v-b-modal.modalFiltersAttachments>Carica allegato</b-btn>
                <b-modal v-model="showModalAttachment" id="modalFiltersAttachments" title="Carica allegato" :hide-footer="true">
                  <b-form-file placeholder="Scegli un file o trascinalo qui..." drop-placeholder="Trascinalo qui..." @change="saveAttachment($event)"/>
                </b-modal>
                <b-dropdown v-for="(item, index) of data.files" :key="index" dropright id="dropdown-1" variant="secondary" size="sm" class="mr-2 mb-2" :text="item.file">
                  <b-dropdown-item @click="downloadDataAttachment(item.file)">Scarica</b-dropdown-item>
                  <b-dropdown-item @click="deleteAttachment(item.file)">Elimina</b-dropdown-item>
                </b-dropdown>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="info" class="mr-2" v-show="data.maintenance.id > 0" @click="downloadData">Scarica</b-btn>
          <b-btn variant="danger" v-show="data.maintenance.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "maintenance",
  data() {
    return {
      show: false,
      data: null,
      fileAttachment: null,
      showModalAttachment: false,
    }
  },
  computed: {
    token (){
      return this.$store.getters.getToken;
    }
  },
  methods: {
    loadData( id, machineriesId, maintenancesheetsId, date ){
      this.openHttp("machineries_maintenance", { id: id, machineries_id: machineriesId, maintenancesheets_id: maintenancesheetsId, date: date }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveAttachment(event){
      this.showModalAttachment = false;
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = ()=> {
        this.saveHttp("machineries_maintenance_save_attachment", { id: this.data.maintenance.id, filename: event.target.files[0].name, base64: reader.result }, "Allegato salvato!" ).then((result) => {
          if ( result.result == true ) this.loadData( this.data.maintenance.id, this.data.maintenance.machineries_id, this.data.maintenance.mainteinancesheets_id, this.data.maintenance.date );
        })
      };
    },
    deleteAttachment(filename){
      this.deleteHttp("machineries_maintenance_delete_attachment", { id: this.data.maintenance.id, filename: filename }, "Allegato eliminato!" ).then((result) => {
        if ( result.result == true ) this.loadData( this.data.maintenance.id, this.data.maintenance.machineries_id, this.data.maintenance.mainteinancesheets_id, this.data.maintenance.date );
      })
    },
    downloadDataAttachment(fileName){
      this.fileAttachment = fileName;
      this.$nextTick(() => {
        this.$refs.downloadAttachmentForm.submit();
      })
    },
    saveData(){
      this.saveHttp("machineries_maintenance_save", this.data.maintenance, "Manutenzione salvata!").then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("machineries_maintenance_delete", { id: this.data.maintenance.id }, "Manutenzione eliminato!").then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    downloadData(){
      this.$refs.downloadForm.submit();
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('reload');
    }
  }
}
</script>