<template>
  <div>
    <doctoken ref="doctoken"/>
    <maintenance ref="maintenance" @reload="loadData"/>
    <machinery ref="machinery" @reload="loadData"/>
    <maintenancesheet ref="maintenancesheet" @reload="loadData"/>
    <machineriestype ref="machineriestype" @reload="loadData"/>
    <form ref="excelQuery" method="POST" target="_blank" action="excel2.php">
      <input type="hidden" name="data" ref="excelQueryData">
    </form>
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Scadenze" active>
          <b-row class="mb-3">
            <b-col lg="9">
              <b-btn variant="primary" @click="loadDeadlines">Aggiorna</b-btn>
            </b-col>
            <b-col lg="3">
              <b-form-input v-model="deadlines.filter" style="float: right" placeholder="Filtra..."/>
            </b-col>
          </b-row>
          <b-table bordered responsive show-empty :sticky-header="(winHeight - 265) + 'px'" class="mt-3" :busy="deadlines.isBusy" :items="deadlines.items" :fields="deadlines.fields" :filter="deadlines.filter">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
            <template v-slot:cell(val)="row">
              <nobr @click="loadMachinery(row.item.id)">{{ row.value }}</nobr>
            </template>
            <template v-slot:cell(expired)="row">
              <b-btn v-for="(item, index) of row.value" :key="index" variant="danger" size="sm" class="mr-2 mb-2" @click="loadMaintenance(0, row.item.id, item.id, item.date)">{{ item.val + ' ' + dateFormatter(item.date) }}</b-btn>
            </template>
            <template v-slot:cell(month0)="row">
              <b-btn v-for="(item, index) of row.value" :key="index" variant="warning" size="sm" class="mr-2 mb-2" @click="loadMaintenance(0, row.item.id, item.id, item.date)">{{ item.val + ' ' + dateFormatterShort(item.date) }}</b-btn>
            </template>
            <template v-slot:cell(month1)="row">
              <b-btn v-for="(item, index) of row.value" :key="index" variant="success" size="sm" class="mr-2 mb-2" @click="loadMaintenance(0, row.item.id, item.id, item.date)">{{ item.val + ' ' + dateFormatterShort(item.date) }}</b-btn>
            </template>
            <template v-slot:cell(month2)="row">
              <b-btn v-for="(item, index) of row.value" :key="index" variant="success" size="sm" class="mr-2 mb-2" @click="loadMaintenance(0, row.item.id, item.id, item.date)">{{ item.val }}</b-btn>
            </template>
            <template v-slot:cell(month3)="row">
              <b-btn v-for="(item, index) of row.value" :key="index" variant="success" size="sm" class="mr-2 mb-2" @click="loadMaintenance(0, row.item.id, item.id, item.date)">{{ item.val }}</b-btn>
            </template>
            <template v-slot:cell(month4)="row">
              <b-btn v-for="(item, index) of row.value" :key="index" variant="success" size="sm" class="mr-2 mb-2" @click="loadMaintenance(0, row.item.id, item.id, item.date)">{{ item.val }}</b-btn>
            </template>
            <template v-slot:cell(month5)="row">
              <b-btn v-for="(item, index) of row.value" :key="index" variant="success" size="sm" class="mr-2 mb-2" @click="loadMaintenance(0, row.item.id, item.id, item.date)">{{ item.val }}</b-btn>
            </template>
            <template v-slot:cell(month6)="row">
              <b-btn v-for="(item, index) of row.value" :key="index" variant="success" size="sm" class="mr-2 mb-2" @click="loadMaintenance(0, row.item.id, item.id, item.date)">{{ item.val }}</b-btn>
            </template>
            <template v-slot:cell(month7)="row">
              <b-btn v-for="(item, index) of row.value" :key="index" variant="success" size="sm" class="mr-2 mb-2" @click="loadMaintenance(0, row.item.id, item.id, item.date)">{{ item.val }}</b-btn>
            </template>
            <template v-slot:cell(month8)="row">
              <b-btn v-for="(item, index) of row.value" :key="index" variant="success" size="sm" class="mr-2 mb-2" @click="loadMaintenance(0, row.item.id, item.id, item.date)">{{ item.val }}</b-btn>
            </template>
            <template v-slot:cell(month9)="row">
              <b-btn v-for="(item, index) of row.value" :key="index" variant="success" size="sm" class="mr-2 mb-2" @click="loadMaintenance(0, row.item.id, item.id, item.date)">{{ item.val }}</b-btn>
            </template>
            <template v-slot:cell(month10)="row">
              <b-btn v-for="(item, index) of row.value" :key="index" variant="success" size="sm" class="mr-2 mb-2" @click="loadMaintenance(0, row.item.id, item.id, item.date)">{{ item.val }}</b-btn>
            </template>
            <template v-slot:cell(month11)="row">
              <b-btn v-for="(item, index) of row.value" :key="index" variant="success" size="sm" class="mr-2 mb-2" @click="loadMaintenance(0, row.item.id, item.id, item.date)">{{ item.val }}</b-btn>
            </template>
            <template v-slot:cell(month12)="row">
              <b-btn v-for="(item, index) of row.value" :key="index" variant="success" size="sm" class="mr-2 mb-2" @click="loadMaintenance(0, row.item.id, item.id, item.date)">{{ item.val }}</b-btn>
            </template>
          </b-table>
        </b-tab>
        <b-tab title="Elenco macchinari">
          <b-modal v-model="machineries.showFilter" title="Filtra" @ok="filterSetMachineries">
            <b-form-group label="Matricola / Identificativo">
              <b-form-input type="text" v-model="machineries.tempFilter.machinery"/>
            </b-form-group>
            <b-form-group label="Stato">
              <b-form-select v-model="machineries.tempFilter.unactive" :options="machineries.activeCombo" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Tipologia di macchinario">
              <b-form-select v-model="machineries.tempFilter.machineriestypes_id" :options="comboSeeAll(machineries.filtersMenu.machineriestypes)" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Scheda manutenzione">
              <b-form-select v-model="machineries.tempFilter.mainteinancesheets_id" :options="comboSeeAll(machineries.filtersMenu.mainteinancesheets)" value-field="id" text-field="val"/>
            </b-form-group>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadMachineries">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="warning" @click="filterResetMachineries">Reset filtri</b-btn>
          <b-btn class="mr-2" variant="secondary" @click="filterOpenMachineries">Filtra</b-btn>
          <b-btn class="mr-2" variant="success" @click="loadMachinery(0)">Nuovo</b-btn>
          <b-btn class="mr-2" variant="info" @click="excelOpen(1)">Excel Macchinari</b-btn>
          <b-btn class="mr-2" variant="info" @click="excelOpen(2)">Excel Manutenzioni</b-btn>
          <b-btn class="mr-2" variant="info" v-if="document != null && document.records_id > 0" @click="$refs.doctoken.downloadRecord(document.records_id)">Scarica Documento {{ document.date }}</b-btn>
          <b-btn variant="success" @click="generateDocument()">Genera Documento</b-btn>
          <form ref="excelForm1" method="POST" target="_blank" action="excel.php?machineries1">
            <input type="hidden" name="token" :value="token">
            <input type="hidden" name="filters" :value="filterComputed">
          </form>
          <form ref="excelForm2" method="POST" target="_blank" action="excel.php?machineries2">
            <input type="hidden" name="token" :value="token">
          </form>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :tbody-tr-class="rowClass" :items="machineries.items.data" :fields="machineries.fields" :busy="machineries.isBusy" :no-local-sorting="true" :sort-by.sync="machineries.search.sort.by" :sort-desc.sync="machineries.search.sort.desc" @sort-changed="sortingChangeMachineries" @row-clicked="loadMachinery($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
            <template v-slot:cell(nextdate)="row">
              <b-button variant="secondary" size="sm" @click="row.toggleDetails" class="mr-2">
                {{ row.detailsShowing ? 'Nascondi' : ( checkMaintenances(row.item.maintenances) ? 'Scadute' : 'Regolari' )}}
              </b-button>
            </template>
            <template v-slot:row-details="row">
              <b-table hover :tbody-tr-class="rowDetailClass" :items="row.item.maintenances" :fields="machineries.fieldsDetails" @row-clicked="loadMainteinance(0, $event.machineries_id, $event.maintenancesheet_id, $event.nextdate)"/>
            </template>
          </b-table>
          <tablebottom :perpage="machineries.search.page.perpage" @updatePerPage="machineries.search.page.perpage = $event" :currentpage="machineries.search.page.currentpage" @updateCurrentPage="machineries.search.page.currentpage = $event" :total="machineries.items.total" @loadData="loadMachineries"/>
        </b-tab>
        <b-tab title="Elenco schede di manutenzione">
          <b-modal v-model="maintenancesheets.showFilter" title="Filtra" @ok="filterSetMaintenancesheets">
            <b-form-group label="Tipologia manutenzione">
              <b-form-select v-model="maintenancesheets.tempFilter.active" :options="maintenancesheets.filtersMenu.active" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Tipologia manutenzione">
              <b-form-input v-model="maintenancesheets.tempFilter.maintenancesheet"/>
            </b-form-group>
            <b-form-group label="Macchinario">
              <b-form-select v-model="maintenancesheets.tempFilter.machineries_id" :options="comboSeeAll(maintenancesheets.filtersMenu.machineries)" value-field="id" text-field="val"/>
            </b-form-group>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadMaintenancesheets">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="warning" @click="filterResetMaintenancesheets">Reset filtri</b-btn>
          <b-btn class="mr-2" variant="secondary" @click="filterOpenMaintenancesheets">Filtra</b-btn>
          <b-btn class="mr-2" variant="success" @click="loadMaintenancesheet(0)">Nuovo</b-btn>          
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="maintenancesheets.items.data" :fields="maintenancesheets.fields" :busy="maintenancesheets.isBusy" :no-local-sorting="true" :sort-by.sync="maintenancesheets.search.sort.by" :sort-desc.sync="maintenancesheets.search.sort.desc" @sort-changed="sortingChangeMaintenancesheets" @row-clicked="loadMaintenancesheet($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
            <template v-slot:cell(count)="row">{{ row.item.machineries.length }}</template>
            <template v-slot:cell(machineries)="row">
              <b-button variant="primary" size="sm" @click="row.toggleDetails" :disabled="row.item.machineries.length === 0" class="mr-2">
                {{ (row.detailsShowing ? 'Nascondi' : 'Dettagli' ) + " (" + row.item.machineries.length + ")"}}
              </b-button>
            </template>
            <template v-slot:row-details="row">
              <b-table hover :items="row.item.machineries" :fields="maintenancesheets.fieldsDetails"/>
            </template>
          </b-table>
          <tablebottom :perpage="maintenancesheets.search.page.perpage" @updatePerPage="maintenancesheets.search.page.perpage = $event" :currentpage="maintenancesheets.search.page.currentpage" @updateCurrentPage="maintenancesheets.search.page.currentpage = $event" :total="maintenancesheets.items.total" @loadData="loadMaintenancesheets"/>
        </b-tab>
        <b-tab title="Elenco tipologie">
          <b-btn class="mr-2" variant="primary" @click="loadMachineriesTypes">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="success" @click="loadMachineryType(0)">Nuovo</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="machineriesTypes.items.data" :fields="machineriesTypes.fields" :busy="machineriesTypes.isBusy" :no-local-sorting="true" :sort-by.sync="machineriesTypes.search.sort.by" :sort-desc.sync="machineriesTypes.search.sort.desc" @sort-changed="sortingChangeMachineriesTypes" @row-clicked="loadMachineryType($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
          <tablebottom :perpage="machineriesTypes.search.page.perpage" @updatePerPage="machineriesTypes.search.page.perpage = $event" :currentpage="machineriesTypes.search.page.currentpage" @updateCurrentPage="machineriesTypes.search.page.currentpage = $event" :total="machineriesTypes.items.total" @loadData="loadMachineriesTypes"/>
        </b-tab>
        <b-tab title="Analisi costi">
          <b-modal v-model="costsAnalysis.showFilter" title="Filtra" @ok="filterSetCostsAnalysis">
            <b-form-group label="Tipologia">
              <b-form-select v-model="costsAnalysis.tempFilter.machineriestypes_id" :options="comboSeeAll(costsAnalysis.filtersMenu.machineriestypes)" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Macchinario">
              <b-form-select v-model="costsAnalysis.tempFilter.machineries_id" :options="comboSeeAll(costsAnalysis.filtersMenu.machineries)" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Manutenzione">
              <b-form-select v-model="costsAnalysis.tempFilter.mainteinancesheets_id" :options="comboSeeAll(costsAnalysis.filtersMenu.mainteinancesheets)" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Tipologia">
              <b-form-select v-model="costsAnalysis.tempFilter.type" :options="costsAnalysis.filtersMenu.types" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Da">
              <b-form-input v-model="costsAnalysis.tempFilter.datefrom" type="date"/>
            </b-form-group>
            <b-form-group label="A">
              <b-form-input v-model="costsAnalysis.tempFilter.dateto" type="date"/>
            </b-form-group>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadCostsAnalysis">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="warning" @click="filterResetCostsAnalysis">Reset filtri</b-btn>
          <b-btn class="mr-2" variant="secondary" @click="filterOpenCostsAnalysis">Filtra</b-btn>
          <b-btn class="mr-2" variant="info">{{ costsAnalysis.items.costs }} €</b-btn>
          <b-btn variant="info" @click="downloadCostsExcel">Excel</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="costsAnalysis.items.data" :fields="costsAnalysis.fields" :busy="costsAnalysis.isBusy" :no-local-sorting="true" :sort-by.sync="costsAnalysis.search.sort.by" :sort-desc.sync="costsAnalysis.search.sort.desc" @sort-changed="sortingChangeCostsAnalysis">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
          <tablebottom :perpage="costsAnalysis.search.page.perpage" @updatePerPage="costsAnalysis.search.page.perpage = $event" :currentpage="costsAnalysis.search.page.currentpage" @updateCurrentPage="costsAnalysis.search.page.currentpage = $event" :total="costsAnalysis.items.total" @loadData="loadCostsAnalysis"/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import maintenance from './maintenance.vue'
import machinery from './machinery.vue'
import maintenancesheet from './maintenancesheet.vue'
import machineriestype from './machineriestype.vue'
import doctoken from "../doctoken";

export default {
  name: "machineries",
  components: { maintenance, machinery, maintenancesheet, machineriestype, doctoken },
  data() {
    return {
      deadlines : {
        isBusy: true,
        fields: [{
          key: 'val',
          label: 'Macchinario',
          sortable: true,
        },{
          key: 'mindate',
          label: 'Prossima',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'expired',
          label: "Scadute",
        },{
          key: 'month0',
          label: "",
        },{
          key: 'month1',
          label: "",
        },{
          key: 'month2',
          label: "",
        },{
          key: 'month3',
          label: "",
        },{
          key: 'month4',
          label: "",
        },{
          key: 'month5',
          label: "",
        },{
          key: 'month6',
          label: "",
        },{
          key: 'month7',
          label: "",
        },{
          key: 'month8',
          label: "",
        },{
          key: 'month9',
          label: "",
        },{
          key: 'month10',
          label: "",
        },{
          key: 'month11',
          label: "",
        },{
          key: 'month12',
          label: "",
        }],
        filter: null,
        items: [],
      },
      machineries: {
        showFilter: false,
        activeCombo: [{
          id: false,
          val: 'Attivo',
        },{
          id: true,
          val: 'Dismesso',
        },{
          id: null,
          val: 'Tutti',
        }],
        fields: [{
          key: 'machineriestype',
          label: 'Tipologia',
          sortable: true,
        },{
          key: 'serialnumber',
          label: "Identificativo",
          sortable: true,
        },{
          key: "registrationnumber",
          label: "Matricola",
        },{
          key: "unactive",
          label: "Stato",
          sortable: true,
          formatter: value => {
            return value ? 'Non attivo' : 'Attivo';
          }
        },{
          key: 'location',
          label: 'Ubicazione',
          sortable: true,
        },{
          key: 'nextdate',
          label: 'Manutenzioni',
          sortable: true,
        }],
        fieldsDetails: [{
          key: 'mainteinancesheet',
          label: 'Manutenzione',
        },{
          key: 'nextdate',
          label: "Prossima Data",
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'lastdate',
          label: "Ultima Data",
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        }],
        isBusy: true,
        search: {
          page: {
            currentpage: 1,
            perpage: 50,
          },
          filters: {
            machinery: null,
            unactive: false,
            machineriestypes_id: null,
            mainteinancesheets_id: null,
          },
          sort: {
            by: "nextdate",
            desc: false,
          },
        },
        tempFilter: {
          machinery: null,
          unactive: false,
          machineriestypes_id: null,
          mainteinancesheets_id: null,
        },
        filtersMenu: {
          machineriestypes: [],
          mainteinancesheets: [],
        },
        items: {
          total: 0,
          data: [],
        },
      },
      maintenancesheets: {
        showFilter: false,
        isBusy: true,
        search: {
          page: {
            currentpage: 1,
            perpage: 50,
          },
          filters: {},
          sort: {
            by: 'maintenancesheet',
            desc: false,
          },
        },
        tempFilter: {
          active: null,
          maintenancesheet: null,
          machineries_id: null,
        },
        filtersMenu: {
          active: [{
            id: null,
            val: "Tutte"
          },{
            id: true,
            val: "Attive"
          },{
            id: false,
            val: "Non attive - Archiviate"
          }],
          machineries: [],
        },
        fields: [{
          key: 'maintenancesheet',
          label: 'Manutenzione',
          sortable: true,
        },{
          key: "active",
          label: "Stato",
          formatter: value => {
            return value === true ? "Attiva" : "Non attiva - archiviata";
          }
        },{
          key: 'external',
          label: "Tipologia",
          sortable: true,
          formatter: value => {
            return value ? 'Esterna' : 'Interna';
          }
        },{
          key: 'periodicity',
          label: "Periodicità",
          formatter: value => {
            return value !== null ? value.replace("year", "anni").replace("month", "mesi").replace("week", "settimane") : "Nessuna";
          }
        },{
          key: 'machineries',
          label: "Macchinari",
        }],
        fieldsDetails: [{
          key: 'machinery',
          label: 'Macchinario',
        }],
        items: {
          total: 0,
          data: [],
        },
      },
      machineriesTypes: {
        fields: [{
          key: 'machineriestype',
          label: 'Tipologia',
          sortable: true,
        },{
          key: 'machineries',
          label: "Infrastrutture",
        },{
          key: 'mainteinancesheets',
          label: "Manutenzioni associate",
        }],
        isBusy: true,
        items: {
          total: 0,
          data: [],
        },
        search: {
          page: {
            currentpage: 1,
            perpage: 50,
          },
          sort: {
            by: 'machineriestype',
            desc: false,
          },
        },
      },
      costsAnalysis: {
        showFilter: false,
        isBusy: true,
        search: {
          page: {
            currentpage: 1,
            perpage: 50,
          },
          filters: {
            machineriestypes_id: null,
            machineries_id: null,
            mainteinancesheets_id: null,
            type: null,
            datefrom: null,
            dateto: null,
          },
          sort: {
            by: 'dateexpirancy',
            desc: false,
          },
        },
        tempFilter: {
          machineriestypes_id: null,
          machineries_id: null,
          mainteinancesheets_id: null,
          type: null,
          datefrom: null,
          dateto: null,
        },
        filtersMenu: {
          machineriestypes: [],
          machineries: [],
          mainteinancesheets: [],
          types: [],
        },
        fields: [{
          key: 'dateexpirancy',
          label: 'Data',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'machineriestype',
          label: 'Tipologia',
          sortable: true,
        },{
          key: 'serialnumber',
          label: 'Identificativo',
          sortable: true,
        },{
          key: 'mainteinancesheet',
          label: 'Manutenzione',
          formatter: (value, key, item) => {
            return item.mainteinancesheet === null ? 'Straordinaria: ' + item.description : value;
          }
        },{
          key: "cost",
          label: "Costo",
        }],
        fieldsDetails: [{
          key: 'machinery',
          label: 'Macchinario',
        }],
        items: {
          total: 0,
          data: [],
          costs: 0,
        },
      }
    }  
  },
  created(){
    this.loadData();
  },
  computed: {
    token (){
      return this.$store.getters.getToken;
    },
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
    filterComputed(){
      return JSON.stringify(this.machineries.search.filters);
    }
  },
  methods: {
    checkMaintenances(maintenances){
      var today = new Date();
      let expired = false;
      for (let item of maintenances){
        let d = new Date(item.nextdate);
        if (d.getTime() <= today.getTime()) expired = true;
      }
      return expired;
    },
    rowClass(row) {
      let today = new Date();
      if ( row !== null ){
        let nextdate = new Date(row.nextdate);
        if (row.unactive === false && nextdate.getTime() <= today.getTime() && row.nextdate != null) return 'table-danger';
      }
      return "";
    },
    rowDetailClass(item) {
      let today = new Date();
      let d = new Date(item.nextdate);
      return d.getTime() <= today.getTime() ? "table-danger" : "";
    },
    dateFormatter(date){
      let tempDate = new Date(date);
      return [tempDate.getDate(), tempDate.getMonth() + 1, tempDate.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
    },
    dateFormatterShort(date){
      let tempDate = new Date(date);
      return [tempDate.getDate(), tempDate.getMonth() + 1].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
    },
    loadData(){
      this.loadDocument();
      this.loadDeadlines();
      this.loadMachineries();
      this.loadMaintenancesheets();
      this.loadMachineriesTypes();
      this.loadCostsAnalysis();
    },
    loadDocument(){
      this.openHttp("machineries_document", null).then((result) => {
        this.document = result;
      })
    },
    generateDocument(){
      this.generateDocumentHttp("machineries");
    },
    loadDeadlines(){
      let i;
      let today = new Date();
      for (i = 0; i <= 12; i++) {
        this.deadlines.fields[i + 3].label = [today.getMonth() + 1, today.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        today.setMonth( today.getMonth() + 1);
      }
      this.deadlines.isBusy = true;
      this.deadlines.filter = null;
      this.openHttp("machineries_deadlines", null).then((result) => {
        this.deadlines.items = result;
        this.deadlines.isBusy = false;
      })
    },
    loadMachineries(){
      this.machineries.isBusy = true;
      this.openHttp("machineries_machineries", { search: this.machineries.search }).then((result) => {
        this.machineries.items = result;
        this.machineries.isBusy = false;
      })
    },
    filterOpenMachineries(){
      this.openHttp("machineries_machineries_filters", { }).then((result) => {
        this.machineries.filtersMenu = result;
        this.machineries.showFilter = true;
      })
    },
    filterSetMachineries(){
      this.machineries.showFilter = false;
      this.machineries.search.filters = this.machineries.tempFilter;
      this.loadMachineries();
    },
    filterResetMachineries(){
      this.machineries.tempFilter = {
        machinery: null,
        unactive: false,
        machineriestypes_id: null,
        mainteinancesheets_id: null,
      };
      this.filterSetMachineries();
    },
    sortingChangeMachineries(ctx) {
      this.machineries.search.sort.by = ctx.sortBy;
      this.machineries.search.sort.desc = ctx.sortDesc;
      this.loadMachineries();
    },
    excelOpen(num){
      this.$refs["excelForm" + num].submit();
    },
    loadMaintenancesheets(){
      this.maintenancesheets.isBusy = true;
      this.openHttp("machineries_maintenancesheets", { search: this.maintenancesheets.search }).then((result) => {
        this.maintenancesheets.items = result;
        this.maintenancesheets.isBusy = false;
      })
    },
    filterOpenMaintenancesheets(){
      this.openHttp("machineries_maintenancesheets_filters", { }).then((result) => {
        this.maintenancesheets.showFilter = true;
        this.maintenancesheets.filtersMenu.machineries = result.machineries;
      })
    },
    filterSetMaintenancesheets(){
      this.maintenancesheets.showFilter = false;
      this.maintenancesheets.search.filters = this.maintenancesheets.tempFilter;
      this.loadMaintenancesheets();
    },
    filterResetMaintenancesheets(){
      this.maintenancesheets.tempFilter = {
        active: null,
        maintenancesheet: null,
        machineries_id: null,
      };
      this.filterSetMaintenancesheets();
    },
    sortingChangeMaintenancesheets(ctx) {
      this.maintenancesheets.search.sort.by = ctx.sortBy;
      this.maintenancesheets.search.sort.desc = ctx.sortDesc;
      this.loadMaintenancesheets();
    },
    loadMachineriesTypes(){
      this.machineriesTypes.isBusy = true;
      this.openHttp("machineries_machineriestypes", { search: this.machineriesTypes.search }).then((result) => {
        this.machineriesTypes.items = result;
        this.machineriesTypes.isBusy = false;
      })
    },
    sortingChangeMachineriesTypes(ctx) {
      this.machineriesTypes.search.sort.by = ctx.sortBy;
      this.machineriesTypes.search.sort.desc = ctx.sortDesc;
      this.loadMachineriesTypes();
    },
    loadCostsAnalysis(){
      this.costsAnalysis.isBusy = true;
      this.openHttp("machineries_costsanalysis", { search: this.costsAnalysis.search }).then((result) => {
        this.costsAnalysis.isBusy = false;
        this.costsAnalysis.items = result;
      })
    },
    filterOpenCostsAnalysis(){
      this.openHttp("machineries_costsanalysis_filters", { }).then((result) => {
        this.costsAnalysis.showFilter = true;
        this.costsAnalysis.filtersMenu = result;
      })
    },
    filterSetCostsAnalysis(){
      this.costsAnalysis.showFilter = false;
      this.costsAnalysis.search.filters = this.costsAnalysis.tempFilter;
      this.loadCostsAnalysis();
    },
    filterResetCostsAnalysis(){
      this.costsAnalysis.tempFilter = {
        machineriestypes_id: null,
        machineries_id: null,
        mainteinancesheets_id: null,
        type: null,
        datefrom: null,
        dateto: null,
      };
      this.filterSetCostsAnalysis();
    },
    sortingChangeCostsAnalysis(ctx) {
      this.costsAnalysis.search.sort.by = ctx.sortBy;
      this.costsAnalysis.search.sort.desc = ctx.sortDesc;
      this.loadCostsAnalysis();
    },
    loadMaintenance( id, machineriesId, maintenancesheetsId, date){
      this.$refs.maintenance.loadData( id, machineriesId, maintenancesheetsId, date );
    },
    loadMachinery( id ){
      this.$refs.machinery.loadData(id);
    },
    loadMaintenancesheet(id){
      this.$refs.maintenancesheet.loadData(id);
    },
    loadMachineryType(id){
      this.$refs.machineriestype.loadData(id);
    },
    downloadCostsExcel(){
      let supportSearch = {
        page: {
          currentpage: 1,
          perpage: 99999,
        },
        filters: {
          machineriestypes_id: this.costsAnalysis.search.filters.machineriestypes_id,
          machineries_id: this.costsAnalysis.search.filters.machineries_id,
          mainteinancesheets_id: this.costsAnalysis.search.filters.mainteinancesheets_id,
          type: this.costsAnalysis.search.filters.type,
          datefrom: this.costsAnalysis.search.filters.datefrom,
          dateto: this.costsAnalysis.search.filters.dateto,
        },
        sort: {
          by: 'dateexpirancy',
          desc: false,
        },
      };
      this.openHttp("machineries_costsanalysis", { search: supportSearch }).then((result) => {
        this.$refs.excelQueryData.value = JSON.stringify({
          header: [{
            id: "dateexpirancy",
            val: "Scadenza" 
          },{
            id: "machineriestype",
            val: "Tipologia" 
          },{
            id: "serialnumber",
            val: "Macchinario" 
          },{
            id: "mainteinancesheet",
            val: "Manutenzione" 
          },{
            id: "description",
            val: "Dettaglio" 
          },{
            id: "cost",
            val: "Costo" 
          }],
          body: result.data
        });
        this.$refs.excelQuery.submit();
      })
    }
  }
}
</script>