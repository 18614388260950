<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" title="Formazione" scrollable @hidden="closeForm()" @close="closeForm()">
      <b-modal v-model="showUploadModal" title="Carica Attestato" :hide-footer="true">
        <b-form-file placeholder="Scegli un file o trascinalo qui..." drop-placeholder="Trascinalo qui..." @change="saveAttachment($event)"/>
      </b-modal>
      <b-row>
        <b-col lg="6">
          <b-form-group label="Formazione">
            <b-form-input v-model="data.training.training"/>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group label="Norme">
            <multiselect v-model="data.training.standardsSupport" :options="data.standards" placeholder="" selectLabel="" deselectLabel="" :multiple="true" track-by="id" label="val" @input="data.training.standards = updateCombo(data.training.standardsSupport)"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Formatore">
            <b-form-input v-model="data.training.teacher"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-show="data.training.id > 0">
        <b-col lg="12">
          <b-btn type="button" size="sm" variant="success" @click="allPassed()">Tutti superato</b-btn>
          <b-table hover class="mt-3" :items="data.training.certificates" :fields="fieldsCertificates">
            <template v-slot:cell(employees_id)="row">
              <span v-if="row.item.id > 0">{{ data.employees.find( x=> x.id === row.item.employees_id).val }}</span>
              <b-form-select v-if="row.item.id == 0" v-model="row.item.employees_id" :options="data.employees" value-field="id" text-field="val"/>
            </template>
            <template v-slot:cell(verified)="row">
              <b-button-group size="sm">
                <b-btn :variant="row.item.verified == 1 ? 'success' : 'secondary'" @click="row.item.verified = 1">Superato</b-btn>
                <b-btn :variant="row.item.verified == 0 ? 'danger' : 'secondary'" @click="row.item.verified = 0">Non superato</b-btn>
                <b-btn :variant="row.item.verified == null ? 'info' : 'secondary'" @click="row.item.verified = null">In corso</b-btn>
              </b-button-group>
            </template>
            <template v-slot:cell(comment)="row">
              <b-form-input size="sm" v-model="row.item.comment"/>
            </template>
            <template v-slot:cell(file)="row">
              <b-btn size="sm" variant="primary" class="mr-2" v-if="row.item.file === false" @click="employeesUpload(row.item.id)">Carica</b-btn>
              <b-btn size="sm" variant="info" class="mr-2" v-if="row.item.file === true" @click="employeesAttachmentDownload(row.item.id)">Scarica</b-btn>
              <b-btn size="sm" variant="danger" v-if="row.item.file === true" @click="employeesAttachmentDelete(row.item.id)">Elimina</b-btn>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <b-form-group label="Personale">
            <multiselect v-model="data.training.employeesSupport" :options="data.employees" placeholder="" selectLabel="" deselectLabel="" :multiple="true" track-by="id" label="val" @input="data.training.employees = updateCombo(data.training.employeesSupport)"></multiselect>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <b-btn type="button" variant="primary" size="sm" @click="datesAdd()">Aggiungi data</b-btn>
          <b-table hover :items="data.training.dates" :fields="datesFields" class="mt-3">
            <template v-slot:cell(from)="row">
              <b-form-input type="date" v-model="row.item.from"/>
            </template>
            <template v-slot:cell(to)="row">
              <b-form-input type="date" v-model="row.item.to"/>
            </template>
            <template v-slot:cell(hours)="row">
              <b-form-input type="number" v-model="row.item.hours"/>
            </template>
            <template v-slot:cell(index)="row">
              <b-btn variant="danger" @click="data.training.dates.splice(row.index,1)">Elimina</b-btn>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="3">
          <b-form-group label="Chiusura/verifica">
            <b-form-input type="date" v-model="data.training.verifydate"/>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group label="Ripeti">
            <b-form-input type="date" v-model="data.training.repeatdate"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <b-form-group label="Note">
            <b-form-textarea v-model="data.training.note"/>
          </b-form-group>
        </b-col>
      </b-row>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="info" class="mr-2" v-show="data.training.id > 0" @click="downloadData">Scarica</b-btn>
          <b-btn variant="danger" v-show="data.training.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
      <form ref="downloadForm" method="POST" target="_blank" action="/api/file.php">
        <input type="hidden" name="form" value="trainings">
        <input type="hidden" name="document" value="training">
        <input type="hidden" name="id" :value="data.training.id">
        <input type="hidden" name="token" :value="token">
      </form>
      <form ref="downloadAttachmentForm" method="POST" target="_blank" action="/api/file.php">
        <input type="hidden" name="form" value="trainings">
        <input type="hidden" name="document" value="attachmenttraining">
        <input type="hidden" name="id" ref="downloadAttachmentFormId">
        <input type="hidden" name="token" :value="token">
      </form>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "training",
  data() {
    return {
      show: false,
      data: null,
      fieldsCertificates: [{
        key: "fullname",
        label: "Nome",
      },{
        key: "verified",
        label: "Stato",
      },{
        key: "comment",
        label: "Commento",
      },{
        key: "file",
        label: "",
      },],
      uploadEmployeesId: -1,
      showUploadModal: false,
      datesFields: [{
        key: "from",
        label: "Da"
      },{
        key: "to",
        label: "A"
      },{
        key: "hours",
        label: "Ore"
      },{
        key: "index",
        label: ""
      },],
    }
  },
  computed: {
    token (){
      return this.$store.getters.getToken;
    }
  },
  methods: {
    loadData( id ){
      this.showModalAttachment = false;
      this.openHttp("trainings_training", { id: id }).then((result) => {
        result.training.employeesSupport = this.convertCombo(result.employees, result.training.employees);
        result.training.standardsSupport = this.convertCombo(result.standards, result.training.standards);
        this.data = result;
        this.show = true;
      })
    },
    allPassed(){
      for (let item of this.data.training.certificates) item.verified = 1;
    },
    employeesUpload(id){
      this.showUploadModal = true;
      this.uploadEmployeesId = id;
    },
    employeesAttachmentDownload(id){
      this.$refs.downloadAttachmentFormId.value = id;
      this.$refs.downloadAttachmentForm.submit();
    },
    saveAttachment(event){
      this.showUploadModal = false;
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = ()=> {
        this.saveHttp("trainings_training_attachment_save", { id: this.uploadEmployeesId, filename: event.target.files[0].name, base64: reader.result }, "Allegato salvato!" ).then((result) => {
          if ( result.result == true ) this.loadData();
        })
      };
    },
    employeesAttachmentDelete(id){
      this.deleteHttp("trainings_training_attachment_delete", { trainings_id: this.data.training.id, id: id }, "Documento eliminato!" ).then((result) => {
        if ( result.result == true ) this.loadData();
      })
    },
    datesAdd(){
      let d = new Date();
      let month = '' + (d.getMonth() + 1);
      if (month.length < 2) month = '0' + month;
      let day = '' + d.getDate();
      if (day.length < 2) day = '0' + day;
      let year = d.getFullYear();
      this.data.training.dates.push({
        from: [year, month, day].join('-'),
        to: [year, month, day].join('-'),
        hours: 0,
      });
    },
    saveData(){
      this.saveHttp("trainings_training_save", this.data.training, "Formazione salvata!").then((result) => {
        if ( result.result == true ) this.closeForm(true);
      })
    },
    deleteData(){
      this.deleteHttp("trainings_training_delete", { id: this.data.training.id }, "Formazione eliminata!").then((result) => {
        if ( result.result == true ) this.closeForm(true);
      })
    },
    downloadData(){
      this.$refs.downloadForm.submit();
    },
    closeForm( reload = false ){
      this.show = false;
      this.data = null;
      if ( reload === true ) this.$emit('reload');
    }
  }
}
</script>