<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" title="Pianificazione" scrollable @hidden="closeForm" @close="closeForm">
      <b-row>
        <b-col lg="12">
          <b-form-group label="Formazione">
            <b-form-select v-model="data.trainingskind.trainingskinds_id" :options="data.trainingskinds" value-field="id" text-field="val" @input="loadData"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <b-form-group label="Lavoratori">
            <multiselect v-model="data.trainingskind.employeesSupport" :options="data.employees" placeholder="" selectLabel="" deselectLabel="" :multiple="true" track-by="id" label="val" @input="data.trainingskind.employees = updateCombo(data.trainingskind.employeesSupport)"/>
          </b-form-group>
        </b-col>
      </b-row>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" @click="saveData">Salva</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "safetytrainingskind",
  data() {
    return {
      show: false,
      data: null,
    }
  },
  methods: {
    loadData(id){
      let trainingskinds_id = id;
      if (this.data !== null) trainingskinds_id = this.data.trainingskind.trainingskinds_id;
      this.openHttp("safetytrainings_trainingskind", { id: trainingskinds_id }).then((result) => {
        result.trainingskind.employeesSupport = this.convertCombo(result.employees, result.trainingskind.employees);
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("safetytrainings_trainingskind_save", this.data.trainingskind, "Pianificazione salvata!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('reload');
    }
  }
}
</script>