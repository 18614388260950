<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" title="Documento conformità legislativa" size="lg" scrollable @hidden="closeForm()" @close="closeForm()">
      <b-form-group label="Tipologia">
        <b-form-select v-model="data.expiration.expirationtypescompaniessites_id" :options="data.typessites" value-field="id" text-field="val"/>
      </b-form-group>
      <b-row>
        <b-col lg="2">
          <b-form-group label="Data">
            <b-form-input type="date" v-model="data.expiration.date"/>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group label="Emittente">
            <b-form-input v-model="data.expiration.issuer"/>
          </b-form-group>
        </b-col>
      </b-row>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.expiration.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: "expiration",
  data() {
    return {
      machineriesSupport: [],
      show: false,
      data: null,
    }
  },
  methods: {
    loadData( id ){
      this.openHttp("expirations_expiration", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("expirations_expiration_save", this.data.expiration, "Documento salvato!" ).then((result) => {
        if ( result.result == true ) this.closeForm(true);
      })
    },
    deleteData(){
      this.deleteHttp("expirations_expiration_delete", { id: this.data.expiration.id }, "Documento eliminato!" ).then((result) => {
        if ( result.result == true ) this.closeForm(true);
      })
    },
    closeForm(reload = false){
      this.show = false;
      this.data = null;
      if (reload === true ) this.$emit('reload');
    }
  }
}
</script>