<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" :title="'Valutazione competenze: ' + data.employee" scrollable @hidden="closeForm" @close="closeForm">
      <b-table :items="data.skills" :fields="fields">
        <template v-slot:cell(actual)="data">
          <b-button-group size="sm">
            <b-btn v-for="(item, index) of levelCompetence" :key="index"  :variant="data.value >= item.id ? ( data.item.target <= data.item.actual ? 'success' : 'warning') : null" @click="data.item.actual = item.id">{{ item.val }}</b-btn>
          </b-button-group>
        </template>
      </b-table>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "skill",
  data() {
    return {
      show: false,
      data: null,
      fields: [{
        key: 'competence',
        label: 'Competenza',
      },{
        key: 'target',
        label: 'Obiettivo',
        formatter: value => {
          return this.data !== null ? this.levelCompetence.find(x => x.id === value).val : null;
        }
      },{
        key: 'actual',
        label: 'Attuale'
      }],
      levelCompetence: [{
        id: 0,
        val: "Nessuna",
      },{
        id: 1,
        val: "Conoscenza di base",
      },{
        id: 2,
        val: "Autonomia parziale",
      },{
        id: 3,
        val: "Autonomia completa",
      },{
        id: 4,
        val: "Autonomia completa e responsabilità",
      }]
    }
  },
  methods: {
    loadData(id){
      this.openHttp("skills_skill", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("skills_skill_save", this.data.skills, "Valutazione salvata!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('reload');
    }
  }
}
</script>