<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" id="modalForm" :title="'Accessi - ' + date" scrollable @hidden="closeForm" @close="closeForm" hide-footer>
      <b-table no-border-collapse hover responsive :items="data.log" :fields="fields">
        <template v-slot:cell(id)="row">
          <nobr>{{ row.value }}</nobr>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "log",
  data() {
    return {
      show: false,
      data: null,
      date: null,
      fields: [{
        key: 'time',
        label: "Ora",
        sortable: true,
      },{
        key: 'function',
        label: "Funzione",
        formatter: value => {
          return value.replace("api_", "").replace("excel_", "");
        }
      },{
        key: 'id',
        label: "Utente",
        formatter: value => {
          if (this.data.employees.findIndex(x => x.id === value) > -1) return this.data.employees.find(x => x.id === value).val
          return value || ' - credenziali non trovate';
        }
      },{
        key: 'data',
        label: "Richiesta",
      }],
    }
  },
  methods: {
    loadData( date ){
      this.date = date;
      this.openHttp("logs_log", { date: date }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('reload');
    }
  }
}
</script>