<template>
  <div>
    <ppedelivery ref="ppedelivery" @reload="loadData"/>
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Scadenze calendario" active>
          <b-btn variant="primary" @click="loadDataDeadlines">Aggiorna</b-btn>
          <b-table bordered responsive show-empty :sticky-header="(winHeight - 265) + 'px'" class="mt-3" :busy="deadlines.isBusy" :items="deadlines.items.deadlines" :fields="deadlines.fields">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
            <template v-slot:cell(expired)="row">
              <b-button v-for="(item, index) in row.value" :key="index" variant="danger" size="sm" class="mr-2 mb-2" @click="loadPpeDelivery(0, row.item.employees_id, item.ppetypes, item.date)">
                {{ item.ppetypes.map(x => deadlines.items.ppetypes.find( y => y.id === x).val ).join(", ") }}
              </b-button>
            </template>
            <template v-slot:cell(month0)="row">
              <b-button v-for="(item, index) in row.value" :key="index" variant="warning" size="sm" class="mr-2 mb-2" @click="loadPpeDelivery(0, row.item.employees_id, item.ppetypes, item.date)">
                {{ item.ppetypes.map(x => deadlines.items.ppetypes.find( y => y.id === x).val ).join(", ") }}
              </b-button>
            </template>
            <template v-slot:cell(month1)="row">
              <b-button v-for="(item, index) in row.value" :key="index" variant="warning" size="sm" class="mr-2 mb-2" @click="loadPpeDelivery(0, row.item.employees_id, item.ppetypes, item.date)">
                {{ item.ppetypes.map(x => deadlines.items.ppetypes.find( y => y.id === x).val ).join(", ") }}
              </b-button>
            </template>
            <template v-slot:cell(month2)="row">
              <b-button v-for="(item, index) in row.value" :key="index" variant="success" size="sm" class="mr-2 mb-2" @click="loadPpeDelivery(0, row.item.employees_id, item.ppetypes, item.date)">
                {{ item.ppetypes.map(x => deadlines.items.ppetypes.find( y => y.id === x).val ).join(", ") }}
              </b-button>
            </template>
            <template v-slot:cell(month3)="row">
              <b-button v-for="(item, index) in row.value" :key="index" variant="success" size="sm" class="mr-2 mb-2" @click="loadPpeDelivery(0, row.item.employees_id, item.ppetypes, item.date)">
                {{ item.ppetypes.map(x => deadlines.items.ppetypes.find( y => y.id === x).val ).join(", ") }}
              </b-button>
            </template>
            <template v-slot:cell(month4)="row">
              <b-button v-for="(item, index) in row.value" :key="index" variant="success" size="sm" class="mr-2 mb-2" @click="loadPpeDelivery(0, row.item.employees_id, item.ppetypes, item.date)">
                {{ item.ppetypes.map(x => deadlines.items.ppetypes.find( y => y.id === x).val ).join(", ") }}
              </b-button>
            </template>
            <template v-slot:cell(month5)="row">
              <b-button v-for="(item, index) in row.value" :key="index" variant="success" size="sm" class="mr-2 mb-2" @click="loadPpeDelivery(0, row.item.employees_id, item.ppetypes, item.date)">
                {{ item.ppetypes.map(x => deadlines.items.ppetypes.find( y => y.id === x).val ).join(", ") }}
              </b-button>
            </template>
            <template v-slot:cell(month6)="row">
              <b-button v-for="(item, index) in row.value" :key="index" variant="success" size="sm" class="mr-2 mb-2" @click="loadPpeDelivery(0, row.item.employees_id, item.ppetypes, item.date)">
                {{ item.ppetypes.map(x => deadlines.items.ppetypes.find( y => y.id === x).val ).join(", ") }}
              </b-button>
            </template>
            <template v-slot:cell(month7)="row">
              <b-button v-for="(item, index) in row.value" :key="index" variant="success" size="sm" class="mr-2 mb-2" @click="loadPpeDelivery(0, row.item.employees_id, item.ppetypes, item.date)">
                {{ item.ppetypes.map(x => deadlines.items.ppetypes.find( y => y.id === x).val ).join(", ") }}
              </b-button>
            </template>
            <template v-slot:cell(month8)="row">
              <b-button v-for="(item, index) in row.value" :key="index" variant="success" size="sm" class="mr-2 mb-2" @click="loadPpeDelivery(0, row.item.employees_id, item.ppetypes, item.date)">
                {{ item.ppetypes.map(x => deadlines.items.ppetypes.find( y => y.id === x).val ).join(", ") }}
              </b-button>
            </template>
            <template v-slot:cell(month9)="row">
              <b-button v-for="(item, index) in row.value" :key="index" variant="success" size="sm" class="mr-2 mb-2" @click="loadPpeDelivery(0, row.item.employees_id, item.ppetypes, item.date)">
                {{ item.ppetypes.map(x => deadlines.items.ppetypes.find( y => y.id === x).val ).join(", ") }}
              </b-button>
            </template>
            <template v-slot:cell(month10)="row">
              <b-button v-for="(item, index) in row.value" :key="index" variant="success" size="sm" class="mr-2 mb-2" @click="loadPpeDelivery(0, row.item.employees_id, item.ppetypes, item.date)">
                {{ item.ppetypes.map(x => deadlines.items.ppetypes.find( y => y.id === x).val ).join(", ") }}
              </b-button>
            </template>
            <template v-slot:cell(month11)="row">
              <b-button v-for="(item, index) in row.value" :key="index" variant="success" size="sm" class="mr-2 mb-2" @click="loadPpeDelivery(0, row.item.employees_id, item.ppetypes, item.date)">
                {{ item.ppetypes.map(x => deadlines.items.ppetypes.find( y => y.id === x).val ).join(", ") }}
              </b-button>
            </template>
            <template v-slot:cell(month12)="row">
              <b-button v-for="(item, index) in row.value" :key="index" variant="success" size="sm" class="mr-2 mb-2" @click="loadPpeDelivery(0, row.item.employees_id, item.ppetypes, item.date)">
                {{ item.ppetypes.map(x => deadlines.items.ppetypes.find( y => y.id === x).val ).join(", ") }}
              </b-button>
            </template>
          </b-table>
        </b-tab>
        <b-tab title="Scadenze personale">
          <b-btn class="mr-2" variant="primary" @click="loadDataEmployees">Aggiorna</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :tbody-tr-class="rowClassEmployees" :items="employees.items.data" :fields="employees.fields" :busy="employees.isBusy" :no-local-sorting="true" :sort-by.sync="employees.search.sort.by" :sort-desc.sync="employees.search.sort.desc" @sort-changed="sortingChangeEmployees" @row-clicked="loadPpeDelivery(0, $event.id, $event.ppes, null)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
            <template v-slot:cell(ppestypes)="row">
              <div v-for="(item, index) of row.value" :key="index">{{ item.ppetype + ' (' + ( item.maxdate === null ? 'Mai consegnato' : item.maxdate) + ')' + ( item.expired === true ? ' Scaduta' : '') }}</div>
            </template>
          </b-table>
          <tablebottom :perpage="employees.search.page.perpage" @updatePerPage="employees.search.page.perpage = $event" :currentpage="employees.search.page.currentpage" @updateCurrentPage="employees.search.page.currentpage = $event" :total="employees.items.total" @loadData="loadDataEmployees"/>
        </b-tab>
        <b-tab title="Elenco consegne">
          <b-modal id="modalFiltersDeliveries" title="Filtra" @ok="filterSetDeliveries">
            <b-form-group label="Tipo di DPI">
              <b-form-select v-model="deliveries.tempFilter.ppetypes_id" :options="deliveries.filtersMenu.ppetypes" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Personale">
              <b-form-select v-model="deliveries.tempFilter.employees_id" :options="deliveries.filtersMenu.employees" value-field="id" text-field="val"/>
            </b-form-group>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadDataDeliveries">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="warning" @click="filterResetDeliveries">Reset filtri</b-btn>
          <b-btn class="mr-2" variant="secondary" v-b-modal.modalFiltersDeliveries @click="filterOpenDeliveries">Filtra</b-btn>
          <b-btn class="mr-2" variant="success" @click="loadPpeDelivery(0, null, [], null)">Nuovo</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="deliveries.items.data" :fields="deliveries.fields" :busy="deliveries.isBusy" :no-local-sorting="true" :sort-by.sync="deliveries.search.sort.by" :sort-desc.sync="deliveries.search.sort.desc" @sort-changed="sortingChangeDeliveries" @row-clicked="loadPpeDelivery($event.id, null, null, null)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
          <tablebottom :perpage="deliveries.search.page.perpage" @updatePerPage="deliveries.search.page.perpage = $event" :currentpage="deliveries.search.page.currentpage" @updateCurrentPage="deliveries.search.page.currentpage = $event" :total="deliveries.items.total" @loadData="loadDataDeliveries"/>
        </b-tab>
        <b-tab title="Pianificazione">
          <ppekind ref="ppekind" @reload="loadData"/>
          <b-btn class="mr-2" variant="primary" @click="loadDataKinds">Aggiorna</b-btn>
          <b-btn variant="success" @click="loadItemKind(0)">Nuovo</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 265) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="kinds.items" :fields="kinds.fields" :busy="kinds.isBusy" @row-clicked="loadItemKind($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
            <template v-slot:cell(deadline)="row">
              {{ kinds.deadlineCombo.find(x => x.id === row.item.deadlineunit).val }} {{ row.item.deadlineunit !== null ? " " + row.item.deadlinenumber : "" }}
            </template>
          </b-table>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import ppedelivery from './ppedelivery.vue'
import ppekind from './ppekind.vue'

export default {
  name: "ppe",
  components: { ppedelivery, ppekind, },
  data() {
    return {
      //1st tab
      deadlines: {
        isBusy: true,
        data: null,
        fields: [{
          key: 'employees_id',
          label: 'Lavoratore',
          formatter: value => {
            return this.deadlines.items.employees.find( x => x.id === value ).val;
          }
        },{
          key: 'deadline',
          label: 'Prossima',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'expired',
          label: "Scadute",
        },{
          key: 'month0',
          label: "",
        },{
          key: 'month1',
          label: "",
        },{
          key: 'month2',
          label: "",
        },{
          key: 'month3',
          label: "",
        },{
          key: 'month5',
          label: "",
        },{
          key: 'month6',
          label: "",
        },{
          key: 'month7',
          label: "",
        },{
          key: 'month8',
          label: "",
        },{
          key: 'month9',
          label: "",
        },{
          key: 'month10',
          label: "",
        },{
          key: 'month11',
          label: "",
        },{
          key: 'month12',
          label: "",
        }],
        items: {
          deadlines: [],
          employees: [],
          ppetypes: [],
        },
      },
      //2rd tab
      employees: {
        isBusy: true,
        search: {
          page: {
            currentpage: 1,
            perpage: 50,
          },
          filters: {

          },
          sort: {
            by: 'firstname',
            desc: false,
          },
        },
        tempFilter: {
          ppetypes_id: null,
          employees_id: null,
        },
        filtersMenu: {
          ppetypes: [],
          employees: [],
        },
        fields: [{
          key: 'firstname',
          label: "Nome",
          sortable: true
        },{
          key: 'lastname',
          label: "Cognome",
          sortable: true
        },{
          key: 'ppestypes',
          label: 'DPI',
        },{
          key: 'expired',
          label: 'Stato',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            return value === true ? 'Scaduto' : 'Regolare';
          }
        }],
        items: {
          total: 0,
          data: [],
        },
      },
      //3rd tab
      deliveries: {
        isBusy: true,
        search: {
          page: {
            currentpage: 1,
            perpage: 50,
          },
          filters: {},
          sort: {
            by: 'date',
            desc: true,
          },
        },
        tempFilter: {
          ppetypes_id: null,
          employees_id: null,
        },
        filtersMenu: {
          ppetypes: [],
          employees: [],
        },
        fields: [{
          key: 'date',
          label: 'Data',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'employee',
          label: "Personale",
          sortable: true
        },{
          key: 'ppetypes',
          label: 'DPI consegnato',
        },],
        items: {
          total: 0,
          data: [],
        },
      },
      // 4th tab
      kinds: {
        deadlineCombo: [{
          id: null,
          val: "Nessuna",
        },{
          id: "week",
          val: "Settimana/e",
        },{
          id: "month",
          val: "Mese/i",
        },{
          id: "year",
          val: "Anno/i",
        },],
        fields: [{
          key: 'ppetype',
          label: "DPI",
          sortable: true
        },{
          key: 'employees',
          label: "Lavoratori",
        },{
          key: 'jobs',
          label: "Mansioni",
        },{
          key: 'deadline',
          label: "Periodicità",
        }],
        isBusy: false,
        items: [],
      },
    }  
  },
  computed: {
    token (){
      return this.$store.getters.getToken;
    },
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.loadDataDeadlines();
      this.loadDataEmployees();
      this.loadDataDeliveries();
      this.loadDataKinds();
    },
    // 1st tab
    loadDataDeadlines(){
      let i;
      let today = new Date();
      for (i = 0; i < 12; i++) {
        this.deadlines.fields[i + 3].label = [today.getMonth() + 1, today.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        today.setMonth( today.getMonth() + 1);
      }
      this.deadlines.isBusy = true;
      this.openHttp("ppe_deadlines", {}).then((result) => {
        this.deadlines.items = result;
        this.deadlines.isBusy = false;
      })
    },
    loadPpeDelivery(id, employees_id, ppetypes, date){
      this.$refs.ppedelivery.loadData(id, employees_id, ppetypes, date);
    },
    // 2nd tab
    rowClassEmployees(row) {
      if ( row !== null ) if (row.expired === true ) return 'table-danger';
      return '';
    },
    loadDataEmployees(){
      this.openHttp("ppe_employees", this.employees.search ).then((result) => {
        this.employees.items = result;
        this.employees.isBusy = false;
      })
    },
    sortingChangeEmployees(ctx) {
      this.employees.search.sort.by = ctx.sortBy;
      this.employees.search.sort.desc = ctx.sortDesc;
      this.loadDataEmployees();
    },
    // 3rd tab
    loadDataDeliveries(){
      this.deliveries.isBusy = true;
      this.openHttp("ppe_deliveries", this.deliveries.search).then((result) => {
        this.deliveries.isBusy = false;
        this.deliveries.items = result;
      })
    },
    filterOpenDeliveries(){
      this.openHttp("ppe_filters", { }).then((result) => {
        this.deliveries.filtersMenu = result;
      })
    },
    filterSetDeliveries(){
      this.deliveries.search.filters = this.deliveries.tempFilter;
      this.loadDataDeliveries();
    },
    filterResetDeliveries(){
      this.deliveries.tempFilter = {
        ppetypes_id: null,
        employees_id: null,
      };
      this.deliveries.search.filters = this.deliveries.tempFilter;
      this.loadDataDeliveries();
    },
    sortingChangeDeliveries(ctx) {
      this.deliveries.search.sort.by = ctx.sortBy;
      this.deliveries.search.sort.desc = ctx.sortDesc;
      this.loadDataDeliveries();
    },
    // 4th tab
    loadDataKinds(){
      this.kinds.isBusy = true;
      this.openHttp("ppe_kinds", {}).then((result) => {
        this.kinds.items = result;
        this.kinds.isBusy = false;
      })
    },
    loadItemKind(id){
      this.$refs.ppekind.loadData(id);
    }
  }
}
</script>