<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" title="Registrazione indicatore" @hidden="closeForm()" @close="closeForm()">
      <b-form-group label="Data">
        <b-form-input type="date" v-model="data.kpivalue.datekpi"/>
      </b-form-group>
      <b-form-group label="Valore">        
        <b-form-input type="number" v-model="data.kpivalue.valuereached"/>
      </b-form-group>
      <b-form-group label="Commento">
        <b-form-textarea v-model="data.kpivalue.comment"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.kpivalue.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "kpivalue",
  data() {
    return {
      show: false,
      data: null,
    }
  },
  methods: {
    loadData( id, kpinames_id, date = null ){
      this.openHttp("kpis_kpivalue", { id: id, kpinames_id: kpinames_id, date: date } ).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("kpis_kpivalue_save", this.data.kpivalue, "Registrazione salvata!" ).then((result) => {
        if ( result.result == true ) this.closeForm(true);
      })
    },
    deleteData(){
      this.deleteHttp("kpis_kpivalue_delete", { id: this.data.kpivalue.id, kpinames_id: this.data.kpivalue.kpinames_id }, "Registrazione eliminata!" ).then((result) => {
        if ( result.result == true ) this.closeForm(true);
      })
    },
    closeForm(reload = false){
      this.show = false;
      this.data = null;
      if (reload === true) this.$emit('reload');
    }
  }
}
</script>