<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" title="Fornitore" scrollable @hidden="closeForm" @close="closeForm">
      <supplierevaluation ref="evaluation" @reload="loadData(data.supplier.id)"/>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Anagrafica" active>
            <b-row>
              <b-col lg="8">
                <b-form-group label="Ragione sociale">
                  <b-form-input v-model="data.supplier.supplier"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Attivo">
                  <b-form-select v-model="data.supplier.unactive" :options="activeCombo" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4">
                <b-form-group label="Freepass">
                  <b-form-select v-model="data.supplier.freepass" :options="freepassCombo" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="8">
                <b-form-group label="Contatti">
                  <b-form-input v-model="data.supplier.contacts"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="12">
                <b-form-group label="Prodotti acquistati">
                  <multiselect v-model="data.supplier.productsSupport" :options="data.products" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.supplier.products = updateCombo(data.supplier.productsSupport)"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="12">
                <b-form-group label="Note">
                  <b-form-textarea v-model="data.supplier.note" rows="3"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="12">
                <b-form-group label="Certificati">
                  <b-btn variant="primary" size="sm" @click="certificatesAdd">Nuovo</b-btn>
                  <b-table hover class="mt-3" :items="data.supplier.certificates" :fields="fieldsCertificates" :tbody-tr-class="rowClassCertificates">
                    <template v-slot:cell(standard)="row">
                      <b-form-input v-model="row.item.standard" :disabled="row.item.archive === true"/>
                    </template>
                    <template v-slot:cell(body)="row">
                      <b-form-input v-model="row.item.body" :disabled="row.item.archive === true"/>
                    </template>
                    <template v-slot:cell(number)="row">
                      <b-form-input v-model="row.item.number" :disabled="row.item.archive === true"/>
                    </template>
                    <template v-slot:cell(archive)="row">
                      <b-form-checkbox switch v-model="row.item.archive">Archiviato</b-form-checkbox>
                    </template>
                    <template v-slot:cell(expiry)="row">
                      <b-form-input type="date" v-if="row.item.archive === false" v-model="row.item.expiry"/>
                    </template>
                    <template v-slot:cell(index)="row">
                      <b-btn variant="danger" v-if="row.item.archive === false" @click="data.supplier.certificates.splice(row.index, 1)">Elimina</b-btn>
                    </template>
                  </b-table>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Valutazioni" :disabled="data.supplier.id == 0">
            <b-btn variant="success" @click="evaluate( null )">Valuta</b-btn>
            <b-table hover class="mt-2" :items="data.evaluations" :fields="fieldsEvaluations">
              <template v-slot:cell(details)="row">
                <div v-for="(item, index) in row.value" :key="index">{{ item.question }}: {{ item.evaluation }} / 10</div>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-if="data.supplier.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import supplierevaluation from './supplierevaluation.vue'

export default {
  name: "supplier",
  components: { supplierevaluation },
  data() {
    return {
      show: false,
      data: null,
      activeCombo: [{
        id: false,
        val: "Attivo"
      },{
        id: true,
        val: "Non attivo"
      },],
      freepassCombo: [{
        id: false,
        val: "No"
      },{
        id: true,
        val: "Si"
      },],
      fieldsEvaluations: [{
        key: 'date',
        label: "Date",
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'evaluation',
        label: "Valutazione",
        sortable: true,
        formatter: value => {
          return value + '/100';
        }
      },{
        key: 'details',
        label: "Dettagli",
      }],
      fieldsCertificates: [{
        key: 'standard',
        label: "Norme",
      },{
        key: 'body',
        label: "Ente",
      },{
        key: 'number',
        label: "Codice",
      },{
        key: 'archive',
        label: "Archivio",
      },{
        key: 'expiry',
        label: "Scadenza",
      },{
        key: "index",
        label: "",
      },],
    }  
  },
  methods: {
    rowClassCertificates(item) {
      if (item != null && item.unactive == true) return 'table-danger';
      if (item != null && item.freepass == true) return 'table-success';
      if (item != null){
        let oneYearAgo = new Date();
        let dateeval = new Date(item.dateeval);
        oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
        if (oneYearAgo.getTime() >= dateeval.getTime()){
          return 'table-danger';
        } else {
          if ( item.evaluation < 60 ) return 'table-warning';
          if ( item.evaluation >= 60 ) return 'table-success';
        }
      }
    },
    loadData( id ){
      this.openHttp("suppliers_supplier", { id: id }).then((result) => {
        result.supplier.productsSupport = this.convertCombo(result.products, result.supplier.products);
        this.data = result;
        this.show = true;
      })
    },
    evaluate(date){
      let d = null;
      if (date == null){
        d = new Date();
      } else {
        d = new Date(date);
      }
      let month = '' + (d.getMonth() + 1);
      if (month.length < 2) month = '0' + month;
      let day = '' + d.getDate();
      if (day.length < 2) day = '0' + day;
      let year = d.getFullYear();
      this.$refs.evaluation.loadData(this.data.supplier.id, [year, month, day].join('-'));
    },
    certificatesAdd(){
      this.data.supplier.certificates.push({
        id: 0,
        standard: null,
        body: null,
        number: null,
        archive: false,
        expiry: null,
      });
    },
    saveData(){
      this.saveHttp("suppliers_supplier_save", this.data.supplier, "Fornitore salvato!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("suppliers_supplier_delete", { id: this.data.supplier.id }, "Fornitore eliminato!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('reload');
    }
  }
}
</script>