<template>
  <div>
    <doctoken ref="doctoken"/>    
    <emergency ref="emergency" @reload="loadData"/>
    <emergencytype ref="type" @reload="loadData"/>
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Scadenze" active>
          <b-btn variant="primary" class="mr-2" @click="loadDeadlines">Aggiorna</b-btn>
          <b-btn variant="info" v-show="emergencies.items.record_id > 0" @click="$refs.doctoken.downloadRecord(emergencies.items.record_id)">Scarica</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 205) + 'px'" hover responsive show-empty mode="remote" class="mt-3"  :tbody-tr-class="rowExpired" :items="deadlines.items" :fields="deadlines.fields" :busy="deadlines.isBusy" :no-local-sorting="true" :sort-by.sync="deadlines.search.sort.by" :sort-desc.sync="deadlines.search.sort.desc" @sort-changed="sortingChangeDeadlines" @row-clicked="loadEmergency(0, $event.emergenciestypes_id, $event.sites_id, $event.employees_id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
        </b-tab>
        <b-tab title="Elenco">
          <b-modal v-model="emergencies.showFilter" title="Filtra" @ok="filterSetEmergencies">
            <b-form-group label="Emergenza">
              <b-form-input type="text" v-model="emergencies.tempFilter.title" />
            </b-form-group>
            <b-form-group label="Personale">
              <b-form-select v-model="emergencies.tempFilter.employees_id" :options="comboSeeAll(emergencies.filtersMenu.employees)" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Norma">
              <b-form-select v-model="emergencies.tempFilter.standards_id" :options="comboSeeAll(emergencies.filtersMenu.standards)" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Tipologia">
              <b-form-select v-model="emergencies.tempFilter.emergenciestypes_id" :options="comboSeeAll(emergencies.filtersMenu.emergenciestypes)" value-field="id" text-field="val"/>
            </b-form-group>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadEmergencies">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="warning" @click="filterResetEmergencies">Reset filtri</b-btn>
          <b-btn class="mr-2" variant="secondary" @click="filterOpenEmergencies">Filtra</b-btn>
          <b-btn class="mr-2" variant="success" @click="loadEmergency(0)">Nuovo</b-btn>
          <b-btn variant="info" v-show="emergencies.items.record_id > 0" @click="$refs.doctoken.downloadRecord(emergencies.items.record_id)">Scarica</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 205) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="emergencies.items.data" :fields="emergencies.fields" :busy="emergencies.isBusy" :no-local-sorting="true" :sort-by.sync="emergencies.search.sort.by" :sort-desc.sync="emergencies.search.sort.desc" @sort-changed="sortingChangeEmergencies" @row-clicked="loadEmergency($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
            <template v-slot:cell(documentsrecords_id)="row">
              <b-btn variant="info" size="sm" v-show="row.item.documentsrecords_id > 0" @click="$refs.doctoken.downloadRecord(row.item.documentsrecords_id)">Scarica</b-btn>
            </template>
          </b-table>
          <tablebottom :perpage="emergencies.search.page.perpage" @updatePerPage="emergencies.search.page.perpage = $event" :currentpage="emergencies.search.page.currentpage" @updateCurrentPage="emergencies.search.page.currentpage = $event" :total="emergencies.items.total" @loadData="loadEmergencies"/>
        </b-tab>
        <b-tab title="Pianificazione">
          <b-btn class="mr-2" variant="primary" @click="loadTypes">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="success" @click="loadType(0)">Nuovo</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 205) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="types.items.data" :fields="types.fields" :busy="types.isBusy" :no-local-sorting="true" :sort-by.sync="types.search.sort.by" :sort-desc.sync="types.search.sort.desc" @sort-changed="sortingChangeTypes" @row-clicked="loadType($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
          <tablebottom :perpage="types.search.page.perpage" @updatePerPage="types.search.page.perpage = $event" :currentpage="types.search.page.currentpage" @updateCurrentPage="types.search.page.currentpage = $event" :total="types.items.total" @loadData="loadTypes"/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import emergency from './emergency.vue'
import emergencytype from './emergencytype.vue'

export default {
  name: "emergencies",
  components: { emergency, emergencytype },
  data() {
    return {
      frequencyCombo: [{
        id: 1,
        val: "Mensile"
      },{
        id: 2,
        val: "Bimensile"
      },{
        id: 3,
        val: "Trimestrale"
      },{
        id: 4,
        val: "Quadrimestrale"
      },{
        id: 6,
        val: "Semestrale"
      },{
        id: 12,
        val: "Annuale"
      },{
        id: 24,
        val: "Biennale"
      },{
        id: 36,
        val: "Triennale"
      },{
        id: 48,
        val: "Quadriennale"
      },{
        id: 60,
        val: "Quinquennale"
      }],
      deadlines: {
        isBusy: true,
        search: {
          page: {
            currentpage: 1,
            perpage: 50,
          },
          sort: {
            by: "nextdate",
            desc: false,
          },
        },
        fields: [{
          key: "nextdate",
          label: "Scadenza",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "type",
          label: "Tipologia",
          sortable: true,
        },{
          key: "site",
          label: "Sito",
          sortable: true,
        },{
          key: "employees",
          label: "Personale",
        }],
        items: []
      },
      emergencies: {
        isBusy: true,
        showFilter: false,
        search: {
          page: {
            currentpage: 1,
            perpage: 50,
          },
          filters: {},
          sort: {
            by: "type",
            desc: false,
          },
        },
        tempFilter: {
          title: null,
          employees_id: null,
          standards_id: null,
          emergenciestypes_id: null,
        },
        filtersMenu: {
          standards: [],
          employees: [],
        },
        fields: [{
          key: "type",
          label: "Tipologia",
          sortable: true,
        },{
          key: "standards",
          label: 'Norme',
        },{
          key: "site",
          label: 'Sito',
        },{
          key: "date",
          label: "Data",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "title",
          label: "Titolo",
        },{
          key: "employees",
          label: "Partecipanti",
        },{
          key: "documentsrecords_id",
          label: "Report"
        }],
        items: {
          total: 0,
          data: [],
        }
      },
      types: {
        isBusy: true,
        search: {
          page: {
            currentpage: 1,
            perpage: 50,
          },
          filters: {},
          sort: {
            by: "type",
            desc: false,
          },
        },
        fields: [{
          key: "type",
          label: "Tipologia",
          sortable: true,
        },{
          key: "standards",
          label: "Standards",
        },{
          key: "site",
          label: 'Sito',
          sortable: true,
        },{
          key: "frequencymonth",
          label: 'Frequenza',
          sortable: true,
          formatter: value => {
            return value === 1 ? "Mensile" : (value === 2 ? "Bimensile" : (value === 3 ? "Trimestrale" : (value === 4 ? "Quadrimestrale" : (value === 6 ? "Semestrale" : (value === 12 ? "Annuale" : (value === 24 ? "Biennale" : (value === 36 ? "Triennale" : (value === 48 ? "Quadriennale" : (value === 60 ? "Quinquennale" : "?")))))))))
          }
        },{
          key: "employees",
          label: "Partecipanti",
        }],
        items: {
          total: 0,
          data: [],
        }
      }
    }  
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    rowExpired(row) {
      if (row !== null) return row.expired === true ? "table-danger" : "";
    },
    loadData(){
      this.loadDeadlines();
      this.loadEmergencies();
      this.loadTypes();
    },
    loadDeadlines(){
      this.deadlines.isBusy = true
      this.openHttp("emergencies_deadlines", { search: this.deadlines.search }).then((result) => {
        this.deadlines.isBusy = false;
        this.deadlines.items = result;
      })
    },
    sortingChangeDeadlines(ctx) {
      this.deadlines.search.sort.by = ctx.sortBy;
      this.deadlines.search.sort.desc = ctx.sortDesc;
      this.loadDeadlines();
    },
    loadEmergencies(){
      this.emergencies.isBusy = true
      this.openHttp("emergencies_emergencies", { search: this.emergencies.search }).then((result) => {
        this.emergencies.isBusy = false;
        this.emergencies.items = result;
      })
    },
    filterOpenEmergencies(){
      this.openHttp("emergencies_emergencies_filters", { }).then((result) => {
        this.emergencies.filtersMenu = result;
        this.emergencies.showFilter = true;
      })
    },
    filterSetEmergencies(){
      this.emergencies.showFilter= false;
      this.emergencies.search.filters = this.emergencies.tempFilter;
      this.loadEmergencies();
    },
    filterResetEmergencies(){
      this.emergencies.tempFilter = {
        title: null,
        employees_id: null,
        standards_id: null,
        emergenciestypes_id: null,
      };
      this.filterSetEmergencies();
    },
    sortingChangeEmergencies(ctx) {
      this.emergencies.search.sort.by = ctx.sortBy;
      this.emergencies.search.sort.desc = ctx.sortDesc;
      this.loadEmergencies();
    },
    loadEmergency(id, emergenciestypesId, sitesId, employees){
      this.$refs.emergency.loadData( id, emergenciestypesId, sitesId, employees );
    },
    loadTypes(){
      this.types.isBusy = true;
      this.openHttp("emergencies_types", { search: this.types.search }).then((result) => {
        this.types.isBusy = false;
        this.types.items = result;
      })
    },
    sortingChangeTypes(ctx) {
      this.types.search.sort.by = ctx.sortBy;
      this.types.search.sort.desc = ctx.sortDesc;
      this.loadData();
    },
    loadType(id){
      this.$refs.type.loadData(id);
    },  
  }
}
</script>