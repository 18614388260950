<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" title="Scheda taratura" scrollable @hidden="closeForm" @close="closeForm">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Scheda" active>
            <b-form-group label="Taratura">
              <b-form-input v-model="data.calibrationsheet.mainteinancesheet" required/>
            </b-form-group>
            <b-row>
              <b-col lg="4">
                <b-form-group label="Tipologia">
                  <b-form-select v-model="data.calibrationsheet.external" :options="externalCombo" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Periodicità">
                  <b-form-input type="number" v-model="data.calibrationsheet.periodnumber" required min="1"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Periodicità (unità)">
                  <b-form-select v-model="data.calibrationsheet.periodunit" :options="periodicityCombo" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Strumenti soggetti">
              <multiselect v-model="data.calibrationsheet.toolsSupport" :options="data.tools" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.calibrationsheet.tools = updateCombo(data.calibrationsheet.toolsSupport)"/>
            </b-form-group>
            <b-form-group label="Note">
              <b-form-textarea v-model="data.calibrationsheet.note"/>
            </b-form-group>
          </b-tab>
          <b-tab title="Parametri da controllare" v-if="data.calibrationsheet.external == false">
            <b-table hover :items="data.calibrationsheet.parameters" :fields="parametersFields" class="mt-3">
              <template v-slot:cell(parameter)="row">
                <b-form-input v-model="row.item.parameter"/>
              </template>
              <template v-slot:cell(units_id)="row">
                <b-form-select v-model="row.item.units_id" :options="data.units" value-field="id" text-field="val"/>
              </template>
              <template v-slot:cell(measure)="row">
                <b-form-input type="number" step="0.00000001" v-model="row.item.measure"/>
              </template>
              <template v-slot:cell(erroradmitted)="row">
                <b-form-input type="number" step="0.00000001" v-model="row.item.erroradmitted"/>
              </template>
              <template v-slot:cell(errortype)="row">
                <b-form-select v-model="row.item.errortype" :options="errortypeCombo" value-field="id" text-field="val"/>
              </template>
              <template v-slot:cell(machineries_id)="row">
                <b-form-select v-model="row.item.machineries_id" :options="data.primarytools" value-field="id" text-field="val"/>
              </template>
              <template v-slot:cell(numbercalibration)="row">
                <b-form-input type="number" v-model="row.item.numbercalibration"/>
              </template>
              <template v-slot:cell(index)="row">
                <b-btn type="button" variant="danger" @click="data.calibrationsheet.parameters.splice(row.index,1)">Elimina</b-btn>
              </template>
            </b-table>
            <b-btn variant="primary" size="sm" @click="parameterAdd">Nuovo</b-btn>
          </b-tab>
          <b-tab title="Scadenze">
            <b-btn class="mb-2" variant="primary" @click="loadData">Aggiorna</b-btn>
            <b-table hover :items="data.deadlines" :fields="deadlinesFields" class="mt-3"/>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.calibrationsheet.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "calibrationsheet",
  data() {
    return {
      show: false,
      data: null,
      externalCombo: [{
        id: false,
        val: "Interna"
      },{
        id: true,
        val: "Esterna"
      }],
      errortypeCombo: [{
        id: false,
        val: "Err. valore"
      },{
        id: true,
        val: "Err. percentuale"
      }],
      periodicityCombo: [{
        id: "day",
        val: "Giorno/i"
      },{
        id: "week",
        val: "Settimana/e"
      },{
        id: "month",
        val: "Mese/i"
      },{
        id: "year",
        val: "Anno/i"
      },],
      parametersFields: [{
        key: 'parameter',
        label: 'Parametro',
      },{
        key: 'units_id',
        label: 'Unità',
      },{
        key: 'measure',
        label: 'Valore nominale',
      },{
        key: 'erroradmitted',
        label: 'Tolleranza',
      },{
        key: 'errortype',
        label: ''
      },{
        key: 'machineries_id',
        label: 'Primario'
      },{
        key: 'numbercalibration',
        label: 'Numero'
      },{
        key: 'index',
        label: ""
      }],
      deadlinesFields: [{
        key: 'machinery',
        label: 'Strumento',
        sortable: true,
      },{
        key: 'location',
        label: 'Ubicazione',
        sortable: true,
      },{
        key: 'lastdate',
        label: 'Ultima',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'nextdate',
        label: 'Prossima',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      }],
    }
  },
  methods: {
    loadData(id){
      this.openHttp("tools_calibrationsheet", { id: id }).then((result) => {
        if (this.data != null){
          this.data.deadlines = result.deadlines;
        } else {
          result.calibrationsheet.toolsSupport = this.convertCombo(result.tools, result.calibrationsheet.tools);
          this.data = result;
        }
        this.show = true;
      })
    },
    parameterAdd(){
      this.data.calibrationsheet.parameters.push({
        id: 0,
        parameter: "",
        units_id: this.data.units[0].id,
        measure: 0,
        erroradmitted: 0,
        errortype: true,
        machineries_id: null,
        numbercalibration: 1,
      });
    },
    saveData(){
      this.saveHttp("tools_calibrationsheet_save", this.data.calibrationsheet, "Scheda taratura salvata!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("tools_calibrationsheet_delete", { id: this.data.calibrationsheet.id }, "Scheda taratura eliminata!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('reload');
    }
  }
}
</script>