<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" title="Taratura" scrollable @hidden="closeForm" @close="closeForm">
      <b-card :title="data.tool.machineriestype + ' ' + data.tool.serialnumber + ' - ' + data.calibrationsheet.calibrationsheet" :sub-title="( data.calibrationsheet.external ? 'Esterna' : 'Interna' ) + ' - ' + data.calibrationsheet.periodnumber + ' ' + data.calibrationsheet.periodunit" class="mb-2">
        <b-card-text>{{ data.calibrationsheet.details }}</b-card-text>
      </b-card>
      <b-row>
        <b-col lg="2">
          <b-form-group label="Data">
            <b-form-input type="date" v-model="data.calibration.date"/>
          </b-form-group>
        </b-col>
        <b-col lg="5">
          <b-form-group label="Responsabile interno">
            <b-form-select v-model="data.calibration.employees_id" :options="data.employees" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="5">
          <b-form-group label="Responsabile esterno / fornitore">
            <b-form-input v-model="data.calibration.supplier"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Parametri" v-if="data.calibration.params.length > 0">
        <b-table hover :items="data.calibration.params" :fields="fieldsCalibration" :tbody-tr-class="rowCheckTool">
          <template v-slot:cell(range)="data">
            {{ data.item.measure + ' ' + data.item.unit + ' ± ' + data.item.erroradmitted + (data.item.errortype === true ? '%' : (' ' + data.item.unit))}}
          </template>
          <template v-slot:cell(result)="data">
            <b-form-input type="number" step="0.0000001" v-model="data.item.result"/>
          </template>
        </b-table>
      </b-form-group>
      <b-form-group label="Note">
        <b-form-textarea v-model="data.calibration.note" rows="3"/>
      </b-form-group>
      <b-row>
        <b-col lg="3">
          <b-form-group label="Costo">
            <b-form-input type="number" v-model="data.calibration.cost"/>
          </b-form-group>
        </b-col>
        <b-col lg="9">
          <b-form-group label="Allegati">
            <b-btn v-show="data.calibration.id == 0" variant="info">Prima di caricare dei files è necessario salvare</b-btn>
            <div v-if="data.calibration.id > 0">
              <b-btn variant="info" size="sm" class="mr-2 mb-2" v-b-modal.modalFiltersAttachments>Carica allegato</b-btn>
              <b-modal v-model="showModalAttachment" id="modalFiltersAttachments" title="Carica allegato" :hide-footer="true">
                <b-form-file placeholder="Scegli un file o trascinalo qui..." drop-placeholder="Trascinalo qui..." @change="saveAttachment($event)"/>
              </b-modal>
              <b-dropdown v-for="(item, index) of data.files" :key="index" dropright id="dropdown-1" variant="secondary" size="sm" class="mr-2 mb-2" :text="item.file">
                <b-dropdown-item @click="downloadDataAttachment(item.file)">Scarica</b-dropdown-item>
                <b-dropdown-item @click="deleteAttachment(item.file)">Elimina</b-dropdown-item>
              </b-dropdown>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="info" class="mr-2" v-show="data.calibration.id > 0" @click="downloadData">Scarica</b-btn>
          <b-btn variant="danger" v-show="data.calibration.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
      <form ref="downloadForm" method="POST" target="_blank" action="/api/file.php">
        <input type="hidden" name="form" value="tools">
        <input type="hidden" name="document" value="calibration">
        <input type="hidden" name="id" :value="data.calibration.id">
        <input type="hidden" name="token" :value="token">
      </form>
      <form ref="downloadAttachmentForm" method="POST" target="_blank" action="/api/file.php">
        <input type="hidden" name="form" value="tools">
        <input type="hidden" name="document" value="attachmentcalibration">
        <input type="hidden" name="id" :value="data.calibration.id">
        <input type="hidden" name="filename" ref="filename">
        <input type="hidden" name="token" :value="token">
      </form>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "calibration",
  data() {
    return {
      show: false,
      data: null,
      showModalAttachment: false,
      fieldsCalibration: [{
        key: 'parameter',
        label: "Parametro",
      },{
        key: 'calibrationnumber',
        label: 'N° prova',
      },{
        key: 'toolmaster',
        label: 'Strumento primario',
      },{
        key: 'range',
        label: 'Valore di taratatura',
      },{
        key: 'result',
        label: 'Valore rilevato',
      }],
    }
  },
  computed: {
    token (){
      return this.$store.getters.getToken;
    }
  },
  methods: {
    rowCheckTool(item) {
      if (item.result === "") item.result = null;
      if (item.result === null) return 'table-warning';
      let maxerror = item.errortype === true ? (item.erroradmitted * item.measure / 100) : item.erroradmitted;
      if ((item.result) > 0){
        return 'table-' + ( (item.result <= item.measure + maxerror ) && (item.result >= item.measure - maxerror) ? "success" : "danger" ) ;
      } else {
        return 'table-' + ( (item.result >= item.measure + maxerror ) && (item.result <= item.measure - maxerror) ? "success" : "danger" ) ;
      }
    },
    loadData( id, tools_id, calibrationsheets_id, date ){
      this.openHttp("tools_calibration", { id: id, tools_id: tools_id, calibrationsheets_id: calibrationsheets_id, date: date }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveAttachment(event){
      this.showModalAttachment = false;
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = ()=> {
        this.saveHttp("tools_calibration_save_attachment", { id: this.data.calibration.id, filename: event.target.files[0].name, base64: reader.result }, "Allegato salvato!" ).then((result) => {
          if ( result.result == true ) this.loadData( this.data.calibration.id, this.data.calibration.machineries_id, this.data.calibration.mainteinancesheets_id, this.data.calibration.date );
        })
      };
    },
    deleteAttachment(filename){
      this.deleteHttp("tools_calibration_delete_attachment", { id: this.data.calibration.id, filename: filename }, "Allegato eliminato!" ).then((result) => {
        if ( result.result == true ) this.loadData( this.data.calibration.id, this.data.calibration.machineries_id, this.data.calibration.mainteinancesheets_id, this.data.calibration.date );
      })
    },
    downloadDataAttachment(fileName){
      this.$refs.filename.value = fileName;
      this.$nextTick(() => {
        this.$refs.downloadAttachmentForm.submit();
      })
    },
    saveData(){
      this.saveHttp("tools_calibration_save", this.data.calibration, "Taratura salvata!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("tools_calibration_delete", { id: this.data.calibration.id }, "Taratura eliminata!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    downloadData(){
      this.$refs.downloadForm.submit();
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('reload');
    }
  }
}
</script>