<template>
  <div>
    <b-modal v-model="show" title="Documento">
      <b-form-group label="Documento">
        <b-form-select v-model="document" :options="documentOptions" value-field="doc" text-field="doc"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" @click="updateData">Salva</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "auditdocument",
  props: ["documentOptions"],
  data() {
    return {
      show: false,
      questionIid: null,
      document: null,
    }  
  },
  methods: {
    loadData( questionIid, document ){
      this.questionIid = questionIid;
      this.document = document;
      this.show = true;
    },
    updateData(){
      this.show = false;
      this.$emit('update', { questionIid: this.questionIid, document: this.document });
    },
  }
}
</script>