<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" title="Responsabilità" size="lg" @hidden="closeForm()" @close="closeForm()">
      <b-form-group label="Responsabilità">
        <b-form-textarea v-model="data.responsibility.responsibility"/>
      </b-form-group>
      <b-form-group label="Mansioni associate">
        <multiselect v-model="jobdescriptionsSupport" :options="data.jobdescriptions" placeholder="" selectLabel="" deselectLabel="" :multiple="true" track-by="id" label="val" @input="data.responsibility.jobdescriptions = updateCombo(jobdescriptionsSupport)"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.responsibility.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "responsibility",
  data() {
    return {
      show: false,
      data: null,
      jobdescriptionsSupport: []
    }
  },
  methods: {
    loadData( id ){
      this.openHttp("jobs_responsibility", { id: id }).then((result) => {
        this.jobdescriptionsSupport = this.convertCombo(result.jobdescriptions, result.responsibility.jobdescriptions);
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("jobs_responsibility_save", this.data.responsibility, "Responsabilità salvata!").then((result) => {
        if ( result.result == true ) this.closeForm(true);
      })
    },
    deleteData(){
      this.deleteHttp("jobs_responsibility_delete", { id: this.data.responsibility.id }, "Responsabilità eliminata!" ).then((result) => {
        if ( result.result == true ) this.closeForm(true);
      })
    },
    closeForm(reload = false){
      this.show = false;
      this.data = null;
      if (reload === true) this.$emit('reload');
    }
  }
}
</script>