<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" title="Soggetto due Diligence" size="lg" @hidden="closeForm()" @close="closeForm()">
      <b-row>
        <b-col lg="8">
          <b-form-group label="Soggetto">
            <b-form-input :value="data.subject.subject + ' (' + data.subject.type + ')'"/>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group label="Necessaria Due Diligence?">
            <b-form-select v-model="data.subject.noduediligence" :options="noduediligenceCombo" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Due Diligences">
        <duediligence ref="duediligence" @reload="loadData( data.subject.id, data.subject.type )"/>
        <b-table :items="data.duediligences" :fields="fields" show-empty @row-clicked="loadDuediligence($event.id, data.subject.type, data.subject.id)">
          <template v-slot:empty>Non ci sono due diligence</template>
            <template v-slot:cell(evaluations)="row">
              <div v-for="(item, index) of row.value" :key="index">
                <strong>{{ item.criteria}}</strong>{{ ( item.mandatory === true ? ' Obbligatorio' : "" ) + ": " + (item.yesno === true ? "Compliant" : (item.yesno === false ? "Not compliant" : "Non applicabile")) + " " + item.note }}  
              </div>
            </template>
        </b-table>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" @click="saveData">Salva</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import duediligence from './duediligence.vue';

export default {
  name: "duediligencesubject",
  components: { duediligence },
  data() {
    return {
      show: false,
      data: null,
      noduediligenceCombo: [{
        id: true,
        val: "Non soggetto"
      },{
        id: false,
        val: "Soggetto"
      }],
      fields: [{
        key: "date",
        label: "Data",
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: "duediligencetype",
        label: "Tipologia",
      },{
        key: "status",
        label: "Risultato",
        formatter: value => {
          return value === true ? "Compliant" : ( value === false ? "Not compliant" : "Non applicabile" )
        }
      },{
        key: "note",
        label: "Note",
      },{
        key: "evaluations",
        label: "Valutazioni",
      }],
    }
  },
  methods: {
    loadData( id, type ){
      this.openHttp("duediligences_subject", { id: id, type: type }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    loadDuediligence(id, type, typeId){
      this.$refs.duediligence.loadData(id, type, typeId);
    },
    saveData(){
      this.saveHttp("duediligences_subject_save", this.data.subject, "Soggetto Due Diligence salvato!" ).then((result) => {
        if ( result.result == true ) this.closeForm(true);
      })
    },
    closeForm(reload = false){
      this.show = false;
      this.data = null;
      if (reload === true) this.$emit('reload');
    }
  }
}
</script>