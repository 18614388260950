<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" title="Riesame" scrollable @hidden="closeForm" @close="closeForm">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Copertina" active>
            <b-row>
              <b-col lg="3">
                <b-form-group label="Apertura">
                  <b-form-input type="date" v-model="data.managementreview.opening"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Chiusura">
                  <b-form-input type="date" v-model="data.managementreview.closure"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Periodo Da">
                  <b-form-input type="date" v-model="data.managementreview.periodfrom"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Periodo A">
                  <b-form-input type="date" v-model="data.managementreview.periodto"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Partecipanti">
              <multiselect v-model="employeesSupport" :options="data.employees" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.managementreview.employees = updateCombo(employeesSupport)"/>
            </b-form-group>
            <b-form-group label="Norme">
              <multiselect v-model="standardsSupport" :options="data.standards" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.managementreview.standards = updateCombo(standardsSupport)"/>
            </b-form-group>
            <b-form-group label="Elementi" v-if="data.managementreview.id === 0">
              <b-form-checkbox-group v-model="data.managementreview.questions">
                <b-table no-border-collapse responsive sticky-header="500px" :items="data.questions.filter(x => x.standards.filter(y => data.managementreview.standards.includes(y)).length > 0)" :fields="fields.questions">
                  <template v-slot:cell(id)="row">
                    <b-form-checkbox :value="row.value"/>
                  </template>
                </b-table>
              </b-form-checkbox-group>
            </b-form-group>
            <b-form-group label="Note">
              <b-form-textarea v-model="data.managementreview.note" rows="2"/>
            </b-form-group>
          </b-tab>
          <b-tab title="Elementi in ingresso" v-if="data.managementreview.id > 0">
            <b-table no-border-collapse responsive :sticky-header="(winHeight - 360) + 'px'" :items="data.managementreview.inputs" :fields="fields.inputsoutputs">
              <template v-slot:cell(question)="row">
                <b-form-group :label="row.value">
                  <b-form-textarea v-model="row.item.answer" max-rows="10"/>
                </b-form-group>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Elementi in uscita" v-if="data.managementreview.id > 0">
            <b-table no-border-collapse responsive :sticky-header="(winHeight - 360) + 'px'" :items="data.managementreview.outputs" :fields="fields.inputsoutputs">
              <template v-slot:cell(question)="row">
                <b-form-group :label="row.value">
                  <b-form-textarea v-model="row.item.answer" max-rows="10"/>
                </b-form-group>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData()">Salva</b-btn>
          <b-btn variant="danger" v-show="data.managementreview.id > 0" @click="deleteData()">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "managementreview",
  data() {
    return {
      show: false,
      data: null,
      employeesSupport: [],
      standardsSupport: [],
      fields: {
        questions:[{
          key: 'isoutput',
          label: "Tipo",
          formatter: value => {
            if (value == null) return null;
            return value === true ? "Output" : "Input";
          }
        },{
          key: 'question',
          label: "Elemento"
        },{
          key: 'id',
          label: ""
        }],
        inputsoutputs:[{
          key: 'question',
          label: "Domanda"
        }]
      }
    }  
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    loadData( id ){
      this.openHttp("managementreviews_managementreview", { id: id } ).then((result) => {
        this.employeesSupport = this.convertCombo(result.employees, result.managementreview.employees);
        this.standardsSupport = this.convertCombo(result.standards, result.managementreview.standards);
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("managementreviews_managementreview_save", this.data.managementreview, "Riesame salvato!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("managementreviews_managementreview_delete", { id: this.data.managementreview.id }, "Riesame eliminato!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('reload');
    }
  }
}
</script>
