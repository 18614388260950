import Vue from 'vue'
import Router from 'vue-router'
import companies from '@/components/companies.vue'
import superusers from '@/components/superusers.vue'
import templates from '@/components/templates/templates.vue'
import home from '@/components/home.vue'
import company from '@/components/company/company.vue'
import auths from '@/components/company/auths.vue'
import logs from '@/components/company/logs.vue'
import processes from '@/components/system/processes.vue'
import documents from '@/components/system/documents.vue'
import duediligences from '@/components/system/duediligences.vue'
import laws from '@/components/system/laws.vue'
import knowledges from '@/components/system/knowledges.vue'
import changes from '@/components/system/changes.vue'
import emergencies from '@/components/system/emergencies.vue'
import context from '@/components/context/context.vue'
import interestedparties from '@/components/context/interestedparties.vue'
import risks from '@/components/context/risks.vue'
import impacts from '@/components/context/impacts.vue'
import chemicals from '@/components/context/chemicals.vue'
import employees from '@/components/humans/employees.vue'
import jobs from '@/components/humans/jobs.vue'
import skills from '@/components/humans/skills.vue'
import organizationchart from '@/components/humans/organizationchart.vue'
import trainings from '@/components/humans/trainings.vue'
import safetytrainings from '@/components/humans/safetytrainings.vue'
import medicals from '@/components/humans/medicals.vue'
import ppe from '@/components/humans/ppe.vue'
import delegations from '@/components/humans/delegations.vue'
import machineries from '@/components/machines/machineries.vue'
import consumptions from '@/components/machines/consumptions.vue'
import tools from '@/components/machines/tools.vue'
import expirations from '@/components/machines/expirations.vue'
import suppliers from '@/components/supplierscustomers/suppliers.vue'
import customers from '@/components/supplierscustomers/customers.vue'
import nonconformities from '@/components/nonconformities/nonconformities.vue'
import correctiveactions from '@/components/nonconformities/correctiveactions.vue'
import opportunities from '@/components/nonconformities/opportunities.vue'
import targets from '@/components/targets/targets.vue'
import kpis from '@/components/targets/kpis.vue'
import audits from '@/components/targets/audits.vue'
import managementreviews from '@/components/targets/managementreviews.vue'

import login from '@/components/login/login.vue'

import axios from 'axios';
import store from '../store'

Vue.use(Router)

const router = new Router  ({
  routes:[{
    path: '/home',
    name: 'home',
    component: home,
    meta: { requiresAuth: true, }
  },{
    path: '/superusers',
    name: 'superusers',
    component: superusers,
    meta: { requiresAuth: true, }
  },{
    path: '/templates',
    name: 'templates',
    component: templates,
    meta: { requiresAuth: true, }
  },{
    path: '/companies',
    name: 'companies',
    component: companies,
    meta: { requiresAuth: true, }
  },{
    path: '/company',
    name: 'company',
    component: company,
    meta: { requiresAuth: true, }
  },{
    path: '/auths',
    name: 'auths',
    component: auths,
    meta: { requiresAuth: true, }
  },{
    path: '/logs',
    name: 'logs',
    component: logs,
    meta: { requiresAuth: true, }
  },{
    path: '/processes',
    name: 'processes',
    component: processes,
    meta: { requiresAuth: true, }
  },{
    path: '/documents',
    name: 'documents',
    component: documents,
    meta: { requiresAuth: true, }
  },{
    path: '/laws',
    name: 'laws',
    component: laws,
    meta: { requiresAuth: true, }
  },{
    path: '/knowledges',
    name: 'knowledges',
    component: knowledges,
    meta: { requiresAuth: true, }
  },{
    path: '/changes',
    name: 'changes',
    component: changes,
    meta: { requiresAuth: true, }
  },{
    path: '/context',
    name: 'context',
    component: context,
    meta: { requiresAuth: true, }
  },{
    path: '/interestedparties',
    name: 'interestedparties',
    component: interestedparties,
    meta: { requiresAuth: true, }
  },{
    path: '/risks',
    name: 'risks',
    component: risks,
    meta: { requiresAuth: true, }
  },{
    path: '/impacts',
    name: 'impacts',
    component: impacts,
    meta: { requiresAuth: true, }
  },{
    path: '/chemicals',
    name: 'chemicals',
    component: chemicals,
    meta: { requiresAuth: true, }
  },{
    path: '/employees',
    name: 'employees',
    component: employees,
    meta: { requiresAuth: true, }
  },{
    path: '/jobs',
    name: 'jobs',
    component: jobs,
    meta: { requiresAuth: true, }
  },{
    path: '/organizationchart',
    name: 'organizationchart',
    component: organizationchart,
    meta: { requiresAuth: true, }
  },{
    path: '/skills',
    name: 'skills',
    component: skills,
    meta: { requiresAuth: true, }
  },{
    path: '/trainings',
    name: 'trainings',
    component: trainings,
    meta: { requiresAuth: true, }
  },{
    path: '/safetytrainings',
    name: 'safetytrainings',
    component: safetytrainings,
    meta: { requiresAuth: true, }
  },{
    path: '/medicals',
    name: 'medicals',
    component: medicals,
    meta: { requiresAuth: true, }
  },{
    path: '/ppe',
    name: 'ppe',
    component: ppe,
    meta: { requiresAuth: true, }
  },{
    path: '/delegations',
    name: 'delegations',
    component: delegations,
    meta: { requiresAuth: true, }
  },{
    path: '/emergencies',
    name: 'emergencies',
    component: emergencies,
    meta: { requiresAuth: true, }
  },{
    path: '/machineries',
    name: 'machineries',
    component: machineries,
    meta: { requiresAuth: true, }
  },{
    path: '/tools',
    name: 'tools',
    component: tools,
    meta: { requiresAuth: true, }
  },{
    path: '/consumptions',
    name: 'consumptions',
    component: consumptions,
    meta: { requiresAuth: true, }
  },{
    path: '/expirations',
    name: 'expirations',
    component: expirations,
    meta: { requiresAuth: true, }
  },{
    path: '/suppliers',
    name: 'suppliers',
    component: suppliers,
    meta: { requiresAuth: true, }
  },{
    path: '/customers',
    name: 'customers',
    component: customers,
    meta: { requiresAuth: true, }
  },{
    path: '/duediligences',
    name: 'duediligences',
    component: duediligences,
    meta: { requiresAuth: true, }
  },{
    path: '/nonconformities',
    name: 'nonconformities',
    component: nonconformities,
    meta: { requiresAuth: true, }
  },{
    path: '/correctiveactions',
    name: 'correctiveactions',
    component: correctiveactions,
    meta: { requiresAuth: true, }
  },{
    path: '/opportunities',
    name: 'opportunities',
    component: opportunities,
    meta: { requiresAuth: true, }
  },{
    path: '/targets',
    name: 'targets',
    component: targets,
    meta: { requiresAuth: true, }
  },{
    path: '/kpis',
    name: 'kpis',
    component: kpis,
    meta: { requiresAuth: true, }
  },{
    path: '/audits',
    name: 'audits',
    component: audits,
    meta: { requiresAuth: true, }
  },{
    path: '/managementreviews',
    name: 'managementreviews',
    component: managementreviews,
    meta: { requiresAuth: true, }
  },{
    path: '/login',
    name: 'login',
    component: login,
  },{
    path: "*",
    redirect: '/home',
  }]
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth){
    if ( store.getters.isLogged == true ){
      let promise = axios.post("api2/api/routes_check", { token: store.state.token });
      return promise.then((data) => {
        let dataParsed = JSON.parse(data.data);
        if ( dataParsed.result === true && dataParsed.allowed.indexOf(to.name) > -1 ){
          store.commit("setRoutes", dataParsed.allowed);
          next();
        } else {
          store.commit("unSetToken");
          next("login");
        }
        return(true)
      }).catch(error => {
        console.log(error);
        return []
      })     
    } else {
      store.commit("unSetToken");
      if ([ "login", "registration" ].includes(to.name)){
        next();
      } else {
        next("login");
      }
    }
  } else {
    next();
  }
})

export default router;