<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" title="Organigramma" scrollable @hidden="closeForm" @close="closeForm">
      <b-row>
        <b-col lg="12">
          <b-form-group label="Data di creazione">
            <b-form-input type="date" v-model="data.organizationchart.createdate"/>
          </b-form-group>
        </b-col>
        <b-col lg="12">
          <b-form-group label="Data di approvazione">
            <b-form-input type="date" v-model="data.organizationchart.approveddate"/>
          </b-form-group>
        </b-col>
        <b-col lg="12">
          <b-form-group label="Approvato da">
            <b-form-select v-model="data.organizationchart.approved_employees_id" :options="data.employees" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="12">
          <b-form-group label="File">
            <b-form-file placeholder="Scegli un file o trascinalo qui..." drop-placeholder="Trascinalo qui..." @change="loadAttachment($event)"/>
          </b-form-group>
        </b-col>
      </b-row>
      <template v-slot:modal-footer>
        <div class="w-100" v-if="data != null">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="primary" class="mr-2" v-show="data.organizationchart.fileyesno == true" @click="downloadData">Scarica</b-btn>
          <b-btn variant="danger" v-show="data.organizationchart.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
      <form ref="downloadForm" method="POST" target="_blank" action="/api/file.php">
        <input type="hidden" name="form" value="organizationchart">
        <input type="hidden" name="document" value="organizationchart">
        <input type="hidden" name="id" :value="data.organizationchart.id">
        <input type="hidden" name="token" :value="token">
      </form>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "organizationchartdetail",
  data() {
    return {
      show: false,
      data: null,
    }
  },
  computed: {
    token (){
      return this.$store.getters.getToken;
    }
  },
  methods: {
    loadData(id){
      this.openHttp("organizationchart_organizationchartdetail", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    loadAttachment(event){
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = ()=> {
        this.data.organizationchart.filedata = reader.result;
        let temp = event.target.files[0].name.split(".");
        this.data.organizationchart.fileextension = temp[temp.length - 1];
      };
    },
    saveData(){
      this.saveHttp("organizationchart_organizationchartdetail_save", this.data.organizationchart, "Organigramma salvato!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    downloadData(){
      this.$refs.downloadForm.submit();
    },
    deleteData(){
      this.deleteHttp("organizationchart_organizationchartdetail_delete", { id: this.data.organizationchart.id }, "Organigramma eliminato!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('reload');
    }
  }
}
</script>