<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" title="Due Diligence" size="lg" @hidden="closeForm()" @close="closeForm()">
      <b-row>
        <b-col lg="2">
          <b-form-group label="Data">
            <b-form-input type="date" v-model="data.duediligence.date"/>
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group label="Eseguita da">
            <b-form-select v-model="data.duediligence.done_employees_id" :options="data.done_employees" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group label="Due Diligence">
            <b-form-select v-model="data.duediligence.duediligencestypes_id" :options="data.duediligencestypes" value-field="id" text-field="val" :disabled="data.duediligence.id > 0"/>
          </b-form-group>
        </b-col>
        <b-col lg="12">
          <b-form-group label="Soggetto">
            <b-form-select v-if="data.duediligence.duediligencestypes_id > 0 && data.duediligencestypes.find(x => x.id === data.duediligence.duediligencestypes_id ).tabletype === 'customers'" v-model="data.duediligence.customers_id" :options="data.customers" value-field="id" text-field="val" :disabled="data.duediligence.id > 0"/>
            <b-form-select v-if="data.duediligence.duediligencestypes_id > 0 && data.duediligencestypes.find(x => x.id === data.duediligence.duediligencestypes_id ).tabletype === 'suppliers'" v-model="data.duediligence.suppliers_id" :options="data.suppliers" value-field="id" text-field="val" :disabled="data.duediligence.id > 0"/>
            <b-form-select v-if="data.duediligence.duediligencestypes_id > 0 && data.duediligencestypes.find(x => x.id === data.duediligence.duediligencestypes_id ).tabletype === 'employees'" v-model="data.duediligence.employees_id" :options="data.employees" value-field="id" text-field="val" :disabled="data.duediligence.id > 0"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Note">
        <b-form-textarea v-model="data.duediligence.note"/>
      </b-form-group>
      <b-form-group label="Criteri">
        <b-table mode="remote" class="mt-3" :items="data.duediligencescriteria.filter(x => x.duediligencestypes_id === data.duediligence.duediligencestypes_id)" :fields="fields">
          <template v-slot:cell(yesno)="row">
            <b-button-group size="sm">
              <b-btn :variant="data.duediligence.evaluations.findIndex(x => x.criteria_id === row.item.id) > -1 && data.duediligence.evaluations.find(x => x.criteria_id === row.item.id).yesno === true ? 'success' : null" @click="setEval(row.item.id, true)">Compliant</b-btn>
              <b-btn :variant="data.duediligence.evaluations.findIndex(x => x.criteria_id === row.item.id) > -1 && data.duediligence.evaluations.find(x => x.criteria_id === row.item.id).yesno === false ? 'danger' : null" @click="setEval(row.item.id, false)">Non Compliant</b-btn>
              <b-btn :variant="data.duediligence.evaluations.findIndex(x => x.criteria_id === row.item.id) > -1 && data.duediligence.evaluations.find(x => x.criteria_id === row.item.id).yesno === null ? 'primary' : null" @click="setEval(row.item.id, null)">Non applicabile</b-btn>
            </b-button-group>
          </template>
          <template v-slot:cell(note)="row">
            <b-form-textarea v-if="data.duediligence.evaluations.findIndex(x => x.criteria_id === row.item.id) > -1 && data.duediligence.evaluations.find(x => x.criteria_id === row.item.id).yesno !== null" v-model="data.duediligence.evaluations.find(x => x.criteria_id === row.item.id).note"/>
          </template>
        </b-table>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" class="mr-2" v-show="data.duediligence.id > 0" @click="deleteData">Elimina</b-btn>
          <b-btn variant="info" v-show="data.duediligence.id > 0" @click="downloadData">Scarica</b-btn>
        </div>
      </template>
      <form ref="downloadForm" method="POST" target="_blank" action="/api/file.php">
        <input type="hidden" name="form" value="duediligences">
        <input type="hidden" name="document" value="duediligence">
        <input type="hidden" name="id" :value="data.duediligence.id">
        <input type="hidden" name="token" :value="token">
      </form>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "duediligence",
  data() {
    return {
      show: false,
      data: null,
      fields: [{
        key: "criteria",
        label: "Criterio",
      },{
        key: 'mandatory',
        label: 'Obbligatorio',
        formatter: value => {
          if (value == null) return null;
          return value === true ? 'Obbligatorio' : 'Non obbligatorio';
        }
      },{
        key: "yesno",
        label: "Presente",
      },{
        key: 'note',
        label: 'Note',
      },],
    }
  },
  computed: {
    token (){
      return this.$store.getters.getToken;
    }
  },
  methods: {
    loadData( id, type, typeId ){
      this.openHttp("duediligences_duediligence", { id: id, type: type, type_id: typeId }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    setEval( criteriaId, val ){
      if ( this.data.duediligence.evaluations.findIndex(x => x.criteria_id === criteriaId) > -1 ){
        this.data.duediligence.evaluations.find(x => x.criteria_id === criteriaId).yesno = val;
      } else {
        this.data.duediligence.evaluations.push({ criteria_id: criteriaId, yesno: val, note: null });
      }
    },
    saveData(){
      this.saveHttp("duediligences_duediligence_save", this.data.duediligence, "Due Diligence salvata!" ).then((result) => {
        if ( result.result == true ) this.closeForm(true);
      })
    },
    deleteData(){
      this.deleteHttp("duediligences_duediligence_delete", { id: this.data.duediligence.id }, "Due Diligence eliminato!" ).then((result) => {
        if ( result.result == true ) this.closeForm(true);
      })
    },
    downloadData(){
      this.$refs.downloadForm.submit();
    },
    closeForm(reload = false){
      this.show = false;
      this.data = null;
      if (reload === true) this.$emit('reload');
    },
  }
}
</script>