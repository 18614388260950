<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" title="Cambiamento" @hidden="closeForm()" @close="closeForm()">
      <b-row>
        <b-col lg="12">
          <b-form-group label="Cambiamento">
            <b-form-textarea v-model="data.change.changetodo" rows="4"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="3">
          <b-form-group label="Data acquisizione">
            <b-form-input type="date" v-model="data.change.datebegin"/>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group label="Responsabilità">
            <b-form-textarea v-model="data.change.responsibilities"/>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group label="Risorse">
            <b-form-textarea v-model="data.change.resources"/>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group label="Obiettivo">
            <b-form-textarea v-model="data.change.target"/>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group label="Data obiettivo">
            <b-form-input type="date" v-model="data.change.dateend"/>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group label="Benefici">
            <b-form-textarea v-model="data.change.benefits"/>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group label="Rischi">
            <b-form-textarea v-model="data.change.risks"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="3">
          <b-form-group label="Autorizzazione?">
            <b-form-select v-model="data.change.authorized" :options="authorizedCombo" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="9">
          <b-form-group label="Note autorizzazione">
            <b-form-textarea v-model="data.change.noteauthorization"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="3">
          <b-form-group label="Efficacia?">
            <b-form-select v-model="data.change.effectiveness" :options="effectivenessCombo" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
      </b-row>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.change.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "change",
  data() {
    return {
      show: false,
      data: null,
      authorizedCombo: [{
        id: null,
        val: "Decisione non presa"
      },{
        id: true,
        val: "Autorizzato dalla direzione"
      },{
        id: false,
        val: "Non autorizzato dalla direzione"
      }],
      effectivenessCombo: [{
        id: 0,
        val: "Non valutabile"
      },{
        id: 1,
        val: "Inefficace"
      },{
        id: 2,
        val: "Parzialmente efficace"
      },{
        id: 3,
        val: "Totalmente efficace"
      }],
    }  
  },
  methods: {
    loadData( id ){
      this.openHttp("changes_change", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("changes_change_save", this.data.change, "Cambiamento salvato!").then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("changes_change_delete", { id: this.data.change.id }, "Cambiamento eliminato!").then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('reload');
    }
  }
}
</script>