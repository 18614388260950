<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" title="Opportunità" @hidden="closeForm()" @close="closeForm()">
      <b-btn variant="primary" @click="addData">Nuovo</b-btn>
      <b-table responsive :sticky-header="(winHeight - 305) + 'px'" class="mt-3" show-empty :items="data.opportunities" :fields="fields">
        <template v-slot:empty>Non ci sono risultati</template>
        <template v-slot:cell(point)="row">
          <b-form-textarea v-model="row.item.point" rows="3"/>
        </template>
        <template v-slot:cell(standards)="row">
          <b-form-checkbox-group v-model="row.item.standards" :options="data.standards" value-field="id" text-field="val"/>
        </template>
        <template v-slot:cell(index)="row">
          <b-btn variant="danger" size="sm" @click="data.opportunities.splice(row.index, 1)">Elimina</b-btn>
        </template>
      </b-table>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" @click="saveData">Salva</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "opportunities",
  data() {
    return {
      show: false,
      data: null,
      fields: [{
        key: "point",
        label: "Opportunità",
        sortable: true
      },{
        key: "standards",
        label: "Norme",
      },{
        key: "index",
        label: "",
      }],
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    addData(){
      this.data.opportunities.push({
        id: 0,
        point: null,
        standards: [],
      });
    },
    loadData(){
      this.openHttp("context_opportunities", null).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("context_opportunities_save", this.data.opportunities, "Opportunità salvate!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('reload');
    }
  }
}
</script>
