<template>
  <div>
    <doctoken ref="doctoken"/>
    <opportunity ref="opportunity" @reload="loadData"/>
    <b-modal v-model="showFilter" title="Filtra" @ok="filterSet">
      <b-form-group label="Ricerca nel testo">
        <b-form-input type="text" v-model="tempFilter.description"/>
      </b-form-group>
      <b-form-group label="Anno">
        <b-form-select v-model="tempFilter.year" :options="comboSeeAll(filtersMenu.years)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Processo">
        <b-form-select v-model="tempFilter.processes_id" :options="comboSeeAll(filtersMenu.processes)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Norma">
        <b-form-select v-model="tempFilter.standards_id" :options="comboSeeAll(filtersMenu.standards)" value-field="id" text-field="val"/>
      </b-form-group>
    </b-modal>
    <b-btn class="mr-2" variant="primary" @click="loadData">Aggiorna</b-btn>
    <b-btn class="mr-2" variant="warning" @click="filterReset">Reset filtri</b-btn>
    <b-btn class="mr-2" variant="secondary" @click="filterOpen">Filtra</b-btn>
    <b-btn class="mr-2" variant="success" @click="$refs.opportunity.loadData(0)">Nuovo</b-btn>
    <b-btn class="mr-2" variant="info" v-if="document != null && document.records_id > 0" @click="$refs.doctoken.downloadRecord(document.records_id)">Scarica Documento {{ document.date }}</b-btn>
    <b-btn variant="success" @click="generateDocument()">Genera Documento</b-btn>
    <b-table no-border-collapse :sticky-header="(winHeight - 205) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="items.data" :fields="fields" :busy="isBusy" :no-local-sorting="true" :sort-by.sync="search.sort.by" :sort-desc.sync="search.sort.desc" @sort-changed="sortingChange" @row-clicked="$refs.opportunity.loadData($event.id)">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>Non ci sono risultati</template>
    </b-table>
    <tablebottom :perpage="search.page.perpage" @updatePerPage="search.page.perpage = $event" :currentpage="search.page.currentpage" @updateCurrentPage="search.page.currentpage = $event" :total="items.total" @loadData="loadData"/>
  </div>
</template>

<script>
import opportunity from './opportunity.vue'

export default {
  name: "opportunities",
  components: { opportunity },
  data() {
    return {
      isBusy: true,
      showFilter: false,
      search:{
        page: {
          currentpage: 1,
          perpage: 50,
        },
        filters: {},
        sort: {
          by: 'dateopening',
          desc: false,
        },
      },
      filtersMenu: {
        years: [],
        processes: [],
        standards: [],
      },
      tempFilter: {
        description: null,
        year: null,
        processes_id: null,
        standards_id: null,
      },
      fields: [{
        key: 'progressive',
        label: "N°",
        sortable: true
      },{
        key: 'yearnc',
        label: 'Anno',
        sortable: true,
      },{
        key: 'dateopening',
        label: 'Apertura',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'description',
        label: 'Situazione',
      },{
        key: 'standards',
        label: 'Norme',
      },{
        key: 'processes',
        label: 'Processi',
      },{
        key: 'treatment',
        label: 'Opportunità',
      },{
        key: 'dateclosure',
        label: 'Chiusura',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'cost',
        label: 'Costo',
        sortable: true,        
        formatter: value => {
          return value + " €";
        }
      }],
      items: {
        total: 0,
        data: [],
      },
      document: null
    }  
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.isBusy = true;
      this.openHttp("opportunities_opportunities", {search: this.search}).then((result) => {
        this.items = result;
        this.isBusy = false;
        this.loadDocument();
      })
    },
    loadDocument(){
      this.openHttp("opportunities_document", null).then((result) => {
        this.document = result;
      })
    },
    generateDocument(){
      this.generateDocumentHttp("opportunities");
    },
    filterOpen(){
      this.openHttp("opportunities_filters", null).then((result) => {
        this.filtersMenu = result;
        this.showFilter = true;
      })
    },
    filterSet(){
      this.search.filters = this.tempFilter;
      this.loadData();
    },
    filterReset(){
      this.tempFilter = {
        description: null,
        year: null,
        processes_id: null,
        standards_id: null,
      }
      this.filterSet();
    },
    sortingChange(ctx) {
      this.search.sort.by = ctx.sortBy;
      this.search.sort.desc = ctx.sortDesc;
      this.loadData();
    }
  }
}
</script>