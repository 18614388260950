<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" title="Macchinario" scrollable @hidden="closeForm" @close="closeForm">
      <maintenance ref="maintenance" @reload="loadData(data.machinery.id)"/>
      <form ref="downloadForm" method="POST" target="_blank" action="/api/file.php">
        <input type="hidden" name="form" value="machineries">
        <input type="hidden" name="document" value="machinery">
        <input type="hidden" name="id" :value="data.machinery.id">
        <input type="hidden" name="token" :value="token">
      </form>
      <form ref="downloadFormOrdinary" method="POST" target="_blank" action="/api/file.php">
        <input type="hidden" name="form" value="machineries">
        <input type="hidden" name="document" value="machinery_ordinary">
        <input type="hidden" name="id" :value="data.machinery.id">
        <input type="hidden" name="token" :value="token">
      </form>      
      <form ref="downloadFormExtraOrdinary" method="POST" target="_blank" action="/api/file.php">
        <input type="hidden" name="form" value="machineries">
        <input type="hidden" name="document" value="machinery_extraordinary">
        <input type="hidden" name="id" :value="data.machinery.id">
        <input type="hidden" name="token" :value="token">
      </form>      
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Scheda" active>
            <b-row>
              <b-col lg="4">
                <b-form-group label="Tipologia">
                  <b-form-select v-model="data.machinery.machineriestypes_id" :options="data.machineriestypes" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Identificativo">
                  <b-form-input v-model="data.machinery.serialnumber"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Matricola">
                  <b-form-input v-model="data.machinery.registrationnumber"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="2">
                <b-form-group label="Stato">
                  <b-form-select v-model="data.machinery.unactive" :options="activeCombo" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Responsabile">
                  <b-form-select v-model="data.machinery.employees_id" :options="data.employees" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group label="Ubicazione">
                  <b-form-input v-model="data.machinery.location"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="6">
                <b-form-group label="Manutenzioni specifiche associate">
                  <multiselect v-model="data.machinery.mainteinancesheetsSupport" :options="data.mainteinancesheets" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.machinery.mainteinancesheets = updateCombo(data.machinery.mainteinancesheetsSupport)"/>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group label="Manutenzioni della tipologia associata">
                  <ul>
                    <li v-for="(item, index) of data.mainteinancesheets.filter( x => x.machineriestypes.includes(data.machinery.machineriestypes_id)).map(x => x.val)" :key="index">
                      {{ item }}
                    </li>
                  </ul>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Note">
              <b-form-textarea v-model="data.machinery.note"/>
            </b-form-group>
            <b-form-group label="Scadenze manutenzioni ordinarie:" v-show="data.machinery.id > 0">
              <b-table hover :items="data.deadlines" :fields="deadlinesFields" class="mt-3" @row-clicked="loadMainteinance(0, $event.id, $event.nextdate)" :tbody-tr-class="rowDetailClass"/>
            </b-form-group>
          </b-tab>
          <b-tab title="Manutenzioni ordinarie" v-if="data.machinery.id > 0">
            <b-row>
              <b-col lg="9">
                <b-btn variant="success" @click="loadMainteinance(0, null, null)" class="mr-2">Nuova</b-btn>
              </b-col>
              <b-col lg="3">
                <b-form-input v-model="criteriaOrdinaryMaintenances" style="float: right" placeholder="Filtra..."/>
              </b-col>
            </b-row>
            <b-table hover :items="data.ordinarymainteinances" :fields="ordinaryFields" class="mt-3" @row-clicked="loadMainteinance($event.id, null, null)"  :filter="criteriaOrdinaryMaintenances"/>
          </b-tab>
          <b-tab title="Manutenzioni straordinarie" v-if="data.machinery.id > 0">
            <b-row>
              <b-col lg="9">
                <b-btn variant="success" @click="loadMainteinance(0, null, null)" class="mr-2">Nuova</b-btn>
              </b-col>
              <b-col lg="3">
                <b-form-input v-model="criteriaExtraordinaryMaintenances" style="float: right" placeholder="Filtra..."/>
              </b-col>
            </b-row>
            <b-table hover :items="data.extraordinarymainteinances" :fields="extraordinaryFields" class="mt-3" @row-clicked="loadMainteinance($event.id, null, null)" :filter="criteriaExtraordinaryMaintenances"/>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" v-show="data.machinery.id > 0" class="mr-2" @click="loadData(data.machinery.id)">Aggiorna</b-btn>
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="info" class="mr-2" v-show="data.machinery.id > 0" @click="downloadData">Scarica</b-btn>
          <b-btn variant="danger" v-show="data.machinery.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import maintenance from './maintenance.vue'

export default {
  name: "machinery",
  components: { maintenance },
  data() {
    return {
      show: false,
      data: null,
      activeCombo:[{
        id: false,
        val: 'Attivo'
      },{
        id: true,
        val: 'Non attivo'
      }],
      deadlinesFields: [{
        key: 'mainteinancesheet',
        label: 'Manutenzione',
        sortable: true,
      },{
        key: 'nextdate',
        label: 'Prossima',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'lastdate',
        label: 'Ultima',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'periodicity',
        label: 'Periodicità',
        formatter: value => {
          return value !== null ? value.replace("year", "anni").replace("month", "mesi").replace("week", "settimane") : "Nessuna";
        }
      }],
      ordinaryFields: [{
        key: 'date',
        label: 'Data',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'mainteinancesheet',
        label: 'Manutenzione',
        sortable: true,
      },{
        key: 'employee',
        label: 'Interno',
        sortable: true,
      },{
        key: 'supplier',
        label: 'Fornitore',
        sortable: true,
      },{
        key: 'cost',
        label: 'Costo',
        sortable: true,
        formatter: value => {
          return value.toFixed(2) + " €";
        }
      }],
      criteriaOrdinaryMaintenances: null,
      extraordinaryFields: [{
        key: 'date',
        label: 'Data',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'mainteinance',
        label: 'Manutenzione',
        sortable: true,
      },{
        key: 'employee',
        label: 'Interno',
        sortable: true,
      },{
        key: 'supplier',
        label: 'Fornitore',
        sortable: true,
      },{
        key: 'cost',
        label: 'Costo',
        sortable: true,
        formatter: value => {
          return value.toFixed(2) + " €";
        }
      }],
      criteriaExtraordinaryMaintenances: null,
    }
  },
  computed: {
    token (){
      return this.$store.getters.getToken;
    }
  },
  methods: {
    rowDetailClass(item) {
      let today = new Date();
      let d = new Date(item.nextdate);
      return item.periodicity !== null ? ( d.getTime() <= today.getTime() ? 'table-danger' : 'table-success') : "";
    },
    loadData( id ){
      this.openHttp("machineries_machinery", { id: id }).then((result) => {
        if (this.data !== null){
          this.data.deadlines = result.deadlines;
          this.data.ordinarymainteinances = result.ordinarymainteinances;
          this.data.extraordinarymainteinances = result.extraordinarymainteinances;
        } else {
          result.machinery.mainteinancesheetsSupport = this.convertCombo(result.mainteinancesheets, result.machinery.mainteinancesheets);
          this.data = result;
        }
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("machineries_machinery_save", this.data.machinery, "Macchinario salvato!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("machineries_machinery_delete", { id: this.data.machinery.id }, "Macchinario eliminato!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    downloadData(){
      this.$refs.downloadForm.submit();
    },
    downloadDataOrdinary(){
      this.$refs.downloadFormOrdinary.submit();
    },
    downloadDataExtraOrdinary(){
      this.$refs.downloadFormExtraOrdinary.submit();
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('reload');
    },
    loadMainteinance(id, maintenancesheetsId, date){
      var d = new Date();
      if (date === null) date = [d.getFullYear(), d.getMonth() + 1, d.getDate()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
      this.$refs.maintenance.loadData( id, this.data.machinery.id, maintenancesheetsId, date );
    },
  }
}
</script>