<template>
  <div>
    <doctoken ref="doctoken"/>
    <supplier ref="supplier" @reload="loadData"/>
    <supplierevaluation ref="evaluation" @reload="loadData"/>
    <supplierproduct ref="product" @reload="loadData"/>
    <supplierquestion ref="question" @reload="loadData"/>
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Elenco fornitori" active>
          <b-modal v-model="suppliers.showFilter" title="Filtra" @ok="filterSetSuppliers">
            <b-form-group label="Fornitore">
              <b-form-input v-model="suppliers.tempFilter.supplier"/>
            </b-form-group>
            <b-form-group label="Attivo">
              <b-form-select v-model="suppliers.tempFilter.unactive" :options="suppliers.activeCombo" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Prodotto">
              <b-form-select v-model="suppliers.tempFilter.products_id" :options="suppliers.filtersMenu.products" value-field="id" text-field="val"/>
            </b-form-group>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadDataSuppliers">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="warning" @click="filterResetSuppliers">Reset filtri</b-btn>
          <b-btn class="mr-2" variant="secondary" @click="filterOpenSuppliers">Filtra</b-btn>
          <b-btn class="mr-2" variant="success" @click="$refs.supplier.loadData(0)">Nuovo</b-btn>
          <b-btn class="mr-2" variant="info" v-if="document != null && document.records_id > 0" @click="$refs.doctoken.downloadRecord(document.records_id)">Scarica Documento {{ document.date }}</b-btn>
          <b-btn class="mr-2" variant="success" @click="generateDocument()">Genera Documento</b-btn>
          <b-btn variant="primary">IQF: {{ suppliers.items.avg }}</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :tbody-tr-class="rowClass" :items="suppliers.items.data" :fields="suppliers.fields" :busy="suppliers.isBusy" :no-local-sorting="true" :sort-by.sync="suppliers.search.sort.by" :sort-desc.sync="suppliers.search.sort.desc" @sort-changed="sortingChangeSuppliers" @row-clicked="$refs.supplier.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
            <template v-slot:cell(index)="row">
              <b-btn type="button" class="mr-2" size="sm" v-if="row.item.freepass == false && row.item.unactive == false && maxOneYear(row.item.dateeval)" variant="danger" @click="evaluateItem(row.item.id)">Da valutare</b-btn>
              <b v-if="row.item.unactive == false && row.item.freepass == false && row.item.evaluation < 50">Non qualificato</b>
              <b v-if="row.item.unactive == false && row.item.freepass == false && row.item.evaluation >= 50 && row.item.evaluation < 60">Qualificato con riserva</b>
            </template>
          </b-table>
          <tablebottom :perpage="suppliers.search.page.perpage" @updatePerPage="suppliers.search.page.perpage = $event" :currentpage="suppliers.search.page.currentpage" @updateCurrentPage="suppliers.search.page.currentpage = $event" :total="suppliers.items.total" @loadData="loadDataSuppliers"/>
        </b-tab>
        <b-tab title="Prodotti acquistati">
          <b-btn class="mr-2" variant="primary" @click="loadDataProducts">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="success" @click="$refs.product.loadData(0)">Nuovo</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="products.items.data" :fields="products.fields" :busy="products.isBusy" :no-local-sorting="true" :sort-by.sync="products.search.sort.by" :sort-desc.sync="products.search.sort.desc" @sort-changed="sortingChangeProducts" @row-clicked="$refs.product.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
          <tablebottom :perpage="products.search.page.perpage" @updatePerPage="products.search.page.perpage = $event" :currentpage="products.search.page.currentpage" @updateCurrentPage="products.search.page.currentpage = $event" :total="products.items.total" @loadData="loadDataProducts"/>
        </b-tab>
        <b-tab title="Criteri di valutazione">
          <b-btn class="mr-2" variant="primary" @click="loadDataQuestions">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="success" @click="$refs.question.loadData(0)">Nuovo</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="questions.items.data" :fields="questions.fields" :busy="questions.isBusy" :no-local-sorting="true" :sort-by.sync="questions.search.sort.by" :sort-desc.sync="questions.search.sort.desc" @sort-changed="sortingChangeQuestions" @row-clicked="$refs.question.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
          <tablebottom :perpage="questions.search.page.perpage" @updatePerPage="questions.search.page.perpage = $event" :currentpage="questions.search.page.currentpage" @updateCurrentPage="questions.search.page.currentpage = $event" :total="questions.items.total" @loadData="loadDataQuestions"/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import supplier from './supplier.vue'
import supplierevaluation from './supplierevaluation.vue'
import supplierproduct from './supplierproduct.vue'
import supplierquestion from './supplierquestion.vue'

export default {
  name: "suppliers",
  components: { 
    supplier, supplierevaluation, supplierproduct, supplierquestion
  },
  data() {
    return {
      suppliers: {
        isBusy: true,
        showFilter: false,
        search: {
          page: {
            currentpage: 1,
            perpage: 50,
          },
          filters: {
            unactive: false,
          },
          sort: { by: "supplier", desc: false, },
        },
        tempFilter: {
          supplier: null,
          unactive: false,
          products_id: null,
        },
        activeCombo: [{
          id: null,
          val: "Tutto",
        },{
          id: false,
          val: "Attivo",
        },{
          id: true,
          val: "Non Attivo",
        },],  
        filtersMenu: {
          products: [],
        },
        fields: [{
          key: "supplier",
          label: "Fornitore",
          sortable: true,
        },{
          key: "products",
          label: "Prodotti",
        },{
          key: "certificates",
          label: "Certificati",
          formatter: (value, key, item) => {
            return (item.certificatesvalid !== null ? item.certificatesvalid: "") + (item.certificatesinvalid !== null ? (" SCADUTI: " + item.certificatesinvalid): "");
          }
        },{
          key: 'unactive',
          label: 'Attivo',
          sortable: true,
          formatter: value => {
            return value ? "Non attivo" : "Attivo";
          }
        },{
          key: 'freepass',
          label: 'Freepass',
          sortable: true,
          formatter: value => {
            return value === true ? "Si" : "No";
          }
        },{
          key: 'dateeval',
          label: 'Data',
          sortable: true,
          formatter: (value, key, item) => {
            if (item.freepass === true && value == null) return "Da valutare";
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "evaluation",
          label: "Valutazione",
          sortable: true,
          formatter: (value, key, item) => {
            return (item.freepass === true && value == null) ? "Da valutare" : value;
          }
        },{
          key: "index",
          label: "",
          sortable: false
        }],
        items: {
          total: 0,
          avg: 0,
          data: [],
        },
      },
      products: {
        isBusy: true,
        search: {
          page: {
            currentpage: 1,
            perpage: 50,
          },
          sort: {
            by: 'product',
            desc: false,
          },
        },
        fields: [{
          key: 'product',
          label: 'Prodotto',
          sortable: true,
        },{
          key: 'suppliers',
          label: 'Fornitori',
          sortable: true,
        }],
        items: {
          total: 0,
          data: [],
        },
      },
      questions: {
        isBusy: true,
        search: {
          page: {
            currentpage: 1,
            perpage: 50,
          },
          filters: {},
          sort: {
            by: 'question',
            desc: false,
          },
        },
        fields: [{
          key: 'question',
          label: "Criterio",
          sortable: true,
        },{
          key: 'weight',
          label: "Peso",
          sortable: true
        }],
        items: {
          total: 0,
          data: [],
        }
      },
      document: null
    }  
  },
  computed: {
    token (){
      return this.$store.getters.getToken;
    },
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    maxOneYear(date){
      let oneYearAgo = new Date();
      let dateeval = new Date(date);
      oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
      return oneYearAgo.getTime() >= dateeval.getTime() ? true : false;
    },
    rowClass(item) {
      if ((item != null && item.unactive == true) && item.certificatesinvalid !== null) return 'table-danger';
      if (item != null && item.freepass == true) return 'table-success';
      if (item != null){
        let oneYearAgo = new Date();
        let dateeval = new Date(item.dateeval);
        oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
        if (oneYearAgo.getTime() >= dateeval.getTime()){
          return 'table-danger';
        } else {
          if (item.evaluation < 60) return 'table-warning';
          if (item.evaluation >= 60) return 'table-success';
        }
      }
    },
    loadData(){
      this.loadDataSuppliers();
      this.loadDataProducts();
      this.loadDataQuestions();
    },
    loadDataSuppliers(){
      this.suppliers.isBusy = true;
      this.openHttp("suppliers_suppliers", this.suppliers.search).then((result) => {
        this.suppliers.isBusy = false;
        this.suppliers.items = result;
        this.loadDocument();
      })
    },
    loadDocument(){
      this.openHttp("suppliers_document", null).then((result) => {
        this.document = result;
      })
    },
    generateDocument(){
      this.generateDocumentHttp("suppliers");
    },
    filterOpenSuppliers(){
      this.openHttp("suppliers_suppliers_filters", {}).then((result) => {
        this.suppliers.showFilter = true;
        this.suppliers.filtersMenu = result;
      })
    },
    filterSetSuppliers(){
      this.suppliers.showFilter = false;
      this.suppliers.search.filters = this.suppliers.tempFilter;
      this.loadDataSuppliers();
    },
    filterResetSuppliers(){
      this.suppliers.tempFilter = {
        supplier: null,
        unactive: false,
        products_id: null,
      }
      this.filterSetSuppliers();
    },
    sortingChangeSuppliers(ctx) {
      this.suppliers.search.sort.by = ctx.sortBy;
      this.suppliers.search.sort.desc = ctx.sortDesc;
      this.loadDataSuppliers();
    },
    evaluateItem(id){
      let today = new Date();
      this.$refs.evaluation.loadData(id, today);
    },
    loadDataProducts(){
      this.products.isBusy = true;
      this.openHttp("suppliers_products", this.products.search).then((result) => {
        this.products.isBusy = false;
        this.products.items = result;
      })
    },
    sortingChangeProducts(ctx) {
      this.products.search.sort.by = ctx.sortBy;
      this.products.search.sort.desc = ctx.sortDesc;
      this.loadDataProducts();
    },
    loadDataQuestions(){
      this.questions.isBusy = true;
      this.openHttp("suppliers_questions", this.products.search).then((result) => {
        this.questions.isBusy = false;
        this.questions.items = result;
      })
    },
    sortingChangeQuestions(ctx) {
      this.questions.search.sort.by = ctx.sortBy;
      this.questions.search.sort.desc = ctx.sortDesc;
      this.loadDataQuestions();
    }
  }
}
</script>