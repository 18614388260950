<template>
  <div>
    <doctoken ref="doctoken"/>
    <skill ref="skill" @reload="loadData"/>      
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Matrice" active>
          <b-modal v-model="matrix.showFilter" title="Filtra" @ok="filterSetMatrix">
            <b-form-group label="Persona">
              <b-form-input v-model="matrix.tempFilter.employee"/>
            </b-form-group>
            <b-form-group label="Competenza">
              <b-form-input v-model="matrix.tempFilter.competence"/>
            </b-form-group>
            <b-form-group label="Mansione">
              <b-form-input v-model="matrix.tempFilter.jobdescription"/>
            </b-form-group>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadMatrix">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="warning" @click="filterResetMatrix">Reset filtri</b-btn>
          <b-btn class="mr-2" variant="secondary" @click="matrix.showFilter = true">Filtra</b-btn>
          <b-btn class="mr-2" variant="info" v-if="document != null && document.records_id > 0" @click="$refs.doctoken.downloadRecord(document.records_id)">Scarica Documento {{ document.date }}</b-btn>
          <b-btn variant="success" @click="generateDocument()">Genera Documento</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="matrix.items" :fields="matrix.fields" :busy="matrix.isBusy" :no-local-sorting="true" :sort-by.sync="matrix.search.sort.by" :sort-desc.sync="matrix.search.sort.desc" @sort-changed="sortingChangeMatrix" @row-clicked="loadItem($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
            <template v-slot:cell(firstname)="data">
              <nobr>{{ data.value }}</nobr>
            </template>
            <template v-slot:cell(lastname)="data">
              <nobr>{{ data.value }}</nobr>
            </template>
            <template v-for="(field,index) in matrix.fields.filter(x => x.key !=='firstname' && x.key !=='lastname' && x.key !=='date' && x.key!== 'jobdescriptions')" v-slot:[`cell(${field.key})`]="{ item }">
              <b-progress max="4" :key="index" v-if="item.evaluations.findIndex(x => x.competences_id === parseInt(field.key.replace('col_', ''))) != -1">
                <b-progress-bar :value="item.evaluations.find(x => x.competences_id === parseInt(field.key.replace('col_', ''))).actual" variant="success"/>
                <b-progress-bar :value="item.evaluations.find(x => x.competences_id === parseInt(field.key.replace('col_', ''))).target - item.evaluations.find(x => x.competences_id === parseInt(field.key.replace('col_', ''))).actual" variant="warning"/>
              </b-progress>
            </template>
          </b-table>
        </b-tab>
        <b-tab title="Elenco">
          <b-modal v-model="skills.showFilter" title="Filtra" @ok="filterSetSkills">
            <b-form-group label="Persona">
              <b-form-input v-model="skills.tempFilter.employee"/>
            </b-form-group>
            <b-form-group label="Competenza">
              <b-form-input v-model="skills.tempFilter.competence"/>
            </b-form-group>
            <b-form-group label="Mansione">
              <b-form-input v-model="skills.tempFilter.jobdescription"/>
            </b-form-group>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadSkills">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="warning" @click="filterResetSkills">Reset filtri</b-btn>
          <b-btn class="mr-2" variant="secondary" @click="skills.showFilter = true">Filtra</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="skills.items.data" :fields="skills.fields" :busy="skills.isBusy" :no-local-sorting="true" :sort-by.sync="skills.search.sort.by" :sort-desc.sync="skills.search.sort.desc" @sort-changed="sortingChangeSkills" @row-clicked="loadItem($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
            <template v-slot:cell(progress)="data">
              <b-progress class="mt-2" :max="100">
                <b-progress-bar :value="data.item.progress" :variant="data.item.progress === 100 ? 'success' : (data.item.progress >= 60 ? 'warning' : 'danger')">
                  <strong>{{ data.item.progress.toFixed(2) }} %</strong>
                </b-progress-bar>
              </b-progress>
            </template>
          </b-table>
          <tablebottom :perpage="skills.search.page.perpage" @updatePerPage="skills.search.page.perpage = $event" :currentpage="skills.search.page.currentpage" @updateCurrentPage="skills.search.page.currentpage = $event" :total="skills.items.total" @loadData="loadSkills"/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import skill from './skill.vue'

export default {
  name: "skills",
  components: { skill },
  data() {
    return {
      skills: {
        isBusy: true,
        showFilter: false,
        search:{
          page: {
            currentpage: 1,
            perpage: 50,
          },
          filters: {},
          sort: {
            by: 'lastname',
            desc: false,
          },
        },
        tempFilter: {
          employees_id: null,
          competences_id: null,
        },
        fields: [{
          key: "firstname",
          label: "Nome",
          sortable: true
        },{
          key: "lastname",
          label: "Cognome",
          sortable: true
        },{
          key: "jobdescriptions",
          label: "Mansioni",
        },{
          key: 'competences',
          label: 'Competenze',
        },{
          key: 'progress',
          label: 'Progresso',
          sortable: true,
        },{
          key: 'maxdate',
          label: 'Aggiornamento',
          sortable: false,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        }],
        items: {
          total: 0,
          data: [],
        }
      },
      matrix: {
        isBusy: true,
        showFilter: false,
        search:{
          page: {
            currentpage: 1,
            perpage: 50,
          },
          filters: {
            employee: null,
            competence: null,
            jobdescription: null,
          },
          sort: {
            by: 'lastname',
            desc: false,
          },
        },
        fields: [],
        tempFilter: {
          employee: null,
          competence: null,
          jobdescription: null,
        },
        items: []
      },
      document: null
    }  
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.loadSkills();
      this.loadMatrix();
    },
    loadSkills(){
      this.skills.isBusy = true;
      this.openHttp("skills_skills", { search: this.skills.search }).then((result) => {
        this.skills.items = result;
        this.skills.isBusy = false;
        this.loadDocument();
      })
    },
    loadDocument(){
      this.openHttp("skills_document", null).then((result) => {
        this.document = result;
      })
    },
    generateDocument(){
      this.generateDocumentHttp("skills");
    },
    filterSetSkills(){
      this.skills.showFilter = false;
      this.skills.search.filters = this.skills.tempFilter;
      this.loadSkills();
    },
    filterResetSkills(){
      this.skills.tempFilter = {
        employee: null,
        competence: null,
      };
      this.filterSetSkills();
    },
    sortingChangeSkills(ctx) {
      this.skills.search.sort.by = ctx.sortBy;
      this.skills.search.sort.desc = ctx.sortDesc;
      this.loadSkills();
    },
    loadItem(id) {
      this.$refs.skill.loadData(id);
    },
    loadMatrix(){
      this.matrix.isBusy = true;
      this.matrix.fields = [{
        key: "firstname",
        label: "Nome",
        sortable: true
      },{
        key: "lastname",
        label: "Cognome",
        sortable: true
      },{
        key: "date",
        label: "Aggiornamento",
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'jobdescriptions',
        label: "Mansioni"
      }];
      this.openHttp("skills_matrix", { search: this.matrix.search }).then((result) => {
        for (let item of result.competences){
          this.matrix.fields.push({
            key: item.id,
            label: item.val,
            thStyle: {
              "min-width": "120px",
              "max-width": "120px"
            }
          });
        }
        this.matrix.items = result.evaluations;
        this.matrix.isBusy = false;
        this.loadDocument();
      })
    },
    filterSetMatrix(){
      this.matrix.search.filters = this.matrix.tempFilter;
      this.loadMatrix();
    },
    filterResetMatrix(){
      this.matrix.tempFilter = {
        employee: null,
        competence: null,
      };
      this.filterSetMatrix();
    },
    sortingChangeMatrix(ctx) {
      this.matrix.search.sort.by = ctx.sortBy;
      this.matrix.search.sort.desc = ctx.sortDesc;
      this.loadMatrix();
    },

  }
}
</script>