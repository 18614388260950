<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" title="Valutazione fornitore" @hidden="closeForm()" @close="closeForm()">
      <b-table hover :items="data.questions" :fields="fields">
        <template v-slot:cell(evaluation)="row">
          <b-button-group size="sm">
            <b-btn class="text-nowrap" :variant="row.item.evaluation == null ? 'primary' : null" @click="row.item.evaluation = null;">Non applicabile</b-btn>
            <b-btn class="text-nowrap" v-for="index in 10" :key="index" v-bind:variant="row.item.evaluation >= index ? 'primary' : null" @click="row.item.evaluation = index;">{{ index }}</b-btn>
          </b-button-group>
        </template>
      </b-table>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" @click="saveData">Salva</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "supplierevaluation",
  data() {
    return {
      show: false,
      data: null,
      fields: [{
        key: 'question',
        label: "Domanda",
      },{
        key: 'evaluation',
        label: "Valutazione",
      }],
    }  
  },
  methods: {
    loadData( suppliersId, date ){
      this.openHttp("suppliers_evaluation", { suppliers_id: suppliersId, date: date }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("suppliers_evaluation_save", this.data.questions, "Valutazione fornitore salvata!").then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('reload');
    }
  }
}
</script>