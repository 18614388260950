<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" title="Azienda" size="lg" scrollable @hidden="closeForm" @close="closeForm">
      <b-form-group label="Azienda">
        <b-form-input v-model="data.company.company" required/>
      </b-form-group>
      <b-form-group label="Partita IVA">
        <b-form-input v-model="data.company.vat" required/>
      </b-form-group>
      <b-row>
        <b-col lg="2">
          <b-form-group label="Fondazione">
            <b-form-input type="number" v-model="data.company.foundation"/>
          </b-form-group>
        </b-col>
        <b-col lg="8">
          <b-form-group label="Sito internet">
            <b-form-input v-model="data.company.web"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Lingua">
            <b-form-select v-model="data.company.languages_id" :options="data.languages" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Codici Ateco">
        <multiselect v-model="data.company.nacesSupport" :options="data.naces" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.company.naces = updateCombo(data.company.nacesSupport)"/>
      </b-form-group>
      <b-form-group label="Logo">
        <b-form-file accept="image/*" placeholder="Scegli un logo o trascinalo qui..." drop-placeholder="Trascinalo qui..." @change="saveLogo($event)"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" @click="saveData">Salva</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "scope",
  data() {
    return {
      show: false,
      data: null,
    }
  },
  methods: {
    loadData(){
      this.openHttp("company_companycompany", {}).then((result) => {
        result.company.nacesSupport = this.convertCombo(result.naces, result.company.naces);
        this.data = result;
        this.show = true;
      })
    },
    saveLogo(event){
      this.showModalLogo = false;      
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = ()=> {
        this.data.company.base64 = reader.result;
        this.data.company.type = event.target.files[0].type;
      };
    },
    saveData(){
      this.saveHttp("company_companycompany_save", this.data.company, "Azienda salvata!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('reload');
    }
  }
}
</script>