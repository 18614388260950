<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" title="Punti di forza" @hidden="closeForm()" @close="closeForm()">
      <b-btn variant="primary" @click="addData">Nuovo</b-btn>
      <b-table responsive :sticky-header="(winHeight - 305) + 'px'" class="mt-3" show-empty :items="data.strengths" :fields="fields">
        <template v-slot:empty>Non ci sono risultati</template>
        <template v-slot:cell(point)="row">
          <b-form-textarea v-model="row.item.point" rows="3"/>
        </template>
        <template v-slot:cell(standards)="row">
          <b-form-checkbox-group v-model="row.item.standards" :options="data.standards" value-field="id" text-field="val"/>
        </template>
        <template v-slot:cell(index)="row">
          <b-btn variant="danger" size="sm" @click="data.strengths.splice(row.index, 1)">Elimina</b-btn>
        </template>
      </b-table>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" @click="saveData">Salva</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "strengths",
  data() {
    return {
      show: false,
      data: null,
      fields: [{
        key: "point",
        label: "Punto di forza",
        sortable: true
      },{
        key: "standards",
        label: "Norme",
      },{
        key: "index",
        label: "",
      }],
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    addData(){
      this.data.strengths.push({
        id: 0,
        point: null,
        standards: [],
      });
    },
    loadData(){
      this.openHttp("context_strengths", null).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("context_strengths_save", this.data.strengths, "Punti di forza salvati!" ).then((result) => {
        if ( result.result == true ) this.closeForm( true );
      })
    },
    closeForm( reload = false ){
      this.show = false;
      this.data = null;
      if ( reload === true ) this.$emit('reload');
    }
  }
}
</script>
