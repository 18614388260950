<template>
  <div>
    <b-modal v-model="show" v-if="data !== null" size="lg" title="Simulazione di emergenza" scrollable @hidden="closeForm()" @close="closeForm()">
      <b-row>
        <b-col lg="4">
          <b-form-group label="Data">
            <b-form-input type="date" v-model="data.emergency.date"/>
          </b-form-group>
        </b-col>
        <b-col lg="8">
          <b-form-group label="Responsabile">
            <b-form-input v-model="data.emergency.supervisor"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6">
          <b-form-group label="Tipologia">
            <b-form-select v-model="data.emergency.emergenciestypes_id" :options="data.emergenciestypes" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group label="Titolo">
            <b-form-input v-model="data.emergency.title"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <b-form-group label="Sito">
            <b-form-select v-model="data.emergency.sites_id" :options="data.sites" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <b-form-group label="Personale coinvolto">
            <multiselect v-model="employeesSupport" :options="data.employees" placeholder="" selectLabel="" deselectLabel="" :multiple="true" track-by="id" label="val" @input="data.emergency.employees = updateCombo(employeesSupport);"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <b-form-group label="Note">
            <b-form-textarea v-model="data.emergency.note" rows="6"/>
          </b-form-group>
        </b-col>
      </b-row>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" class="mr-2" v-show="data.emergency.id > 0" @click="deleteData">Elimina</b-btn>
          <b-btn variant="info" v-show="data.emergency.id > 0 " @click="downloadData">Scarica</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "emergency",
  data() {
    return {
      show: false,
      data: null,
      employeesSupport: []
    }
  },
  methods: {
    loadData( id, emergenciestypesId = null, sitesId = null, employees = [] ){
      this.openHttp("emergencies_emergency", { id: id, emergenciestypes_id: emergenciestypesId, sites_id: sitesId, employees: employees }).then((result) => {
        this.employeesSupport = this.convertCombo(result.employees, result.emergency.employees);
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("emergencies_emergency_save", this.data.emergency, "Emergenza salvata!").then((result) => {
        if ( result.result == true ) this.closeForm( true );
      })
    },
    deleteData(){
      this.deleteHttp("emergencies_emergency_delete", { id: this.data.emergency.id }, "Emergenza eliminata!").then((result) => {
        if ( result.result == true ) this.closeForm( true );
      })
    },
    closeForm( reload = false ){
      this.show = false;
      this.data = null;
      if ( reload === true ) this.$emit('reload');
    }
  }
}
</script>