<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" title="Mansione" scrollable @hidden="closeForm()" @close="closeForm()">
      <b-row>
        <b-col lg="8">
          <b-form-group label="Mansione">
            <b-form-input v-model="data.jobdescription.jobdescription" required/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Tipologia">
            <b-form-select v-model="data.jobdescription.internal" :options="internalcombo" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Nomina">
            <b-form-select v-model="data.jobdescription.appointment" :options="appointmentcombo" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Personale">
        <multiselect v-model="data.jobdescription.employeesSupport" :options="data.employees" placeholder="" selectLabel="" deselectLabel="" :multiple="true" track-by="id" label="val" @input="data.jobdescription.employees = updateCombo(data.jobdescription.employeesSupport)"/>
      </b-form-group>
      <b-row>
        <b-col lg="4">
          <b-form-group label="Mansione responsabile">
            <b-form-select v-model="data.jobdescription.jobdescriptions_id" :options="data.jobdescriptions" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="8">
          <b-form-group label="Processi coinvolti">
            <multiselect v-model="data.jobdescription.processesSupport" placeholder="" selectLabel="" deselectLabel="" :options="data.processes" :multiple="true" track-by="id" label="val" @input="data.jobdescription.processes = updateCombo(data.jobdescription.processesSupport)"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Attestati necessari">
        <multiselect v-model="data.jobdescription.certificatesSupport" placeholder="" selectLabel="" deselectLabel="" :options="data.certificates" :multiple="true" track-by="id" label="val" @input="data.jobdescription.certificates = updateCombo(data.jobdescription.certificatesSupport)"/>
      </b-form-group>
      <b-form-group label="Competenze necessarie">
        <b-table :sticky-header="((winHeight-800) < 400 ? 400 : (winHeight-800)) + 'px'" responsive show-empty :items="data.jobdescription.competences" :fields="fieldsCompetences">
          <template v-slot:empty>Non ci sono competenze necessarie</template>
          <template v-slot:cell(minlevel)="row">
            <b-form-select size="sm" v-model="row.item.minlevel" :options="levelCompetenceCombo" value-field="id" text-field="val"/>
          </template>
        </b-table>
      </b-form-group>
      <b-form-group label="Responsabilità associate">
        <multiselect v-model="data.jobdescription.responsibilitiesSupport" placeholder="" selectLabel="" deselectLabel="" :options="data.responsibilities" :multiple="true" track-by="id" label="val" @input="data.jobdescription.responsibilities = updateCombo(data.jobdescription.responsibilitiesSupport)"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.jobdescription.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "job",
  data() {
    return {
      show: false,
      data: null,
      internalcombo: [{
        id: true,
        val: "Interna"
      },{
        id: false,
        val: "Esterna"
      }],
      appointmentcombo: [{
        id: true,
        val: "Si"
      },{
        id: false,
        val: "No"
      }],
      fieldsCompetences: [{
        key: 'competences_id',
        label: 'Competenza',
        sortable: true,
        formatter: value => {
          return this.data !== null ? this.data.competences.find(x => x.id === value).val : null;
        }
      },{
        key: 'minlevel',
        label: 'Livello necessario',
        sortable: true,
      }],
      levelCompetenceCombo: [{
        id: null,
        val: "Non necessaria",
      },{
        id: 1,
        val: "Conoscenza di base",
      },{
        id: 2,
        val: "Autonomia parziale",
      },{
        id: 3,
        val: "Autonomia completa",
      },{
        id: 4,
        val: "Autonomia completa e responsabilità",
      }]
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    loadData( id ){
      this.show = false;
      this.openHttp("jobs_job", { id: id }).then((result) => {
        result.jobdescription.employeesSupport = this.convertCombo(result.employees, result.jobdescription.employees);
        result.jobdescription.processesSupport = this.convertCombo(result.processes, result.jobdescription.processes);
        result.jobdescription.certificatesSupport = this.convertCombo(result.certificates, result.jobdescription.certificates);
        result.jobdescription.responsibilitiesSupport = this.convertCombo(result.responsibilities, result.jobdescription.responsibilities);
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("jobs_job_save", this.data.jobdescription, "Mansione salvata!" ).then((result) => {
        if ( result.result == true ) this.closeForm(true);
      })
    },
    deleteData(){
      this.deleteHttp("jobs_job_delete", { id: this.data.jobdescription.id }, "Mansione eliminata!" ).then((result) => {
        if ( result.result == true ) this.closeForm(true);
      })
    },
    closeForm(reload = false){
      this.show = false;
      this.data = null;
      if (reload === true) this.$emit('reload');
    }
  }
}
</script>