<template>
  <div>
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Due Diligence" active>
          <duediligence ref="duediligence" @reload="loadData"/>
          <duediligencesubject ref="subject" @reload="loadData"/>
          <b-modal v-model="duediligences.showFilter" title="Filtra" @ok="filterSetDuediligences">
            <b-form-group label="Soggetto">
              <b-form-input v-model="duediligences.tempFilter.subject"/>
            </b-form-group>
            <b-form-group label="Stato">
              <b-form-select v-model="duediligences.tempFilter.unactive" :options="duediligences.activeCombo" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Due diligence">
              <b-form-select v-model="duediligences.tempFilter.noduediligence" :options="duediligences.noduediligenceCombo" value-field="id" text-field="val"/>
            </b-form-group>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadDataDuediligences">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="warning" @click="filterResetDuediligences">Reset filtri</b-btn>
          <b-btn class="mr-2" variant="secondary" @click="duediligences.showFilter = true">Filtra</b-btn>
          <b-btn class="mr-2" variant="success" @click="loadDuediligence(0, null, null)">Nuovo</b-btn>
          <b-btn class="mr-2" variant="info" v-if="document != null && document.records_id > 0" @click="$refs.doctoken.downloadRecord(document.records_id)">Scarica Documento {{ document.date }}</b-btn>
          <b-btn variant="success" @click="generateDocument()">Genera Documento</b-btn>
          <doctoken ref="doctoken"/>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :tbody-tr-class="rowClassDuediligences" :items="duediligences.items.data" :fields="duediligences.fields" :busy="duediligences.isBusy" :no-local-sorting="true" :sort-by.sync="duediligences.search.sort.by" :sort-desc.sync="duediligences.search.sort.desc" @sort-changed="sortingChangeDuediligences" @row-clicked="loadSubject($event.id, $event.typedue)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:cell(status)="row">
              {{ row.value }}
              <b-btn type="button" v-show="checkDate( row.item.maxdate, row.item.status ) !== 2" class="ml-2" size="sm" variant="danger" @click="loadDuediligence(0, row.item.typedue, row.item.id)">Da valutare</b-btn>
            </template>
            <template v-slot:empty>Non ci sono due diligence</template>
          </b-table>
          <tablebottom :perpage="duediligences.search.page.perpage" @updatePerPage="duediligences.search.page.perpage = $event" :currentpage="duediligences.search.page.currentpage" @updateCurrentPage="duediligences.search.page.currentpage = $event" :total="duediligences.items.total" @loadData="loadDataDuediligences"/>
        </b-tab>
        <b-tab title="Criteri">
          <duediligencecriteria ref="criteria" @reload="loadData"/>
          <b-btn class="mr-2" variant="primary" @click="loadDataCriteria">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="success" @click="loadCriteria(0)">Nuovo</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="criteria.items.data" :fields="criteria.fields" :busy="criteria.isBusy" :no-local-sorting="true" :sort-by.sync="criteria.search.sort.by" :sort-desc.sync="criteria.search.sort.desc" @sort-changed="sortingChangeCriteria" @row-clicked="loadCriteria( $event.id )">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono scadenze</template>
          </b-table>
          <tablebottom :perpage="criteria.search.page.perpage" @updatePerPage="criteria.search.page.perpage = $event" :currentpage="criteria.search.page.currentpage" @updateCurrentPage="criteria.search.page.currentpage = $event" :total="criteria.items.total" @loadData="loadDataCriteria"/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import duediligence from './duediligence.vue';
import duediligencesubject from './duediligencesubject.vue';
import duediligencecriteria from "./duediligencecriteria.vue";

export default {
  name: "duediligences",
  components: { duediligence, duediligencesubject, duediligencecriteria },
  data() {
    return {
      duediligences: {
        isBusy: true,
        showFilter: false,
        search: {
          page: {
            currentpage: 1,
            perpage: 50,
          },
          filters: {
            subject: null,
            unactive: false,
            noduediligence: false,
          },
          sort: {
            by: "subject",
            desc: false,
          },
        },
        tempFilter: {
          subject: null,
          unactive: false,
          noduediligence: false,
        },
        noduediligenceCombo: [{
          id: false,
          val: "Soggetto"
        },{
          id: true,
          val: "Non soggetto"
        }],
        activeCombo: [{
          id: null,
          val: "Tutto",
        },{
          id: false,
          val: "Attivo",
        },{
          id: true,
          val: "Non Attivo",
        },],
        fields: [{
          key: "typedue",
          label: 'Categoria',
          sortable: true,
          formatter: value => {
            return value === "suppliers" ? "Fornitore" : (value === "customers" ? "Cliente" : (value === "employees" ? "Personale" : "?"));
          }
        },{
          key: "duename",
          label: "Soggetto",
          sortable: true,
        },{
          key: "duenamenote",
          label: "Dettagli",
        },{
          key: "noduediligence",
          label: "Necessaria?",
          sortable: true,
          formatter: value => {
            return value === true ? "No" : "Si";
          }
        },{
          key: "maxdate",
          label: "Data",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "status",
          label: "Valutazione",
          sortable: true,
          formatter: (value, key, item) => {
            return item.maxdate !== null ? ( value === true ? "Positivo" : (value === false ? "Negativo" : "?")) : "Nessuna";
          }
        },{
          key: "note",
          label: "Note",
        }],
        items: {
          total: 0,
          data: [],
        },
      },
      fieldsDetails: [{
        key: "criteria",
        label: "Tipologia",
      },{
        key: "yesno",
        label: 'Tipologia',
      },{
        key: 'note',
        label: 'Note',
      }],
      criteria: {
        isBusy: true,
        search: {
          page: {
            currentpage: 1,
            perpage: 50,
          },
          filters: {},
          sort: { by: 'criteria', desc: false, },
        },
        fields: [{
          key: 'criteria',
          label: 'Criterio',
          sortable: true,
        },{
          key: 'type',
          label: 'Tipologia',
          sortable: true,
        },{
          key: 'mandatory',
          label: "Obbligatorio",
          sortable: true,
          formatter: value => {
            return value === true ? "Obbligatorio" : "Non obbligatorio";
          }
        }],
        items: {
          total: 0,
          data: [],
        },
      },
    }  
  },
  computed: {
    token (){
      return this.$store.getters.getToken;
    },
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.loadDataDuediligences();
      this.loadDataCriteria();
    },
    downloadOpen(){
      this.$refs.downloadForm.submit();
    },
    checkDate( date, status ){
      let d = new Date( date );
      let oneYearFromNow = new Date();
      oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
      return ( (date === null || oneYearFromNow.getTime() <= d.getTime()) ? 0 : (status === true ? 2 : 1) );
    },
    rowClassDuediligences(item) {
      if ( item === null || item.noduediligence === true) return "";
      console.log(item.noduediligence);
      let ckDt = this.checkDate( item.maxdate, item.status );
      return "table-" + ( ckDt === 0 ? "danger" : ( ckDt === 1 ? "warning" : "success"));
    },
    loadDataDuediligences(){
      this.duediligences.isBusy = true;
      this.openHttp("duediligences_duediligences", { search: this.duediligences.search }).then((result) => {
        this.duediligences.isBusy = false;
        this.duediligences.items = result;
        this.loadDocument();
      })
    },
    loadDocument(){
      this.openHttp("duediligences_document", null).then((result) => {
        this.document = result;
      })
    },
    generateDocument(){
      this.generateDocumentHttp("duediligences");
    },
    loadSubject(id, type){
      this.$refs.subject.loadData(id, type);
    },
    loadDuediligence(id, type, typeId){
      this.$refs.duediligence.loadData(id, type, typeId);
    },
    filterSetDuediligences(){
      this.duediligences.showFilter = false;
      this.duediligences.search.filters = this.duediligences.tempFilter;
      this.loadDataDuediligences();
    },
    filterResetDuediligences(){
      this.duediligences.tempFilter = {
        subject: null,
        unactive: false,
        noduediligence: false,
      };
      this.duediligences.search.filters = this.duediligences.tempFilter;
      this.loadDataDuediligences();
    },
    sortingChangeDuediligences(ctx) {
      this.duediligences.search.sort.by = ctx.sortBy;
      this.duediligences.search.sort.desc = ctx.sortDesc;
      this.loadDataDuediligences();
    },
    downloadOpenDuediligences(){
      this.$refs.downloadForm.submit();
    },
    loadDataCriteria(){
      this.criteria.isBusy = true;
      this.openHttp("duediligences_criterias", {  search: this.criteria.search }).then((result) => {
        this.criteria.items = result;
        this.criteria.isBusy = false;
      })
    },
    sortingChangeCriteria(ctx) {
      this.criteria.search.sort.by = ctx.sortBy;
      this.criteria.search.sort.desc = ctx.sortDesc;
      this.loadDataCriteria();
    },
    loadCriteria(id){
      this.$refs.criteria.loadData(id);
    },
  }
}
</script>