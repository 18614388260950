<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" title="Impatto" scrollable @hidden="closeForm" @close="closeForm">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Impatto" active>
            <b-row>
              <b-col lg="4">
                <b-form-group label="Norma">
                  <multiselect v-model="data.impact.standards_idSupport" :options="data.standards" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.impact.standards_id = updateComboSimple(data.impact.standards_idSupport); loadEvaluation();"/>
                </b-form-group>
              </b-col>
              <b-col lg="8">
                <b-form-group label="Fase">
                  <b-form-input v-model="data.impact.phase" required/>
                </b-form-group>
              </b-col>
              <b-col lg="12">
                <b-form-group label="Processi coinvolti">
                  <multiselect v-model="data.impact.processesSupport" :options="data.processes" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.impact.processes = updateCombo(data.impact.processesSupport)"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Parti interessate coinvolte">
              <multiselect v-model="data.impact.interestedpartiesSupport" :options="data.interestedparties" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.impact.interestedparties = updateCombo(data.impact.interestedpartiesSupport)"/>
            </b-form-group>
            <b-form-group label="Strumenti ed attrezzature utilizzati">
              <multiselect v-model="data.impact.machineriestypesSupport" :options="data.machineriestypes" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.impact.machineriestypes = updateCombo(data.impact.machineriestypesSupport)"/>
            </b-form-group>
            <b-row>
              <b-col lg="4">
                <b-form-group label="Grado di controllo">
                  <multiselect v-model="data.impact.impactsanalysiscontrollevels_idSupport" :options="data.controllevels" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.impact.impactsanalysiscontrollevels_id = updateComboSimple(data.impact.impactsanalysiscontrollevels_idSupport)"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Inputs">
                  <b-form-textarea v-model="data.impact.inputs"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Outputs">
                  <b-form-textarea v-model="data.impact.outputs"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Condizioni">
            <b-row>
              <b-col lg="6">
                <b-form-group label="Condizioni normali">
                  <b-form-textarea v-model="data.impact.conditions_normal" rows="4"/>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group label="Precauzioni in Condizioni normali">
                  <b-form-textarea v-model="data.impact.precautions_normal" rows="4"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="6">
                <b-form-group label="Condizioni anormali">
                  <b-form-textarea v-model="data.impact.conditions_abnormal" rows="4"/>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group label="Precauzioni in Condizioni anormali">
                  <b-form-textarea v-model="data.impact.precautions_abnormal" rows="4"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="6">
                <b-form-group label="Condizioni emergenziali">
                  <b-form-textarea v-model="data.impact.conditions_emergency" rows="4"/>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group label="Precauzioni in Condizioni emergenziali">
                  <b-form-textarea v-model="data.impact.precautions_emergency" rows="4"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Valutazione">
            <b-row>
              <b-col lg="6" v-for="item in data.impact.evaluation" :key="item.id">
                <b-form-group :label="item.val">
                  <b-button-group>
                    <b-button v-for="(item2, index) in evaluationCombo" :key="index" :variant=" item2.id === item.evaluation ? item2.variant : 'light'" @click="item.evaluation = item2.id">{{ item2.val }}</b-button>
                  </b-button-group>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="warning" class="mr-2" v-show="data.impact.id > 0" @click="duplicateData">Duplica</b-btn>
          <b-btn variant="danger" v-show="data.impact.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "impact",
  data() {
    return {
      show: false,
      data: null,
      evaluationCombo: [{
        id: null,
        val: "Impatto non presente",
        variant: "primary",
      },{
        id: 1,
        val: "Basso",
        variant: "success",
      },{
        id: 2,
        val: "Medio",
        variant: "warning",
      },{
        id: 3,
        val: "Alto",
        variant: "danger",
      },],
    }  
  },
  methods: {
    filterStandards(x){
      return x.standards_id === this.data.impact.standards_id ? true : false;
    },
    loadEvaluation(){
      this.data.impact.evaluation = this.data.impactsanalysistypes.filter(this.filterStandards).map( x => { return { id: x.id, val: x.val, evaluation: null } } );
    },
    loadData( id ){
      this.show = false;
      this.openHttp("impacts_impact", { id: id }).then((result) => {
        result.impact.standards_idSupport = this.convertComboSimple(result.standards, result.impact.standards_id);
        result.impact.impactsanalysiscontrollevels_idSupport = this.convertComboSimple(result.controllevels, result.impact.impactsanalysiscontrollevels_id);
        result.impact.processesSupport = this.convertCombo(result.processes, result.impact.processes);
        result.impact.interestedpartiesSupport = this.convertCombo(result.interestedparties, result.impact.interestedparties);
        result.impact.machineriestypesSupport = this.convertCombo(result.machineriestypes, result.impact.machineriestypes);
        this.data = result;
        this.show = true;
      })
    },
    duplicateData(){
      this.data.impact.id = 0;
      this.data.impact.phase = this.data.impact.phase + " - duplicata";
    },
    saveData(){
      this.saveHttp("impacts_impact_save", this.data.impact, "Impatto salvato!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("impacts_impact_delete", { id: this.data.impact.id }, "Impatto eliminato!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('reload');
    }
  }
}
</script>