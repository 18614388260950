<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" :title="'Nomina ' + data.jobdescription" scrollable @hidden="closeForm()" @close="closeForm()">
      <b-table :items="data.employees" :fields="fields">
        <template v-slot:cell(date)="row">
          <b-form-input type="date" v-model="row.item.date"/>
        </template>
        <template v-slot:cell(note)="row">
          <b-form-textarea v-show="row.item.date != null && row.item.date != ''" v-model="row.item.note"/>
        </template>
      </b-table>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" @click="saveData">Salva</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "appointment",
  data() {
    return {
      show: false,
      data: null,
      fields: [{
        key: "employee",
        label: "Persona",
      },{
        key: "date",
        label: "Data nomina",
      },{
        key: "note",
        label: "Note",
      }],
    }  
  },
  methods: {
    loadData(id){
      this.openHttp("jobs_appointment", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("jobs_appointment_save", this.data.employees, "Nomina salvata!" ).then((result) => {
        if ( result.result == true ) this.closeForm( true );
      })
    },
    closeForm( reload = false ){
      this.show = false;
      this.data = null;
      if ( reload === true ) this.$emit('reload');
    }
  }
}
</script>