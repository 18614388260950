<template>
  <div>
    <b-modal v-if="data != null" v-model="show" size="lg" title="Autorizzazione" @hidden="closeForm" @close="closeForm">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Profilo" active>
            <b-row>
              <b-col lg="6">
                <b-form-group label="Mail">
                  <b-form-input name ="mail1" v-model="data.auth.mail"/>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group label="Password">
                  <b-input-group>
                    <b-form-input name ="psw" :type="typePassword" v-model="data.auth.psw"/>
                    <template v-slot:append>
                      <b-btn variant="primary" @mousedown="clearPsw(true)" @mouseup="clearPsw(false)"><b-icon icon="eye"/></b-btn>
                    </template>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="8">
                <b-form-group label="Persona">
                  <b-form-select v-model="data.auth.employees_id" :options="data.employees" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4">
                <b-form-group label="Amministratore">
                  <b-form-select v-model="data.auth.isadmin" :options="yesNoCombo" value-field="id" text-field="val" :disabled="data.auth.credentials_id != null"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Approvare documenti">
                  <b-form-select v-model="data.auth.canapprove" :options="yesNoCombo" value-field="id" text-field="val" :disabled="data.auth.credentials_id === null"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Frequenza invio mail">
                  <b-form-select v-model="data.auth.frequencymail" :options="frequencymailCombo" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Accessi" :disabled="data.auth.isadmin === true">
            <b-table :sticky-header="(winHeight - 400) + 'px'" class="mt-3" no-border-collapse responsive :items="data.routes" :fields="fields">
              <template v-slot:cell(deny)="row">
                <b-button :variant="data.auth.routes.findIndex(x => x.id === row.item.id) === -1 ? 'danger' : ''" size="sm" @click="denyRoute(row.item.id)">Vietato</b-button>
              </template>
              <template v-slot:cell(read)="row">
                <b-button :variant="data.auth.routes.findIndex(x => x.id === row.item.id && x.readauth === true && x.writeauth === false) > -1 ? 'warning' : ''" size="sm" @click="readRoute(row.item.id)">Sola lettura</b-button>
              </template>
              <template v-slot:cell(full)="row">
                <b-button :variant="data.auth.routes.findIndex(x => x.id === row.item.id && x.readauth === true && x.writeauth === true) > -1 ? 'success' : ''" size="sm" @click="fullRoute(row.item.id)">Lettura e scrittura</b-button>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-if="data.auth.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "auth",
  data() {
    return {
      show: false,
      data: null,
      yesNoCombo:[{
        id: false,
        val: 'No'
      },{
        id: true,
        val: 'Si'
      }],
      frequencymailCombo:[{
        id: null,
        val: 'Nessuna'
      },{
        id: '1 day',
        val: 'Giornaliera'
      },{
        id: '1 week',
        val: 'Settimanale'
      },{
        id: '1 month',
        val: 'Mensile'
      },{
        id: '2 month',
        val: 'Bimenstrale'
      },{
        id: '3 month',
        val: 'Trimenstrale'
      },{
        id: '6 month',
        val: 'Semenstrale'
      },{
        id: '1 year',
        val: 'Annuale'
      }],
      fields: [{
        key: "val",
        label: "Maschera"
      },{
        key: "deny",
        label: "Vietato"
      },{
        key: "read",
        label: "Sola lettura"
      },{
        key: "full",
        label: "Lettura e scrittura"
      }],
      typePassword: "password",
    }  
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    }
  },
  methods: {
    loadData( id ){
      this.openHttp("auths_auth", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    clearPsw(upDown){
      this.typePassword = upDown === true ? 'text' : 'password';
    },
    denyRoute(routesId){
      if (this.data.auth.routes.findIndex(x => x.id === routesId) > -1){
        this.data.auth.routes.splice(this.data.auth.routes.findIndex(x => x.id === routesId), 1);
      }
    },
    readRoute(routesId){
      if (this.data.auth.routes.findIndex(x => x.id === routesId) > -1){
        this.data.auth.routes.find(x => x.id === routesId).readauth = true;
        this.data.auth.routes.find(x => x.id === routesId).writeauth = false;
      } else {
        this.data.auth.routes.push({id: routesId, readauth: true, writeauth: false});
      }
    },
    fullRoute(routesId){
      if (this.data.auth.routes.findIndex(x => x.id === routesId) > -1){
        this.data.auth.routes.find(x => x.id === routesId).readauth = true;
        this.data.auth.routes.find(x => x.id === routesId).writeauth = true;
      } else {
        this.data.auth.routes.push({id: routesId, readauth: true, writeauth: true});
      }
    },
    saveData(){
      this.saveHttp("auths_auth_save", this.data.auth, "Autorizzazione salvata!").then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("auths_auth_delete", { id: this.data.auth.id }, "Autorizzazione eliminata!").then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('reload');
    }
  }
}
</script>