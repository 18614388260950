<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" title="Analisi del Rischio" scrollable @hidden="closeForm()" @close="closeForm()">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Analisi" active>
            <b-row>
              <b-col lg="12">
                <b-form-group label="Mitigazione">
                  <b-form-textarea v-model="data.riskanalysis.mitigation" required rows="2" max-rows="6"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="12">
                <b-form-group label="Prevenzione">
                  <b-form-textarea v-model="data.riskanalysis.prevenction" required rows="2" max-rows="6"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3">
                <b-form-group label="Data">
                  <b-form-input type="date" v-model="data.riskanalysis.dateanalysis"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Probabilità">
                  <b-form-select v-model="data.riskanalysis.probability" :options="probabilityCombo" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Gravità">
                  <b-form-select v-model="data.riskanalysis.seriousness" :options="seriousnessCombo" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Rilevabilità">
                  <b-form-select v-model="data.riskanalysis.detection" :options="detectionCombo" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="6">
                <b-form-group label="Griglia di valutazione">
                  <b-table-simple responsive>
                    <b-thead>
                      <b-tr>
                        <b-th>Livello</b-th>
                        <b-th>Minimo</b-th>
                        <b-th>Massimo</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr>
                        <b-td variant="success">Basso</b-td>
                        <b-td>1</b-td>
                        <b-td>4</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td variant="warning">Medio</b-td>
                        <b-td>5</b-td>
                        <b-td>12</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td variant="danger">Alto</b-td>
                        <b-td>13</b-td>
                        <b-td>64</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3">
                <b-form-group label="Calcolo">
                  <b-form-input :value="data.riskanalysis.probability * data.riskanalysis.seriousness * data.riskanalysis.detection + '/64'"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Decisione">
                  <b-form-select v-model="data.riskanalysis.risksevaluation" :options="evaluationCombo" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab :title="'Azioni correttive (' + data.riskanalysis.correctiveactions.length + ')'" :disabled="data.riskanalysis.id === 0">
            <b-btn size="sm" variant="primary" @click="newcorrectiveaction">Nuova azione correttiva</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 395) + 'px'" responsive show-empty class="mt-3" :items="data.riskanalysis.correctiveactions" :fields="fields">
              <template v-slot:cell(open)="row">
                <b-row>
                  <b-col lg="3">
                    <b-form-group label="Data apertura">
                      <b-form-input type="date" v-model="row.item.dateopen"/>
                    </b-form-group>
                  </b-col>
                  <b-col lg="3">
                    <b-form-group label="Data obiettivo">
                      <b-form-input type="date" v-model="row.item.datetarget"/>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6">
                    <b-form-group label="Responsabile apertura">
                      <b-form-select v-model="row.item.employeesopen_id" :options="data.employees" value-field="id" text-field="val"/>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12">
                    <b-form-group label="Azione correttiva">
                      <b-form-textarea v-model="row.item.descriptionopen" rows="2" max-rows="4"/>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12">
                    <b-form-group label="Note">
                      <b-form-textarea v-model="row.item.noteopen" rows="2" max-rows="4"/>
                    </b-form-group>
                  </b-col>
                </b-row>
              </template>
              <template v-slot:cell(close)="row">
                <b-row>
                  <b-col lg="3">
                    <b-form-group label="Data chiusura">
                      <b-form-input type="date" v-model="row.item.dateclose"/>
                    </b-form-group>
                  </b-col>
                  <b-col lg="3">
                    <b-form-group label="Data verifica">
                      <b-form-input type="date" v-model="row.item.dateverify"/>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6">
                    <b-form-group label="Responsabile chiusura">
                      <b-form-select v-model="row.item.employeesclose_id" :options="data.employees" value-field="id" text-field="val"/>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12">
                    <b-form-group label="Evidenza del risultato">
                      <b-form-textarea v-model="row.item.descriptionclose" rows="2" max-rows="4"/>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12">
                    <b-form-group label="Note">
                      <b-form-textarea v-model="row.item.noteclose" rows="2" max-rows="4"/>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="4">
                    <b-form-group label="Esito">
                      <b-form-select v-model="row.item.success" :options="successCombo" value-field="id" text-field="val"/>
                    </b-form-group>
                  </b-col>
                  <b-col lg="4">
                    <b-form-group label="Costo">
                      <b-form-input type="number" v-model="row.item.cost"/>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-btn variant="danger" size="sm" @click="data.riskanalysis.correctiveactions.splice(row.index, 1)">Elimina</b-btn>
              </template>
              <template v-slot:empty>Non ci sono azioni correttive</template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="warning" class="mr-2" v-show="data.riskanalysis.id > 0" @click="data.riskanalysis.id = 0">Duplica</b-btn>
          <b-btn variant="danger" v-show="data.riskanalysis.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "riskanalysis",
  data() {
    return {
      show: false,
      data: null,
      successCombo: [{
        id: null,
        val: "In corso"
      },{
        id: true,
        val: "Conclusa con esito positivo"
      },{
        id: false,
        val: "Conclusa con esito negativo"
      }],
      probabilityCombo: [{
        id: 1,
        val: "Trascurabile"
      },{
        id: 2,
        val: "Medio bassa"
      },{
        id: 3,
        val: "Medio alta"
      },{
        id: 4,
        val: "Elevata"
      }],
      seriousnessCombo:[{
        id: 1,
        val: "Lieve"
      },{
        id: 2,
        val: "Medio bassa"
      },{
        id: 3,
        val: "Medio alta"
      },{
        id: 4,
        val: "Elevata"
      }],
      detectionCombo:[{
        id: 1,
        val: "Costante monitoraggio"
      },{
        id: 2,
        val: "Facilmente rilevabile"
      },{
        id: 3,
        val: "Rilevabile"
      },{
        id: 4,
        val: "Difficilmente rilevabile"
      }],
      evaluationCombo: [{
        id: 1,
        val: "Accettare il rischio",
      },{
        id: 2,
        val: "Trasferire il rischio",
      },{
        id: 3,
        val: "Finanziare il rischio",
      },{
        id: 4,
        val: "Ridurre il rischio",
      },{
        id: 5,
        val: "Rischio eliminato",
      }],
      fields: [{
        key: "open",
        label: "Apertura",
        thStyle: { width: "50%" }
      },{
        key: "close",
        label: "Chiusura",
        thStyle: { width: "50%" }
      }],
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    loadData( id, risksId ){
      this.openHttp("risks_riskanalysis", { id: id, risks_id: risksId }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    newcorrectiveaction(){
      var today = new Date();
      this.data.riskanalysis.correctiveactions.push({
        id: 0,
        employeesopen_id: this.data.employees[0].id,
        employeesclose_id: null,
        dateopen: [today.getFullYear(), today.getMonth() + 1, today.getDate()].map(n => n < 10 ? `0${n}` : `${n}`).join('-'),
        dateclose: null,
        datetarget: [today.getFullYear(), today.getMonth() + 1, today.getDate()].map(n => n < 10 ? `0${n}` : `${n}`).join('-'),
        dateverify: [today.getFullYear(), today.getMonth() + 1, today.getDate()].map(n => n < 10 ? `0${n}` : `${n}`).join('-'),
        descriptionopen: null,
        noteopen: null,
        descriptionclose: null,
        noteclose: null,
        success: null,
        cost: 0,
      });
    },
    saveData(){
      this.saveHttp("risks_riskanalysis_save", this.data.riskanalysis, "Analisi del rischio salvato!" ).then((result) => {
        if ( result.result == true ) this.closeForm( true );
      })
    },
    deleteData(){
      this.deleteHttp("risks_riskanalysis_delete", { id: this.data.riskanalysis.id }, "Analisi del rischio eliminato!" ).then((result) => {
        if ( result.result == true ) this.closeForm( true );
      })
    },
    closeForm(reload = false){
      this.show = false;
      this.data = null;
      if ( reload === true ) this.$emit('reload');
    }
  }
}
</script>
