<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" title="Revisione" scrollable @hidden="closeForm()" @close="closeForm()">
      <b-form-group label="Data revisione">
        <b-form-input type="date" v-model="data.revision.createdate"/>
      </b-form-group>
      <b-form-group label="N° Revisione">
        <b-form-input min="0" step="1" v-model="data.revision.revision"/>
      </b-form-group>
      <b-form-group label="Creata / Revisionata da">
        <b-form-select v-model="data.revision.created_employees_id" :options="data.employees" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Fonte">
        <b-form-select v-model="data.revision.fromfile" :options="fromCombo" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="File" v-if="data.revision.fromfile === true">
        <b-form-file placeholder="Scegli un file o trascinalo qui..." drop-placeholder="Trascinalo qui..." @change="handleFile($event)"/>
      </b-form-group>
      <b-form-group label="Approvata da">
        <b-form-select v-model="data.revision.approved_employees_id" :options="data.employees.filter(x => x.canapprove === true)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Approvata">
        <b-form-input type="date" v-model="data.revision.approveddate"/>
      </b-form-group>
      <b-form-group label="Scadenza">
        <b-form-input type="date" v-model="data.revision.expirydate"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.revision.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: "documentrevision",
  data() {
    return {
      show: false,
      data: null,
      fromCombo: [{
        id: false,
        val: "Crea da testo",
      },{
        id: true,
        val: "Crea da file",
      }]
    }  
  },
  methods: {
    loadData( documentsId, id ){
      this.show = false;
      this.openHttp("documents_revision", { id: id, documents_id: documentsId }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    handleFile(event){
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = ()=> {
        this.data.revision.file = reader.result;
        this.data.revision.filename = event.target.files[0].name;
      };
    },
    saveData(){
      this.saveHttp("documents_revision_save", this.data.revision, "Revisione salvata!" ).then((result) => {
        if ( result.result == true ) this.closeForm(true);
      })
    },
    deleteData(){
      this.deleteHttp("documents_revision_delete", { id: this.data.revision.id }, "Revisione eliminata!" ).then((result) => {
        if ( result.result == true ) this.closeForm(true);
      })
    },
    closeForm(reload = false){
      this.show = false;
      this.data = null;
      if(reload === true) this.$emit('reload');
    }
  }
}
</script>