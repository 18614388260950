<template>
  <div>
    <doctoken ref="doctoken"/>
    <nonconformity ref="nonconformity" @reload="loadData()"/>
    <nonconformityproduct ref="product" @reload="loadData()"/>
    <nonconformitycause ref="cause" @reload="loadData()"/>
    <nonconformitydefect ref="defect" @reload="loadData()"/>
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Non conformità" active>
          <b-modal v-model="nonconformities.showFilter" title="Filtra" @ok="filterSetNonconformities()">
            <b-form-group label="Ricerca nel testo">
              <b-form-input type="text" v-model="nonconformities.tempFilter.description"/>
            </b-form-group>
            <b-form-group label="Anno">
              <b-form-select v-model="nonconformities.tempFilter.year" :options="comboSeeAll(nonconformities.filtersMenu.years)" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Processo">
              <b-form-select v-model="nonconformities.tempFilter.processes_id" :options="comboSeeAll(nonconformities.filtersMenu.processes)" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Norma">
              <b-form-select v-model="nonconformities.tempFilter.standards_id" :options="comboSeeAll(nonconformities.filtersMenu.standards)" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Fornitore">
              <b-form-select v-model="nonconformities.tempFilter.suppliers_id" :options="comboSeeAll(nonconformities.filtersMenu.suppliers)" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Cliente">
              <b-form-select v-model="nonconformities.tempFilter.customers_id" :options="comboSeeAll(nonconformities.filtersMenu.customers)" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Difetti">
              <b-form-select v-model="nonconformities.tempFilter.defects_id" :options="comboSeeAll(nonconformities.filtersMenu.defects)" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Cause">
              <b-form-select v-model="nonconformities.tempFilter.causes_id" :options="comboSeeAll(nonconformities.filtersMenu.causes)" value-field="id" text-field="val"/>
            </b-form-group>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadNonconformities()">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="warning" @click="filterResetNonconformities()">Reset filtri</b-btn>
          <b-btn class="mr-2" variant="secondary" @click="filterOpenNonconformities()">Filtra</b-btn>
          <b-btn class="mr-2" variant="success" @click="$refs.nonconformity.loadData(0, null)">Nuovo</b-btn> 
          <b-btn class="mr-2" variant="info" v-if="document != null && document.records_id > 0" @click="$refs.doctoken.downloadRecord(document.records_id)">Scarica Documento {{ document.date }}</b-btn>
          <b-btn class="mr-2" variant="success" @click="generateDocument()">Genera Documento</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="nonconformities.items.data" :fields="nonconformities.fields" :busy="nonconformities.isBusy" :no-local-sorting="true" :sort-by.sync="nonconformities.search.sort.by" :sort-desc.sync="nonconformities.search.sort.desc" @sort-changed="sortingChangeNonconformities" @row-clicked="$refs.nonconformity.loadData($event.id, null)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
          <tablebottom :perpage="nonconformities.search.page.perpage" @updatePerPage="nonconformities.search.page.perpage = $event" :currentpage="nonconformities.search.page.currentpage" @updateCurrentPage="nonconformities.search.page.currentpage = $event" :total="nonconformities.items.total" @loadData="loadNonconformities"/>
        </b-tab>
        <b-tab title="Processi">
          <b-btn variant="primary" @click="loadProcesses()">Aggiorna</b-btn>
          <b-row>
            <b-col>
              <b-table no-border-collapse :sticky-header="(winHeight - 300) + 'px'" responsive show-empty class="mt-3" :items="processes.items.data" :fields="fieldsProcesses" :busy="processes.isBusy">
                <template v-slot:thead-top>
                  <b-tr>
                    <b-th colspan="2"></b-th>
                    <b-th :colspan="processes.items.fields.length == 0 ? 1 : processes.items.fields.length" variant="secondary">Numero NC</b-th>
                    <b-th :colspan="processes.items.fields.length == 0 ? 1 : processes.items.fields.length" variant="primary">Costi NC</b-th>
                  </b-tr>
                </template>
                <template v-slot:table-busy>
                  <div class="text-center text-primary">
                    <b-spinner class="align-middle"/>
                  </div>
                </template>
                <template v-slot:empty>Non ci sono risultati</template>
              </b-table>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Clienti">
          <b-btn class="mr-2" variant="primary" @click="loadCustomers()">Aggiorna</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 300) + 'px'" responsive show-empty class="mt-3" :items="customers.items.data" :fields="fieldsCustomers" :busy="customers.isBusy">
            <template v-slot:thead-top>
              <b-tr>
                <b-th></b-th>
                <b-th :colspan="products.items.fields.length == 0 ? 1 : products.items.fields.length" variant="secondary">Numero NC</b-th>
                <b-th :colspan="products.items.fields.length == 0 ? 1 : products.items.fields.length" variant="primary">Costi NC</b-th>
              </b-tr>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
        </b-tab>
        <b-tab title="Fornitori">
          <b-btn variant="primary" @click="loadSuppliers()">Aggiorna</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 300) + 'px'" responsive show-empty class="mt-3" :items="suppliers.items.data" :fields="fieldsSuppliers" :busy="suppliers.isBusy">
            <template v-slot:thead-top>
              <b-tr>
                <b-th></b-th>
                <b-th :colspan="products.items.fields.length == 0 ? 1 : products.items.fields.length" variant="secondary">Numero NC</b-th>
                <b-th :colspan="products.items.fields.length == 0 ? 1 : products.items.fields.length" variant="primary">Costi NC</b-th>
              </b-tr>
            </template>          
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
        </b-tab>
        <b-tab title="Prodotti">
          <b-btn class="mr-2" variant="primary" @click="loadProducts()">Aggiorna</b-btn>
          <b-btn variant="success" @click="$refs.product.loadData(0)">Nuovo</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 300) + 'px'" responsive show-empty class="mt-3" :items="products.items.data" :fields="fieldsProducts" :busy="products.isBusy" @row-clicked="$refs.product.loadData($event.id)">
            <template v-slot:thead-top>
              <b-tr>
                <b-th colspan="2"></b-th>
                <b-th :colspan="products.items.fields.length == 0 ? 1 : products.items.fields.length" variant="secondary">Numero NC</b-th>
                <b-th :colspan="products.items.fields.length == 0 ? 1 : products.items.fields.length" variant="primary">Costi NC</b-th>
              </b-tr>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
        </b-tab>
        <b-tab title="Cause">
          <b-btn class="mr-2" variant="primary" @click="loadCauses()">Aggiorna</b-btn>
          <b-btn variant="success" @click="$refs.cause.loadData(0)">Nuovo</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 300) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="causes.items.data" :fields="fieldsCauses" :busy="causes.isBusy" @row-clicked="$refs.cause.loadData($event.id)">
            <template v-slot:thead-top>
              <b-tr>
                <b-th colspan="2"></b-th>
                <b-th :colspan="products.items.fields.length == 0 ? 1 : products.items.fields.length" variant="secondary">Numero NC</b-th>
                <b-th :colspan="products.items.fields.length == 0 ? 1 : products.items.fields.length" variant="primary">Costi NC</b-th>
              </b-tr>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
        </b-tab>
        <b-tab title="Difetti">
          <b-btn class="mr-2" variant="primary" @click="loadDefects()">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="success" @click="$refs.defect.loadData(0)">Nuovo</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 300) + 'px'" responsive show-empty class="mt-3" :items="defects.items.data" :fields="fieldsDefects" :busy="defects.isBusy" @row-clicked="$refs.defect.loadData($event.id)">
            <template v-slot:thead-top>
              <b-tr>
                <b-th></b-th>
                <b-th colspan="3" variant="secondary">Numero NC</b-th>
                <b-th colspan="3" variant="primary">Costi NC</b-th>
              </b-tr>
            </template>          
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import nonconformity from './nonconformity.vue'
import nonconformityproduct from './nonconformityproduct.vue';
import nonconformitycause from './nonconformitycause.vue';
import nonconformitydefect from './nonconformitydefect.vue'

export default {
  name: "nonconformities",
  components: { nonconformity, nonconformityproduct, nonconformitycause, nonconformitydefect },
  data() {
    return {
      nonconformities: {
        showFilter: false,
        isBusy: true,
        search:{
          page: {
            currentpage: 1,
            perpage: 50,
          },
          filters: {
            description: null,
            years: null,
            processes_id: null,
            standards_id: null,
            suppliers_id: null,
            customers_id: null,
            defects_id: null,
            causes_id: null,
          },
          sort: {
            by: 'dateopening',
            desc: true
          },
        },
        filtersMenu: {
          years: [],
          processes: [],
          standards: [],
          suppliers: [],
          customers: [],
          defects: [],
          causes: [],
        },
        tempFilter: {
          description: null,
          years: null,
          processes_id: null,
          standards_id: null,
          suppliers_id: null,
          customers_id: null,
          defects_id: null,
          causes_id: null,
        },
        fields: [{
          key: 'progressive',
          label: "N°",
          sortable: true
        },{
          key: 'yearnc',
          label: 'Anno',
          sortable: true,
        },{
          key: 'dateopening',
          label: 'Apertura',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'description',
          label: 'Descrizione',
        },{
          key: 'standards',
          label: 'Norme',
        },{
          key: 'processes',
          label: 'Processi',
        },{
          key: 'treatment',
          label: 'Trattamento',
        },{
          key: 'dateclosure',
          label: 'Chiusura',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'cost',
          label: 'Costo',
          sortable: true,        
          formatter: value => {
            return value + " €";
          }
        }],
        items: {
          total: 0,
          data: [],
        }
      },

      processes: {
        isBusy: true,
        items: {
          fields: [],
          data: [],
        }
      },
      customers: {
        isBusy: true,
        items: {
          fields: [],
          data: [],
        }
      },
      suppliers: {
        isBusy: true,
        items: {
          fields: [],
          data: []
        }
      },
      causes: {
        isBusy: true,
        items: {
          fields: [],
          data: []
        }
      },
      products: {
        isBusy: true,
        items: {
          fields: [],
          data: []
        }
      },
      defects: {
        isBusy: true,
        items: {
          fields: [],
          data: [],
        },
      },
      document: null
    }
  },
  computed: {
    token (){
      return this.$store.getters.getToken;
    },
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
    fieldsProcesses(){
      let fields = [{
        key: 'process',
        label: 'Processo',
        sortable: true,
      },{
        key: 'external',
        label: 'Tipologia',
        sortable: true,
        formatter: value => {
          return value === true ? 'Esterna' : 'Interna';
        }
      }];
      for (let item of this.processes.items.fields) fields.push({ key: 'totalnc_' + item.field, label: '' + item.year });
      for (let item of this.processes.items.fields) fields.push({ key: 'sumnc_' + item.field, label: '' + item.year, formatter: value => { return value.toFixed(2) + " €"; } });
      return fields;
    },
    fieldsCustomers(){
      let fields = [{
        key: "customer",
        label: "Cliente",
        sortable: true,
      }];
      for (let item of this.customers.items.fields) fields.push({ key: 'totalnc_' + item.field, label: '' + item.year });
      for (let item of this.customers.items.fields) fields.push({ key: 'sumnc_' + item.field, label: '' + item.year, formatter: value => { return value.toFixed(2) + " €"; } });
      return fields;
    },
    fieldsSuppliers(){
      let fields = [{
        key: 'supplier',
        label: 'Fornitore',
        sortable: true,
      }];
      for (let item of this.suppliers.items.fields) fields.push({ key: 'totalnc_' + item.field, label: '' + item.year });
      for (let item of this.suppliers.items.fields) fields.push({ key: 'sumnc_' + item.field, label: '' + item.year, formatter: value => { return value.toFixed(2) + " €"; } });
      return fields;
    },

    fieldsProducts(){
      let fields = [{
        key: 'family',
        label: 'Famiglia',
        sortable: true,
      },{
        key: 'product',
        label: 'Prodotto',
        sortable: true
      }];
      for (let item of this.products.items.fields) fields.push({ key: 'totalnc_' + item.field, label: '' + item.year });
      for (let item of this.products.items.fields) fields.push({ key: 'sumnc_' + item.field, label: '' + item.year, formatter: value => { return value.toFixed(2) + " €"; } });
      return fields;
    },
    fieldsCauses(){
      let fields = [{
        key: 'cause',
        label: 'Causa',
        sortable: true,
      }];
      for (let item of this.causes.items.fields) fields.push({ key: 'totalnc_' + item.field, label: '' + item.year });
      for (let item of this.causes.items.fields) fields.push({ key: 'sumnc_' + item.field, label: '' + item.year, formatter: value => { return value.toFixed(2) + " €"; } });
      return fields;
    },
    fieldsDefects(){
      let fields = [{
        key: 'defect',
        label: 'Difetto',
        sortable: true
      }];
      for (let item of this.products.items.fields) fields.push({ key: 'totalnc_' + item.field, label: '' + item.year });
      for (let item of this.products.items.fields) fields.push({ key: 'sumnc_' + item.field, label: '' + item.year, formatter: value => { return value.toFixed(2) + " €"; } });
      return fields;
    }
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.loadNonconformities();
      this.loadProcesses();
      this.loadCustomers();
      this.loadSuppliers();
      this.loadCauses();
      this.loadProducts();
      this.loadDefects();
    },
    loadDocument(){
      this.openHttp("nonconformities_document", null).then((result) => {
        this.document = result;
      })
    },
    generateDocument(){
      this.generateDocumentHttp("nonconformities");
    },
    loadNonconformities(){
      this.nonconformities.isBusy = true;
      this.openHttp("nonconformities_nonconformities", this.nonconformities.search).then((result) => {
        this.nonconformities.isBusy = false;
        this.nonconformities.items = result;
        this.loadDocument();
      })
    },
    filterOpenNonconformities(){
      this.openHttp("nonconformities_filters", null).then((result) => {
        this.nonconformities.filtersMenu = result;
        this.nonconformities.showFilter = true;
      })
    },
    filterSetNonconformities(){
      this.nonconformities.showFilter = false;
      this.nonconformities.search.filters = this.nonconformities.tempFilter;
      this.loadNonconformities();
    },
    filterResetNonconformities(){
      this.nonconformities.tempFilter = {
        description: null,
        years: null,
        processes_id: null,
        standards_id: null,
        suppliers_id: null,
        customers_id: null,
        defects_id: null,
        causes_id: null,
      };
      this.filterSetNonconformities();
    },
    sortingChangeNonconformities(ctx) {
      this.nonconformities.search.sort.by = ctx.sortBy;
      this.nonconformities.search.sort.desc = ctx.sortDesc;
      this.loadNonconformities();
    },
    loadProcesses(){
      this.processes.isBusy = true;
      this.openHttp("nonconformities_processes", {}).then((result) => {
        this.processes.items = result;
        this.processes.isBusy = false;
      })
    },
    loadCustomers(){
      this.customers.isBusy = true;
      this.openHttp("nonconformities_customers", {}).then((result) => {
        this.customers.items = result;
        this.customers.isBusy = false;
      })
    },
    loadSuppliers(){
      this.suppliers.isBusy = true;
      this.openHttp("nonconformities_suppliers", {}).then((result) => {
        this.suppliers.items = result;
        this.suppliers.isBusy = false;
      })
    },
    loadProducts(){
      this.products.isBusy = true;
      this.openHttp("nonconformities_products", {}).then((result) => {
        this.products.items = result;
        this.products.isBusy = false;
      })
    },
    loadCauses(){
      this.causes.isBusy = true;
      this.openHttp("nonconformities_causes", {}).then((result) => {
        this.causes.items = result;
        this.causes.isBusy = false;
      })
    },
    loadDefects(){
      this.defects.isBusy = true;
      this.openHttp("nonconformities_defects", {}).then((result) => {
        this.defects.items = result;
        this.defects.isBusy = false;
      })
    },
  }
}
</script>
