<template>
  <div>
    <b-modal v-if="data !== null" v-model="show"  size="lg" title="Pianificazione DPI" scrollable @hidden="closeForm" @close="closeForm">
      <b-row>
        <b-col lg="12">
          <b-form-group label="DPI">
            <b-form-select v-model="data.ppekind.ppetypes_id" :options="data.ppetypes" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6">
          <b-form-group label="Scadenza Unità">
            <b-form-select v-model="data.ppekind.deadlineunit" :options="deadlineCombo" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="6" v-if="data.ppekind.deadlineunit !== null">
          <b-form-group label="Scadenza Numero">
            <b-form-input type="number" min="0" v-model="data.ppekind.deadlinenumber"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Lavoratori">
        <multiselect v-model="employeesSupport" :options="data.employees" placeholder="" selectLabel="" deselectLabel="" :multiple="true" track-by="id" label="val" @input="data.ppekind.employees = updateCombo(employeesSupport)"/>
      </b-form-group>
      <b-form-group label="Mansioni">
        <multiselect v-model="jobsSupport" :options="data.jobs" placeholder="" selectLabel="" deselectLabel="" :multiple="true" track-by="id" label="val" @input="data.ppekind.jobs = updateCombo(jobsSupport)"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn class="mr-2" variant="success" @click="saveData">Salva</b-btn>
          <b-btn class="mr-2" v-if="data.ppekind.id > 0" variant="warning" @click="duplicateData">Duplica</b-btn>
          <b-btn variant="danger" v-show="data.ppekind.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "ppekind",
  data() {
    return {
      show: false,
      data: null,
      employeesSupport: [],
      jobsSupport: [],
      deadlineCombo: [{
        id: null,
        val: "Nessuna",
      },{
        id: "week",
        val: "Settimana/e",
      },{
        id: "month",
        val: "Mese/i",
      },{
        id: "year",
        val: "Anno/i",
      },],
    }
  },
  watch: {
    data: {
      handler( val ) {
        if ( val !== null){
          if ( val.ppekind.deadlineunit !== null && val.ppekind.deadlinenumber < 1) this.data.ppekind.deadlinenumber = 1;
        }
      },
      deep: true
    }
  },
  methods: {
    loadData(id){
      this.openHttp("ppe_kind", { id: id }).then((result) => {
        this.employeesSupport = this.convertCombo(result.employees, result.ppekind.employees);
        this.jobsSupport = this.convertCombo(result.jobs, result.ppekind.jobs);
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("ppe_kind_save", this.data.ppekind, "Pianificazione DPI salvata!" ).then((result) => {
        if ( result.result == true ){
          if ( this.data.ppekind.id === 0 ){
            this.loadData(result.id, null, [], null);
          } else {
            this.closeForm();
          }
        }
      })
    },
    duplicateData(){
      this.data.ppekind.id = 0;
    },
    deleteData(){
      this.deleteHttp("ppe_kind_delete", { id: this.data.ppekind.id }, "Pianificazione DPI eliminata!" ).then((result) => {
       if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('reload');
    }
  }
}
</script>