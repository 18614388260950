<template>
  <div>   
    <b-modal v-if="data !== null" v-model="show" size="xl" title="Audit interno" scrollable @hidden="closeForm()" @close="closeForm()">
      <opportunity ref="opportunity" @reload="loadData(data.audit.id)"/>
      <nonconformity ref="nonconformity" @reload="loadData(data.audit.id)"/>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Copertina" active>
            <b-row>
              <b-col lg="3">
                <b-form-group label="Apertura">
                  <b-form-input type="date" v-model="data.audit.opening"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Chiusura">
                  <b-form-input type="date" v-model="data.audit.closure"/>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group label="Auditor">
                  <b-form-input v-model="data.audit.auditor"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Partecipanti">
              <multiselect v-model="data.audit.employeesSupport" :options="data.employees" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.audit.employees = updateCombo(data.audit.employeesSupport)"/>
            </b-form-group>
            <b-form-group label="Norme">
              <multiselect v-model="data.audit.standardsSupport" :options="data.standards" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.audit.standards = updateCombo(data.audit.standardsSupport)"/>
            </b-form-group>
            <b-form-group label="Processi">
              <multiselect v-model="data.audit.processesSupport" :options="data.processes" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.audit.processes = updateCombo(data.audit.processesSupport)"/>
            </b-form-group>
            <b-form-group label="Note">
              <b-form-textarea v-model="data.audit.note" rows="3"/>
            </b-form-group>
          </b-tab>
          <b-tab title="Checklist" v-if="data.audit.id > 0">
            <auditdocument ref="auditdocument" :documentOptions="data.documents" @update="upadateDocumentSuggestion($event)"/>
            <b-table no-border-collapse responsive :sticky-header="(winHeight - 330) + 'px'" :items="data.audit.anwsers" :fields="fields">
              <template v-slot:cell(question)="row">
                <strong>{{ row.item.points }} - {{ row.item.question }}</strong>
                <div v-if="row.item.note !== null">{{ row.item.note }}</div>
              </template>
              <template v-slot:cell(answer)="row">
                <b-form-input :disabled="data.audit.closure !== null" v-if="row.item.questiontype === 'text'" v-model="row.item.answer" @change="saveAnswer($event, row.item.id)"/>
                <b-form-textarea :disabled="data.audit.closure !== null" v-if="row.item.questiontype === 'area'" v-model="row.item.answer" rows="4" @change="saveAnswer($event, row.item.id)"/>
                <b-form-input :disabled="data.audit.closure !== null" type="date" v-if="row.item.questiontype === 'date'" v-model="row.item.answer" @change="saveAnswer($event, row.item.id)"/>
                <b-form-select :disabled="data.audit.closure !== null" v-if="row.item.questiontype === 'bool'" v-model="row.item.answer" :options="boolCombo" value-field="id" text-field="val" @change="saveAnswer($event, row.item.id)"/>
                <b-input-group :disabled="data.audit.closure !== null" v-if="row.item.questiontype === 'document'">
                <b-form-input  :disabled="data.audit.closure !== null" v-model="row.item.answer" @change="saveAnswer($event, row.item.id)"/>
                  <template v-slot:append>
                    <b-btn variant="secondary" :disabled="data.audit.closure !== null" @click="openDocumentSuggestion(row.item.id, row.item.answer)"><b-icon icon="three-dots"/></b-btn>
                  </template>
                </b-input-group>
                <b-btn class="mt-2" v-if="row.item.notapplicable && row.item.answer !== 'Non Applicabile'" :disabled="data.audit.closure !== null" variant="warning" @click=" row.item.answer = 'Non Applicabile'; saveAnswer('Non Applicabile', row.item.id )">Non applicabile</b-btn>
                <b-button-group v-if="row.item.questiontype === 'int'" size="sm">
                  <b-button :variant="row.item.answer === '1' ? 'success' : 'light'" @click="saveAnswer(1, row.item.id)"><nobr>Conforme</nobr></b-button>
                  <b-button :variant="row.item.answer === '2' ? 'danger' : 'light'" @click="saveAnswer(2, row.item.id)"><nobr>Non conforme</nobr></b-button>
                  <b-button :variant="row.item.answer === '3' ? 'info' : 'light'" @click="saveAnswer(3, row.item.id)"><nobr>Non applicabile</nobr></b-button>
                </b-button-group>
              </template>
              <template v-slot:cell(opportunities)="row">
                <b-btn class="mr-2 mb-2" size="sm" v-for="(item, index) in row.value" :key="index" variant="warning" @click="loadItemOpportunity( item.id, row.item.id)"><nobr>Opp. {{ item.progressive }}</nobr></b-btn>
                <b-btn class="mr-2 mb-2" size="sm" variant="primary" @click="loadItemOpportunity( 0, row.item.id)" :disabled="data.audit.closure !== null">Nuova</b-btn>
              </template>
              <template v-slot:cell(nonconformities)="row">
                <b-btn class="mr-2 mb-2" size="sm" v-for="(item, index) in row.value" :key="index" variant="danger" @click="loadItemNonconformity( item.id, row.item.id)"><nobr>NC. {{ item.progressive }}</nobr></b-btn>
                <b-btn class="mr-2 mb-2" size="sm" variant="primary" @click="loadItemNonconformity( 0, row.item.id)" :disabled="data.audit.closure !== null">Nuova</b-btn>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.audit.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import nonconformity from "../nonconformities/nonconformity.vue";
import opportunity from "../nonconformities/opportunity.vue";
import auditdocument from "./auditdocument.vue";

export default {
  name: "audit",
  components: { auditdocument, nonconformity, opportunity },
  data() {
    return {
      show: false,
      data: null,
      boolCombo: [{
        id: null,
        val: "Compilare ..."
      },{
        id: true,
        val: "Si"
      },{
        id: false,
        val: "No"
      }],
      fields: [{
        key: "question",
        label: "Quesito"
      },{
        key: "answer",
        label: "Esito"
      },{
        key: 'opportunities',
        label: "Opportunità"
      },{
        key: 'nonconformities',
        label: "Non-conformità"
      },]
    }  
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    loadData( id ){
      this.openHttp("audits_audit", { id: id } ).then((result) => {
        result.audit.employeesSupport = this.convertCombo(result.employees, result.audit.employees);
        result.audit.processesSupport = this.convertCombo(result.processes, result.audit.processes);
        result.audit.standardsSupport = this.convertCombo(result.standards, result.audit.standards);
        this.data = result;
        this.show = true;
      })
    },
    loadItemOpportunity(id, auditsanwersId){
      this.$refs.opportunity.loadData(id, auditsanwersId);
    },
    loadItemNonconformity(id, auditsanwersId){
      this.$refs.nonconformity.loadData(id, auditsanwersId);
    },
    openDocumentSuggestion(id, document){
      this.$refs.auditdocument.loadData(id, document);
    },
    upadateDocumentSuggestion(event){
      this.data.audit.anwsers.find(x => x.id === event.questionIid).answer = event.document;
      this.saveHttp("audits_answer_save", { auditsreports_id: this.data.audit.id, auditsanwers_id: this.data.audit.anwsers.find(x => x.id === event.questionIid).id, answer: this.data.audit.anwsers.find(x => x.id === event.questionIid).answer }, "Risposta salvata!" ).then((result) => {
        if ( result.result == true ) this.loadData(this.data.audit.id);
      })
    },
    saveAnswer(answer, auditsanwersId){
      this.saveHttp("audits_answer_save", { auditsreports_id: this.data.audit.id, auditsanwers_id: auditsanwersId, answer: answer }, "Risposta salvata!" ).then((result) => {
        if ( result.result == true ) this.loadData(this.data.audit.id);
      })
    },
    saveData(){
      this.saveHttp("audits_audit_save", this.data.audit, "Audit interno salvato!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("audits_audit_delete", { id: this.data.audit.id }, "Audit interno eliminato!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.data = null;
      this.show = false;
      this.$emit('reload');
    }
  }
}
</script>
