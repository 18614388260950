<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" title="Domanda" size="lg" scrollable @hidden="closeForm" @close="closeForm">
      <b-form-group label="Domande">
        <b-form-textarea v-model="data.question.question"/>
      </b-form-group>
      <b-form-group label="Nota">
        <b-form-textarea v-model="data.question.note" rows="3"/>
      </b-form-group>
      <b-row>
        <b-col lg="2">
          <b-form-group label="Ordine">
            <b-form-input type="number" v-model="data.question.orderquest"/>
          </b-form-group>          
        </b-col>
        <b-col lg="2">
          <b-form-group label="Nascondi">
            <b-form-checkbox v-model="data.question.hide"/>
          </b-form-group>          
        </b-col>
        <b-col lg="2">
          <b-form-group label="Non applicabile">
            <b-form-checkbox v-model="data.question.notapplicable"/>
          </b-form-group>          
        </b-col>
        <b-col lg="2">
          <b-form-group label="Tipologia">
            <b-form-select v-model="data.question.questiontype" :options="typeCombo" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Punti norma">
        <multiselect v-model="data.question.pointsSupport" :options="data.points" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.question.points = updateCombo(data.question.pointsSupport)"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="warning" class="mr-2" v-show="data.question.id > 0" @click="data.question.id = 0">Duplica</b-btn>
          <b-btn variant="danger" v-show="data.question.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "templateaudit",
  data() {
    return {
      show: false,
      data: null,
      typeCombo: [{
        id: "area",
        val: "Evidenza - Area"
      },{
        id: "text",
        val: "Evidenza - Testo"
      },{
        id: "document",
        val: "Documento"
      },{
        id: "date",
        val: "Data"
      },{
        id: "bool",
        val: "Si/No"
      },{
        id: "int",
        val: "Conforme/Non Conforme/Non Applicabile"
      }]
    }  
  },
  methods: {
    loadData( id ) {
      this.openHttp("templates_auditquestion", { id: id }).then((result) => {
        result.question.pointsSupport = this.convertCombo(result.points, result.question.points);
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("templates_auditquestion_save", this.data.question, "Domanda salvata!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("templates_auditquestion_delete", { id: this.data.question.id }, "Domanda eliminata!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('reload');
    }
  }
}
</script>