<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" title="Prodotto venduto" @close="closeForm()">
      <b-form-group label="Prodotto / codice / identificativo">
        <b-form-input v-model="data.product.product"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.product.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "customerproduct",
  data() {
    return {
      show: false,
      data: null,
    }
  },
  methods: {
    loadData( id ){
      this.openHttp("customers_product", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("customers_product_save", this.data.product, "Prodotto cliente salvato!" ).then((result) => {
        if ( result.result == true ) this.closeForm(true);
      })
    },
    deleteData(){
      this.deleteHttp("customers_product_delete", { id: this.data.product.id }, "Prodotto cliente eliminato!" ).then((result) => {
        if ( result.result == true ) this.closeForm(true);
      })
    },
    closeForm( reload = false ){
      this.show = false;
      this.data = null;
      if ( reload === true ) this.$emit('reload');
    }
  }
}
</script>