<template>
  <div>
    <b-modal v-model="show" title="Login" no-close-on-esc no-close-on-backdrop hide-header-close hide-footer>
      <b-form ref="form">
        <b-form-group label="Username">
          <b-form-input :class="{ 'is-invalid': submitted && !usr }" v-model="usr" required/>
        </b-form-group>
        <b-form-group label="Password">
          <b-form-input type="password" :class="{ 'is-invalid': submitted && !psw }"  v-model="psw" required/>
        </b-form-group>
        <b-btn variant="primary" @click="login">Login</b-btn>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: true,
      submitted: false,
      usr: "",
      psw: ""
    }
  },
  mounted() {
    this.$store.commit( 'unSetToken' );
  },
  methods: {
    login(){
      this.submitted = true;
      this.openHttp("login_login", { usr: this.usr, psw: this.psw }).then((result) => {
        if (result.token != null) {
          this.$store.commit('setToken', result.token);
          if (result.form == "home"){
            this.openHttp("routes_check", { usr: this.usr, psw: this.psw }).then((result2) => {
              if ( result2.result === true){
                this.$store.commit('setRoutes', result2.allowed);
                this.$store.state.routes = result2.allowed;
                this.$nextTick(() => {
                  this.$router.push("home");
                })
              }
            })
          } else {
            if (this.$route.name != result.form) this.$router.push(result.form);
          }
        } else {
          this.psw = null;
        }
      })
    }
  }
}
</script>
