<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" title="Attestato" scrollable @hidden="closeForm" @close="closeForm">
      <b-form-group label="Attestato">
        <b-form-input v-model="data.certificate.competence" required/>
      </b-form-group>
      <b-form-group label="Mansioni associate">
        <multiselect v-model="data.certificate.jobdescriptionsSupport" :options="data.jobdescriptions" placeholder="" selectLabel="" deselectLabel="" :multiple="true" track-by="id" label="val" @input="data.certificate.jobdescriptions = updateCombo(data.certificate.jobdescriptionsSupport)"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-if="data.certificate.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "certificate",
  data() {
    return {
      show: false,
      data: null,
    }
  },
  methods: {
    loadData( id ){
      this.openHttp("jobs_certificate", { id: id }).then((result) => {
        result.certificate.jobdescriptionsSupport = this.convertCombo(result.jobdescriptions, result.certificate.jobdescriptions);
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("jobs_certificate_save", this.data.certificate, "Attestato salvato!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("jobs_certificate_delete", { id: this.data.certificate.id }, "Attestato eliminato!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('reload');
    }
  }
}
</script>