<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" title="Criterio di valutazione fornitori" @hidden="closeForm" @close="closeForm">
      <b-form-group label="Criterio">
        <b-form-input v-model="data.question.question"/>
      </b-form-group>
      <b-form-group label="Importanza (1 - 10)">
        <b-form-input type="number" v-model="data.question.weight" min="1" max="10"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.question.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "supplierquestion",
  data() {
    return {
      show: false,
      data: null,
    }
  },
  methods: {
    loadData( id ){
      this.openHttp("suppliers_question", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("suppliers_question_save", this.data.question, "Criterio di valutazione fornitori salvato!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("suppliers_question_delete", { id: this.data.question.id }, "Criterio di valutazione fornitori eliminato!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('reload');
    }
  }
}
</script>