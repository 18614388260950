<template>
  <div>
    <medical ref="medical" @reload="loadData"/>
    <doctoken ref="doctoken"/>
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Riepilogo Visite mediche" active>
          <b-btn class="mr-2" variant="primary" @click="loadDataDeadlines">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="info" v-if="document != null && document.records_id > 0" @click="$refs.doctoken.downloadRecord(document.records_id)">Scarica Documento {{ document.date }}</b-btn>
          <b-btn variant="success" @click="generateDocument()">Genera Documento</b-btn>
          <b-table bordered hover responsive show-empty :sticky-header="(winHeight - 265) + 'px'" class="mt-3" :busy="deadlines.isBusy" :items="deadlines.items" :fields="deadlines.fields">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
            <template v-slot:cell(val)="row">
              <nobr>{{ row.value }}</nobr>
            </template>
            <template v-slot:cell(expired)="row">
              <b-button variant="danger" v-if="row.value.id != null" size="sm" class="mr-2 mb-2" @click="loadItemMedical(0, row.item.id, row.value.id, row.value.date)">
                {{row.value.val.join(', ') }}
              </b-button>
            </template>
            <template v-slot:cell(month0)="row">
              <b-button variant="warning" v-if="row.value.id != null" size="sm" class="mr-2 mb-2" @click="loadItemMedical(0, row.item.id, row.value.id, row.value.date)">
                {{row.value.val.join(', ') }}
              </b-button>
            </template>
            <template v-slot:cell(month1)="row">
              <b-button variant="warning" v-if="row.value.id != null" size="sm" class="mr-2 mb-2" @click="loadItemMedical(0, row.item.id, row.value.id, row.value.date)">
                {{row.value.val.join(', ') }}
              </b-button>
            </template>
            <template v-slot:cell(month2)="row">
              <b-button variant="success" v-if="row.value.id != null" size="sm" class="mr-2 mb-2" @click="loadItemMedical(0, row.item.id, row.value.id, row.value.date)">
                {{row.value.val.join(', ') }}
              </b-button>
            </template>
            <template v-slot:cell(month3)="row">
              <b-button variant="success" v-if="row.value.id != null" size="sm" class="mr-2 mb-2" @click="loadItemMedical(0, row.item.id, row.value.id, row.value.date)">
                {{row.value.val.join(', ') }}
              </b-button>
            </template>
            <template v-slot:cell(month4)="row">
              <b-button variant="success" v-if="row.value.id != null" size="sm" class="mr-2 mb-2" @click="loadItemMedical(0, row.item.id, row.value.id, row.value.date)">
                {{row.value.val.join(', ') }}
              </b-button>
            </template>
            <template v-slot:cell(month5)="row">
              <b-button variant="success" v-if="row.value.id != null" size="sm" class="mr-2 mb-2" @click="loadItemMedical(0, row.item.id, row.value.id, row.value.date)">
                {{row.value.val.join(', ') }}
              </b-button>
            </template>
            <template v-slot:cell(month6)="row">
              <b-button variant="success" v-if="row.value.id != null" size="sm" class="mr-2 mb-2" @click="loadItemMedical(0, row.item.id, row.value.id, row.value.date)">
                {{row.value.val.join(', ') }}
              </b-button>
            </template>
            <template v-slot:cell(month7)="row">
              <b-button variant="success" v-if="row.value.id != null" size="sm" class="mr-2 mb-2" @click="loadItemMedical(0, row.item.id, row.value.id, row.value.date)">
                {{row.value.val.join(', ') }}
              </b-button>
            </template>
            <template v-slot:cell(month8)="row">
              <b-button variant="success" v-if="row.value.id != null" size="sm" class="mr-2 mb-2" @click="loadItemMedical(0, row.item.id, row.value.id, row.value.date)">
                {{row.value.val.join(', ') }}
              </b-button>
            </template>
            <template v-slot:cell(month9)="row">
              <b-button variant="success" v-if="row.value.id != null" size="sm" class="mr-2 mb-2" @click="loadItemMedical(0, row.item.id, row.value.id, row.value.date)">
                {{row.value.val.join(', ') }}
              </b-button>
            </template>
            <template v-slot:cell(month10)="row">
              <b-button variant="success" v-if="row.value.id != null" size="sm" class="mr-2 mb-2" @click="loadItemMedical(0, row.item.id, row.value.id, row.value.date)">
                {{row.value.val.join(', ') }}
              </b-button>
            </template>
            <template v-slot:cell(month11)="row">
              <b-button variant="success" v-if="row.value.id != null" size="sm" class="mr-2 mb-2" @click="loadItemMedical(0, row.item.id, row.value.id, row.value.date)">
                {{row.value.val.join(', ') }}
              </b-button>
            </template>
            <template v-slot:cell(month12)="row">
              <b-button variant="success" v-if="row.value.id != null" size="sm" class="mr-2 mb-2" @click="loadItemMedical(0, row.item.id, row.value.id, row.value.date)">
                {{row.value.val.join(', ') }}
              </b-button>
            </template>
          </b-table>
        </b-tab>
        <b-tab title="Elenco">
          <b-modal id="modalFiltersMedicals" title="Filtra" @ok="filterSetMedicals">
            <b-form-group label="Tipo di corso">
              <b-form-select v-model="medicals.tempFilter.trainingskinds_id" :options="medicals.filtersMenu.trainingskinds" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Personale">
              <b-form-select v-model="medicals.tempFilter.employees_id" :options="medicals.filtersMenu.employees" value-field="id" text-field="val"/>
            </b-form-group>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadDataMedicals">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="warning" @click="filterResetMedicals">Reset filtri</b-btn>
          <b-btn class="mr-2" variant="secondary" v-b-modal.modalFiltersMedicals @click="filterOpenMedicals">Filtra</b-btn>
          <b-btn class="mr-2" variant="success" @click="loadItemMedical(0, null, [], null)">Nuovo</b-btn>
          <b-btn class="mr-2" variant="info" v-if="document != null && document.records_id > 0" @click="$refs.doctoken.downloadRecord(document.records_id)">Scarica Documento {{ document.date }}</b-btn>
          <b-btn variant="success" @click="generateDocument()">Genera Documento</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="medicals.items.data" :fields="medicals.fields" :busy="medicals.isBusy" :no-local-sorting="true" :sort-by.sync="medicals.search.sort.by" :sort-desc.sync="medicals.search.sort.desc" @sort-changed="sortingChangeMedicals" @row-clicked="loadItemMedical($event.id, null, null, null)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
          <tablebottom :perpage="medicals.search.page.perpage" @updatePerPage="medicals.search.page.perpage = $event" :currentpage="medicals.search.page.currentpage" @updateCurrentPage="medicals.search.page.currentpage = $event" :total="medicals.items.total" @loadData="loadDataMedicals"/>
        </b-tab>
        <b-tab title="Pianificazione">
          <medicalskind ref="medicalskind" @reload="loadData"/>
          <b-btn class="mr-2" variant="primary" @click="loadDataMedicalsKinds">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="success" @click="$refs.medicalskind.loadData(0)">Nuovo</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 265) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="medicalsKinds.items" :fields="medicalsKinds.fields" :busy="medicalsKinds.isBusy" @row-clicked="$refs.medicalskind.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
            <template v-slot:cell(trainingskind)="row">
              <nobr>{{ row.value }}</nobr>
            </template>
          </b-table>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import medical from './medical.vue'
import medicalskind from './medicalskind.vue'

export default {
  name: "medicals",
  components: { medical, medicalskind },
  data() {
    return {
      // 1st tab
      deadlines: {
        fields: [{
          key: 'val',
          label: 'Corso',
          sortable: true,
        },{
          key: 'mindate',
          label: 'Prossima',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'expired',
          label: "Scadute",
        },{
          key: 'month0',
          label: "",
        },{
          key: 'month1',
          label: "",
        },{
          key: 'month2',
          label: "",
        },{
          key: 'month3',
          label: "",
        },{
          key: 'month4',
          label: "",
        },{
          key: 'month5',
          label: "",
        },{
          key: 'month6',
          label: "",
        },{
          key: 'month7',
          label: "",
        },{
          key: 'month8',
          label: "",
        },{
          key: 'month9',
          label: "",
        },{
          key: 'month10',
          label: "",
        },{
          key: 'month11',
          label: "",
        },{
          key: 'month12',
          label: "",
        }],
        isBusy: true,
        items: [],
      },
      // 2nd tab
      medicals: {
        fields: [{
          key: 'trainingskind',
          label: 'Visita medica',
          sortable: true,
        },{
          key: 'employees',
          label: "Lavoratori",
          sortable: false,
          formatter: value => {
            return value.join(", ");
          },
        },{
          key: 'verifydate',
          label: 'Data',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        }],
        filtersMenu: {
          trainingskinds: [],
          employees: [],
        },
        isBusy: true,
        items: {
          total: 0,
          data: [],
        },
        search: {
          page: {
            currentpage: 1,
            perpage: 50,
          },
          filters: {
            trainingskinds_id: null,
            employees_id: null,            
          },
          sort: {
            by: 'trainingskind',
            desc: false,
          },
        },
        tempFilter: {
          trainingskinds_id: null,
          employees_id: null,
        },
      },
      // 3rd tab
      medicalsKinds: {
        fields: [{
          key: 'trainingskind',
          label: "Visita medica",
        },{
          key: 'employees',
          label: "Lavoratori",
        }],
        isBusy: true,
        items: [],
      },
    }  
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    }
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.loadDataDeadlines();
      this.loadDataMedicals();
      this.loadDataMedicalsKinds();
      this.loadDocument();
    },
    // 1st tab
    loadDataDeadlines(){
      let i;
      let today = new Date();
      for (i = 0; i < 12; i++) {
        this.deadlines.fields[i + 3].label = [today.getMonth() + 1, today.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        today.setMonth( today.getMonth() + 1);
      }
      this.openHttp("medicals_deadlines1", {}).then((result) => {
        this.deadlines.items = result;
        this.deadlines.isBusy = false;
      })
    },
    loadDocument(){
      this.openHttp("medicals_document", null).then((result) => {
        this.document = result;
      })
    },
    generateDocument(){
      this.generateDocumentHttp("medicals");
    },
    loadItemMedical(id, trainingsKindsId, employees, date){
      this.$refs.medical.loadData(id, trainingsKindsId, employees, date);
    },
    excelOpenDeadlines(){
      this.$refs.excelFormDeadlines.submit()
    },
    // 2nd tab
    loadDataMedicals(){
      this.medicals.isBusy = true;
      this.openHttp("medicals_medicals", {search: this.medicals.search}).then((result) => {
        this.medicals.items = result;
        this.medicals.isBusy = false;
      })
    },
    filterOpenMedicals(){
      this.openHttp("medicals_filters", { }).then((result) => {
        this.medicals.filtersMenu = result;
        this.medicals.showfilter = true;
      })
    },
    filterSetMedicals(){
      this.medicals.search.filters = this.medicals.tempFilter;
      this.medicals.showfilter = false;
      this.loadDataMedicals();
    },
    filterResetMedicals(){
      this.medicals.tempFilter = {
        trainingskinds_id: null,
        employees_id: null,
      };
      this.filterSetMedicals();
    },
    sortingChangeMedicals(ctx) {
      this.medicals.search.sort.by = ctx.sortBy;
      this.medicals.search.sort.desc = ctx.sortDesc;
      this.loadDataMedicals();
    },
    excelOpenMedicals(){
      this.$refs.excelFormMedicals.submit();
    },
    // 3rd tab
    loadDataMedicalsKinds(){
      this.medicalsKinds.isBusy = true;
      this.openHttp("medicals_trainingskinds", { }).then((result) => {
        this.medicalsKinds.items = result;
        this.medicalsKinds.isBusy = false;
      })
    }
  }
}
</script>