<template>
  <div>
    <b-modal v-model="show" v-if="data !== null" size="lg" title="Pianificazione di emergenza" scrollable @hidden="closeForm()" @close="closeForm()">
      <b-row>
        <b-col lg="12">
          <b-form-group label="Tipologia">
            <b-form-select v-model="data.type.emergenciestypes_id" :options="data.emergenciestypes" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="10">
          <b-form-group label="Sito">
            <b-form-select v-model="data.type.sites_id" :options="data.sites" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Frequenza">
            <b-form-select v-model="data.type.frequencymonth" :options="frequencyCombo" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <b-form-group label="Personale coinvolto">
            <multiselect v-model="data.type.employeesSupport" :options="data.employees" placeholder="" selectLabel="" deselectLabel="" :multiple="true" track-by="id" label="val" @input="data.type.employees = updateCombo(data.type.employeesSupport);"/>
          </b-form-group>
        </b-col>
      </b-row>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.type.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "emergencytype",
  data() {
    return {
      show: false,
      data: null,
      frequencyCombo: [{
        id: 1,
        val: "Mensile"
      },{
        id: 2,
        val: "Bimensile"
      },{
        id: 3,
        val: "Trimestrale"
      },{
        id: 4,
        val: "Quadrimestrale"
      },{
        id: 6,
        val: "Semestrale"
      },{
        id: 12,
        val: "Annuale"
      },{
        id: 24,
        val: "Biennale"
      },{
        id: 36,
        val: "Triennale"
      },{
        id: 48,
        val: "Quadriennale"
      },{
        id: 60,
        val: "Quinquennale"
      }]
    }
  },
  methods: {
    loadData( id ){
      this.openHttp("emergencies_type", { id: id }).then((result) => {
        result.type.employeesSupport = this.convertCombo(result.employees, result.type.employees);
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("emergencies_type_save", this.data.type, "Pianificazione emergenza salvata!" ).then((result) => {
        if ( result.result == true ) this.closeForm( true );
      })
    },
    deleteData(){
      this.deleteHttp("emergencies_type_delete", { id: this.data.type.id }, "Pianificazione emergenza eliminata!" ).then((result) => {
        if ( result.result == true ) this.closeForm( true );
      })
    },
    closeForm( reload = false ){
      this.show = false;
      this.data = null;
      if ( reload === true ) this.$emit('reload');
    }
  }
}
</script>