<template>
  <div>
    <auth ref="auth" @reload="loadData"/>
    <b-btn class="mr-2" variant="primary" @click="loadData">Aggiorna</b-btn>
    <b-btn variant="success" @click="$refs.auth.loadData(0)">Nuovo</b-btn>
    <b-table no-border-collapse :sticky-header="(winHeight - 205) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="items.data" :fields="fields" :busy="isBusy" :no-local-sorting="true" :sort-by.sync="search.sort.by" :sort-desc.sync="search.sort.desc" @sort-changed="sortingChange" @row-clicked="$refs.auth.loadData($event.id)">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>Non ci sono risultati</template>
    </b-table>
    <tablebottom :perpage="search.page.perpage" @updatePerPage="search.page.perpage = $event" :currentpage="search.page.currentpage" @updateCurrentPage="search.page.currentpage = $event" :total="items.total" @loadData="loadData"/>
  </div>
</template>

<script>
import auth from './auth.vue'

export default {
  name: "auths",
  components: { auth },
  data() {
    return {
      isBusy: true,
      search:{
        page: {
          currentpage: 1,
          perpage: 50,
        },
        filters: {},
        sort: {
          by: 'isadmin',
          desc: true,
        },
      },
      fields: [{
        key: 'isadmin',
        label: 'Tipologia',
        sortable: true,
        formatter: value => {
          return value == true ? 'Amministratore' : 'Utente';
        }
      },{
        key: 'employee',
        label: "Nome completo",
        sortable: true
      },{
        key: 'mail',
        label: 'Mail',
        sortable: true
      }],
      items: {
        total: 0,
        data: [],
      }
    }  
  },
  computed: {
    token (){
      return this.$store.getters.getToken;
    },
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.isBusy = true;
      this.openHttp("auths_auths", this.search).then((result) => {
        this.items = result;
        this.isBusy = false;
      })
    },
    sortingChange(ctx) {
      this.search.sort.by = ctx.sortBy;
      this.search.sort.desc = ctx.sortDesc;
      this.loadData();
    }
  }
}
</script>