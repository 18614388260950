<template>
  <div>
    <chemical ref="chemical" @reload="loadData"/>
    <b-modal v-model="showFilter" title="Filtra" @ok="filterSet">
      <b-form-group label="Fase">
        <b-form-input v-model="tempFilter.phase" />
      </b-form-group>
      <b-form-group label="Processi">
        <b-form-select v-model="tempFilter.processes_id" :options="comboSeeAll(filtersMenu.processes)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Norme">
        <b-form-select v-model="tempFilter.standards_id" :options="comboSeeAll(filtersMenu.standards)" value-field="id" text-field="val"/>
      </b-form-group>
    </b-modal>
    <b-btn class="mr-2" variant="primary" @click="loadData">Aggiorna</b-btn>
    <b-btn class="mr-2" variant="warning" @click="filterReset">Reset filtri</b-btn>
    <b-btn class="mr-2" variant="secondary" @click="filterOpen">Filtra</b-btn>
    <b-btn class="mr-2" variant="success" @click="loadItem(0)">Nuovo</b-btn>
    <b-btn variant="info" @click="$refs.doctoken.downloadData(48)">Scarica</b-btn><doctoken ref="doctoken"/>
    <b-table no-border-collapse :sticky-header="(winHeight - 205) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="items.data" :fields="fields" :busy="isBusy" :no-local-sorting="true" :sort-by.sync="search.sort.by" :sort-desc.sync="search.sort.desc" @sort-changed="sortingChange" @row-clicked="loadItem($event.id)">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>Non ci sono risultati</template>
    </b-table>
    <tablebottom :perpage="search.page.perpage" @updatePerPage="search.page.perpage = $event" :currentpage="search.page.currentpage" @updateCurrentPage="search.page.currentpage = $event" :total="items.total" @loadData="loadData"/>
  </div>
</template>

<script>
import chemical from './chemical.vue'

export default {
  name: "chemicals",
  components: { chemical },
  data() {
    return {
      isBusy: true,
      showFilter: false,
      search:{
        page: {
          currentpage: 1,
          perpage: 50,
        },
        filters: {},
        sort: {
          by: 'productname',
          desc: false,
        },
      },
      filtersMenu: {
        processes: [],
        standards: [],
      },
      tempFilter: {
        phase: null,
        processes_id: null,
        standards_id: null,
      },
      fields: [{
        key: "productname",
        label: "Prodotto",
        sortable: true
      },{
        key: "typenote",
        label: "Note",
      },{
        key: "codes",
        label: "Codici",
      },{
        key: 'sitesdepartments',
        label: "Siti ed aree",
      },{
        key: "quantity",
        label: 'Quantità (Kg)',
        sortable: true
      }],
      items: {
        total: 0,
        data: [],
      },
    }  
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.isBusy = true;
      this.openHttp("chemicals_chemicals", { search: this.search }).then((result) => {
        this.isBusy = false;
        this.items = result;
      })
    },
    filterOpen(){
      this.openHttp("chemicals_filters", { }).then((result) => {
        this.filtersMenu = result;
        this.showFilter = true;
      })
    },
    filterSet(){
      this.showFilter = false;
      this.search.filters = this.tempFilter;
      this.loadData();
    },
    filterReset(){
      this.tempFilter = {
        phase: null,
        processes_id: null,
        standards_id: null,
      };
      this.search.filters = this.tempFilter;
      this.loadData();
    },
    sortingChange(ctx) {
      this.search.sort.by = ctx.sortBy;
      this.search.sort.desc = ctx.sortDesc;
      this.loadData();
    },
    loadItem(id){
      this.$refs.chemical.loadData(id);
    }
  }
}
</script>