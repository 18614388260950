<template>
  <div>
    <doctoken ref="doctoken"/>
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Piano/scandenziario corsi" active>
          <safetytraining ref="safetytraining" @reload="loadData"/>
          <b-btn class="mr-2" variant="primary" @click="loadDataDeadlines1">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="info" v-if="document != null && document.records_id > 0" @click="$refs.doctoken.downloadRecord(document.records_id)">Scarica Documento {{ document.date }}</b-btn>
          <b-btn variant="success" @click="generateDocument()">Genera Documento</b-btn>
          <doctoken ref="doctoken"/>
          <b-table bordered hover responsive show-empty :sticky-header="(winHeight - 265) + 'px'" class="mt-3" :busy="tableDeadlines1.isBusy" :items="tableDeadlines1.items" :fields="tableDeadlines1.fields">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
            <template v-slot:cell(val)="row">
              <nobr>{{ row.value }}</nobr>
            </template>
            <template v-slot:cell(expired)="row">
              <b-button variant="danger" v-if="row.value.id != null" size="sm" class="mr-2 mb-2" @click="loadSafetytraining(0, row.item.id, row.value.id, row.value.date)">{{row.value.val.join(', ') }}</b-button>
            </template>
            <template v-slot:cell(month0)="row">
              <b-button variant="warning" v-if="row.value.id != null" size="sm" class="mr-2 mb-2" @click="loadSafetytraining(0, row.item.id, row.value.id, row.value.date)">{{row.value.val.join(', ') }}</b-button>
            </template>
            <template v-slot:cell(month1)="row">
              <b-button variant="warning" v-if="row.value.id != null" size="sm" class="mr-2 mb-2" @click="loadSafetytraining(0, row.item.id, row.value.id, row.value.date)">{{row.value.val.join(', ') }}</b-button>
            </template>
            <template v-slot:cell(month2)="row">
              <b-button variant="success" v-if="row.value.id != null" size="sm" class="mr-2 mb-2" @click="loadSafetytraining(0, row.item.id, row.value.id, row.value.date)">{{row.value.val.join(', ') }}</b-button>
            </template>
            <template v-slot:cell(month3)="row">
              <b-button variant="success" v-if="row.value.id != null" size="sm" class="mr-2 mb-2" @click="loadSafetytraining(0, row.item.id, row.value.id, row.value.date)">{{row.value.val.join(', ') }}</b-button>
            </template>
            <template v-slot:cell(month4)="row">
              <b-button variant="success" v-if="row.value.id != null" size="sm" class="mr-2 mb-2" @click="loadSafetytraining(0, row.item.id, row.value.id, row.value.date)">{{row.value.val.join(', ') }}</b-button>
            </template>
            <template v-slot:cell(month5)="row">
              <b-button variant="success" v-if="row.value.id != null" size="sm" class="mr-2 mb-2" @click="loadSafetytraining(0, row.item.id, row.value.id, row.value.date)">{{row.value.val.join(', ') }}</b-button>
            </template>
            <template v-slot:cell(month6)="row">
              <b-button variant="success" v-if="row.value.id != null" size="sm" class="mr-2 mb-2" @click="loadSafetytraining(0, row.item.id, row.value.id, row.value.date)">{{row.value.val.join(', ') }}</b-button>
            </template>
            <template v-slot:cell(month7)="row">
              <b-button variant="success" v-if="row.value.id != null" size="sm" class="mr-2 mb-2" @click="loadSafetytraining(0, row.item.id, row.value.id, row.value.date)">{{row.value.val.join(', ') }}</b-button>
            </template>
            <template v-slot:cell(month8)="row">
              <b-button variant="success" v-if="row.value.id != null" size="sm" class="mr-2 mb-2" @click="loadSafetytraining(0, row.item.id, row.value.id, row.value.date)">{{row.value.val.join(', ') }}</b-button>
            </template>
            <template v-slot:cell(month9)="row">
              <b-button variant="success" v-if="row.value.id != null" size="sm" class="mr-2 mb-2" @click="loadSafetytraining(0, row.item.id, row.value.id, row.value.date)">{{row.value.val.join(', ') }}</b-button>
            </template>
            <template v-slot:cell(month10)="row">
              <b-button variant="success" v-if="row.value.id != null" size="sm" class="mr-2 mb-2" @click="loadSafetytraining(0, row.item.id, row.value.id, row.value.date)">{{row.value.val.join(', ') }}</b-button>
            </template>
            <template v-slot:cell(month11)="row">
              <b-button variant="success" v-if="row.value.id != null" size="sm" class="mr-2 mb-2" @click="loadSafetytraining(0, row.item.id, row.value.id, row.value.date)">{{row.value.val.join(', ') }}</b-button>
            </template>
            <template v-slot:cell(month12)="row">
              <b-button variant="success" v-if="row.value.id != null" size="sm" class="mr-2 mb-2" @click="loadSafetytraining(0, row.item.id, row.value.id, row.value.date)">{{row.value.val.join(', ') }}</b-button>
            </template>
          </b-table>
        </b-tab>
        <b-tab title="Piano/scandenziario persone">
          <b-btn class="mr-2" variant="primary" @click="loadDataDeadlines2">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="info" v-if="document != null && document.records_id > 0" @click="$refs.doctoken.downloadRecord(document.records_id)">Scarica Documento {{ document.date }}</b-btn>
          <b-btn variant="success" @click="generateDocument()">Genera Documento</b-btn>
          <b-table bordered hover responsive show-empty :sticky-header="(winHeight - 265) + 'px'" class="mt-3" :busy="tableDeadlines2.isBusy" :items="tableDeadlines2.items" :fields="tableDeadlines2.fields">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
            <template v-slot:cell(val)="row">
              <nobr>{{ row.value }}</nobr>
            </template>
            <template v-slot:cell(expired)="row">
              <b-button v-for="(item, index) of row.value" :key="index" variant="danger" size="sm" class="mr-2 mb-2" @click="loadSafetytraining(0, row.item.id, item.id, item.date)">{{ item.val }}</b-button>
            </template>
            <template v-slot:cell(month0)="row">
              <b-button v-for="(item, index) of row.value" :key="index" variant="warning" size="sm" class="mr-2 mb-2" @click="loadSafetytraining(0, row.item.id, item.id, item.date)">{{ item.val }}</b-button>
            </template>
            <template v-slot:cell(month1)="row">
              <b-button v-for="(item, index) of row.value" :key="index" variant="warning" size="sm" class="mr-2 mb-2" @click="loadSafetytraining(0, row.item.id, item.id, item.date)">{{ item.val }}</b-button>
            </template>
            <template v-slot:cell(month2)="row">
              <b-button v-for="(item, index) of row.value" :key="index" variant="success" size="sm" class="mr-2 mb-2" @click="loadSafetytraining(0, row.item.id, item.id, item.date)">{{ item.val }}</b-button>
            </template>
            <template v-slot:cell(month3)="row">
              <b-button v-for="(item, index) of row.value" :key="index" variant="success" size="sm" class="mr-2 mb-2" @click="loadSafetytraining(0, row.item.id, item.id, item.date)">{{ item.val }}</b-button>
            </template>
            <template v-slot:cell(month4)="row">
              <b-button v-for="(item, index) of row.value" :key="index" variant="success" size="sm" class="mr-2 mb-2" @click="loadSafetytraining(0, row.item.id, item.id, item.date)">{{ item.val }}</b-button>
            </template>
            <template v-slot:cell(month5)="row">
              <b-button v-for="(item, index) of row.value" :key="index" variant="success" size="sm" class="mr-2 mb-2" @click="loadSafetytraining(0, row.item.id, item.id, item.date)">{{ item.val }}</b-button>
            </template>
            <template v-slot:cell(month6)="row">
              <b-button v-for="(item, index) of row.value" :key="index" variant="success" size="sm" class="mr-2 mb-2" @click="loadSafetytraining(0, row.item.id, item.id, item.date)">{{ item.val }}</b-button>
            </template>
            <template v-slot:cell(month7)="row">
              <b-button v-for="(item, index) of row.value" :key="index" variant="success" size="sm" class="mr-2 mb-2" @click="loadSafetytraining(0, row.item.id, item.id, item.date)">{{ item.val }}</b-button>
            </template>
            <template v-slot:cell(month8)="row">
              <b-button v-for="(item, index) of row.value" :key="index" variant="success" size="sm" class="mr-2 mb-2" @click="loadSafetytraining(0, row.item.id, item.id, item.date)">{{ item.val }}</b-button>
            </template>
            <template v-slot:cell(month9)="row">
              <b-button v-for="(item, index) of row.value" :key="index" variant="success" size="sm" class="mr-2 mb-2" @click="loadSafetytraining(0, row.item.id, item.id, item.date)">{{ item.val }}</b-button>
            </template>
            <template v-slot:cell(month10)="row">
              <b-button v-for="(item, index) of row.value" :key="index" variant="success" size="sm" class="mr-2 mb-2" @click="loadSafetytraining(0, row.item.id, item.id, item.date)">{{ item.val }}</b-button>
            </template>
            <template v-slot:cell(month11)="row">
              <b-button v-for="(item, index) of row.value" :key="index" variant="success" size="sm" class="mr-2 mb-2" @click="loadSafetytraining(0, row.item.id, item.id, item.date)">{{ item.val }}</b-button>
            </template>
            <template v-slot:cell(month12)="row">
              <b-button v-for="(item, index) of row.value" :key="index" variant="success" size="sm" class="mr-2 mb-2" @click="loadSafetytraining(0, row.item.id, item.id, item.date)">{{ item.val }}</b-button>
            </template>
          </b-table>
        </b-tab>
        <b-tab title="Archivio storico">
          <b-modal id="modalFiltersSafety" title="Filtra" @ok="filterSetSafety">
            <b-form-group label="Tipo di corso">
              <b-form-select v-model="tableSafety.tempFilter.trainingskinds_id" :options="comboSeeAll(tableSafety.filtersMenu.trainingskinds)" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Personale">
              <b-form-select v-model="tableSafety.tempFilter.employees_id" :options="comboSeeAll(tableSafety.filtersMenu.employees)" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Anno">
              <b-form-select v-model="tableSafety.tempFilter.year" :options="comboSeeAll(tableSafety.filtersMenu.years)" value-field="id" text-field="val"/>
            </b-form-group>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadDataSafety">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="warning" @click="filterResetSafety">Reset filtri</b-btn>
          <b-btn class="mr-2" variant="secondary" v-b-modal.modalFiltersSafety @click="filterOpenSafety">Filtra</b-btn>
          <b-btn class="mr-2" variant="success" @click="loadSafetytraining(0, null, [], null)">Nuovo</b-btn>
          <b-btn class="mr-2" variant="info" v-if="document != null && document.records_id > 0" @click="$refs.doctoken.downloadRecord(document.records_id)">Scarica Documento {{ document.date }}</b-btn>
          <b-btn variant="success" @click="generateDocument()">Genera Documento</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="tableSafety.items.data" :fields="tableSafety.fields" :busy="tableSafety.isBusy" :no-local-sorting="true" :sort-by.sync="tableSafety.search.sort.by" :sort-desc.sync="tableSafety.search.sort.desc" @sort-changed="sortingChangeSafey" @row-clicked="loadSafetytraining($event.id, null, null, null)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
          <tablebottom :perpage="tableSafety.search.page.perpage" @updatePerPage="tableSafety.search.page.perpage = $event" :currentpage="tableSafety.search.page.currentpage" @updateCurrentPage="tableSafety.search.page.currentpage = $event" :total="tableSafety.items.total" @loadData="loadDataSafety"/>
        </b-tab>
        <b-tab title="Pianificazione">
          <safetytrainingskind ref="safetytrainingskind" @reload="loadData"/>
          <b-btn class="mr-2" variant="primary" @click="loadDataSafetyKinds">Aggiorna</b-btn>
          <b-btn variant="success" @click="$refs.safetytrainingskind.loadData(0)">Nuovo</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 265) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="tableSafetyKinds.items" :fields="tableSafetyKinds.fields" :busy="tableSafetyKinds.isBusy" @row-clicked="$refs.safetytrainingskind.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import safetytraining from './safetytraining.vue'
import safetytrainingskind from './safetytrainingskind.vue'
import doctoken from "../doctoken";

export default {
  name: "safetytrainings",
  components: {
    safetytraining,
    safetytrainingskind,
    doctoken
  },
  data() {
    return {
      // 1st tab
      tableDeadlines1: {
        fields: [{
          key: 'val',
          label: 'Corso',
          sortable: true,
        },{
          key: 'mindate',
          label: 'Prossima',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'expired',
          label: "Scadute",
        },{
          key: 'month0',
          label: "",
        },{
          key: 'month1',
          label: "",
        },{
          key: 'month2',
          label: "",
        },{
          key: 'month3',
          label: "",
        },{
          key: 'month4',
          label: "",
        },{
          key: 'month5',
          label: "",
        },{
          key: 'month6',
          label: "",
        },{
          key: 'month7',
          label: "",
        },{
          key: 'month8',
          label: '',
        },{
          key: 'month9',
          label: "",
        },{
          key: 'month10',
          label: "",
        },{
          key: 'month11',
          label: "",
        },{
          key: 'month12',
          label: "",
        }],
        isBusy: true,
        items: [],
      },
      // 2nd tab
      tableDeadlines2: {
        fields: [{
          key: 'val',
          label: 'Persona',
          sortable: true,
        },{
          key: 'mindate',
          label: 'Prossima',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'expired',
          label: "Scadute",
        },{
          key: 'month0',
          label: '',
        },{
          key: 'month1',
          label: '',
        },{
          key: 'month2',
          label: '',
        },{
          key: 'month3',
          label: '',
        },{
          key: 'month4',
          label: '',
        },{
          key: 'month5',
          label: '',
        },{
          key: 'month6',
          label: '',
        },{
          key: 'month7',
          label: '',
        },{
          key: 'month8',
          label: '',
        },{
          key: 'month9',
          label: '',
        },{
          key: 'month10',
          label: '',
        },{
          key: 'month11',
          label: '',
        },{
          key: 'month12',
          label: '',
        }],
        items: [],
        isBusy: true,
      },
      // 3rd tab
      tableSafety: {
        fields: [{
          key: 'trainingskind',
          label: 'Corso',
          sortable: true,
        },{
          key: 'trainingstype',
          label: "Tipologia",
          sortable: false
        },{
          key: 'employees',
          label: "Partecipanti",
          sortable: false,
          formatter: value => {
            return value.join(", ");
          }
        },{
          key: 'verifydate',
          label: 'Attestato',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        }],
        filtersMenu: {
          trainingskinds: [],
          employees: [],
          years: []
        },
        isBusy: true,
        items: {
          total: 0,
          data: [],
        },
        search: {
          page: { currentpage: 1, perpage: 50, },
          filters: {
            trainingskinds_id: null,
            employees_id: null,
            year: null
          },
          sort: { by: 'trainingskind', desc: false, },
        },
        tempFilter: {
          trainingskinds_id: null,
          employees_id: null,
          year: null
        }
      },
      // 4th tab      
      tableSafetyKinds: {
        fields: [{
          key: 'trainingskind',
          label: "Corso",
        },{
          key: 'periodicity',
          label: "Periodicità",
          formatter: value => {
            return value > 0 ? value + " anni" : "No";
          }
        },{
          key: "employees",
          label: "Lavoratori",
        }],
        isBusy: false,
        items: [],
      },
      document: null
    }  
  },
  computed: {
    token (){
      return this.$store.getters.getToken;
    },
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.loadDataDeadlines1();
      this.loadDataDeadlines2();
      this.loadDataSafety();
      this.loadDataSafetyKinds();
    },
    // 1st tab
    loadDataDeadlines1(){
      let i;
      let today = new Date();
      for (i = 0; i < 12; i++) {
        this.tableDeadlines1.fields[i + 3].label = [today.getMonth() + 1, today.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        today.setMonth( today.getMonth() + 1);
      }
      this.tableDeadlines1.isBusy = true;
      this.openHttp("safetytrainings_deadlines1", {}).then((result) => {
        this.tableDeadlines1.items = result;
        this.tableDeadlines1.isBusy = false;
        this.loadDocument();
      })
    },
    // 2nd tab
    loadDataDeadlines2(){
      let i;
      let today = new Date();
      for (i = 0; i < 12; i++) {
        this.tableDeadlines2.fields[i + 3].label = [today.getMonth() + 1, today.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        today.setMonth( today.getMonth() + 1);
      }
      this.tableDeadlines2.isBusy = true;
      this.openHttp("safetytrainings_deadlines2", {}).then((result) => {
        this.tableDeadlines2.items = result;
        this.tableDeadlines2.isBusy = false;
        this.loadDocument();
      })
    },
    loadSafetytraining( id, employeesId, trainingsKindsId, date ){
      this.$refs.safetytraining.loadData( id, employeesId, trainingsKindsId, date );
    },
    // 3rd tab
    loadDataSafety(){
      this.tableSafety.isBusy = true;
      this.openHttp("safetytrainings_safetytrainings", {search: this.tableSafety.search}).then((result) => {
        this.tableSafety.items = result;
        this.tableSafety.isBusy = false;
      })
    },
    filterOpenSafety(){
      this.openHttp("safetytrainings_filters", { }).then((result) => {
        this.tableSafety.filtersMenu = result;
      })
    },
    filterSetSafety(){
      this.tableSafety.search.filters = this.tableSafety.tempFilter;
      this.loadDataSafety();
    },
    filterResetSafety(){
      this.tableSafety.tempFilter = {
        trainingskinds_id: null,
        employees_id: null,
        year: null
      };
      this.filterSetSafety();
    },
    sortingChangeSafey(ctx) {
      this.tableSafety.search.sort.by = ctx.sortBy;
      this.tableSafety.search.sort.desc = ctx.sortDesc;
      this.loadDataSafety();
    },
    // 4th tab    
    loadDataSafetyKinds(){
      this.tableSafetyKinds.isBusy = true;
      this.openHttp("safetytrainings_trainingskinds", {}).then((result) => {
        this.tableSafetyKinds.items = result;
        this.tableSafetyKinds.isBusy = false;
      })
    },
    loadDocument(){
      this.openHttp("safetytrainings_document", null).then((result) => {
        this.document = result;
      })
    },
    generateDocument(){
      this.generateDocumentHttp("safetytrainings");
    },
  }
}
</script>