<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" title="Indicatore" scrollable @hidden="closeForm()" @close="closeForm()">
      <kpivalue ref="kpivalue" @reload="loadData( data.kpi.id )"/>
      <b-form-group label="Nome indicatore">
        <b-form-input v-model="data.kpi.kpiname"/>
      </b-form-group>
      <b-form-group label="Processo">
        <b-form-select v-model="data.kpi.processes_id" :options="data.processes" value-field="id" text-field="val"/>
      </b-form-group>
      <b-row v-show="data.kpi.id > 0">
        <b-col lg="12">
          <b-btn variant="primary" class="mb-3" @click="loadItem(0)">Nuova registazione</b-btn>
          <b-form-group label="Registrazioni">
            <b-table bordered hover show-empty :items="data.values" :fields="fields" @row-clicked="loadItem($event.id)">
              <template v-slot:empty>Non ci sono registrazioni</template>
            </b-table>
          </b-form-group>
        </b-col>
      </b-row>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.kpi.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import kpivalue from './kpivalue.vue'

export default {
  name: "kpi",
  components: { kpivalue },
  data() {
    return {
      show: false,
      data: null,
      fields: [{
        key: "datekpi",
        label: "Data",
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        },
        sortable: true
      },{
        key: "valuereached",
        label: "Valore",
      },{
        key: "comment",
        label: "Commento",
      },]
    }  
  },
  methods: {
    loadData( id ){
      if ( this.data === null ) this.show = false;
      this.openHttp("kpis_kpi", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("kpis_kpi_save", this.data.kpi, "Indicatore salvato!" ).then((result) => {
        if ( result.result == true ) this.closeForm( true );
      })
    },
    deleteData(){
      this.deleteHttp("kpis_kpi_delete", { id: this.data.kpi.id }, "Indicatore eliminato!" ).then((result) => {
        if ( result.result == true ) this.closeForm( true );
      })
    },
    closeForm( reload = false ){
      this.show = false;
      this.data = null;
      if ( reload === true ) this.$emit('reload');
    },
    loadItem( id ){
      this.$refs.kpivalue.loadData(id, this.data.kpi.id);
    },
  }
}
</script>