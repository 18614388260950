<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" title="Tipologia di scadenza per sito" scrollable @close="closeForm">

      <b-form-group label="Tipologia scadenza">
        <b-form-select v-model="data.typesite.expirationtypes_id" :options="data.expirationtypes" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Sito">
        <b-form-select v-model="data.typesite.sites_id" :options="data.sites" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Applicabile">
        <b-form-select v-model="data.typesite.applicable" :options="applicableCombo" value-field="id" text-field="val"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.typesite.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "expirationtypesite",
  data() {
    return {
      show: false,
      data: null,
      applicableCombo: [{
        id: true,
        val: "Si"
      },{
        id: false,
        val: "No"
      }]
    }
  },
  methods: {
    loadData( id ){
      this.show = false;
      this.openHttp("expirations_typesite", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("expirations_typesite_save", this.data.typesite, "Tipologia e sito salvato!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("expirations_typesite_delete", { id: this.data.typesite.id }, "Tipologia e sito eliminato!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.data = null;
      this.show = false;
      this.$emit('reload');
    }
  }
}
</script>