<template>
  <div>
    <b-modal v-if="data != null" v-model="show" title="Compito non conformità" scrollable @hidden="closeForm" @close="closeForm">
      <b-row>
        <b-col lg="6">
          <b-form-group label="Inizio">
            <b-form-input type="date" v-model="data.task.fromdate"/>
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group label="Scadenza">
            <b-form-input type="date" v-model="data.task.todate"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Personale coinvolto">
        <multiselect v-model="data.task.employeesSupport" :options="data.employees" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.task.employees = updateCombo(data.task.employeesSupport)"/>
      </b-form-group>
      <b-form-group label="Da fare">
        <b-form-textarea v-model="data.task.tododescription"/>
      </b-form-group>
      <b-form-group label="Alert?">
        <b-form-select v-model="data.task.alert" :options="alertTaskCombo" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Evidenza del risultato">
        <b-form-textarea v-model="data.task.donedescription"/>
      </b-form-group>
      <b-form-group label="Concluso?">
        <b-form-select v-model="data.task.done" :options="comboDoneTaskCombo" value-field="id" text-field="val"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.task.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "nonconformitytask",
  data() {
    return {
      show: false,
      data: null,
      alertTaskCombo: [{
        id: false,
        val: "Nessun alert Mail"
      },{
        id: true,
        val: "Alert per Mail"
      }],
      comboDoneTaskCombo: [{
        id: null,
        val: "In corso"
      },{
        id: true,
        val: "Concluso esito positivo"
      },{
        id: false,
        val: "Concluso esito negativo"
      }],
    }
  },
  methods: {
    loadData(id, nonconformities_id ){
      this.openHttp("nonconformities_nonconformitytask", { id: id, nonconformities_id: nonconformities_id }).then((result) => {
        result.task.employeesSupport = this.convertCombo( result.employees, result.task.employees );
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("nonconformities_nonconformitytask_save", this.data.task, "Compito Non conformità salvata!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("nonconformities_nonconformitytask_delete", { id: this.data.task.id }, "Compito Non conformità eliminata!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('reload');
    }
  }
}
</script>