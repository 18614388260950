<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" title="Conoscenza organizzativa" @hidden="closeForm" @close="closeForm">
      <b-row>
        <b-col lg="12">
          <b-form-group label="Conoscenza">
            <b-form-textarea v-model="data.knowledge.knowledge" rows="6"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6">
          <b-form-group label="Data acquisizione">
            <b-form-input type="date" v-model="data.knowledge.dateknowledge"/>
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group label="Tipologia">
            <b-form-select v-model="data.knowledge.external" :options="externalCombo" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <b-form-group label="Proprietari">
            <b-form-textarea v-model="data.knowledge.owners"/>
          </b-form-group>
        </b-col>
      </b-row>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.knowledge.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "knowledge",
  data() {
    return {
      show: false,
      data: null,
      externalCombo: [{
        id: false,
        val: "Interna"
      },{
        id: true,
        val: "Esterna"
      }],
    }  
  },
  methods: {
    loadData(id){
      this.show = false;
      this.openHttp("knowledges_knowledge", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("knowledges_knowledge_save", this.data.knowledge, "Conoscenza organizzativa salvata!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("knowledges_knowledge_delete", { id: this.data.knowledge.id }, "Conoscenza organizzativa eliminata!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('reload');
    }
  }
}
</script>