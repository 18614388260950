<template>
  <div>
    <risk ref="risk" @reload="loadData"/>
    <doctoken ref="doctoken"/>
    <b-modal v-model="showFilter" title="Filtra" @ok="filterSet">
      <b-form-group label="Rischio">
        <b-form-input v-model="tempFilter.risk" />
      </b-form-group>
      <b-form-group label="Processi">
        <b-form-select v-model="tempFilter.processes_id" :options="comboSeeAll(filtersMenu.processes)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Norme">
        <b-form-select v-model="tempFilter.standards_id" :options="comboSeeAll(filtersMenu.standards)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Parti interessate">
        <b-form-select v-model="tempFilter.interestedparties_id" :options="comboSeeAll(filtersMenu.interestedparties)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Rischi eliminati">
        <b-form-select v-model="tempFilter.deleted_risk" :options="deletedRiskCombo" value-field="id" text-field="val"/>
      </b-form-group>
    </b-modal>
    <b-modal id="modalRisksLegend" title="Legenda" hide-footer>
      <b-table-simple responsive>
        <b-thead>
          <b-tr>
            <b-th>Livello</b-th>
            <b-th>Minimo</b-th>
            <b-th>Massimo</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-td variant="success">Basso</b-td>
            <b-td>1</b-td>
            <b-td>4</b-td>
          </b-tr>
          <b-tr>
            <b-td variant="warning">Medio</b-td>
            <b-td>5</b-td>
            <b-td>12</b-td>
          </b-tr>
          <b-tr>
            <b-td variant="danger">Alto</b-td>
            <b-td>13</b-td>
            <b-td>64</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-modal>
    <b-btn class="mr-2" variant="primary" @click="loadData">Aggiorna</b-btn>
    <b-btn class="mr-2" variant="warning" @click="filterReset">Reset filtri</b-btn>
    <b-btn class="mr-2" variant="secondary" @click="filterOpen">Filtra</b-btn>
    <b-btn class="mr-2" variant="success" @click="loadItem(0)">Nuovo</b-btn>
    <b-btn class="mr-2" variant="secondary" v-b-modal.modalRisksLegend>Legenda</b-btn>
    <b-btn class="mr-2" variant="info" v-if="document != null && document.records_id > 0" @click="$refs.doctoken.downloadRecord(document.records_id)">Scarica Documento {{ document.date }}</b-btn>
    <b-btn variant="success" @click="generateDocument()">Genera Documento</b-btn>
    <b-table no-border-collapse :sticky-header="(winHeight - 205) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :tbody-tr-class="rowClass" :items="items.data" :fields="fields" :busy="isBusy" :no-local-sorting="true" :sort-by.sync="search.sort.by" :sort-desc.sync="search.sort.desc" @sort-changed="sortingChange" @row-clicked="loadItem($event.id)">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>Non ci sono risultati</template>
    </b-table>
    <tablebottom :perpage="search.page.perpage" @updatePerPage="search.page.perpage = $event" :currentpage="search.page.currentpage" @updateCurrentPage="search.page.currentpage = $event" :total="items.total" @loadData="loadData"/>
  </div>
</template>

<script>
import risk from './risk.vue';

export default {
  name: "risks",
  components: { risk },
  data() {
    return {
      isBusy: true,
      showFilter: false,      
      search:{
        page: {
          currentpage: 1,
          perpage: 50,
        },
        filters: {
          risk: null,
          processes_id: null,
          standards_id: null,
          interestedparties_id: null,
          deleted_risk: false
        },
        sort: {
          by: "evaluation",
          desc: true,
        },
      },
      filtersMenu: {
        processes: [],
        standards: [],
        interestedparties: [],
      },
      deletedRiskCombo: [{
        id: false,
        val: "Nascondi rischi eliminati"
      },{
        id: true,
        val: "Vedi solo rischi eliminati"
      },{
        id: null,
        val: "Vedi rischi attivi ed eliminati"
      },],
      tempFilter: {
        risk: null,
        processes_id: null,
        standards_id: null,
        interestedparties_id: null,
        deleted_risk: false,
      },
      fields: [{
        key: "risk",
        label: "Rischio",
        sortable: true
      },{
        key: "standards",
        label: "Norme"
      },{
        key: "causes",
        label: "Cause",
      },{
        key: "dateanalysis",
        label: "Data",
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: "risksevaluation",
        label: "Valutazione",
        sortable: true,
        formatter: value => {
          return value === 1 ? "Accettare il rischio" : ( value === 2 ? "Trasferire il rischio" : ( value === 3 ? "Finanziare il rischio" : ( value === 4 ? "Ridurre il rischio" : ( value === 5 ? "Rischio eliminato" : "?" ) ) ) );
        }
      },{
        key: "mitigation",
        label: "Mitigazione",
      },{
        key: "prevenction",
        label: "Prevenzione",
      },{
        key: "evaluation",
        label: "Valutazione",
        sortable: true,
        formatter: value => {
          if (value !== null) return value + '/64';
        }
      }],
      items: {
        total: 0,
        data: [],
      },
      document: null
    }  
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    rowClass(item) {
      if (item != null) return "table-" + (item.evaluation <= 4 ? "success" : (item.evaluation <= 12 ? "warning" : "danger" ));
    },
    loadData(){
      this.isBusy = true;
      this.openHttp("risks_risks", { search: this.search }).then((result) => {
        this.items = result;
        this.isBusy = false;
        this.loadDocument();
      })
    },
    loadDocument(){
      this.openHttp("risks_document", null).then((result) => {
        this.document = result;
      })
    },
    generateDocument(){
      this.generateDocumentHttp("risks");
    },
    filterOpen(){
      this.openHttp("risks_filters", null).then((result) => {
        this.showFilter = true;
        this.filtersMenu = result;
      })
    },
    filterSet(){
      this.showFilter = false;
      this.search.page.currentpage = 1;
      this.search.filters = this.tempFilter;
      this.loadData();
    },
    filterReset(){
      this.tempFilter = {
        risk: null,
        processes_id: null,
        standards_id: null,
        interestedparties_id: null,
        deleted_risk: false,
      };
      this.filterSet();
    },
    sortingChange(ctx) {
      this.search.sort.by = ctx.sortBy;
      this.search.sort.desc = ctx.sortDesc;
      this.loadData();
    },
    loadItem(id){
      this.$refs.risk.loadData(id);
    },
  }
}
</script>