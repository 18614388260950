<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" title="Consumo" scrollable @hidden="closeForm()" @close="closeForm()">
      <b-form-group label="Tipologia">
        <b-form-select v-model="data.consumption.consumptionstypes_id" :options="data.consumptionstypes" value-field="id" text-field="val"/>
      </b-form-group>
      <b-row>
        <b-col lg="6">
          <b-form-group label="Data registrazione">
            <b-form-input type="date" v-model="data.consumption.date"/>
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group label="Da (Opzionale)">
            <b-form-input type="date" v-model="data.consumption.date_from"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Consumo">
        <b-form-input type="number" v-model="data.consumption.val"/>
      </b-form-group>
      <b-form-group label="Macchine">
        <multiselect v-model="machineriesSupport" :options="data.machineries" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.consumption.machineries = updateCombo(machineriesSupport)"/>
      </b-form-group>
      <b-form-group label="Note">
        <b-form-textarea v-model="data.consumption.note"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.consumption.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: "consumption",
  data() {
    return {
      machineriesSupport: [],
      show: false,
      data: null,
    }
  },
  methods: {
    loadData( id ){
      this.openHttp("consumptions_consumption", { id: id }).then((result) => {
        this.machineriesSupport = this.convertCombo(result.machineries, result.consumption.machineries);
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("consumptions_consumption_save", this.data.consumption, "Consumo salvato!" ).then((result) => {
        if ( result.result == true ) this.closeForm(true);
      })
    },
    deleteData(){
      this.deleteHttp("consumptions_consumption_delete", { id: this.data.consumption.id }, "Consumo eliminato!" ).then((result) => {
        if ( result.result == true ) this.closeForm(true);
      })
    },
    closeForm(reload = false){
      this.show = false;
      this.data = null;
      if (reload === true ) this.$emit('reload');
    }
  }
}
</script>