<template>
  <div>
    <b-modal v-model="show" v-if="data !== null" size="lg" title="Parte interessata" @hidden="closeForm()" @close="closeForm()">
      <b-row>
        <b-col lg="12">
          <b-form-group label="Parte interessata">
            <b-form-input v-model="data.interestedparty.interestedparty"/>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group label="Tipologia">
            <b-form-select v-model="data.interestedparty.internal" :options="internalCombo" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group label="Tipologia comunicazioni">
            <b-form-input v-model="data.interestedparty.communicationtypes"/>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group label="Metodi di comunicazione">
            <b-form-input v-model="data.interestedparty.communicationmethodology"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <b-form-group label="Esempi">
            <b-form-textarea v-model="data.interestedparty.samples"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <b-form-group label="Aspettative primarie">
            <b-form-textarea v-model="data.interestedparty.primaryexpectations" rows="3"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <b-form-group label="Aspettative secondarie">
            <b-form-textarea v-model="data.interestedparty.secondaryexpectations" rows="3"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <b-form-group label="Aspettative non soddisfatte">
            <b-form-textarea v-model="data.interestedparty.notsatisfiedexpectations" rows="3"/>
          </b-form-group>
        </b-col>
      </b-row>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.interestedparty.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "interestedparty",
  data() {
    return {
      show: false,
      data: null,
      internalCombo: [{
        id: true,
        val: "Interna"
      },{
        id: false,
        val: "Esterna"
      }],
    }  
  },
  methods: {
    loadData(id){
      this.openHttp("interestedparties_interestedparty", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("interestedparties_interestedparty_save", this.data.interestedparty, "Parte interessata salvata!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("interestedparties_interestedparty_delete", { id: this.data.interestedparty.id }, "Parte interessata eliminata!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('reload');
    }
  }
}
</script>