import axios from 'axios';

export default {
    methods: {
        openHttp (functionName, parameters){
            let dataHttp = {
                token: this.$store.getters.getToken
            };
            if ( parameters !== null ) dataHttp.data = parameters;
            return new Promise(resolve => {
                axios.post("api2/api/" +  functionName, dataHttp )
                .then(function (response) {
                    resolve(JSON.parse(response.data));
                })
                .catch(function (error) {
                    console.log("Errore: ", error);
                });
            });
        },
        generateDocumentHttp (functionName){
            let self = this;
            return new Promise(resolve => {
                axios.post("api2/api/" +  functionName + "_document_generate", { token: this.$store.getters.getToken })
                .then(function (response) {
                    self.$bvToast.toast("Documento in corso di creazione attendere qualche minuto e premere aggiorna", {
                        title: "Servizi alle imprese",
                        variant: "primary",
                        autoHideDelay: 2000,
                        appendToast: true
                    })
                    resolve(JSON.parse(response.data));
                })
                .catch(function (error) {
                    console.log("Errore: ", error);
                })
            });
        },
        saveHttp (functionName, parameters, textPopUp){
            let self = this;
            return new Promise(resolve => {
                axios.post("api2/api/" +  functionName, { token: this.$store.getters.getToken, data: parameters } )
                .then(function (response) {
                    self.$bvToast.toast(textPopUp, {
                        title: "Servizi alle imprese",
                        variant: "success",
                        autoHideDelay: 1500,
                        appendToast: true
                    })
                    resolve(JSON.parse(response.data));
                })
                .catch(function (error) {
                    console.log("Errore: ", error);
                })
            });
        },
        deleteHttp (functionName, parameters, textPopUp){
            let self = this;
            return new Promise(resolve => {
                axios.post("api2/api/" +  functionName, { token: this.$store.getters.getToken, data: parameters } )
                .then(function (response) {
                    self.$bvToast.toast(textPopUp, {
                        title: "Servizi alle imprese",
                        variant: "danger",
                        autoHideDelay: 1500,
                        appendToast: true
                    })
                    resolve(JSON.parse(response.data));
                })
                .catch(function (error) {
                    console.log("Errore: ", error);
                });
            });
        },
        convertCombo( fromData, fromValues ){
            this.$forceUpdate();
            return fromValues.map(x => fromData.find(y => y.id === x) );
        },
        updateCombo( fromValues ){
            this.$forceUpdate();
            return fromValues.map(x => x.id);
        },
        convertComboSimple( fromData, fromValues ){
            this.$forceUpdate();
            return fromData.find(x => x.id === fromValues);
        },
        updateComboSimple( fromValues ){
            this.$forceUpdate();
            return fromValues != null ? fromValues.id : null;
        },
        comboSeeAll( fromValues ){
            return [{ id: null, val: "Vedi tutti/e" }].concat(fromValues);            
        },
        comboNull(fromValues){
            return [{ id: null, val: "Nessuno/a" }].concat(fromValues);
        },
        numberWithCommas( fromNumber ) {
            return fromNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        
    }
}