<template>
  <div>
    <b-row style="width:101.5%">
      <b-col lg="4" v-if="credentials !== null">
        <b-card :title="credentials.fullname" class="mb-3">
          <b-card-text>
            Azienda: <b>{{ credentials.company }}</b><br>
            Mansioni: <b>{{ credentials.jobdescriptions }}</b>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col lg="8">
        <b-card title="Scadenze" class="mb-3">
          <b-card-text>
            <b-table no-border-collapse small hover :items="deadlines" :fields="fieldsDeadlines">
              <template v-slot:empty>Non ci sono risultati</template>
              <template v-slot:cell(form)="row">
                <b-link v-if="row.item.expired > 0 || row.item.expirednextmonth > 0" :to="row.item.form" variant="primary">{{ 'Controlla ' + deadlinesForm.find(x => x.id === row.value).val }}</b-link>
                <span v-if="row.item.expired == 0 && row.item.expirednextmonth == 0">{{ 'Ok ' + deadlinesForm.find( x=> x.id === row.value).val }}</span>
              </template>
            </b-table>
          </b-card-text>
        </b-card>
        <b-table></b-table>
      </b-col>
      <b-col lg="3" v-if="auth.targets">
        <b-card class="mb-3">
          <b-card-text>
            Raggiungimento degli Obiettivi (%)
            <barchart ref="chartTargets"/>
            <b-link to="targets" variant="primary">Vai agli Obiettivi</b-link>            
          </b-card-text>
        </b-card>        
      </b-col>
      <b-col lg="3" v-if="auth.kpis">
        <b-card class="mb-3">
          <b-card-text>
            Raggiungimento degli Indicatori (%)
            <barchart ref="chartKpis"/>
            <b-link to="kpis" variant="primary">Vai agli Indicatori</b-link>
          </b-card-text>
        </b-card>        
      </b-col>
      <b-col lg="3" v-if="auth.customers">
        <b-card class="mb-3">
          <b-card-text>
            Soddisfazione del cliente (%)
            <barchart ref="chartCustomers"/>
            <b-link to="customers" variant="primary">Vai ai Clienti</b-link>
          </b-card-text>
        </b-card>        
      </b-col>
      <b-col lg="3" v-if="auth.suppliers">
        <b-card class="mb-3">
          <b-card-text>
            Qualifica dei Fornitori (%)
            <barchart ref="chartSuppliers"/>
            <b-link to="suppliers" variant="primary">Vai ai Fornitori</b-link>
          </b-card-text>
        </b-card>        
      </b-col>
      <b-col lg="3" v-if="auth.machineries">        
        <b-card class="mb-3">
          <b-card-text>
            Costo Manutenzioni macchinari (€)
            <barchart ref="chartMachineries"/>
            <b-link to="machineries" variant="primary">Vai ai Macchinari</b-link>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col lg="3" v-if="auth.tools">
        <b-card class="mb-3">
          <b-card-text>
            Costo Tarature strumenti (€)
            <barchart ref="chartTools"/>
            <b-link to="tools" variant="primary">Vai agli Strumenti</b-link>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col lg="3" v-if="auth.nonconformities">
        <b-card class="mb-3">
          <b-card-text>
            Costo Non-Conformità (€)
            <barchart ref="chartNonconformities"/>
            <b-link to="nonconformities" variant="primary">Vai alle Non-Conformità</b-link>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import barchart from './barchart';

export default {
  name: "homepage",
  components: { barchart },
  data() {
    return {
      credentials: null,
      auth: {
        targets: false,
        kpis: false,
        customers: false,
        suppliers: false,
        machineries: false,
        tools: false,
        nonconformities: false,
      },
      fieldsDeadlines: [{
        key: 'form',
        label: "Maschera",
        sortable: true,
      },{
        key: 'expired',
        label: 'Scadute',
        sortable: true,
      },{
        key: 'expirednextmonth',
        label: 'In scadenza',
        sortable: true,
      },{
        key: 'form2',
        label: '',
      }],
      deadlines: [],
      deadlinesForm: [{
        id: "documents",
        val: "Documenti",
      },{
        id: "machineries",
        val: "Macchinari",
      },{
        id: "tools",
        val: "Strumenti",
      },{
        id: "suppliers",
        val: "Fornitori",
      },{
        id: "customers",
        val: "Clienti",
      },{
        id: "employees",
        val: "Personale",
      },{
        id: "trainings",
        val: "Formazione",
      },{
        id: "safetytrainings",
        val: "Formazione Sicurezza",
      },{
        id: "medicals",
        val: "Visite mediche",
      }],
    }
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.openHttp("home_panels", {}).then((result) => {
        if (result.targets !== null) this.auth.targets = true;
        if (result.kpis !== null) this.auth.kpis = true;
        if (result.customers !== null) this.auth.customers = true;
        if (result.suppliers !== null) this.auth.suppliers = true;
        if (result.machineries !== null) this.auth.machineries = true;
        if (result.tools !== null) this.auth.tools = true;
        if (result.nonconformities !== null) this.auth.nonconformities = true;
        
        setTimeout( function () {
          if (result.targets !== null) this.$refs.chartTargets.load( result.targets );
          if (result.kpis !== null) this.$refs.chartKpis.load( result.kpis );
          if (result.customers !== null) this.$refs.chartCustomers.load( result.customers );
          if (result.suppliers !== null) this.$refs.chartSuppliers.load( result.suppliers );
          if (result.machineries !== null) this.$refs.chartMachineries.load( result.machineries );
          if (result.tools !== null) this.$refs.chartTools.load( result.tools );
          if (result.nonconformities !== null) this.$refs.chartNonconformities.load( result.nonconformities );
        }.bind(this), 500);

        this.credentials = result.credentials;
        this.deadlines = result.deadlines;
      })
    }
  }
}
</script>