<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" title="Documento" @hidden="closeForm()" @close="closeForm()">
      <b-form-group label="File" v-show="data.document.id === 0">
        <b-form-file placeholder="Scegli un file o trascinalo qui..." drop-placeholder="Trascinalo qui..." @change="handlerUpdate($event)"/>
      </b-form-group>
      <b-form-group label="Nome" v-show="data.document.id > 0">
        <b-form-input v-model="data.document.docname"/>
      </b-form-group>
      <b-form-group label="Scadenza">
        <b-form-input type="date" v-model="data.document.deadlinedate"/>
      </b-form-group>
      <b-form-group label="Stato">
        <b-form-select v-model="data.document.archived" :options="archivedCombo" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Note">
        <b-form-textarea v-model="data.document.note" rows="4"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-if="data.document.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "employeedocument",
  data() {
    return {
      show: false,
      data: null,
      archivedCombo: [{
        id: false,
        val: "In corso di validità"
      },{
        id: true,
        val: "Archiviato"
      }]
    }
  },
  methods: {
    loadData(id, employees_id){
      this.show = false;
      this.openHttp("employees_employeedocument", { id: id, employees_id: employees_id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    handlerUpdate(event){
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = ()=> {
        this.data.document.docname = event.target.files[0].name;
        this.data.document.base64 = reader.result;
      };
    },
    saveData(){
      this.saveHttp("employees_employeedocument_save", this.data.document, "Documento salvato!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("employees_employeedocument_delete", { id: this.data.document.id }, "Documento eliminato!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('reload');
    }
  }
}
</script>