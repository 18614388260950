<template>
  <div>
    <process ref="process" @reload="loadData"/>
    <b-modal v-model="showFilter" title="Filtra" @ok="filterSet">
      <b-form-group label="Processo">
        <b-form-input type="text" v-model="tempFilter.process"/>
      </b-form-group>
      <b-form-group label="Tipologia">
        <b-form-select v-model="tempFilter.external" :options="filtersMenu.external" value-field="id" text-field="val"/>
      </b-form-group>
    </b-modal>
    <b-btn class="mr-2" variant="primary" @click="loadData">Aggiorna</b-btn>
    <b-btn class="mr-2" variant="warning" @click="filterReset">Reset filtri</b-btn>
    <b-btn class="mr-2" variant="secondary" @click="filterOpen">Filtra</b-btn>
    <b-btn class="mr-2" variant="success" @click="$refs.process.loadData(0)">Nuovo</b-btn>
    <b-btn class="mr-2" variant="info" v-if="document != null && document.records_id > 0" @click="$refs.doctoken.downloadRecord(document.records_id)">Scarica Documento {{ document.date }}</b-btn>
    <b-btn variant="success" @click="generateDocument()">Genera Documento</b-btn>
    <doctoken ref="doctoken"/>
    <b-table no-border-collapse :sticky-header="(winHeight - 205) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="items.data" :fields="fields" :busy="isBusy" :no-local-sorting="true" :sort-by.sync="search.sort.by" :sort-desc.sync="search.sort.desc" @sort-changed="sortingChange" @row-clicked="$refs.process.loadData($event.id)">
      <template #cell(subprocesses)="data">
        <div v-for="(item, index) in data.value" :key="index">
          <b-link @click="$refs.process.loadData(item.id)">{{ item.process }}</b-link>
        </div>
      </template>
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>Non ci sono risultati</template>
    </b-table>
    <tablebottom :perpage="search.page.perpage" @updatePerPage="search.page.perpage = $event" :currentpage="search.page.currentpage" @updateCurrentPage="search.page.currentpage = $event" :total="items.total" @loadData="loadData"/>
  </div>
</template>

<script>
import process from './process.vue';

export default {
  name: "processes",
  components: { process },
  data() {
    return {
      isBusy: true,
      showFilter: false,
      search: {
        page: {
          currentpage: 1,
          perpage: 50,
        },
        filters: {
          process: null,
          external: null,
        },
        sort: {
          by: 'external',
          desc: false,
        },
      },
      tempFilter: {
        process: null,
        external: null,
      },
      filtersMenu: {
        external: [{
          id: null,
          val: "Tutti"
        },{
          id: false,
          val: "Interni"
        },{
          id: true,
          val: "Esterni"
        }],
      },
      fields: [{
        key: 'external',
        label: 'Tipologia',
        sortable: true,
        formatter: value => {
          return value ? 'Esterna' : 'Interna';
        }
      },{
        key: 'process',
        label: "Processo",
        sortable: true
      },{
        key: 'subprocesses',
        label: "Sottoprocessi"
      },{
        key: "jobdescriptions",
        label: "Mansioni"
      }],
      fieldsSub: [{
        key: 'process',
        label: "Processo",
        sortable: true
      },{
        key: 'external',
        label: 'Tipologia',
        sortable: true,
        formatter: value => {
          return value ? 'Esterna' : 'Interna';
        }
      },{
        key: 'jobdescriptions',
        label: 'Mansioni',
      }],
      items: {
        total: 0,
        record_id: null,
        data: [],
      },
      document: null
    }  
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    CalcLen(val){
      return val.length;
    },
    loadData(){
      this.isBusy = true;
      this.openHttp("processes_processes", { search: this.search } ).then((result) => {
        this.items = result;
        this.isBusy = false;
        this.loadDocument();
      })
    },
    loadDocument(){
      this.openHttp("processes_document", null).then((result) => {
        this.document = result;
      })
    },
    generateDocument(){
      this.generateDocumentHttp("processes");
    },
    filterOpen(){
      this.showFilter = true;
    },
    filterSet(){
      this.showFilter = false;
      this.search.filters = this.tempFilter;
      this.loadData();
    },
    filterReset(){
      this.tempFilter = {
        process: null,
        external: null,
      };
      this.filterSet();
    },
    sortingChange(ctx) {
      this.search.sort.by = ctx.sortBy;
      this.search.sort.desc = ctx.sortDesc;
      this.loadData();
    }
  }
}
</script>