<template>
  <div>
    <consumption ref="consumption" @reload="loadData"/>
    <consumptionstype ref="type" @reload="loadData"/>
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Report" active>
          <b-btn variant="primary" class="mb-2" @click="loadReport">Aggiorna</b-btn>
          <b-form-group label="Consumi per anno">
            <b-table-simple responsive>
              <b-thead>
                <b-tr>
                  <b-th>Consumo</b-th>
                  <b-th v-for="(item, index) in fielsdYears" :key="index"><nobr>{{ item }}</nobr></b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(item, index) in report.years" :key="index">
                  <b-td>{{ item.consumptionstype }}</b-td>
                  <b-td v-for="(item2, index2) in fielsdYears" :key="index2">{{ item.vals.find(x => x.year === item2).consumptions }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-form-group>
          <b-form-group label="Consumi per mese">
            <b-table-simple responsive>
              <b-thead>
                <b-tr>
                  <b-th>Consumo</b-th>
                  <b-th v-for="(item, index) in fielsdMonths" :key="index"><nobr>{{ item }}</nobr></b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(item, index) in report.months" :key="index">
                  <b-td>{{ item.consumptionstype }}</b-td>
                  <b-td v-for="(item2, index2) in item.vals" :key="index2">{{ item2.consumptions }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-form-group>
        </b-tab>
        <b-tab title="Registrazioni">
          <b-modal v-model="consumptions.showFilter" title="Filtra" @ok="filterSetConsumptions">
            <b-form-group label="Tipologia di consumo">
              <b-form-select v-model="consumptions.tempFilter.consumptionstypes_id" :options="comboSeeAll(consumptions.filtersMenu.consumptionstypes)" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Macchinario">
              <b-form-select v-model="consumptions.tempFilter.machineries_id" :options="comboSeeAll(consumptions.filtersMenu.machineries)" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Da">
              <b-form-input type="date" v-model="consumptions.tempFilter.fromdate"/>
            </b-form-group>
            <b-form-group label="A">
              <b-form-input type="date" v-model="consumptions.tempFilter.todate"/>
            </b-form-group>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadConsumptions">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="warning" @click="filterResetConsumptions">Reset filtri</b-btn>
          <b-btn class="mr-2" variant="secondary" @click="filterOpenConsumptions">Filtra</b-btn>
          <b-btn class="mr-2" variant="success" @click="$refs.consumption.loadData(0)">Nuovo</b-btn>
          <b-btn class="mr-2" variant="info" v-if="document != null && document.records_id > 0" @click="$refs.doctoken.downloadRecord(document.records_id)">Scarica Documento {{ document.date }}</b-btn>
          <b-btn variant="success" @click="generateDocument()">Genera Documento</b-btn>
          <doctoken ref="doctoken"/>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="consumptions.items.data" :fields="consumptions.fields" :busy="consumptions.isBusy" :no-local-sorting="true" :sort-by.sync="consumptions.search.sort.by" :sort-desc.sync="consumptions.search.sort.desc" @sort-changed="sortingChangeConsumptions" @row-clicked="$refs.consumption.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
          <tablebottom :perpage="consumptions.search.page.perpage" @updatePerPage="consumptions.search.page.perpage = $event" :currentpage="consumptions.search.page.currentpage" @updateCurrentPage="consumptions.search.page.currentpage = $event" :total="consumptions.items.total" @loadData="loadConsumptions"/>
        </b-tab>
        <b-tab title="Tipologie">
          <b-btn class="mr-2" variant="primary" @click="loadTypes">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="success" @click="$refs.type.loadData(0)">Nuovo</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="types.items.data" :fields="types.fields" :busy="types.isBusy" :no-local-sorting="true" :sort-by.sync="types.search.sort.by" :sort-desc.sync="types.search.sort.desc" @sort-changed="sortingChangeTypes" @row-clicked="$refs.type.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
          <tablebottom :perpage="types.search.page.perpage" @updatePerPage="types.search.page.perpage = $event" :currentpage="types.search.page.currentpage" @updateCurrentPage="types.search.page.currentpage = $event" :total="types.items.total" @loadData="loadTypes"/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import consumption from './consumption.vue';
import consumptionstype from './consumptionstype.vue';

export default {
  name: "consumptions",
  components: { consumption, consumptionstype },
  data() {
    return {
      report: {
        months: [],
        years: []
      },
      consumptions: {
        showFilter: false,
        fields: [{
          key: "consumptionstype",
          label: "Tipologia",
          sortable: true,
        },{
          key: "machineries",
          label: "Macchinari",
        },{
          key: "date",
          label: "Data",
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
          sortable: true,
        },{
          key: "val",
          label: "Consumo",
        }],
        filtersMenu: {
          consumptionstypes: [],
          machineries: [],
        },
        items: {
          total: 0,
          data: [],
        },
        isBusy: true,
        search: {
          page: {
            currentpage: 1,
            perpage: 50,
          },
          filters: {
            consumptionstypes_id: null,
            machineries_id: null,
          },
          sort: {
            by: "fromdate",
            desc: false,
          },
        },
        tempFilter: {
          consumptionstypes_id: null,
          machineries_id: null,
          fromdate: null,
          todate: null,
        },
      },
      types: {
        fields: [{
          key: "consumptionstype",
          label: "Tipologia",
          sortable: true,
        }],
        isBusy: true,
        items: {
          total: 0,
          data: [],
        },
        search: {
          page: {
            currentpage: 1,
            perpage: 50,
          },
          sort: {
            by: "consumptionstype",
            desc: false,
          }
        }
      },
      document: null
    }  
  },
  created(){
    this.loadData();
  },
  computed: {
    winHeight(){
      return this.$store.getters.getWinHeight;
    },
    fielsdMonths(){
      let vals = [];
      let dayX = new Date();
      for (let i = 0; i < 12; i++) {
        dayX.setMonth(dayX.getMonth()-1);
        vals.push([ dayX.getMonth() + 1, dayX.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-'));
      }
      return vals;
    },
    fielsdYears(){
      return this.report.years
        .map(x => x.vals.map(y => y.year))
        .reduce((acc, cur) => acc.concat(cur), [])
        .reduce((acc, cur) => acc.concat(acc.includes(cur) == false ? cur : null), [])
        .filter(x => x !== null)
        .sort();
    }
  },
  methods: {
    loadData(){
      this.loadReport();
      this.loadConsumptions();
      this.loadTypes();
    },
    loadReport(){
      this.openHttp("consumptions_report", { }).then((result) => {
        console.log(result);
        this.report = result;
      })
    },
    loadConsumptions(){
      this.consumptions.isBusy = true;
      this.openHttp("consumptions_consumptions", this.consumptions.search).then((result) => {
        this.consumptions.items = result;
        this.consumptions.isBusy = false;
      })
    },
    filterOpenConsumptions(){
      this.openHttp("consumptions_filters", null).then((result) => {
        this.consumptions.filtersMenu = result;
        this.consumptions.showFilter = true;
      })
    },
    filterSetConsumptions(){
      this.consumptions.search.page.currentpage = 1;
      this.consumptions.search.filters = this.consumptions.tempFilter;
      this.consumptions.showFilter = false;
      this.loadConsumptions();
    },
    filterResetConsumptions(){
      this.consumptions.tempFilter = {
        consumptionstypes_id: null,
        machineries_id: null,
        fromdate: null,
        todate: null,
      };
      this.filterSetConsumptions();
    },
    sortingChangeConsumptions(ctx) {
      this.consumptions.search.sort.by = ctx.sortBy;
      this.consumptions.search.sort.desc = ctx.sortDesc;
      this.loadConsumptions();
    },
    loadTypes(){
      this.types.isBusy = true;
      this.openHttp("consumptions_consumptionstypes", this.types.search).then((result) => {
        this.types.items = result;
        this.types.isBusy = false;
      })
    },
    sortingChangeTypes(ctx) {
      this.types.search.sort.by = ctx.sortBy;
      this.types.search.sort.desc = ctx.sortDesc;
      this.loadTypes();
    }
  }
}
</script>