<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" title="Template" size="xl" scrollable @hidden="closeForm" @close="closeForm" no-close-on-esc no-close-on-backdrop>
      <b-form-group label="Nome">
        <b-form-input v-model="data.template.file"/>
      </b-form-group>
      <b-form-group label="Controllo">
        <customeditor v-model="data.template.template" :height="winHeight - 380" :showToolbar="true"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="warning" class="mr-2" v-show="data.template.oldfile !== null" @click="duplicateData">Duplica</b-btn>
          <b-btn variant="danger" v-show="data.template.oldfile !== null" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import customeditor from '../customeditor.vue';

export default {
  name: "templatehtml",
  components: {
    customeditor
  },
  data() {
    return {
      show: false,
      data: null,
    }  
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    loadData(file) {
      this.openHttp("templates_templatehtml", { file: file }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("templates_templatehtml_save", this.data.template, "Template salvato!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    duplicateData(){
      this.data.template.oldfile = null;
      this.data.template.file = this.data.template.file + "_duplicato";
    },
    deleteData(){
      this.deleteHttp("templates_templatehtml_delete", { file: this.data.template.file }, "Template eliminato!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('reload');
    }
  }
}
</script>