<template>
  <div>
    <b-modal v-if="data != null" v-model="show" title="Consulente" scrollable @hidden="closeForm" @close="closeForm">
      <b-form-group label="Mail">
        <b-form-input type="email" v-model="data.superuser.mail"/>
      </b-form-group>
      <b-form-group label="Password" v-if="data.superuser.superuserinternal === false">
        <b-form-input v-model="data.superuser.psw"/>
      </b-form-group>
      <b-form-group label="Nome">
        <b-form-input v-model="data.superuser.firstname"/>
      </b-form-group>
      <b-form-group label="Cognome">
        <b-form-input v-model="data.superuser.lastname"/>
      </b-form-group>
      <b-form-group label="Tipologia" v-if="data.superuser.id === 0">
        <b-form-select v-model="data.superuser.superuserinternal" :options="internalCombo" value-field="id" text-field="val"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.superuser.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
    <h4>Elenco consulenti</h4>
    <b-row>
      <b-col lg="9">
        <b-btn variant="primary" class="mr-2" @click="loadData">Aggiorna</b-btn>
        <b-btn variant="success" @click="loadItem(0)">Nuovo</b-btn>
      </b-col>
      <b-col lg="3">
        <b-form-input v-model="criteria" style="float: right" placeholder="Filtra..."/>
      </b-col>
    </b-row>
    <b-table no-border-collapse :sticky-header="(winHeight - 200) + 'px'" hover class="mt-3" :items="items" :fields="fields" :busy="isBusy" :filter="criteria" @row-clicked="loadItem($event.id)">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>Non ci sono risultati</template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: "superusers",
  data() {
    return {
      isBusy: true,
      fields: [{
        key: 'fullname',
        label: 'Nome',
        sortable: true,
      },{
        key: 'mail',
        label: "Mail",
        sortable: true,
      },{
        key: 'internal',
        label: "Tipologia",
        sortable: true,
        formatter: value => {
          return value === true ? 'Interno' : 'Esterno';
        }
      }],
      items: [],
      criteria: null,
      show: false,
      data: null,
      internalCombo: [{
        id: false,
        val: "Esterno",
      },{
        id: true,
        val: "Interno",
      }]
    }  
  },
  computed: {
    token (){
      return this.$store.getters.getToken;
    },
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.isBusy = true
      this.companyShow = false;
      this.openHttp("superusers_superusers", {}).then((result) => {
        this.isBusy = false;
        this.items = result;
      })
    },
    loadItem(id) {
      this.openHttp("superusers_superuser", { id: id } ).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("superusers_superuser_save", this.data.superuser, "Salvato!").then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("superusers_superuser_delete", { id: this.data.superuser.id }, "Eliminato!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.loadData();
    },
  }
}
</script>