<template>
  <div>
    <b-navbar toggleable="lg" type="dark" variant="primary">
      <b-navbar-brand to="/home" v-show="checkRoute(['home'])">Servizi alle imprese</b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"/>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item-dropdown text="Azienda" v-show="checkRoute(['company','auths','logs'])">
            <b-dropdown-item to="company" v-show="checkRoute(['company'])">Azienda</b-dropdown-item>
            <b-dropdown-item to="auths" v-show="checkRoute(['auths'])">Autorizzazioni</b-dropdown-item>
            <b-dropdown-item to="logs" v-show="checkRoute(['logs'])">Cronologia attività</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown text="Sistema" v-show="checkRoute(['processes','documents','duediligences','laws','knowledges','changes'])">
            <b-dropdown-item to="processes" v-show="checkRoute(['processes'])">Processi</b-dropdown-item>
            <b-dropdown-item to="documents" v-show="checkRoute(['documents'])">Informazioni documentate</b-dropdown-item>
            <b-dropdown-item to="duediligences" v-show="checkRoute(['duediligences'])">Due diligences</b-dropdown-item>
            <b-dropdown-item to="laws" v-show="checkRoute(['laws'])">Elenco Norme & Leggi</b-dropdown-item>
            <b-dropdown-item to="knowledges" v-show="checkRoute(['knowledges'])">Conoscenza organizzativa</b-dropdown-item>
            <b-dropdown-item to="changes" v-show="checkRoute(['changes'])">Gestione del cambiamento</b-dropdown-item>
            <b-dropdown-item to="emergencies" v-show="checkRoute(['emergencies'])">Simulazione emergenze</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown text="Contesto" v-show="checkRoute(['context','interestedparties','risks','impacts'])">
            <b-dropdown-item to="context" v-show="checkRoute(['context'])">Contesto</b-dropdown-item>
            <b-dropdown-item to="interestedparties" v-show="checkRoute(['interestedparties'])">Parti interessate</b-dropdown-item>
            <b-dropdown-item to="risks" v-show="checkRoute(['risks'])">Rischi</b-dropdown-item>
            <b-dropdown-item to="impacts" v-show="checkRoute(['impacts'])">Impatti</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown text="Risorse umane" v-show="checkRoute(['employees','jobs','organizationchart','skills','trainings','safetytrainings','medicals','ppe','delegations'])">
            <b-dropdown-item to="employees" v-show="checkRoute(['employees'])">Personale</b-dropdown-item>
            <b-dropdown-item to="jobs" v-show="checkRoute(['jobs'])">Mansionario</b-dropdown-item>
            <b-dropdown-item to="organizationchart" v-show="checkRoute(['organizationchart'])">Organigramma</b-dropdown-item>
            <b-dropdown-item to="skills" v-show="checkRoute(['skills'])">Valutazione competenze</b-dropdown-item>
            <b-dropdown-item to="trainings" v-show="checkRoute(['trainings'])">Formazione</b-dropdown-item>
            <b-dropdown-item to="safetytrainings" v-show="checkRoute(['safetytrainings'])">Corsi sicurezza</b-dropdown-item>
            <b-dropdown-item to="medicals" v-show="checkRoute(['medicals'])">Visite mediche</b-dropdown-item>
            <b-dropdown-item to="ppe" v-show="checkRoute(['ppe'])">Gestione DPI</b-dropdown-item>
            <b-dropdown-item to="delegations" v-show="checkRoute(['delegations'])">Deleghe</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown text="Infrastruttura" v-show="checkRoute(['machineries','tools','consuptions','expirations'])">
            <b-dropdown-item to="machineries" v-show="checkRoute(['machineries'])">Macchinari ed infrastruttura</b-dropdown-item>
            <b-dropdown-item to="tools" v-show="checkRoute(['tools'])">Strumenti</b-dropdown-item>
            <b-dropdown-item to="consumptions" v-show="checkRoute(['consumptions'])">Consumi</b-dropdown-item>
            <b-dropdown-item to="expirations" v-show="checkRoute(['expirations'])">Scadenze legislative</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown text="Fornitori e Clienti" v-show="checkRoute(['suppliers','customers'])">
            <b-dropdown-item to="suppliers" v-show="checkRoute(['suppliers'])">Fornitori</b-dropdown-item>
            <b-dropdown-item to="customers" v-show="checkRoute(['customers'])">Clienti</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown text="Non conformità" v-show="checkRoute(['nonconformities','correctiveactions','opportunities'])">
            <b-dropdown-item to="nonconformities" v-show="checkRoute(['nonconformities'])">Non conformità</b-dropdown-item>
            <b-dropdown-item to="correctiveactions" v-show="checkRoute(['correctiveactions'])">Azioni correttive</b-dropdown-item>
            <b-dropdown-item to="opportunities" v-show="checkRoute(['opportunities'])">Opportunità</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown text="Obiettivi" v-show="checkRoute(['targets','kpis','audits','managementreviews'])">
            <b-dropdown-item to="targets" v-show="checkRoute(['targets'])">Obiettivi</b-dropdown-item>
            <b-dropdown-item to="kpis" v-show="checkRoute(['kpis'])">Indicatori</b-dropdown-item>
            <b-dropdown-item to="audits" v-show="checkRoute(['audits'])">Audit interni</b-dropdown-item>
            <b-dropdown-item to="managementreviews" v-show="checkRoute(['managementreviews'])">Riesame della direzione</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item href="mailto:software@servizialleimprese.eu?subject=Ho%20un%20problema%20con%20il%20software" right>Assistenza</b-nav-item>
          <b-nav-item-dropdown text="Gestione" v-show="checkRoute(['templates', 'superusers'])" right>
            <b-dropdown-item to="templates" v-show="checkRoute(['templates'])">Templates</b-dropdown-item>
            <b-dropdown-item to="superusers" v-show="checkRoute(['superusers'])">Super Utenze</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown text="Utente" right>
            <b-dropdown-item @click="loadData">Profilo</b-dropdown-item>
            <b-dropdown-item to="companies">Gestione aziende</b-dropdown-item>
            <b-dropdown-item to="login">Log out</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-modal v-if="data != null" v-model="showCredentials" title="Credenziali" @hidden="closeForm" @close="closeForm">
      <b-form-group label="Mail">
        <b-form-input type="email" v-model="data.credentials.mail" required disabled/>
      </b-form-group>
      <b-form-group label="Password">
        <b-input-group>
          <b-form-input :type="typePassword" v-model="data.credentials.psw" required/>
          <template v-slot:append>
            <b-button variant="primary" @mousedown="clearPsw(true)" @mouseup="clearPsw(false)"><b-icon icon="eye"/></b-button>
          </template>
        </b-input-group>
      </b-form-group>
      <b-form-group label="Frequenza Mail">
        <b-form-select v-model="data.credentials.frequencymail" :options="frequencyMailCombo" value-field="id" text-field="val"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" @click="saveData">Salva</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "navbar",
  data() {
    return {
      data: null,
      showCredentials: false,
      typePassword: "password",
      frequencyMailCombo: [{
        id: null,
        val: "Nessuna email",
      },{
        id: "1 day",
        val: "Giornaliero",
      },{
        id: "1 week",
        val: "Settimanale",
      },{
        id: "2 week",
        val: "Bisettimanale",
      },{
        id: "1 month",
        val: "Mensile",
      },{
        id: "2 month",
        val: "Bimenstrale",
      },{
        id: "3 month",
        val: "Trimestrale",
      },{
        id: "6 month",
        val: "Semestrale",
      },{
        id: "12 month",
        val: "Annuale",
      }]
    }
  },
  computed: {
    routesAllowed (){
      return this.$store.getters.getRoutes;
    },
    token (){
      return this.$store.getters.getToken;
    }
  },
  methods: {
    checkRoute(routeToCheck){
      for (let item of routeToCheck){
        if (this.routesAllowed.indexOf(item) > -1) return true;
      }
      return false;
    },
    loadData(){
      this.showCredentials = false;
      this.openHttp("credentials_credentials", {}).then((result) => {
        this.showCredentials = true;
        this.data = result;
      })
    },
    clearPsw( upDown ){
      this.typePassword = upDown === true ? 'text' : 'password';
    },
    saveData(){
      this.saveHttp("credentials_credentials_save", this.data.credentials, "Salvato!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.showCredentials = false;
    }
  }
}
</script>