<template>
  <div>
    <b-modal v-if="data !== null" v-model="companyShow" size="lg" title="Anagrafica" scrollable @hidden="closeForm" @close="closeForm">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Anagrafica" active>
            <b-form-group label="Ragione sociale">
              <b-form-input v-model="data.company.company"/>
            </b-form-group>
            <b-form-group label="Partita IVA">
              <b-form-input v-model="data.company.vat"/>
            </b-form-group>
            <b-form-group label="Norme">
              <multiselect v-model="data.company.standardsSupport" :options="data.standards" placeholder="" selectLabel="" deselectLabel="" :multiple="true" track-by="id" label="val" @input="data.company.standards = updateCombo(data.company.standardsSupport)"/>
            </b-form-group>
            <b-form-group label="Autorizzati" v-if="data.credentials.length > 0">
              <multiselect v-model="data.company.credentialsSupport" :options="data.credentials" placeholder="" selectLabel="" deselectLabel="" :multiple="true" track-by="id" label="val" @input="data.company.credentials = updateCombo(data.company.credentialsSupport)"/>
            </b-form-group>
          </b-tab>
          <b-tab title="Templates" v-if="data.company.id > 0">
            <b-table no-border-collapse hover :items="data.templates" :fields="fields.template">
              <template v-slot:cell(id)="row">
                <b-btn variant="info" size="sm" @click="setTemplate(row.value)">Carica i dati</b-btn>
              </template>
            </b-table>
          </b-tab>
          <!--
          <b-tab title="Credenziali" v-if="data.company.id > 0">
            <b-table no-border-collapse hover :items="data.credentialscompananies" :fields="fields.credentialscompananies"/>
          </b-tab>
          -->
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="primary" v-if="data.company.id > 0" class="mr-2" @click="openItem(data.company.id)">Accedi</b-btn>
        </div>
      </template>
    </b-modal>
    <h4>Elenco aziende</h4>
    <b-row>
      <b-col lg="9">
        <b-btn variant="primary" class="mr-2" @click="loadData">Aggiorna</b-btn>
        <b-btn variant="success" v-if="superuser == true" @click="editItem(0)">Nuovo</b-btn>
      </b-col>
      <b-col lg="3">
        <b-form-input v-model="criteria" style="float: right" placeholder="Filtra..."/>
      </b-col>
    </b-row>
    <b-table no-border-collapse :sticky-header="(winHeight - 200) + 'px'" hover class="mt-3" :items="items" :fields="fields.companies" :busy="isBusy" :filter="criteria">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>Non ci sono risultati</template>
      <template v-slot:cell(id)="row">
        <nobr>
          <b-btn variant="primary" v-if="row.value > 0" size="sm" class="mr-2" @click="openItem(row.value)">Accedi</b-btn>
          <b-btn variant="warning" v-if="row.value > 0 && superuser == true" size="sm" class="mr-2" @click="editItem(row.value)">Modifica</b-btn>
        </nobr>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: "companies",
  data() {
    return {
      isBusy: true,
      fields: {
        companies: [{
          key: 'company',
          label: "Azienda",
          sortable: true,
        },{
          key: 'standards',
          label: 'Norme',
        },{
          key: 'id',
          label: '',
          sortable: true,
        }],
        template: [{
          key: 'val',
          label: 'Template',
          sortable: true,
        },{
          key: 'sql',
          label: 'Valore',
          sortable: true,
        },{
          key: 'id',
          label: "",
        }],
        credentialscompananies: [{
          key: 'fullname',
          label: 'Nome',
        },{
          key: 'mail',
          label: 'Username',
        },{
          key: 'psw',
          label: 'Password',
        }]
      },
      criteria: "",
      superuser: false,
      items: [],
      companyShow: false,
      data: null,
    }  
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.$store.commit('setRoutes', this.$store.state.routes.filter(value => -1 !== ["companies", "superusers", "templates"].indexOf(value)));
    this.loadData();
  },
  methods: {
    loadData(){
      this.isBusy = true
      this.companyShow = false;
      this.openHttp("companies_companies", {}).then((result) => {
        this.isBusy = false;
        if (result.token != null) this.$store.commit('setToken', result.token);
        this.items = result.companies;
        this.superuser = result.superuser;
      })
    },
    openItem(id) {
      this.openHttp("companies_set", { id: id }).then((result) => {
        this.$store.commit('setToken', result.token);
        this.$router.push("home");
      })
    },
    editItem(id) {
      this.openHttp("companies_company", { id: id }).then((result) => {
        result.company.standardsSupport = this.convertCombo(result.standards, result.company.standards);
        if (result.credentials.length > 0) result.company.credentialsSupport = this.convertCombo(result.credentials, result.company.credentials);
        this.companyShow = true;
        this.data = result;
      })
    },
    saveData(){
      this.saveHttp("companies_company_save", this.data.company, "Azienda salvata!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.companyShow = false;
      this.loadData();
    },
    setTemplate( templatesId ){
      this.saveHttp("companies_set_template", { companies_id: this.data.company.id, templates_id: templatesId }, "Template impostato!" ).then((result) => {
        if ( result.result == true ) this.editItem( this.data.company.id );
      })
    }
  }
}
</script>