<template>
  <div>
    <b-modal v-model="show" v-if="data != null" size="lg" title="Consegna dpi" scrollable @hidden="closeForm" @close="closeForm">
      <b-modal v-model="showUploadModal" title="Carica modulo compilato" :hide-footer="true">
        <b-form-file placeholder="Scegli un file o trascinalo qui..." drop-placeholder="Trascinalo qui..." @change="attachmentSave($event)"/>
      </b-modal>
      <b-row>
        <b-col lg="4">
          <b-form-group label="Data">
            <b-form-input type="date" v-model="data.ppedelivery.date"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Dipendente">
        <b-form-select v-model="data.ppedelivery.employees_id" :options="data.employees" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="DPI consegnati">
        <multiselect v-model="data.ppedelivery.ppetypesSupport" :options="data.ppetypes" placeholder="" selectLabel="" deselectLabel="" :multiple="true" track-by="id" label="val" @input="data.ppedelivery.ppetypes = updateCombo(data.ppedelivery.ppetypesSupport); checkDates()"/>
      </b-form-group>
      <b-row v-if="data.ppedelivery.ppetypesdates.length > 0">
        <b-col lg="4" v-for="(item, index) in data.ppedelivery.ppetypesdates" :key="index">
          <b-form-group :label="'DPI data produzione ' + data.ppetypes.find(x => x.id === item.id).val + ' (facoltativo)'">
            <b-form-input type="date" v-model="item.date"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Note">
        <b-form-textarea v-model="data.ppedelivery.note"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" class="mr-2" v-show="data.ppedelivery.id > 0" @click="deleteData">Elimina</b-btn>
          <b-btn variant="info" class="mr-2" v-show="data.ppedelivery.id > 0 && data.ppedelivery.file === false" @click="downloadDataBlank">Scarica modulo bianco</b-btn>
          <b-btn variant="primary" v-show="data.ppedelivery.id > 0 && data.ppedelivery.file === false" @click="showUploadModal = true">Carica modulo firmato</b-btn>
          <b-btn variant="info" class="mr-2" v-show="data.ppedelivery.id > 0 && data.ppedelivery.file === true" @click="downloadDataFilled">Scarica modulo compilato</b-btn>
          <b-btn variant="warning" v-show="data.ppedelivery.id > 0 && data.ppedelivery.file === true" @click="attachmentDelete">Elimina allegato</b-btn>
        </div>
      </template>
      <form ref="downloadFormBlank" method="POST" target="_blank" action="/api/file.php">
        <input type="hidden" name="form" value="ppe">
        <input type="hidden" name="document" value="ppedelivery_blank">
        <input type="hidden" name="id" :value="data.ppedelivery.id">
        <input type="hidden" name="token" :value="token">
      </form>
      <form ref="downloadFormFilled" method="POST" target="_blank" action="/api/file.php">
        <input type="hidden" name="form" value="ppe">
        <input type="hidden" name="document" value="ppedelivery_filled">
        <input type="hidden" name="id" :value="data.ppedelivery.id">
        <input type="hidden" name="token" :value="token">
      </form>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "ppedelivery",
  data() {
    return {
      showUploadModal: false,
      show: false,
      data: null
    }
  },
  computed: {
    token (){
      return this.$store.getters.getToken;
    }
  },
  methods: {
    checkDates(){
      let tempDates = [];
      for (let item of this.data.ppedelivery.ppetypes){
        if (this.data.ppetypes.findIndex(x => x.id === item && x.requiredate === true ) > -1){
          if (this.data.ppedelivery.ppetypesdates.findIndex(x => x.id === item) > -1 ){
            tempDates.push({id: item, date: this.data.ppedelivery.ppetypesdates.find(x => x.id === item).date });
          } else {
            tempDates.push({id: item, date: null});
          }
        }
      }
      this.data.ppedelivery.ppetypesdates = tempDates;
    },
    loadData( id, employees_id, ppetypes, date ){
      this.openHttp("ppe_delivery", { id: id, ppetypes: ppetypes, employees_id: employees_id, date: date }).then((result) => {
        result.ppedelivery.ppetypesSupport = this.convertCombo(result.ppetypes, result.ppedelivery.ppetypes);
        this.data = result;
        if (this.data.ppedelivery.id === 0) this.checkDates();
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("ppe_delivery_save", this.data.ppedelivery, "Consegna DPI salvata!").then((result) => {
        if ( result.result == true ){
          if ( this.data.ppedelivery.id === 0 ){
            this.loadData(result.id, null, [], null);
          } else {
            this.closeForm();
          }
        }
      })
    },
    deleteData(){
      this.deleteHttp("ppe_delivery_delete", { id: this.data.ppedelivery.id }, "Consegna DPI eliminata!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    downloadDataBlank(){
      this.$refs.downloadFormBlank.submit();
    },
    downloadDataFilled(){
      this.$refs.downloadFormFilled.submit();
    },
    attachmentSave(event){
      this.showUploadModal = false;
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = ()=> {
        this.saveHttp("ppe_delivery_attachment_save", { id: this.data.ppedelivery.id, filename: event.target.files[0].name, base64: reader.result }, "Allegato salvato!" ).then((result) => {
          if ( result.result == true ) this.loadData(this.data.ppedelivery.id, null, [], null);
        })
      };
    },
    attachmentDelete(){
      this.deleteHttp("ppe_delivery_attachment_delete", { id: this.data.ppedelivery.id }, "Allegato eliminato!" ).then((result) => {
        if ( result.result == true ) this.loadData(this.data.ppedelivery.id, null, [], null);
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('reload');
    }
  }
}
</script>